import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DataService } from './service/data.service';
import { CommonService } from './service/common.service';
import { ThemeService } from './service/theme.service';
import { NavigationEnd, NavigationStart, Router, NavigationError, ResolveEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErudaService } from './service/eruda.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RoService } from './service/ro.service';
import { AlertService } from './service/alert.service';
import { PrintService } from './sharedModule/printModule/PrintService';
import { TouchUtils } from './common/TouchUtils';
import { OupService } from './service/oup.service';
@Component({
	selector: 'app-root',
	template: `
    <router-outlet></router-outlet>
	<div class="router" [class.hidden-for-print]="printService.isPrinting">
		<router-outlet></router-outlet>
	</div>
	
	<div class="router"  [class.hidden]="!(printService.isPrinting && printService.mode == 'router')">
		<router-outlet name="print"></router-outlet>
	</div>
	
	<div id="printContainer" [class.hidden]="!(printService.isPrinting && printService.mode == 'container')"></div>

	<appStatus></appStatus>
	<div class="debugText" *ngIf="showDebugText" ngDraggable ngResizable [handle]="myHandle">
        <div class="header" #myHandle><span (click)="goToUrl()">console</span></div>
        <div class="content"><div *ngFor="let log of coms.logs;" class="{{log.type}}"><span class="time">{{log.time}}</span> - {{log.text}}</div></div>
        <div class="btns">
            <div class="btn" (mousedown)="showDebugText=false">×</div>
        </div>
    </div>

    <div *ngIf="showDebugText" style="position: absolute; left: 0px; top: 0px; z-index: 9999; font-size: 8px;">Browser Title: {{title.getTitle()}}</div>
	<p-toast position="top-center" key="tc"></p-toast>
    `,
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
	@HostListener('dblclick', []) onDblClick() { this.coms.log(this); }
	public environment: any = environment;
	public showDebugText: boolean = false;
	public debugClickCount: number = 0;
	public resizeSubject = new Subject();
	private customScale;
	private roReadySent = false;
	@HostListener('click', []) onClick() {
		this.debugClickCount++;
		if (this.debugClickCount > 12 && !environment.production) {
			this.debugClickCount = 0;
			if (document['documentMode']) { //if browser is ie, then true
				this.showDebugText = !this.showDebugText;
			} else {
				this.eruda.toggle();
			}
		}
		setTimeout(() => { if (this.debugClickCount > 0) this.debugClickCount--; }, 3000);
	}

	@HostListener('window:pageshow', ['$event']) pageshow($event){
		if ($event.persisted) {
			// window.location.reload();
		}
	}

	@HostListener('document:keydown', ['$event']) keydown($event){
		this.coms.keydownListeners.forEach(listener=>{
			if (listener.meet($event)){
				listener.callback($event);
			}
		})
	}

	constructor(
		public printService:PrintService,
		private meta: Meta, public title: Title, private datas: DataService, public coms: CommonService, private themeService: ThemeService, private router: Router, private trans: TranslateService, private eruda: ErudaService, private ros: RoService, private als: AlertService,
		private oups:OupService
	) {
		this.customScale = this.getQuery('scale');
		this.avoidEncodedUrl();
		this.registerWebOrApp();
		this.initFaviconFromHostname();
		this.setScale();
		window.onerror = (error, url, line) => {
			this.coms.log('ERROR ERROR: ' + error);
			this.coms.log('ERROR URL: ' + url);
			this.coms.log('ERROR LINE: ' + line);
		};
		this.initRouterEvent();
		this.resizeSubject.pipe(debounceTime(50)).subscribe(event=>{
			this.setScale();
		});
		this.disableDoubleTap();
	}

	ngOnInit(): void {
		this.checkLogin();
		this.checkLocalStorageUid();
		this.initLangAndAppId();
		this.initEula();
	}
	
	private disableDoubleTap():void
	{
		TouchUtils.suppressesDoubleTaps();
	}

	setScale() {
		setTimeout(() => {
			let height = window.screen.width < window.screen.height ? window.screen.width : window.screen.height;
			let scale = (height / 768).toFixed(2);
            let inRange = height / 768 < 1 || height / 768 > 1.5;
			scale = inRange? scale: '1';
			let dimMobile = window.screen.width / window.screen.height > 1.65 || window.screen.height / window.screen.width > 1.65;
			let excepted = ['iPad', 'ipad', 'Android', 'android'];
			const customScale = this.customScale;
			if (customScale) {
				this.meta.updateTag({
					name: 'viewport',
					content: 'width=device-width,target-densitydpi=device-dpi,user-scalable=0,initial-scale=' + customScale + ', minimum-scale=' + customScale + ',maximum-scale=' + customScale + ',user-scalable=no'
				});
			} else if (localStorage.getItem('in-web') == '1' || location.port != ''|| this.datas.isInWeb) {
				this.meta.updateTag({ name: 'viewport', content: 'width=device-width,target-densitydpi=device-dpi,user-scalable=0,initial-scale=' + scale + ', minimum-scale=' + scale + ',maximum-scale=' + scale + ',user-scalable=no' });
			} else if (dimMobile) {
				this.coms.log('dimMobile');
				this.meta.updateTag({
					name: 'viewport',
					content: 'width=device-width,target-densitydpi=device-dpi,user-scalable=0,initial-scale=0.5, minimum-scale=0.5,maximum-scale=0.5,user-scalable=no'
				});
			} else if (excepted.indexOf(this.datas.dev.info.device.toLowerCase()) == -1 && this.datas.dev.info.os.toLowerCase() != 'mac') { //ignore iPad Pro
				this.coms.log('non-iPad, non-Android, scale=' + scale);
				this.meta.updateTag({
					name: 'viewport',
					content: 'width=device-width,target-densitydpi=device-dpi,user-scalable=0,initial-scale=' + scale + ', minimum-scale=' + scale + ',maximum-scale=' + scale + ',user-scalable=no'
				});
			}
		}, 10);
	}

	calScaleByHeight(refHeight: number) {
		/*
		android 平板用 768計
		iOS 平板用	web scale
		手機用 615計
  
		height	(768)			(615)			(665)
		768		1		768		1.25	614		1.25	614
		1536	2		768		2.5		614		2.5		614
		2048	2.75	744		3.5		585		3.25	630
		1668	2.25	741		2.75	606		2.75	606
		1620	2.25	720		2.75	589		2.5		648
		640		1		640		1.25	512		1		640
		750		1		750		1.25	600		1.25	600
		828		1.25	662		1.5		552		1.25	662		iPhone 11
		1080	1.5		720		2		540		1.75	617
		1125	1.5		750		2		562		1.75	643		iPhone X
		1242	1.75	709		2.25	540		2		621
		*/
		// full screen size
		var h: number = Math.round(Math.min(window.innerWidth, window.innerHeight) * window.devicePixelRatio);
		//var ary:any = [768,1536,2048,1668,1620,640,750,828,1080,1125,1242, screenheight];

		for (var _s: number = 350; _s > 0; _s -= 25) {
			if (h * 100 / _s > refHeight) {
				_s = (_s + 25) / 100;
				break;
			}
		}

		return _s;
	}

	registerWebOrApp() {
		let body = document.body;
		let appDomains = ['ro2.azurewebsites.net', 'api.openknowledge.hk'];
		if (appDomains.indexOf(location.hostname) == -1 || location.port != '') {
			sessionStorage.setItem('in-web', '1');//不取消以免其他project未轉用localStorage
			localStorage.setItem('in-web', '1');
			body.classList.add('in-web');
			this.datas.isInWeb = true;
		} else {
			sessionStorage.setItem('in-app', '1');//不取消以免其他project未轉用localStorage
			localStorage.setItem('in-web', '0');
			body.classList.add('in-app');
			this.datas.isInWeb = false;
			// this.sendRoReady();
		}
		if (!localStorage){
			const msg = {tc: '系統沒有權限使用localStorage或會令到系統出現問題。建議允許使用.',sc: '', en:'LocalStorage is blocked. Please enable it to avoid some system issue'}[this.datas.lang];
			this.als.toastError(msg);	
		}
		let roWebPath = location.href.substr(0, location.href.indexOf('/', location.href.indexOf('roWeb')) + 1);
		if (location.port !=""){
			roWebPath = '//dev.openknowledge.hk/RainbowOne/webapp/2.8/roWeb/';
		}
		sessionStorage.setItem('roweb-path', roWebPath);
	}

	checkLogin() { //extend jwt expiry, and kick if expired
		
		if (this.datas.userInfo) {
			this.datas.post('ROHomeScreen.checkLogin').subscribe();
		}
		setTimeout(() => { this.checkLogin(); }, 3 * 60 * 1000);
	}

	checkLocalStorageUid(){
		if (this.datas.userInfo) {
			const localStorageUid = localStorage.getItem('common_uid');
			const isInAlert = !!document.querySelector('.alert-dialog');
			const isInLoginPage = location.href.indexOf('/login/') > -1;
			if (!isInAlert && !isInLoginPage && localStorageUid && localStorageUid != this.datas.userInfo.uid){
				const msg = {tc:'系統逾時，請重新登入',sc:'系统逾时，请重新登入',en:'System timeout. Please login again'}[this.datas.lang];
				this.als.alert(msg).then(()=>{
					localStorage.removeItem('common_jwt');
					localStorage.removeItem('common_uid');
					localStorage.removeItem('common_schoolId');
					localStorage.removeItem('common_schoolLogo');
					localStorage.removeItem('common_photo');
					sessionStorage.removeItem('common_photo_done');
					sessionStorage.removeItem('common_schoolLogo_done');
					localStorage.removeItem('homeScreen_data');
					this.router.navigate(['login']);
				});
			}
		}
		setTimeout(() => { this.checkLocalStorageUid(); }, 3 * 1000);
	}

	getQuery(q) {
		return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
	}

	sendRoReady(){
		if (this.roReadySent == false){
			location.hash = 'ro/ready';
			this.ros.request('langSwitch', {lang: this.datas.lang}).then(()=>{
				this.roReadySent = true;
			}).catch();
			setTimeout(()=>{ this.sendRoReady();}, 100);
		}
	}


	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.resizeSubject.next(event);
	}

	//Because some android browser can't see the hash change, change the title will do so.
	@HostListener('window:hashchange', ['$event'])
	onhashchange(event) {
		if (localStorage.getItem('in-web') == '0' || !this.datas.isInWeb) {
			let hash = decodeURI(window.location.hash);
			this.title.setTitle(environment.title + '[' + hash + ']');
		}
	}

	public goToUrl(): void {
		if (environment.production) return;
		let url = prompt("Please input the URL:");
		if (url != null) window.location.href = url;
	}

	initRouterEvent() {
		this.router.events.subscribe(e => {
			if (e instanceof NavigationStart) {
				this.navigationStart(e);
			}
			if (e instanceof NavigationEnd) {
				this.navigationEnd(e);
			}
			if (e instanceof NavigationError){
				// if (e.error && e.error.name == 'ChunkLoadError'){
				// 	let reloadUrl = sessionStorage.getItem('roweb-path');
				// 	reloadUrl = reloadUrl.substr(0, reloadUrl.length - 1) + e.url;
				// 	location.href = reloadUrl;
				// }
			}
		});
	}

	navigationStart(event: any) {
		let arr = event.url.split('/');
		if (arr.length >= 4) {
			let langs = ['tc', 'sc', 'en'];
			arr.forEach((e, i) => {
				if (langs.indexOf(e) > -1) {
					this.trans.use(e);
					this.datas.setLang(e);
					let appId = arr[i + 1];
					appId = appId.toLowerCase();
					appId = appId.replace(/\#.*/, '').replace(/%.*/, '');
					this.datas.appId = appId;
					this.datas.appId = this.datas.appId2;
				}
			});
		}
		if (this.datas) {
			if (this.datas.userInfo) {
				if (this.datas.userInfo.personalSettings) {
					if (typeof this.datas.userInfo.personalSettings == 'string') {
						this.datas.userInfo.personalSettings = JSON.parse(this.datas.userInfo.personalSettings);
					}
				}
			}
		}
	}

	navigationEnd(event) {
		this.coms.urlHistories.push(event);
	}

	initFaviconFromHostname(){
		const hostname = location.hostname;
		let favEl = <HTMLLinkElement>document.querySelector("link[type='image/x-icon']");
		let defaultFavicon = '/favicon.ico';
		if (hostname.indexOf('readingstar.net') > -1){
			defaultFavicon = '//readingstar.net/RainbowOne/webapp/2.8/roWeb/assets/img/reading_star_favicon.png';
			this.title.setTitle('ReadingStar');
		} else if (hostname.indexOf('rainbowse.fun') > -1){
			this.datas.appId = 'rainbowse';
			defaultFavicon = '//rainbowse.fun/RainbowOne/webapp/2.8/roWeb/assets/img/rainbowse_favicon.png';
			this.title.setTitle('天虹智能化評估系統');
		} else if (hostname.indexOf('aioral.rainbowone.hk') > -1 || hostname.indexOf('myaibuddy.com.hk')>-1){
			defaultFavicon = '//oka.blob.core.windows.net/media/roWebAssets/course/myaibuddy_favicon.png';
			this.title.setTitle('MyAIBuddy');
		}
		favEl.href = defaultFavicon;
	}
	//-------------------------------------------------------------------------------------------------
	avoidEncodedUrl(){
		let decoded = decodeURIComponent(location.href);
		if (location.href != decoded){
			location.href = decoded;
		}
	}

	initLangAndAppId(){
		this.trans.addLangs(['tc', 'sc', 'en']);
		if (location.host == 'aioral.rainbowone.hk' || location.host.indexOf('myaibuddy.com.hk') > -1){
			this.datas.setLang('en');
			this.datas.appId = 'smartchatai';
			this.trans.use('en');
		} else {
			this.trans.use(this.datas.getLang('tc'));
		}
	}

	initEula() {
		this.oups.isOupUser$.subscribe((isOupUser:boolean)=>{
			if (isOupUser) {
				this.datas.post2({ data: { api: 'Account.getUpdateSetting', json: [] }}).then((res:any)=>{
					try {
						let ps:any = JSON.parse(res.account.personalSettings);
						if (!ps.oupEulaVer || ps.oupEulaVer<this.datas.oupEulaVer) {
							
							// 在未route之前，而又已知route path的時候navigate
							// let subscription:Subscription = this.router.events.subscribe((event:any)=>{
							// 	if (event instanceof ResolveEnd) {
							// 		this.datas.oupEulaRouteTemp = event.url;
							// 		this.router.navigate(['eula']);
							// 		subscription.unsubscribe();
							// 	}
							// });
							this.datas.oupEulaRouteTemp = this.router.url;
							this.router.navigate(['eula']);
						}
					} catch(e){}
				});
			}
		});



		// let lastUid:number = null;
		// this.datas.userInfo$.subscribe((userInfo:any)=>{
		// 	if (userInfo && userInfo.uid && userInfo.uid != lastUid) {
		// 		lastUid = userInfo.uid;

		// 		if (this.oups.isOupUser) {
		// 			let userOupEulaVer:any = this.datas.getPersonalSetting('oupEulaVer');
		// 			console.log("userOupEulaVer: " + userOupEulaVer);
		// 			console.log("this.datas.oupEulaVer: " + this.datas.oupEulaVer);

		// 			if (!userOupEulaVer || userOupEulaVer<this.datas.oupEulaVer) {
		// 				//在未route之前，而又已知route path的時候navigate
		// 				let subscription:Subscription = this.router.events.subscribe((event:any)=>{
		// 					if (event instanceof ResolveEnd) {
		// 						this.datas.oupEulaRouteTemp = event.url;
		// 						this.router.navigate(['eula']);
		// 						subscription.unsubscribe();
		// 					}
		// 				});

						
		// 			}
		// 		}
		// 	}
		// });
	}

}
