import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DataService} from 'src/app/service/data.service';
import {LoadingService} from 'src/app/sharedModule/loadingModule/loading.service';
import {AlertService} from 'src/app/service/alert.service';
import {faCheckCircle} from '@fortawesome/pro-duotone-svg-icons';
import {faCheckCircle as faCheckCircleLight} from '@fortawesome/pro-light-svg-icons';
import {ThemeService} from 'src/app/service/theme.service';
import {InlineSvgService} from 'src/app/sharedModule/inlineSvgModule/inlineSvg.service';
import {WebLessonService} from "../../services/WebLesson.service";
import {CommonService} from "../../../../../service/common.service";
import {TranslateService} from '@ngx-translate/core';
import {OupService} from "../../../../../service/oup.service";

@Component({
	selector: 'web-lesson-select-book-modal',
	template: `
		
		<modal2
			#modal2
			[width]="modalWidth" [title]="'course.pls-select-content'|translate"
			[minWidth]="modalWidth" [headerStyle]="headerStyle"
			[contentStyle]="contentStyle"
			(confirmClick)="addClick($event)"
			(cancelClick)="cancelClick($event)"
		>
			<div class="modal-body" [style.minWidth]="modalWidth" #body>
				<popup-toggle [(toggle)]="toggle" bindLabel="label" bindValue="value" [items]="toggles"></popup-toggle>
				<div class="main" #main>
					<section *ngFor="let col of columns;let i=index" [attr.level]="i" [style.width.px]="columnWidth">
						<div class="column-header">
							<a class="field">
								<span>{{ 'course.item'|translate }}</span>
							</a>
						</div>
						<div class="column-wrapper" #ps [ngClass]="(datas.dev.isMobile?'mobile':'')">
							<ng-container *ngIf="col.books || col.children">
								<div [ngClass]="['item collection', (columns.indexOf(item) > - 1?'selected':'')]" *ngFor="let item of col.children" (click)="folderClick(col, item)">
									<i class="collection section-{{toggle}}">
										<svg-icon [name]="item.icon"></svg-icon>
									</i>
									<div class="item__name">{{ item.title }}</div>
									<a class="item__select" (click)="itemClick($event, item)">
										<fa-icon [ngClass]="(selectedItems.indexOf(item) > -1?'checked':'')" [icon]="(selectedItems.indexOf(item) > -1?faCheckCircle:faCheckCircleLight)"></fa-icon>
									</a>
								</div>
								<div class="item book entry-{{item.type}}" *ngFor="let item of col.books" (click)="itemClick($event, item)">
									<i [ngClass]="[item.type]">
										<svg-icon [name]="item.icon"></svg-icon>
									</i>
									<div class="item__name">{{ item.title }}</div>
									<a class="item__select" (click)="itemClick($event, item)">
										<fa-icon [ngClass]="(selectedItems.indexOf(item) > -1?'checked':'')" [icon]="(selectedItems.indexOf(item) > -1?faCheckCircle:faCheckCircleLight)"></fa-icon>
									</a>
								</div>
							</ng-container>
							<ng-container *ngIf="col.shares">
								<div class="item share entry-{{item.type}}" *ngFor="let item of col.shares" (click)="itemClick($event, item)">
									<i [ngClass]="[item.type]">
										<svg-icon [name]="item.icon"></svg-icon>
									</i>
									<div class="item__name">{{ item.title }}</div>
									<a class="item__select" (click)="itemClick($event, item)">
										<fa-icon [ngClass]="(selectedItems.indexOf(item) > -1?'checked':'')" [icon]="(selectedItems.indexOf(item) > -1?faCheckCircle:faCheckCircleLight)"></fa-icon>
									</a>
								</div>
							</ng-container>
						</div>
					</section>
				</div>
			</div>
		</modal2>
	`
	, styleUrls: ['./web-lesson-select-book.modal.scss'],
})
export class WebLessonSelectBookModalComponent implements OnInit {
	@Input() data: any;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public modalStyle;
	public headerColor;
	public root: any = {};
	private firstColPos = 0;
	public columnWidth;
	public modalWidth;
	@Input() fullWidth = false;
	public maxColumn: number = 0;
	public columns: any[] = [];
	@ViewChild('main', {static: false}) main;
	@ViewChild('modal2', {static: false}) modal2;
	// private moveFirstColumn$;
	public selectedItems: any = [];
	public toggle = 'public';
	public toggles: any = [
		{
			label: 'web-lesson.workspace', value: 'public', onEnter: () => {
				this.getData('public');
			}
		},
		{
			label: 'web-lesson.auth', value: 'auth', onEnter: () => {
				this.getData('auth');
			}
		},
		{
			label: 'web-lesson.bookshelf', value: 'bookshelf', onEnter: () => {
				this.getData('bookshelf');
			}
		},
	];
	public headerStyle;
	public contentStyle;

	public entitlement_map = {};
	public selected_entitlement = null;

	constructor(
		public datas: DataService,
		public load: LoadingService,
		private als: AlertService,
		private theme: ThemeService,
		private eleRef: ElementRef,
		private iconReg: InlineSvgService,
		public web_lesson: WebLessonService,
		public coms: CommonService,
		public trans: TranslateService,
		public oup: OupService,
	) {
		this.trans.use(this.datas.lang);
		this.initModal();
		this.iconReg.regSvgFiles([
			{url: 'icon_folder.svg', name: 'icon_folder'},
			{url: 'course/icon_folder_blue.svg', name: 'icon_folder_blue'},
			{url: 'icon_folder_purple.svg', name: 'icon_folder_purple'},
			{url: 'icon_book.svg', name: 'icon_book'},
			{url: 'icon_book_purple.svg', name: 'icon_book_purple'},
			{url: 'fileicon/fileicon2_qb.svg', name: 'fileicon2_qb'},
			{url: 'fileicon/fileicon2_xls.svg', name: 'fileicon2_xls'},
			{url: 'fileicon/fileicon2_doc.svg', name: 'fileicon2_doc'},
			{url: 'fileicon/fileicon2_pdf.svg', name: 'fileicon2_pdf'},
			{url: 'fileicon/fileicon2_ppt.svg', name: 'fileicon2_ppt'},
			{url: 'fileicon/fileicon2_image.svg', name: 'fileicon2_image'},
			{url: 'fileicon/fileicon2_others.svg', name: 'fileicon2_others'},
			{url: 'fileicon/fileicon2_audio.svg', name: 'fileicon2_audio'},
			{url: 'fileicon/fileicon2_video.svg', name: 'fileicon2_video'}
		]);
	}

	ngOnInit() {
		this.theme.applyStyleObj({
			"default": {
				"remainer-bgcolor": "#F1F1F7",
				"popupform-rowselectedbgcolor": "#dbdbdb",
				"popupframe-okbuttonwidth": "80px",
				"popupframe-okbuttonheight": "34px",
				"popupframe-okbuttontextsize": "17px",
				"popupframe-okbuttontextweight": "bold",
				"popupframe-okbuttontextcolor": "#FFFFFF",
				"popupframe-okbuttoninsidemargin": "10px",
				"popupframe-cancelbuttonwidth": "80px",
				"popupframe-cancelbuttonheight": "54px",
				"popupframe-cancelbuttontextweight": "bold",
				"cancelbuttontextcolor": "#999",
				"first-book-row": "#f0f0f0",
				"selected-book-row": "#e6e6e6",
				"popupform-rowbordercolor": "#ccc"
			},
			"dark": {
				"remainer-bgcolor": "#1E1E29",
				"popupform-rowselectedbgcolor": "#2d2c43",
				"first-book-row": "2d2c43",
				"selected-book-row": "#2d2c43",
				"popupform-rowbordercolor": "#53536a",
				"section-popupbgcolor": "#2d2c43",
			}
		}, this.eleRef.nativeElement);
		this.getData(this.toggle);
		this.initWidth();

	}

	ngAfterViewInit() {
		// setTimeout(() => {
		// 	this.modal2.open();
		// }, 10);
	}

	// getData(toggle){
	// 	this.toggle = toggle;
	// 	this.columns = [];
	// 	this.datas.post('ROCourse.list_all_entries', [this.datas.userInfo.school_id]).subscribe((res: any) => {
	// 		this.load.remove('load-entries');
	// 		if (res.code == 0) {
	// 			this.initRoot(res.root, res.entries);
	// 			this.columns[0] = this.root;
	// 			this.initWidth();
	// 		}
	// 	}, (err) => {
	// 		this.data.close();
	// 		this.load.remove('load-entries');
	// 	});
	// }

	async getData(toggle) {
		this.toggle = toggle;
		this.columns = [];

		if (['public', 'auth',].includes(toggle)) {
			if (this.oup.isOupUser && toggle === 'auth') {
				this.entitlement_map = {};
				let codes = this.oup.entitlement_result.map(entitlement => `${entitlement.code}`);
				let response = await this.datas.call('ROOUP.list_activity_pack_with_code', {code_list: codes});
				this.add_column(response.rows);
			} else {
				let response = await this.datas.call('ROBook.getExplorerList', toggle);
				this.add_column(response.result);
			}

		} else if (toggle === 'bookshelf') {
			let response = await this.datas.call('ROBookShare.list_book_share_for_lesson', this.web_lesson.lesson_id);
			console.log(response);
			let result = response.shares;
			// sort by updated_at asc
			result.sort((a, b) => {
				return a.updated_at.localeCompare(b.updated_at);
			});
			result = result.filter((share) => {
				return share.books && share.books.length;
			}).map(share => {
				let entry = share.books[0];
				let entry_type = entry.type;
				let share_type_trans_key = 'web-lesson.bookshelf_share_type';
				if (share.type) {
					share_type_trans_key += '_' + share.type.toLowerCase();
				}
				let share_type = this.trans.instant(share_type_trans_key);
				let title = "(" + share_type + ")" + entry.title;
				let item = {
					title,
					entry,
					share,
					type: entry_type,
				}

				this.setIcon(item);
				return item;
			});

			console.log(result);

			this.add_column(result);

		}
		// debugger;
	}

	sort_column_list(list) {
		list.sort((a, b) => {
			if (a.type > b.type) {
				return -1;
			} else if (a.type < b.type) {
				return 1;
			}
			return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
		})
	}

	add_column(list) {
		if (['public', 'auth'].includes(this.toggle)) {
			if (this.oup.isOupUser && this.toggle === 'auth') {
				let is_first_column = this.columns.length === 0;
				if (is_first_column) {
					list = list.map(entitlement => {
						return {
							id: entitlement.id,
							title: entitlement.title,
							type: 'collection',
							permission: 25,
						}
					});
					this.sort_column_list(list);
					let children = list.filter(collection => collection.type == 'collection');
					children.forEach(collection => {
						this.setIcon(collection);
					});
					let books = [];
					this.columns.push({
						children: children,
						books: books,
						level: this.columns.length,
					})
				} else {
					console.log({list});
					let type_map = {
						'folder': 'collection',
						'finalized_entry': 'book',
					}
					list = list
						.filter(entry => {
							return entry.type == 'folder' || entry.type == 'finalized_entry';
						})
						.map(entry => {
							let id = entry.id;
							if (entry.type == 'finalized_entry') {
								id = entry.entry_id;
							}
							return {
								id: id,
								title: entry.title,
								type: type_map[entry.type],
							}
						});
					let children = list.filter(collection => collection.type == 'collection');
					children.forEach(collection => {
						this.setIcon(collection);
					});
					let books = list.filter(book => book.type == 'book');
					books.forEach(book => {
						this.setIcon(book);
					});
					this.columns.push({
						children: children,
						books: books,
						level: this.columns.length,
					})
				}
			} else {
				list = list.filter(entry => {
					return entry && entry.id !== 'trash' && (!entry.permission || entry.permission == 25 || entry.permission == 9 || entry.permission == 3);
				});
				this.sort_column_list(list);
				let children = list.filter(collection => collection.type == 'collection');
				children.forEach(collection => {
					this.setIcon(collection);
				});
				let books = list.filter(book => book.type == 'book');
				books.forEach(book => {
					this.setIcon(book);
				});
				this.columns.push({
					children: children,
					books: books,
					level: this.columns.length,
				})
			}
		} else if (this.toggle === 'bookshelf') {
			this.sort_column_list(list);
			this.columns.push({
				shares: list,
				level: this.columns.length,
			})
		}
	}


	protected initModal() {
		// this.modalStyle = {
		// 	width: '896px',
		// 	height: '636px',
		// };
		this.contentStyle = {padding: '0', height: 'calc(100dvh - 80px)', minWidth: '896px'};
		this.headerStyle = {borderBottom: '0'};
	}

	initWidth() {
		this.columnWidth = 284;
		if (this.maxColumn >= 3 || true) {
			this.modalWidth = '896px';
		} else if (this.maxColumn == 2) {
			this.modalWidth = (284 * 2) + 'px';
		} else {
			this.columnWidth = 896;
			this.modalWidth = '896px';
		}
		if (this.fullWidth) {
			this.modalWidth = 'calc(100% - 20px)';
			return;
		}
	}

	async folderClick(col, item) {
		console.log({col, item});
		this.columns = this.columns.filter(c => c.level <= col.level);
		if (this.oup.isOupUser && this.toggle === 'auth') {
			let is_click_first_column = col.level === 0;
			let pid = item.id;
			if (is_click_first_column) {
				this.selected_entitlement = item;
				this.entitlement_map = {};
				pid = "0";
				let response = await this.datas.call('ROOUP.list_pack_content', {pack_id: item.id});
				console.log(response);
				let all_content = response.activity_pack;
				for (let content of all_content) {
					if (content.type === 'folder') {
						this.entitlement_map[content.id] = [];
					}
					if (!this.entitlement_map[content.pid]) {
						this.entitlement_map[content.pid] = [];
					}
					this.entitlement_map[content.pid].push(content);
				}
			}
			this.add_column(this.entitlement_map[pid]);
		} else {
			// remove the columns after col
			let response = await this.datas.call('ROBook.fetch', item.id);
			console.log(response);
			this.add_column(response.result);
		}
		await this.coms.timeout(0);
		this.eleRef.nativeElement.querySelector('section:last-child').scrollIntoView({behavior: 'smooth'});
	}

	itemClick($event, item) {
		$event.stopPropagation();
		if (item.type == 'pdf-paper') {
			return;
		}
		if (this.data.singleOnly && this.selectedItems.length > 0) {
			this.selectedItems = [];
		}
		if (this.selectedItems.length > 0 && item.type == 'collection') {
			if (this.selectedItems[0].level != item.level) {
				this.als.alert2('course.select-same-column-item');
				return;
			}
		}
		let index = this.selectedItems.indexOf(item);
		if (index == -1) {
			this.selectedItems.push(item);
		} else {
			this.selectedItems.splice(index, 1);
		}
	}

	async addClick($event = null) {
		if ($event) {
			$event.stopPropagation();
		}
		if (this.selectedItems.length == 0) {
			this.als.alert2('course.no-book-added');
			return;
		} else {
			if (this.oup.isOupUser && this.toggle === 'auth') {
				let book = await this.web_lesson.get_book_entry(this.selectedItems[0].id, true);
				book.published = 0;
				this.selectedItems = [book];
			}
			this.data.close(this.selectedItems);
		}
	}

	cancelClick($event) {
		$event.stopPropagation();
		this.data.close();
	}

	setIcon(item) {
		if (item.type == 'collection') {
			item.icon = this.toggle == 'public' ? 'icon_folder_blue' : 'icon_folder_purple';
		} else if (item.type == 'book') {
			item.icon = this.toggle == 'public' ? 'icon_book' : 'icon_book_purple';
		} else if (item.type == 'assessment') {
			item.icon = 'fileicon2_qb';
		} else if (item.type == 'excel') {
			item.icon = 'fileicon2_xls';
		} else if (item.type == 'word') {
			item.icon = 'fileicon2_doc';
		} else if (item.type == 'pdf') {
			item.icon = 'fileicon2_pdf';
		} else if (item.type == 'ppt') {
			item.icon = 'fileicon2_ppt';
		} else if (item.type == 'image') {
			item.icon = 'fileicon2_image';
		} else if (item.type == 'file') {
			item.icon = 'fileicon2_others';
		} else if (item.type == 'audio') {
			item.icon = 'fileicon2_audio';
		} else if (item.type == 'video') {
			item.icon = 'fileicon2_video';
		} else if (item.type == 'others') {
			item.icon = 'fileicon2_others';
		} else if (item.type == 'pdf-paper') {
			item.icon = 'fileicon2_testpaper';
		} else {
			item.icon = 'icon_folder_blue'
		}
	}

	open() {
		this.selectedItems = [];
		return this.modal2.open();
	}

	close() {
		return this.modal2.close();
	}
}
