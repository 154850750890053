import { Injectable, EmbeddedViewRef, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef, Type, ViewContainerRef, ComponentFactory, ViewRef, ElementRef } from '@angular/core';
///////////////////////////////////////////////////////////////////////////////////////////////////
@Injectable({ providedIn: 'root' })
///////////////////////////////////////////////////////////////////////////////////////////////////
export class DynamicComponentService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {
    }
//-------------------------------------------------------------------------------------------------
	public createComponentRef<T>(T:Type<T>, para:any = {}):ComponentRef<T> {
        const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(T);
        let compRef:ComponentRef<T> = factory.create(this.injector);
        let instance:T = compRef.instance;
        var inputMap:any = {}
        factory.inputs.forEach((input)=>{
            inputMap[input.propName] = input;
        });
        for (let key in para) if (inputMap.hasOwnProperty(key)) instance[key] = para[key];
		// assign the viewMode to the component instance
		if (para.parent && para.parent.context && para.parent.context.config && para.parent.context.config.viewMode) {
			const viewMode = para.parent.context.config.viewMode
			instance["viewMode"] = viewMode
		}
		return compRef;
	}
   
    public create<T>(T:Type<T>, para:any = {}):T {
        const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(T);
        let compRef:ComponentRef<T> = factory.create(this.injector);
        let comp:T = compRef.instance;
        comp['compRef'] = compRef;
        var inputMap:any = {}
        factory.inputs.forEach((input)=>{
            inputMap[input.propName] = input;
        });
        for (let key in para) if (inputMap.hasOwnProperty(key)) comp[key] = para[key];
		return comp;	
    }
//-------------------------------------------------------------------------------------------------
    public open<T>(comp:T, appendEle:HTMLElement = document.body):void {
        if (!comp['compRef'] || comp['compRef'].hostView['_appRef']) return;
        this.appRef.attachView(comp['compRef'].hostView);
        appendEle.appendChild((comp['compRef'].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
    }

	public appendVFToDOM(hostView:ViewRef, dom:HTMLElement):void
	{
		this.appRef.attachView(hostView);
        dom.appendChild((hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
	}

    public appendVFToEF(hostView:ViewRef, elementRef:ElementRef):void
    {
        this.appRef.attachView(hostView);
        elementRef.nativeElement.appendChild((hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
    }

	public appendVFToEF2(viewContainerRef:ViewContainerRef, hostView:ViewRef, elementRef:ElementRef):void
    {
		// this.appRef.attachView(hostView);
        elementRef.nativeElement.appendChild((hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
		viewContainerRef.insert(hostView)
        setTimeout(()=>{
			elementRef.nativeElement.appendChild((hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
		}, 100)
    }
//-------------------------------------------------------------------------------------------------
	public destroy<T>(comp:T):void {
        if (!comp['compRef'] || !comp['compRef'].hostView['_appRef']) return;
		comp["compRef"].destroy();
    }
    public close<T>(comp:T):void {
        if (!comp['compRef'] || !comp['compRef'].hostView['_appRef']) return;
		var hostView:ViewRef = comp['compRef'].hostView;
		// hostView.detach();
        this.appRef.detachView(comp['compRef'].hostView);
    }
///////////////////////////////////////////////////////////////////////////////////////////////////
}