import {NgModule} from '@angular/core';
import {DashLineComponent} from "./dash-line.component";
import {CommonModule} from "@angular/common";

@NgModule({
	imports: [
		CommonModule
	],
	exports: [DashLineComponent],
	declarations: [DashLineComponent],
	providers: [],
})
export class DashLineModule {
}
