import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { CommonService } from 'src/app/service/common.service';
import Quill from 'quill'

@Component({
	selector: 'customQuillEditor',
	template: `
	    <quill-editor #quillEditor class="customQuillEditor"
			placeholder=""
			[modules]="{toolbar: false}" 
			[(ngModel)]="content"
			(onSelectionChanged)="quillSelectionChanged($event)"
      		(onEditorCreated)="onEditorCreated($event)" 
			(ngModelChange)="onContentChange($event)"
			(onFocus)="onFocus($event)"
            (onBlur)="onBlur($event)"
		></quill-editor>`,
	styleUrls: ['./customQuillEditor.component.scss'],
})

export class CustomQuillEditorComponent {
	@Input() content: string;
	@Input() editorId: string;
	@Output() editorCreated = new EventEmitter<any>();
	@Output() contentChange = new EventEmitter<string>();

	public quill:any = null;
	public isBold: boolean = false
	public isItalic: boolean = false
	public isUnderline: boolean = false
	public isFocus:boolean = false
	public textColor:string | null = null
	public backgroundColor:string | null = null
	public selection = null
	public isBlur:boolean = false

	constructor(public coms:CommonService) {
		this.resetToDefaultBlock()
	}
	// remove the reigster from new edit
	resetToDefaultBlock() {
		const parchment = Quill.import('parchment');
		
		// Use the default block format (which is <p>)
		const DefaultBlock = parchment.query('block');
	
		// Optionally, ensure it uses <p> as the tag
		DefaultBlock.tagName = 'P';
	
		// Register the default block format again
		Quill.register(DefaultBlock, true);
	}

	onEditorCreated(quill: any) {
		this.quill = quill
		this.editorCreated.emit({ quill, editorId : this.editorId });
	}

	onContentChange(content: string) {
		this.contentChange.emit(content);
	}

	onFocus(event) {
		this.coms.log('quillFocus');
		this.isFocus = true
		this.isBlur = false
	}

	onBlur(event) {
		this.coms.log('quillBlur');
		this.isBlur = true
		// prevent the blue issue in safari
		if (event && event.editor.selection.savedRange.length >= 1) {
			this.selection = event.editor.selection.savedRange
			this.quill.setSelection(event.editor.selection.savedRange)
		} else {
			this.quill.setSelection(this.selection)
		}
	}

	public getSelection() {
		if (this.quill) return this.quill.getSelection()

		return { length: 0 }
	}

	public getTextColor() {
		return this.quill.getFormat()
	}

	// detect if the current text is formatted
	public quillSelectionChanged(data:any, force:boolean = false):void {
		if (this.quill) {
			// prevent the blue issue in safari
			// if (this.isBlur && data && data.oldRange && (!data.range || data.range.length == 0) ) {
			// 	this.isBlur = false
			// 	this.quill.setSelection(data.oldRange)
			// }

			if (force || (data && data.range)) {
				this.isBold = this.quill.getFormat().bold || false;
				this.isItalic = this.quill.getFormat().italic || false;
				this.isUnderline = this.quill.getFormat().underline || false;
				this.textColor = this.quill.getFormat().color || null;
				this.backgroundColor = this.quill.getFormat().background || null;
			}
		}
    }
	
	// Method to format text (for question bank)	
	public formatText(style: string, color = "", type: string = null) {
		const quill = this.quill;
		if (!quill) return;
	
		let selection = quill.getSelection();
		if (!selection || selection.length == 0) selection = this.selection;
		
		// Get the selected text and full text without trimming
		const text = quill.getText(selection.index, selection.length);
		const fullText = quill.getText();
		
		// Apply format to the entire selection first
		if (type === "QBFillingBlank" || type === "QBToggleOptions") {
			const currentFormat = quill.getFormat(selection.index, selection.length);
			const shouldRemoveFormat = currentFormat[style];
			
			// Apply or remove format
			quill.formatText(selection.index, selection.length, {
				[style]: shouldRemoveFormat ? false : (color || true)
			}, 'user');
	
			// Then remove formatting from brackets and their content
			let inBracket = false;
			let bracketStart = -1;
	
			for (let i = 0; i < fullText.length; i++) {
				if (fullText[i] === '[') {
					inBracket = true;
					bracketStart = i;
				} else if (fullText[i] === ']' && inBracket) {
					inBracket = false;
					// Remove formatting from the bracket content if it's in the selection range
					if (i >= selection.index && bracketStart <= (selection.index + selection.length)) {
						quill.formatText(bracketStart, i - bracketStart + 1, {
							'bold': false,
							'italic': false,
							'underline': false,
							'color': null,
							'background': null
						}, 'user');
					}
				}
			}
		} else {
			// Handle non-QB formatting
			switch (style) {
				case "bold":
				case "italic":
				case "underline":
					quill.formatText(selection.index, selection.length, {
						[style]: !quill.getFormat()[style]
					}, 'user');
					break;
				case "color":
				case "background":
					quill.formatText(selection.index, selection.length, {
						[style]: color
					}, 'user');
					break;
			}
		}
	
		this.quillSelectionChanged(null, true);
	}
}
