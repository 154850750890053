import {
	Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { DragManager } from '../sharedModule/roBookModule/DragManager';
import { Subject, Subscription } from 'rxjs';
import { DOMHelper } from '../common/DOMHelper';

@Directive({
	selector: '[drag-to-move]'
})
export class DragToMoveDirective implements OnChanges {
	public dom:any;
	private dd:DragManager;
	
	@Input() xAlign:string = 'left';
	@Input() yAlign:string = 'top';
	@Input() x:number = 0;
	@Input() y:number = 0;
	@Output() positionChange:EventEmitter<any> = new EventEmitter<any>();
	@Input() step: number = null;
	@Input() emitOnResize: boolean = false;
	@Input() stopPropagation: boolean = false;

	@Input()
	get handle(): any { return this._handle; }
	set handle(value: any) { this._handle = value; }
	_handle = null;
	
	@Input()
	get bounds(): any { return this._bounds; }
	set bounds(value: any) { this._bounds = value; }
	_bounds = null;
	
	@Input()
	get inBounds():boolean { return this._inBounds; }
	set inBounds(value:boolean) { this._inBounds = value; }
	_inBounds = false;
	@Input() method = 'getBoundingClientRect';
	constructor(private elementRef:ElementRef)
	{
		this.dom = elementRef.nativeElement;
		this.dd = new DragManager();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		// 檢查有無出界
		var change:boolean = false;

		// 移動範圍內座標修正。
		var gRect:DOMRect = this.bounds.getBoundingClientRect() as DOMRect;
		var boundSize:any = DOMHelper.getLocalPoint(this.bounds, {x:gRect.right,y:gRect.bottom});
		var org:DOMRect = this.dom.getBoundingClientRect() as DOMRect;

		var left:number = this.x;
		if(this.xAlign == "right")
			left = boundSize.x - this.dom.clientWidth - left; // 將右座標變做左座標

		var top:number = this.y;
		if(this.yAlign == "bottom")
			top = boundSize.y - this.dom.clientHeight - top; // 將下座標變做上座標
		
		// 出界修正
		if(left<0) {
			left = 0;
			change = true;
		} else if(left + this.dom.clientWidth>boundSize.x) {
			left = boundSize.x - this.dom.clientWidth;
			change = true;
		}
		if(top<0) {
			top = 0;
			change = true;
		} else if(top + this.dom.clientHeight>boundSize.y) {
			top = boundSize.y - this.dom.clientHeight;
			change = true;
		}


		// 設定新座標
		if(this.xAlign == "left") {
			this.x = left;
		} else if(this.xAlign == "right") {
			this.x = boundSize.x - left - this.dom.clientWidth;
		}

		if(this.yAlign == "top") {
			this.y = top;
		} else if(this.yAlign == "bottom") {
			this.y = boundSize.y - top - this.dom.clientHeight;
		}


		if (change) {
			this.updatePosition();
			if (this.emitOnResize) {
				this.positionChange.emit({event: 'move', data: {x: this.x, y: this.y, xAlign: this.xAlign, yAlign: this.yAlign}});
			}
		}
	}

	ngOnChanges(changes: SimpleChanges){
		if(!this.inBounds) {
			if(this.xAlign == "right")
				this.xAlign = "left";
			if(this.yAlign == "bottom")
				this.yAlign = "top";
		}
		this.updatePosition();
	}
	
	@HostListener('pointerdown', ['$event'])
	startPress(e):void {
		if(this.stopPropagation) {
			e.stopPropagation();
			e.stopImmediatePropagation();
		}
		if(this.hitHandle(e.target)) {
			var org:any = this.dom.getBoundingClientRect() as DOMRect;
			var gRect:any = null;
			if(this.inBounds){
				gRect = this.bounds.getBoundingClientRect() as DOMRect;
			}
			if (this.method == 'getComputedStyle'){
				const orgStyles = window.getComputedStyle(this.dom);
				org = this.dom.getBoundingClientRect() as DOMRect;
				org = Object.assign({}, org);
				gRect = this.bounds.getBoundingClientRect();
				gRect = Object.assign({}, gRect);
				org.x = orgStyles.left?parseFloat(orgStyles.left):org.x;
				org.y = orgStyles.top?parseFloat(orgStyles.top):org.y;
				if (this.inBounds){
					const gRectStyles = window.getComputedStyle(this.bounds);
					gRect.x = gRectStyles.left?parseFloat(gRectStyles.left):gRect.x;
					gRect.y = gRectStyles.top?parseFloat(gRectStyles.top):gRect.y;
					gRect.right = gRectStyles.right?parseFloat(gRectStyles.right):gRect.right;
					gRect.bottom = gRectStyles.bottom?parseFloat(gRectStyles.bottom):gRect.bottom;
				}
			}
			var startPt = {x:0,y:0};
			// Keep track of the last updated position.
			let lastX = this.x;
			let lastY = this.y;

			var subject:Subject<any> = this.dd.pointerStart(e);
			var subscription:Subscription = subject.subscribe((o:any)=>{
				if(o.type == "start")
					startPt = o.point;
				var pt = {
					x:o.point.x - startPt.x + org.x,
					y:o.point.y - startPt.y + org.y
				};
				var boundSize: any;
				if (this.inBounds && gRect) {
					if(pt.x<gRect.x)
						pt.x = gRect.x;
					else if(pt.x+org.width>gRect.right)
						pt.x = gRect.right-org.width;
					if(this.xAlign == "right")
						pt.x += org.width;

					if(pt.y<gRect.y)
						pt.y = gRect.y;
					else if(pt.y+org.height>gRect.bottom)
						pt.y = gRect.bottom-org.height;
					if(this.yAlign == "bottom")
						pt.y += org.height;

					boundSize = DOMHelper.getLocalPoint(this.bounds, {x: gRect.right, y: gRect.bottom});
				} else {
					boundSize = DOMHelper.getLocalPoint(this.dom.parentElement, pt);
				}
				pt = DOMHelper.getLocalPoint(this.dom.parentElement, pt);

				// Compute candidate positions.
				let candidateX: number;
				let candidateY: number;
				if(this.xAlign == "left") {
					candidateX = Math.floor(pt.x);
				} else if(this.xAlign == "right") {
					candidateX = Math.floor(boundSize.x - pt.x);
				}
				if(this.yAlign == "top") {
					candidateY = Math.floor(pt.y);
				} else if(this.yAlign == "bottom") {
					candidateY = Math.floor(boundSize.y - pt.y);
				}

				// Update only if step is null or if the movement is greater than or equal to step in x or y.
				if (this.step !== null) {
					if (Math.abs(candidateX - lastX) < this.step && Math.abs(candidateY - lastY) < this.step) {
						candidateX = lastX;
						candidateY = lastY;
					} else {
						// 修正至 step 應該有的數值
						candidateX = Math.round(candidateX / this.step) * this.step;
						candidateY = Math.round(candidateY / this.step) * this.step;
					}
				}

				// Update positions and the last updated values.
				this.x = candidateX;
				this.y = candidateY;
				lastX = candidateX;
				lastY = candidateY;

				this.updatePosition();
				this.positionChange.emit({event:o.type, data:{x:this.x, y:this.y, xAlign:this.xAlign, yAlign:this.yAlign}});
				
				if(o.type == "cancel" || o.type == "timeout" || o.type == "end") {
					subscription.unsubscribe();
				}
			});

//			e.preventDefault();
//			e.stopImmediatePropagation();
		}
	}

	protected updatePosition():void {
		this.dom.style.left = this.dom.style.right = this.dom.style.top = this.dom.style.bottom = null;

		if(this.xAlign == "left") {
			this.dom.style.left = this.x+"px";
		} else if(this.xAlign == "right") {
			this.dom.style.right = this.x+"px";
		}

		if(this.yAlign == "top") {
			this.dom.style.top = this.y+"px";
		} else if(this.yAlign == "bottom") {
			this.dom.style.bottom = this.y+"px";
		}

	}

	protected hitHandle(target:any):boolean {
		while(true) {
			if(target && target == this.handle)
				return true;
			if(!target || target == this.dom)
				return !this.handle;
			target = target.parentElement;
		}
	}
}
