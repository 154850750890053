import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';

@Component({
    selector: 'homeBtn',
    template: `
    <div
        [class]="'logo ' + appId"
        (click)="this.logoClicked()"
    ></div>
    `,
    styleUrls: ['./homeBtn.component.scss']
})

export class HomeBtnComponent {

    @Output() public btnClick:EventEmitter<void> = new EventEmitter();

    public appId:string = this.datas.appId.trim().toLowerCase() || 'rainbowone';

    constructor(public datas:DataService, private ros:RoService, private router:Router) {
    }

    public logoClicked():void {
        if (this.btnClick.observers.length>0) {
            this.btnClick.emit();
        } else {
            if (this.appId=='happyreading') {
                this.router.navigate(['keiKokLibrary', this.datas.lang, this.datas.appId]);
            } else {
                this.ros.request("0", {}, false, "ro/exit");
                setTimeout(() => {
                //   this.router.navigate(['home-screen', this.datas.lang, this.datas.appId]);
				  this.router.navigate(['home-screen']);
                }, 500);
            }
        }
    }
}
