import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TimerModalComponent} from "./timer-modal.component";
import {CommonModule} from "@angular/common";
import {DirectiveModule} from "../../../../../directive/DirectiveModule";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DigitalTimerComponent} from "./digital-timer.component";
import {WhitePopupModule} from "../../../../../sharedModule/whitePopupModule/whitePopup.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		DirectiveModule,
		FontAwesomeModule,
		WhitePopupModule,
		TranslateModule
	],
	exports: [
		TimerModalComponent,
		DigitalTimerComponent,
	],
	declarations: [
		TimerModalComponent,
		DigitalTimerComponent,
	],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TimerModule {
}
