import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { faCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "speechBox",
	templateUrl: "./speechBox.html",
	styleUrls: ["./speechBox.scss"]
})
export class speechBox implements OnChanges {
	@ViewChild('dictionaryPopup', { static: false }) dictionaryPopup: any;
	@ViewChild('textContainer', { static: false }) textContainer: ElementRef;

	@Input() public parent;
	@Input() public loading: boolean;
	@Input() public role: "robot" | "student" = "robot";
	@Input() public canPlay: boolean = true;
	@Input() public content: string = "";
	@Input() public recognition: [] = [];
	@Input() public showPlayButton: boolean = false;
	@Input() public showListenButton: boolean = false;
	@Input() public ImageUrl: string = "";
	@Input() public isCorrect: boolean = undefined;
	@Input() public isHint: boolean = false;
	@Input() public isParagraph: boolean;
	@Input() public EDBNumber: string;

	@Output() public playButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public listenButtonClicked: EventEmitter<void> = new EventEmitter<void>();

	public loadingDot: IconDefinition = faCircle;
	public clicked_span: any = null;
	public text_array: string [] = [];
	public recognitionText = [];
	public dictionaryPopupBGstyle = {
		top: '-100vh',
		left: '-100vw',
		height: '200vh',
		width: "200vw"
	}

	public copyBtn = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/btn_myaibuddy_copy.svg')`;
	
	ngOnInit() {
		if (this.content && this.recognition) {
			this.handleRecognitionWord();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.content) this.processContent();
	}

	private processContent() {
		this.text_array = this.string_to_array(this.content);
	}

	public play() {
		this.parent.readText(this.content)
	}

	string_to_array(string) {
		var str = string;
		// str = "I'll you're well-being 🙂I'd like to have this. And you?";
		// str = "Today we're discussing “Food” together, Mary!"
		const result = [];
		let word = '';

		for (let i = 0; i < str.length; i++) {
			if (str[i + 1] && str[i] == "'" && ["d", "m", "s", "t"].indexOf(str[i + 1]) != -1
			) {
				result.push(word + str[i] + str[i + 1])
				word = '';
				i += 2
				continue
			}
			if (str[i + 1] && str[i + 2] && str[i] == "'" &&
				(
					(str[i + 1] == 'l'  && str[i + 2] == 'l') ||
					(str[i + 1] == 'r'  && str[i + 2] == 'e')
				)
			) {
				result.push(word + str[i] + str[i + 1] + str[i+2])
				word = '';
				i += 3
				continue
			}
			if (str[i] === ' ') {
				if (word) result.push(word);
				result.push(' ');
				word = '';
			} else if ([',', '.', '?', '!', "'", "…", ";", '"','”',':'].indexOf(str[i]) != -1) {
				if (word) result.push(word + str[i]);
				word = '';
			} else if (this.isEnglishLetter(str[i])) {
				word += str[i];
			} else if (str[i + 1] && this.isEmoji(str[i] + str[i + 1])) {
				result.push(str[i] + str[i + 1])
				i++
			}else {
				word += str[i]
			}
		}
		if (word) result.push(word);
		return result;
	}

	isEmoji(char) {
		const code = char.codePointAt(0);

		return (code >= 0x1F600 && code <= 0x1F64F) || // Emoticons
			(code >= 0x1F300 && code <= 0x1F5FF) || // Misc Symbols and Pictographs
			(code >= 0x1F680 && code <= 0x1F6FF) || // Transport and Map Symbols
			(code >= 0x1F700 && code <= 0x1F77F) || // Alchemical Symbols
			(code >= 0x1F780 && code <= 0x1F7FF) || // Geometric Shapes Extended
			(code >= 0x1F800 && code <= 0x1F8FF) || // Supplemental Arrows-C
			(code >= 0x1F900 && code <= 0x1F9FF) || // Supplemental Symbols and Pictographs
			(code >= 0x1FA00 && code <= 0x1FA6F) || // Chess Symbols
			(code >= 0x1FA70 && code <= 0x1FAFF) || // Symbols and Pictographs Extended-A
			(code >= 0x2600 && code <= 0x26FF) ||   // Miscellaneous Symbols
			(code >= 0x2700 && code <= 0x27BF) ||   // Dingbats
			(code >= 0xFE00 && code <= 0xFE0F) ||   // Variation Selectors
			(code >= 0x1F1E6 && code <= 0x1F1FF);   // Flags
	}

	isEnglishLetter(char) {
		const code = char.charCodeAt(0);
		return (code >= 65 && code <= 90) || (code >= 97 && code <= 122);
	}

	isEnglishWord(str) {
		str = str.trim()
		if (str.length >= 2 && [',', '.', '?', '!', "'", "…", ";", '"','”',':'].indexOf(str[str.length - 1]) != -1) {
			str = str.substring(0, str.length - 1)
		} else if (str.length >= 3 && ["'s", "'d", "'m", "'t"].indexOf(str.substring(str.length - 2, str.length)) != -1) {
			str = str.substring(0, str.length - 2)
		} else if (str.length >= 4 && ["'re", "'ll"].indexOf(str.substring(str.length - 3, str.length)) != -1) {
			str = str.substring(0, str.length - 3)
		}
		if (str.length >= 2 && ['“'].indexOf(str[0]) != -1) {
			str = str.substring(1,str.length)
		}
		return /^[a-zA-Z-]+$/.test(str);
	}

	isLastCharPunctuation(str) {
		str = str.trim()
		return str.length >= 2 && [',', '.', '?', '!', "'", "…", ";", '"','”',':'].indexOf(str[str.length - 1]) != -1
	}

	isFirstCharPunctuation(str){
		str = str.trim()
		return ['“'].indexOf(str[0]) != -1
	}

	openDictionary(str) {
		str = str.trim()
		if (str.length >= 2 && [',', '.', '?', '!', "'", "…", ";", '"','”',':'].indexOf(str[str.length - 1]) != -1) {
			str = str.substring(0, str.length - 1)
		} else if (str.length >= 3 && ["'s", "'d", "'m"].indexOf(str.substring(str.length - 2, str.length)) != -1) {
			str = str.substring(0, str.length - 2)
		} else if (str.length >= 4 && ["'re", "'ll"].indexOf(str.substring(str.length - 3, str.length)) != -1) {
			str = str.substring(0, str.length - 3)
		}
		if (str.length >= 2 && ['“'].indexOf(str[0]) != -1) {
			str = str.substring(1,str.length)
		}

		if (this.isEnglishWord(str)) {
			this.dictionaryPopup.open(str)
		}
	}

	onDictionaryPopupClosed(event) {
		try {
			this.clicked_span.classList.remove("yellowBorder")
		} catch (error) {
			console.log(error);
		}
	}

	addYellowBorder(eventTarget) {
		this.clicked_span = eventTarget
		this.clicked_span.classList.add("yellowBorder")
	}

	handleRecognitionWord() {
		let answer: any = this.recognition;
		for (let [index, word] of answer.entries()) {
			if (index === 0) {
				this.recognitionText.push({ item: `${word.Word.charAt(0).toUpperCase()}${word.Word.slice(1)}`, error: word.ErrorType, accuracyScore: word.AccuracyScore })
			} else {
				this.recognitionText.push({ item: word.Word, error: word.ErrorType, accuracyScore: word.AccuracyScore })
			}
		}
	}

	copyToClipboard() {
		try {
			const spans = this.textContainer.nativeElement.querySelectorAll('span.text.ng-star-inserted');
			const filteredSpans: any[] = Array.from(spans).filter((span: HTMLElement) => {
			  return span.innerText
			}).map((span: HTMLElement) => span.innerText);
	
			const cleanedArray = filteredSpans.map(item => item.replace(/[\n ]/g, ''));
			const resultString = cleanedArray.join(' ');
			navigator.clipboard.writeText(resultString).then(() => {
				console.log(`Text: ${resultString} copied to clipboard`);
			}).catch(error => {
				console.error('Failed to copy text: ', error);
			});
		} catch (error) {
			console.error('Failed to copy text: ', error);
		}
	}
}