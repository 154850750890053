export class DataCollection{
	map:any;
	public key:string;
	private elements:any [];
	constructor(key:string)
	{
		this.key = key;
	}

	reset(elements:any []):DataCollection
	{
		this.elements = elements;
		this.map = {};
		//this.elements = elements;
		elements.forEach((element)=>{
			var key:string = element[this.key];
			this.map[key] = element;
		});
		return this;
	}
	
	forEach(fn:any):void
	{
		this.elements.forEach(fn);
	}

	getElement(key:string):any
	{
		if(key)
		{
			return this.map[key];
		}
		return null;
	}
}


