import {Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from 'src/app/service/alert.service';
import {DataService} from 'src/app/service/data.service';
import {ScriptService} from 'src/app/service/script.service';
import {LoadingService} from 'src/app/sharedModule/loadingModule/loading.service';
import {ThemeService} from 'src/app/service/theme.service';
import * as moment from 'moment';
import {TargetSelectorComponent} from 'src/app/sharedModule/targetSelectorModule/targetSelector.component';
import {Modal2Component} from 'src/app/sharedModule/modal2Module/modal2.component';
import {faTrash} from '@fortawesome/pro-solid-svg-icons';
import {DateTimePicker3Component} from 'src/app/sharedModule/dateTimePicker3Module/dateTimePicker3.component';
import {OupService} from "src/app/service/oup.service";

@Component({
	selector: 'web-lesson-edit-modal',
	template: `
		<modal2
			#modalRef
			[title]="modalTitle"
			width="590px"
			class="modal2-EditLessonModal"
			[class.isTsSelecting]="isTsSelecting"
			[lang]="datas.lang"
			[contentStyle]="contentStyle" [headerStyle]="headerStyle"
			(confirmClick)="confirmClick()"
			(cancelClick)="cancelClick()"
		>
			<div class="edit-lesson-modal" *ngIf="ready">
				<div [ngClass]="['body', toggle]">
					<section *ngIf="toggle == 'advance'">
						<div class="first-child row check-answer">
							<div class="row__label">{{ {tc: '即時核對答案', sc: '即时核对答案', en: 'Check answer'}[lang] }}:</div>
							<div class="radio-group row__data">
								<radioBox class="live-verify" [(value)]="liveVerify" [options]="booleanOptions"></radioBox>
							</div>
						</div>
						
						<div class="row pass-rate">
							<div class="row__label mark-required">{{ {tc: '合格百分比', sc: '合格百分比', en: 'Passing rate'}[lang] }}</div>
							<div class="row__data">
								<numberInput class="pass-rate" min="0" max="100" [step]="10" [(ngModel)]="passLevel"></numberInput>
							</div>
						</div>
						
						<div class="row pass-rate">
							<div class="row__label">{{ {tc: '限制每頁提交次數', sc: '限制每页提交次数', en: 'Submission times'}[lang] }}:</div>
							<div class="row__data">
								<okaPulldown2 class="lesson" [ngClass]="['max-submit-per-page', 'row__data', lang]"
											  [options]="maxSubmitPerPageItems" [bindLabel]="lang" bindValue="value" [(ngModel)]="maxSubmitPerPage"></okaPulldown2>
							</div>
						</div>
						
						<div class="row treatment {{datas.lang}}">
							<div class="row__label">{{ {tc: '課後電子書處理', sc: '课后电子书处理', en: 'E-book treatment when lesson ends'}[lang] }}:</div>
							<div class="three-row row__data">
								<okaPulldown2 class="lesson" [ngClass]="['treatment', 'row__data', lang]"
											  [okaPulldownStyle]="{width: '370px'}"
											  [options]="treatmentOptions" [bindLabel]="lang" bindValue="value" [(ngModel)]="treatment[0]"></okaPulldown2>
								<okaPulldown2 class="lesson" *ngIf="treatment[0] == 4" [ngClass]="['treatment', 'row__data', lang]"
											  [okaPulldownStyle]="{width: '370px'}"
											  [options]="treatment2Options" [bindLabel]="lang" bindValue="value" [(ngModel)]="treatment[1]"></okaPulldown2>
								<okaPulldown2 class="lesson" *ngIf="treatment[0] == 4" [ngClass]="['treatment', 'row__data', lang]"
											  [okaPulldownStyle]="{width: '370px'}"
											  [options]="treatment3Options" [bindLabel]="lang" bindValue="value" [(ngModel)]="treatment[2]"></okaPulldown2>
							</div>
						</div>
						
						<div class="last-child row video-url">
							<div class="row__label">{{ {tc: '視訊網址', sc: '视讯网址', en: 'Video Url'}[lang] }}:</div>
							<textarea class="row__data" [(ngModel)]="videoUrl"></textarea>
						</div>
					</section>
				</div>
			</div>
		
		</modal2>
	`
	, styleUrls: ['./web-lesson-edit-modal.component.scss'],
})
export class WebLessonEditModalComponent implements OnInit {
	@Input() data: any;
	@ViewChild('modalRef', {static: false}) modalRef: Modal2Component;
	@ViewChild('dtp3', {static: false}) dtp3: DateTimePicker3Component;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	public ready = false;
	public lang;
	public title = '';
	public liveVerify = 0;
	public passLevel = 50;
	public treatment = [0, 1, 1];
	public treatmentOptions = [{tc: '不變更', sc: '不变更', en: 'No changes', value: 0}, {tc: '轉為自主學習', sc: '转为自主学习', en: 'Convert to self-study', value: 1}, {tc: '轉為今天的功課', sc: '转为今天的功课', en: 'Convert to today\'s homework', value: 2}, {tc: '轉為本週的功課', sc: '转为本周的功课', en: 'Convert to this week\'s homework', value: 3}, {tc: '自訂...', sc: '自订...', en: 'Custom...', value: 4}];
	public treatment2Options = [{tc: '建立新的分享及重新作答', sc: '建立新的分享及重新作答', en: 'Create new sharing and start new records.', value: 1}, {tc: '建立新的分享及延續課堂作答數據', sc: '建立新的分享及延续课堂作答数据', en: 'Create new sharing and continue lesson records.', value: 2}];
	public treatment3Options = [{tc: '分享方式:自主學習', sc: '分享方式:自主学习', en: 'Sharing type:self-study', value: 1}, {tc: '分享方式:功課', sc: '分享方式:功课', en: 'Shareing type:Homework', value: 2}];
	public radioOptions = [{label: 'yes', value: 1}, {label: 'no', value: 0}];
	public booleanOptions: any = [];
	public videoUrl = '';
	public periods = [];
	public maxSubmitPerPageItems = [];
	public maxSubmitPerPage: any = 'null';
	public toggles: any = [];
	public toggle: any = 'advance';
	public contentStyle;
	public headerStyle;
	public tsOptions;
	public tagText = '';
	public targets;
	public isTsSelecting = false;
	public faTrash = faTrash;
	public modalTitle;
	public invalids: any = [];

	constructor(
		public datas: DataService,
		private script: ScriptService,
		private trans: TranslateService,
		private als: AlertService,
		public eleRef: ElementRef,
		private lds: LoadingService,
		public theme: ThemeService,
		public oup: OupService
	) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.initOption();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.modalRef.open();
		}, 10);
		setTimeout(() => {
			this.ready = true;
		}, 500);
	}

	ngOnDestroy(): void {
	}

	initOption() {
		const lang = this.lang;
		this.toggles = [
			{label: {tc: '基本', sc: '基本', en: 'Basic'}[lang], value: 'basic', onLeave: (popupToggleComponent) => this.validate()},
			{label: {tc: '進階', sc: '进阶', en: 'Advance'}[lang], value: 'advance'},
			{label: {tc: '時間', sc: '时间', en: 'Period'}[lang], value: 'period'},
		];
		this.toggle = 'advance';
		this.maxSubmitPerPageItems = [{tc: '不限', sc: '不限', en: 'No limit', value: 'null'}, {tc: '1次', sc: '1次', en: 'once', value: '1'}, {tc: '2次', sc: '2次', en: 'twice', value: '2'}];
		for (let i = 3; i < 11; i++) {
			this.maxSubmitPerPageItems.push({tc: i + '次', sc: i + '次', en: i + ' times', value: '' + i});
		}
		this.maxSubmitPerPage = '1';

		this.booleanOptions = [{label: {tc: '是', sc: '是', en: 'Yes'}[lang], value: 1}, {label: {tc: '否', sc: '否', en: 'No'}[lang], value: 0}];

		this.contentStyle = {padding: '0', minHeight: '183px'};
		this.headerStyle = {borderBottom: '0'};

		this.targets = [];
		this.modalTitle = {tc: '設定', sc: '设定', en: 'Setting'}[this.lang];
		if (this.data.lesson) {
			this.modalTitle = {tc: '設定', sc: '设定', en: 'Setting'}[this.lang];
			this.loadExistingLesson(this.data.lesson);
		}
		this.initTagText();
	}

	get filteredPeriods() {
		return this.periods.filter(e => e.deleted != 1);
	}

	loadExistingLesson(lesson) {
		console.log(lesson);
		this.title = lesson.title;
		this.liveVerify = lesson.live_verify;
		this.passLevel = isNaN(lesson.pass_level) ? 50 : +lesson.pass_level;
		this.videoUrl = lesson.video_url;
		this.maxSubmitPerPage = lesson.max_submit_per_page && lesson.max_submit_per_page != 'null' ? lesson.max_submit_per_page : 'null';
		if (lesson.treatment > 3) {
			this.treatment[0] = 4;
			this.treatment[1] = lesson.treatment <= 5 ? 1 : 2;
			this.treatment[2] = lesson.treatment == 4 || lesson.treatment == 6 ? 1 : 2;
		} else if (lesson.treatment) {
			this.treatment[0] = lesson.treatment;
		}
		if (lesson.tid) {
			this.targets = [{tType: 'tag', value: lesson.tid, title: lesson.tag_name}];
		}
	}


	confirmClick() {
		if (!this.validate()) {
			return;
		}
		const lesson = this.data.lesson;
		if (lesson) {
			lesson.title = this.title;
			lesson.live_verify = this.liveVerify;
			lesson.pass_level = this.passLevel;
			lesson.video_url = this.videoUrl;
			lesson.tag_name = this.targets[0].title;
		}
		let lessonToApi: any = {title: this.title, live_verify: this.liveVerify, pass_level: this.passLevel, video_url: this.videoUrl};
		const tagId = this.targets[0].id ? this.targets[0].id : this.targets[0].value;
		lessonToApi.tagId = tagId;
		let api = 'ROLessonWeb.addLesson';
		if (lesson && lesson.id) {
			lessonToApi.id = lesson.id;
			api = 'ROLessonWeb.editLesson';
		}
		let treatment = this.treatment[0];
		if (this.treatment[0] == 4) {
			if (this.treatment[1] == 1) { //start a record
				if (this.treatment[2] == 1) {
					treatment = 4;
				} else {
					treatment = 5;
				}
			} else { //continue record
				if (this.treatment[2] == 1) {
					treatment = 6;
				} else {
					treatment = 7;
				}
			}
		}

		lessonToApi.treatment = treatment;
		this.periods.forEach(p => {
			p.recursive_day = p.recursive_day ? p.recursive_day : null;
		});
		lessonToApi.periods = JSON.stringify(this.periods);
		const max_submit_per_page = this.maxSubmitPerPage == 'null' ? null : this.maxSubmitPerPage;
		lessonToApi.max_submit_per_page = max_submit_per_page;
		if (lesson) {
			lesson.treatment = treatment;
			lesson.max_submit_per_page = max_submit_per_page;
		}
		this.lds.add(api, 10000);
		this.datas.post(api, [lessonToApi]).subscribe((res: any) => {
			let msg = {tc: '已建立課堂「$lesson」', sc: '已建立課堂「$lesson」', en: 'Lesson "$lesson" was created.'}[this.lang];
			if (lesson && lesson.id) {
				msg = {tc: '課堂「$lesson」已修改', sc: '已建立課堂「$lesson」已修改', en: 'Lesson "$lesson" was updated.'}[this.lang];
			}
			msg = msg.replace('$lesson', this.oup.get_lesson_name(lesson.owner));
			this.als.toastSuccess(msg);
			this.lds.remove(api);
			this.data.close(lesson);
		});

	}

	validate() {
		if (!this.title) {
			this.title = `${this.data.lesson.owner}`;
		}
		if (!this.targets || this.targets.length != 1 || this.targets.find(e => e.tType != 'tag')) {
			this.als.toastError();
			this.invalids.push('group');
			return false;
		}
		return true;
	}

	cancelClick() {
		this.data.close();
	}

	tsConfirm($event) {
		if ($event) {
			this.targets = $event;
		}
		this.resetValidation('group');
		this.initTagText();
	}

	initTagText() {
		if (this.targets.length == 0) {
			this.tagText = {tc: '請選擇...', sc: '请选择...', en: 'Select...'}[this.lang];
			return;
		}
		this.tagText = this.targets.map(e => e.title).join(',');
	}

	periodClick($event, period) {
		let ele: any;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
			ele = $event.target;
		} else {
			ele = this.eleRef.nativeElement.querySelector('.period-item.last .period-item-text');
		}
		const start = moment(period.start_time, 'YYYY-MM-DD HH:mm:ss').toDate();
		const end = moment(period.end_time, 'YYYY-MM-DD HH:mm:ss').toDate();
		this.dtp3.open(ele, {
			lang: this.lang,
			clearBtn: false,
			noDateBtn: false,
			singleDate: false,
			enablePickTime: true,
			showWholeDayOption: false,
			showEndDateTimeOption: false,
			showPresetOptions: false,
			skipLoadApi: true,
			inOneDayOnly: true,
			value: {startDateTime: start, endDateTime: end, needEndTime: true}
		}, this.eleRef.nativeElement).then(result => {
			if (result) {
				period.start_time = result.startDateTime;
				period.end_time = result.endDateTime;
			}
		});
	}

	periodRemove(period) {
		period.deleted = 1;
	}

	resetValidation(field) {
		this.invalids = this.invalids.filter(e => e != field);
	}

}
