import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonService} from "../../../../../service/common.service";

@Component({
	selector: 'digital-timer',
	template: `
		<div #timer class="digital-timer" [class.is-started]="is_started" [class.is-time-up]="is_time_up" [class.is-student]="is_student">
			<swiper-container #digit_1 direction="vertical" pagination="false" loop="true" slides-per-view="auto" centered-slides="true">
				<swiper-slide>0</swiper-slide>
				<swiper-slide>1</swiper-slide>
				<swiper-slide>2</swiper-slide>
				<swiper-slide>3</swiper-slide>
				<swiper-slide>4</swiper-slide>
				<swiper-slide>5</swiper-slide>
			</swiper-container>
			<swiper-container #digit_2 direction="vertical" pagination="false" loop="true" slides-per-view="auto" centered-slides="true">
				<swiper-slide>0</swiper-slide>
				<swiper-slide>1</swiper-slide>
				<swiper-slide>2</swiper-slide>
				<swiper-slide>3</swiper-slide>
				<swiper-slide>4</swiper-slide>
				<swiper-slide>5</swiper-slide>
				<swiper-slide>6</swiper-slide>
				<swiper-slide>7</swiper-slide>
				<swiper-slide>8</swiper-slide>
				<swiper-slide>9</swiper-slide>
			</swiper-container>
			<div class="divider">:</div>
			<swiper-container #digit_3 direction="vertical" pagination="false" loop="true" slides-per-view="auto" centered-slides="true">
				<swiper-slide>0</swiper-slide>
				<swiper-slide>1</swiper-slide>
				<swiper-slide>2</swiper-slide>
				<swiper-slide>3</swiper-slide>
				<swiper-slide>4</swiper-slide>
				<swiper-slide>5</swiper-slide>
			</swiper-container>
			<swiper-container #digit_4 direction="vertical" pagination="false" loop="true" slides-per-view="auto" centered-slides="true">
				<swiper-slide>0</swiper-slide>
				<swiper-slide>1</swiper-slide>
				<swiper-slide>2</swiper-slide>
				<swiper-slide>3</swiper-slide>
				<swiper-slide>4</swiper-slide>
				<swiper-slide>5</swiper-slide>
				<swiper-slide>6</swiper-slide>
				<swiper-slide>7</swiper-slide>
				<swiper-slide>8</swiper-slide>
				<swiper-slide>9</swiper-slide>
			</swiper-container>
		</div>
	`,
	styleUrls: ['./digital-timer.component.scss'],
})
export class DigitalTimerComponent implements OnInit {

	@ViewChild('timer', {static: false}) timer;
	@ViewChild('digit_1', {static: false}) digit_1;
	@ViewChild('digit_2', {static: false}) digit_2;
	@ViewChild('digit_3', {static: false}) digit_3;
	@ViewChild('digit_4', {static: false}) digit_4;

	@Input() is_student = false;

	@Output() time_up = new EventEmitter();
	@Output() time_passed = new EventEmitter();

	is_started = false;
	is_time_up = false;
	seconds = 0;
	stop_cb = null;

	history = [
		"00:00",
	];

	constructor(
		public coms: CommonService,
	) {
	}

	ngOnInit(): void {
		this.init_swipers();
	}

	async init_swipers() {
		await this.coms.waitFor(() => this.digit_1 && this.digit_2 && this.digit_3 && this.digit_4, 9999999, 10);
		console.log({digit_1: this.digit_1, digit_2: this.digit_2, digit_3: this.digit_3, digit_4: this.digit_4});
		let swiper_1 = this.digit_1.nativeElement.swiper;
		let swiper_2 = this.digit_2.nativeElement.swiper;
		let swiper_3 = this.digit_3.nativeElement.swiper;
		let swiper_4 = this.digit_4.nativeElement.swiper;
	}

	async start() {
		return new Promise((resolve) => {
			let time = this.get_time();
			if (this.history.length === 1) {
				this.history.push(time);
			}
			let sec = this.time_to_sec(time);
			this.seconds = sec;
			this.is_started = true;
			this.time_passed.emit(this.seconds);

			if (this.seconds <= 0) {
				this.is_time_up = true;
				this.time_up.emit();
				this.stop();
				resolve();
				return;
			}
			let interval_id = setInterval(() => {
				this.seconds--;
				this.time_passed.emit(this.seconds);
				let time = this.sec_to_time(this.seconds);
				this.set_time(time);
				if (this.seconds <= 0) {
					this.is_time_up = true;
					this.time_up.emit();
					if (this.stop_cb) {
						this.stop_cb();
					}
				}
			}, 1000);
			this.stop_cb = () => {
				clearInterval(interval_id);
				resolve();
			}
		})
	}

	stop() {
		if (this.stop_cb) {
			this.stop_cb();
		}
		this.is_started = false;
	}

	reset() {
		if (this.stop_cb) {
			this.stop_cb();
		}
		this.is_started = false;
		this.is_time_up = false;
		this.seconds = 0;
		this.set_time(this.history.pop());
		if (this.history.length === 0) {
			this.history.push("00:00");
		}
	}

	time_to_sec(time) {
		return time.split(':').reduce((acc, time) => (60 * acc) + +time);
	}

	sec_to_time(sec) {
		// format MM:SS
		let min: any = Math.floor(sec / 60) % 60;
		let sec_remain: any = sec % 60;

		if (min < 10) {
			min = `0${min}`;
		}
		if (sec_remain < 10) {
			sec_remain = `0${sec_remain}`;
		}
		return `${min}:${sec_remain}`;
	}

	get_time() {
		let time = [];
		this.timer.nativeElement.querySelectorAll('.swiper-slide-active').forEach((slide) => {
			time.push(slide.innerHTML);
		});

		return `${time[0]}${time[1]}:${time[2]}${time[3]}`;
	}

	async set_time(time) {
		let [min, sec] = time.split(':');
		this.digit_1.nativeElement.swiper.slideToLoop(min[0] * 1);
		this.digit_2.nativeElement.swiper.slideToLoop(min[1] * 1);
		this.digit_3.nativeElement.swiper.slideToLoop(sec[0] * 1);
		this.digit_4.nativeElement.swiper.slideToLoop(sec[1] * 1);
	}

	add_time(sec) {
		let current_sec = this.time_to_sec(this.get_time());
		current_sec += sec;
		let time = this.sec_to_time(current_sec);
		this.set_time(time);
	}
}