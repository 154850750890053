// import { Deflate } from "fast-zlib";
import { decode } from "./base64-arraybuffer";

import {ByteArray, Endian} from "openfl";

export class ByteArrayUtils
{
	static fromBase64ToArrayBuffer(base64:string):ArrayBuffer
	{
		var arrayBuffer:ArrayBuffer = decode(base64);
		return arrayBuffer;
	}

	static fromBase64(base64:string):ByteArray
	{
		var arrayBuffer:ArrayBuffer = ByteArrayUtils.fromBase64ToArrayBuffer(base64);
		var array = new Uint8Array(arrayBuffer);
		var bytes:ByteArray = new ByteArray();
		bytes.endian = Endian.BIG_ENDIAN;
		for(var i = 0; i< array.byteLength;i++)
		{
			bytes.writeByte(array[i]);
		}
		return bytes;
	}
	static toBase64(byteArray:ByteArray):string
	{
		const chars:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
		
		let len:number = byteArray.length;
		let str:string = "";
		
		byteArray.position = 0;
		while(byteArray.position < len)
		{
			let chunk:number = (byteArray.readUnsignedByte() << 16);
			if(byteArray.position < len)
				chunk |= (byteArray.readUnsignedByte() << 8);
			if(byteArray.position < len)
				chunk |= byteArray.readUnsignedByte();

			str += chars[(chunk & 16515072) >> 18]; // 6bits
			str += chars[(chunk & 258048)   >> 12];
			str += chars[(chunk & 4032)     >>  6];
			str += chars[chunk & 63];
		}
		
		if ((len % 3) === 2)
			return str.substring(0, str.length - 1) + "=";
		if ((len % 3) === 1)
			return str.substring(0, str.length - 2) + "==";
		return str;
	}

	static toUint8Array(content: string) {
		const bytes: ByteArray = new ByteArray();
		bytes.writeUTF(content);
		return this.toBase64(bytes)
	}
}
