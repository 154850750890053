import {NgModule} from '@angular/core';
import {WebLessonPointerComponent} from "./web-lesson-pointer.component";
import {CommonModule} from "@angular/common";

@NgModule({
	imports: [
		CommonModule
	],
	exports: [
		WebLessonPointerComponent,
	],
	declarations: [
		WebLessonPointerComponent,
	],
	providers: [],
})
export class PointerModule {
}
