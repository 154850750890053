import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptService {
	public assetsUrl;
	public imgUrl;
	public mediaServerUrl;
	// 防止同時間載入兩次 script
	public waitingList:any[] = [];
	public loadedScript:any[] = [];
	constructor() {
		this.initAssetUrl();
	}

	initAssetUrl(){
		let app_base_href:string = document.querySelector("base").getAttribute("href");

		this.assetsUrl = app_base_href+'assets/';
		if (location.port != ''){
			// this.assetsUrl = '//dev.openknowledge.hk/RainbowOne/webapp/2.8/roWeb/assets/';
		}
		this.imgUrl = this.assetsUrl + 'img/';
		this.mediaServerUrl = '//oka.blob.core.windows.net/media';
	}

	load(filename, options:any={}){
		filename = filename.replace('[blob]', '//oka.blob.core.windows.net/media');
		if(this.loadedScript.indexOf(filename) != -1)
		{
			return Promise.resolve(null);
		}
		let url:string = filename.indexOf("//") == -1 ? this.assetsUrl + filename : filename;
		console.log("filename", filename)
		return new Promise((resolve,reject)=>{
			let ele =  document.querySelector('script[src="' + filename +'"]');
			console.log("elem", ele)
			if(ele!=null) {
				if(ele.getAttribute("ready") == "true")
					resolve(true);
				else
					this.waitingList.push({
						resolve:resolve,
						reject:reject,
						url:url
					});
			} else {
				let scriptElement = document.createElement('script');
				scriptElement.src = url;
				console.log("option", options)
				if (options.type){
					scriptElement.type = options.type;
				} else {
					scriptElement.type = 'text/javascript';
				}
				scriptElement.onload = ()=>{
					scriptElement.setAttribute('ready', 'true');
					resolve(null);
					this.loadedScript.push(filename);
					this.waitingList = this.waitingList.filter(e => {
						if(e.url == url) {
							e.resolve(null);
							return false;
						}
						return true;
					});
				};
				document.body.appendChild(scriptElement);
				let timeout = options.timeout?options.timeout:10000;
				setTimeout(()=>{
					reject({msg:'timeout load '+ filename});
					this.waitingList = this.waitingList.filter(e => {
						if(e.url == url) {
							e.reject({msg:'timeout load '+ filename});
							return false;
						}
						return true;
					});
				},timeout);
			}
		});
	}

	/*protected checkUploaded(filename){
		let ele =  document.querySelector('script[src="' + filename +'"]');
		return !!ele;
	}*/

}
