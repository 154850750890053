import { Injectable} from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ArrayUtils } from '../common/ArrayUtils';
import {OupService} from "./oup.service";


@Injectable({ providedIn: 'root' })
export class UserInfoHelperService {
	protected cache:any = {};
	protected requestTimer:number = -1;
	protected requestList:any[] = [];

	constructor(private datas: DataService, private sans: DomSanitizer, public oups: OupService) {
	}

	public requestUserInfo(uid:any):Promise<any> {
		uid = parseInt(uid);
		// 看這裡有無
		if(this.cache.hasOwnProperty(uid)) {
			return Promise.resolve(this.cache[uid]);
		}
		
		// 無，放入待取
		return new Promise((resolve, reject)=>{
			this.requestList.push({uid:uid, resolve:resolve, reject:reject});
			this.clearRequestTimer();
			this.requestTimer = window.setTimeout(()=>this.findUser(), 16);
		});
	}

	protected clearRequestTimer():void {
		if(this.requestTimer!=-1) {
			clearTimeout(this.requestTimer);
			this.requestTimer = -1;
		}
	}

	protected findUser():void {
		this.clearRequestTimer();

		if(this.requestList.length>0) {
			var uids:any[] = ArrayUtils.unique(this.requestList.map(e=>e.uid));
			this.datas.post2({data:{
				api:'Account.getUsersInfo2',
				json:[uids]
			}, loading: false}).then((res:any)=>{
				res.users.forEach(u => {
					// 加入 cache
					this.cache[u.uid] = u;

					// 回覆要求者
					for(let i:number=0; i<this.requestList.length; i++) {
						var r:any = this.requestList[i];
						if(parseInt(r.uid) == u.uid) {
							r.resolve(u);
							this.requestList.splice(i,1);
							i--;
						}
					}
				});

			});
		}
	}

	public getProfileImgPath(userInfo:any):string {
		if (!userInfo) {
			return "assets/img/m3.png";
		} else if (userInfo.url) {
			return userInfo.url;
		} else if (userInfo.user_role) {
			if (userInfo.user_role == 2)
				return (userInfo.gender==2) ? 'assets/img/avatar_student_female.svg' : 'assets/img/avatar_student_male.svg';
			return (userInfo.gender==2) ? 'assets/img/avatar_teacher_female.svg': 'assets/img/avatar_teacher_male.svg';
		}
		return "assets/img/avatar_teacher_male.svg";
	}

	public getProfileImgPathStyle(userInfo:any):SafeStyle {
		return this.sans.bypassSecurityTrustStyle("url('"+this.getProfileImgPath(userInfo)+"')");
	}

	public getProfileImgObject(userInfo:any, lang="tc", old:boolean = false):any {
		if(old)
			return {type:"img", data:this.getProfileImgPath(userInfo)} 

		if(userInfo.url) {
			// 1. 有相用相
			return {type:"img", data:userInfo.url};
		} else if(userInfo.display_name == "" || userInfo.display_name == null) {
			if(lang != "en") {
				// 3. 中文介面-取姓第1個字
				return {type:"txt", data:userInfo.c_last_name.substr(0,1)};
			} else {
				// 4. 英文介面-取英文姓第1個字
				return {type:"txt", data:userInfo.first_name.substr(0,1)};
			}
		}

		// 2. 暱稱-中文時取第1個字
		return {type:"txt", data:userInfo.display_name.substr(0,1)};
	}

	public logout() {
		this.datas.post('Account.logout').subscribe((res: any) => {
			setTimeout(()=>{ //avoid error
				this.datas.userInfo = null;
				this.datas.jwt = '';
				this.datas.jwtObj = null;
				if (localStorage) {
					localStorage.removeItem('common_jwt');
					localStorage.removeItem('common_uid');
					localStorage.removeItem('common_schoolId');
					localStorage.removeItem('common_schoolLogo');
					localStorage.removeItem('common_photo');
					sessionStorage.removeItem('common_photo_done');
					sessionStorage.removeItem('common_schoolLogo_done');
					localStorage.removeItem('homeScreen_data');
				}
				if (this.oups.isOupUser) {
					this.oups.oupLogout();
				} else {
					// let url = location.origin + '/login/' + this.datas.lang + '/' + this.datas.appId2;
					let url = location.origin + '/login';
					if (location.port == "") {
						const href = window.location.href;
						url = href.replace(/roWeb.*/, 'roWeb/login');
					}
					location.href = url;
				}
			},10);
		});
	}

}
