import { Injectable, ComponentFactoryResolver, ApplicationRef, ComponentRef, Injector, EmbeddedViewRef, NgZone } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
//import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
//import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
//import { throwError } from 'rxjs';
//import { catchError, delay, take, filter } from 'rxjs/operators';
//import { ProgressModal } from './progress.modal';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
//import { UploadAlertModal } from './upload-alert.modal';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
//import { resolve } from 'path';
//import { rejects } from 'assert';
import { ScriptService } from 'src/app/service/script.service';
import { interval, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
declare var WebAudioRecorder;

@Injectable({ providedIn: 'root' })
export class FileIOService {
	private webAudioRecorder:any;
	private stream:any;
	private recordState:string = 'stop';
	private stopByTimeLimit:boolean = false;

	constructor(private datas: DataService, private ros: RoService, private http: HttpClient, private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector, private als: AlertService, private coms: CommonService, private sans: DomSanitizer, 
		private script:ScriptService, private zone: NgZone) {
		this.script.load('web-audio-recorder/WebAudioRecorder.min.js');
	}

	// =======================================
	// sound record function
	// =======================================
	public createSoundFromURL(url:string):HTMLAudioElement
	{
		let snd:any = new Audio();
		snd.autoplay = false;
		snd.src = url;
		snd.load();
		return snd;
	}
	public createSoundFromFile(file:File):HTMLAudioElement
	{
		let snd:any = new Audio();
		snd.autoplay = false;
		snd.src = URL.createObjectURL(file);
		
		snd.load();
		return snd;
	}
	public record2(timeLimitInSec:number = 3600, encoding:string = "mp3"):Observable<any> {
		// var subject:Subject<any> = new Subject();
		var subject:Subject<any> = new ReplaySubject(1);

		var extension:string = encoding;
		navigator.mediaDevices.getUserMedia({audio: true, video:false}).then((stream)=>{
			let audioContext = new AudioContext();
			let input = audioContext.createMediaStreamSource(stream);
			var webAudioRecorder = new WebAudioRecorder(input, 
				{
					encoding: encoding,
					workerDir: this.script.assetsUrl + 'web-audio-recorder/',
					numChannels:1//,
					//bitRate:160
				}
			);
			// this.stream = stream;
			webAudioRecorder.onComplete = (recorder, blob)=>{
				let file:any = new File([blob],'record.'+extension,{type: blob.type});
				subject.next({ type:"output",sound:{type:"file", file:file} });
				subject.complete();
			};
			var options:any = {
				timeLimit: timeLimitInSec,
				encodeAfterRecord: true
			}
			if(encoding == "mp3")
			{
				options.mp3 =  {bitRate: 128};
			}
			webAudioRecorder.setOptions(options);
			
			var subscription:Subscription = subject.subscribe(()=>{}, 
				(reason:any)=>{
					subscription.unsubscribe();
					stream.getAudioTracks().forEach((track)=>{
						track.stop();
					});
				},
				()=>{
					subscription.unsubscribe();
					stream.getAudioTracks().forEach((track)=>{
						track.stop();
					});
				}
			)
			subscription.add(
				interval(300).subscribe(
					()=>{
						var time = webAudioRecorder.recordingTime();
						subject.next({type:"time", time:time});
					}
				)
			);

			subject.next({type:"recorder", recorder:webAudioRecorder});
			subject.next({type:"time", time:0});
			webAudioRecorder.startRecording();
			
		}).catch((reason:any) => {
			subject.error(reason);
		});
		
		
		return subject;
	}
	private getObjectLength(blob:any):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			let snd = new Audio();
			snd.autoplay = false;
			snd.src = URL.createObjectURL(blob);
			snd.addEventListener('loadeddata', ()=> {
				resolve({duration:snd.duration});
			});
			snd.load();
		});
		
	}
	public record(timeLimitInSec:number = 3600, encoding:string = "mp3"):Promise<any> {
		return new Promise((resolve, reject)=>{
			var extension:string = encoding;
			navigator.mediaDevices.getUserMedia({audio: true, video:false}).then((stream)=>{
				let audioContext = new AudioContext();
				let input = audioContext.createMediaStreamSource(stream);
				this.webAudioRecorder = new WebAudioRecorder(input, 
					{encoding: encoding,workerDir: this.script.assetsUrl + 'web-audio-recorder/'});
				this.stream = stream;
				this.webAudioRecorder.onComplete = (recorder, blob)=>{
					this.stream.getAudioTracks().forEach(function(track) {
						track.stop();
					});
					this.stream = null;
					this.webAudioRecorder = null;

					this.getObjectLength(blob).then((info)=>{
						let f:any = new File([blob],'record.'+extension,{type: blob.type});
						info.file = f;
						info.stopByTimeLimit = this.stopByTimeLimit;
						resolve(info);
					})
					/*
					let snd = new Audio();
					snd.autoplay = false;
					snd.src = URL.createObjectURL(blob);
					snd.addEventListener('loadeddata', ()=> {
						let f:any = new File([blob],'record.'+extension,{type: blob.type});
						resolve({file:f, duration:snd.duration, stopByTimeLimit:this.stopByTimeLimit});
						this.recordState = "stop";
					});
					snd.load();
					*/
				};
				var options:any = {
					timeLimit: timeLimitInSec,
					encodeAfterRecord: true
				}
				if(encoding == "mp3")
				{
					options.mp3 =  {bitRate: 128};
				}
				this.webAudioRecorder.setOptions(options);
				this.webAudioRecorder.startRecording();
				this.recordState = "recording";
			}).catch((reason:any) => {
				reject({msg:"no permission"});
			});
		});
	}

	public stopRecord(stopByTimeLimit:boolean = false):void {
		this.stopByTimeLimit = stopByTimeLimit;
		if(this.recordState == "recording") {
			this.recordState = "compressing";
			//this.stream.getAudioTracks()[0].stop();
			this.webAudioRecorder.finishRecording();
			/*this.stream.getAudioTracks().forEach(function(track) {
				console.log(track);
				track.stop();
			});*/
		}
	}

	public getRecordingState():string {
		return this.recordState;
	}

	// =======================================
	// asset function
	// =======================================
	public getResourceServer(path:string) {
		if(path.indexOf("tmp_upload/")==0) {
			let hostname:string = window.location.hostname.indexOf('localhost') > -1 ? 'ro2.azurewebsites.net' : window.location.hostname;
			return "https://"+hostname+"/RainbowOne/"
		}
		return "https://oka.blob.core.windows.net/media/";
	}

	public getAssetsUrl(asset:any):string {
		if (asset.url.indexOf('//') > -1){
			return asset.url;
		}
		return this.getResourceServer(asset.url)+asset.url;
	}

	public isGraphic(name:string):boolean {
		return this.matchFileExt(["png","gif","svg","jpg","jpeg","bmp"], name);
	}

	public isVideo(name:string):boolean {
		return this.matchFileExt(["mp4","mov","m4v","webm"], name);
	}

	public isSound(name:string):boolean {
		return this.matchFileExt(["mp3","m4a","wav","ogg"], name);
	}

	public matchFileExt(exts:any, name:string):boolean {
		if(name && name.indexOf(".")>=0) {
			let parts:any = name.split(".");
			return exts.indexOf(parts.pop().toLowerCase()) >= 0;
		}
		return false;
	}

	public calContainSizeInSquare(width:number, height:number, limitSize:number):any {
		if(width > limitSize) {
			if(height<=limitSize || width > height) {
				return {
					width:limitSize,
					height:height*limitSize/width
				};
			} else {
				return {
					width:width*limitSize/height,
					height:limitSize
				};
			}
		} else if(height>limitSize) {
			return {
				width:width*limitSize/height,
				height:limitSize
			};
		}
		
		return {
			width:width,
			height:height
		};
	}

	// =======================================
	// file function
	// =======================================
	public getLocalFile(options: any = {}):Promise<any> {
		return new Promise((resolve, reject) => {
			let el = document.createElement('input');
			el.style.visibility = 'hidden';
			el.setAttribute('type', 'file');
			el.setAttribute('accept', 
				(['image', 'video', 'audio'].indexOf(options.fileType) > -1) ? 
				options.fileType + '/*' : options.fileType);

			document.body.appendChild(el);
			el.onchange = (event: any) => {
				let file: any = event.target.files[0];
				resolve({file:file});
				/*let file: any = event.target.files[0];
				if (options.size) {
					if (file.size > options.size) {
						reject({ msg: 'file-size-exist' });
						return;
					}
				}
				if (this.upload$) {
					this.upload$.unsubscribe();
				}
				this.upload$ = this.httpCall(file, options).subscribe((res: any) => {
					this.upload$.unsubscribe();
					this.upload$ = null;
					document.body.removeChild(el);
					el = null;
					let obj:any = {url: res.body.url, size: res.body.size, originFilename: file.name};
					resolve(obj);
				});*/
			};
			let checkCancel = setInterval(()=>{
				if (el == null){
					clearInterval(checkCancel);console.log("select file cancel");
				} else if (el.value == '' && document.hasFocus()){
					reject({msg: 'cancel-by-close-window'});
					clearInterval(checkCancel);console.log("select file cancel");
				}
			}, 1000);
			el.click();
		});
	}

	public toDataURL(url:string):Promise<any> {
		return new Promise((resolve, reject) => {
			let xhr = new XMLHttpRequest();
			xhr.onload = () => {
				let reader = new FileReader();
				reader.onloadend = ()=> {
					resolve(reader.result);
				};
				reader.onerror = (event) => {
					reject(event);
				};
				reader.readAsDataURL(xhr.response);
			};
			xhr.onerror = (event) => {
				reject(event);
			};
			xhr.open('GET', url);
			xhr.responseType = 'blob';
			xhr.send();
		});
	}

	public trustURLStyle(url:string):SafeStyle {
		return this.sans.bypassSecurityTrustStyle("url('"+url+"')");
	}

	public downloadAsset(assetUrl: string): Observable<Blob> {
		if (!assetUrl) return 
		
		return this.http.get(assetUrl, { responseType: 'blob' });
	}

}

export interface IWebAudioRecorder{
	startRecording();
	isRecording():boolean;
	
	// recording time (second) or null (not recording)
	recordingTime():number;
	cancelRecording():void;
	finishRecording():void;
	cancelEncoding():void;
}