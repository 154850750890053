import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { environment } from '@env';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { delay, retryWhen, take  } from 'rxjs/operators';

@Directive()
@Injectable()

export class ApiService {
  @Output() public loaded$: EventEmitter<any> = new EventEmitter();
  @Output() public session$: EventEmitter<any> = new EventEmitter();
  private url;
  constructor(private storage: StorageService, private http: HttpClient,private sanitizer: DomSanitizer) {
    this.url = environment.apiUrl;
    // this.url = "";
  }

  get(params: any, retry=true) {
    // debugger;
    let url = this.url + '?api=' + params[0] + '&json=[';
    for (let i = 1; i < params.length; i++){
      if (typeof params[i] == 'string') {
        // url = url + '"' + decodeURI(params[i]) + '",';
        url = url + '"' + encodeURIComponent(params[i]) + '",';
      } else if (typeof params[i] == 'number') {
        url = url + params[i] + ',';
      } else if (typeof params[i] == 'boolean') {
        url = url + (params[i] ? 'true,' : 'false,');
      } else {
        url = url + JSON.stringify(params[i]) + ',';
      }
    };
    if (url.substr(-1) == ',')
      url = url.substr(0, url.length - 1);
    url = url + ']';
    //Set Token
    let headers:HttpHeaders = new HttpHeaders();
    if (this.storage.jwt) headers = headers.append("Authorization", 'Bearer ' + this.storage.jwt);
    let ob = this.http.get(url, {headers:headers});
    if (retry){
      return ob.pipe(retryWhen(err=>{
        return err.pipe(
          delay(2000),
          take(2)
        )})
      );
    } else {
      return ob;
    }
  }

  post(params: any,retry = true) {
    // debugger;
    let api = params[0].indexOf('.') > -1 ? params[0] : 'ROMobile.' + params[0];
    let fd = new FormData();
    fd.append('api', api);
    let postData = params.filter((e,i)=> i > 0);
    let jsonStr = JSON.stringify(postData);
    fd.append('json', jsonStr);
    let url = this.url;

    //Set Token
    let headers:HttpHeaders = new HttpHeaders();
    if (this.storage.jwt) headers = headers.append("Authorization", 'Bearer ' + this.storage.jwt);
    let ob = this.http.post(url, fd, {headers:headers});
    if (retry){
      return ob.pipe(retryWhen(err=>{
        return err.pipe(
          delay(2000),
          take(2)
        )})
      );
    } else {
      return ob;
    }
  }

  ro(hash) {
    setTimeout(() => {
      let ts = +new Date();
      window.location.hash = '#ro/' + hash + '/' + ts;
    }, 500);
  }

  handleError(some,error) {
  }

  getCrawlerUrl(article) {
    let url = environment['apiUrl'] + '?api=HelpCrawler.render&json=[]&lang=';
    url = url + this.storage.lang + '&article=' + article;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}
