import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faPlusCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { isFunction } from 'util';
import { WhitePopupService } from '../whitePopupModule/whitePopup.service';

@Component({
	selector: 'agGridFileFormatter2',
	template: `
	<ng-container *ngIf="showData">
		<div class="add" *ngIf="multiAssets || (params && params.jsonObj && params.jsonObj.files && params.jsonObj.files.length==0)">
			<fa-icon
				right [icon]="faPlusCircle" 
				class="add-btn" [class.disable]="!realEditable"
				(click)="addFile($event)"></fa-icon>
			<div class="add-label" *ngIf="label">{{ label }}</div>
		</div>

		<ng-container *ngIf="params && params.jsonObj && params.jsonObj.files && params.jsonObj.files.length>0">
			<ng-container *ngIf="display == 'name'">
				<span
					class="filename"
					*ngFor="let fileObj of params.jsonObj.files; let i = index;"
					[class.color]="params.sectionColor"
					(click)="openMenu($event, fileObj, i)"
				>{{ fileObj && !!fileObj.name?fileObj.name:trimPath(fileObj) }}</span>
			</ng-container>
			<ng-container *ngIf="display == 'thumbnail'">
				<img *ngFor="let fileObj of params.jsonObj.files; let i = index;"
					class="thumbnail" 
					[src]="'//oka.blob.core.windows.net/media/' + (fileObj && !!fileObj.name?fileObj.name:fileObj)"
					[style.height.px]="(params.height?params.height:50)" 
					(click)="openMenu($event, '//oka.blob.core.windows.net/media/' + (fileObj && !!fileObj.name?fileObj.name:fileObj), i)" />
			</ng-container>
		</ng-container>
	</ng-container>
	`,
	styleUrls: ['./fileFormatter2.component.scss']
})

export class FileFormatter2Component /*implements AfterViewInit*/ {
	public faPlusCircle:IconDefinition = faPlusCircle;

	public params: any;
	public curFileObj:any;
	public multiAssets:boolean = false;
	public realEditable:boolean = false;
	public showData:boolean = false;
	public display = 'name';
	public label = '';
	/*
	assets data 格式：
	{
		files:[
			{
				name,
				url
			}, ...
		]
	}
	*/
	
	constructor(public translate:TranslateService, private eleRef:ElementRef, private whitePopupService:WhitePopupService, public renderer2: Renderer2) {
	}
    
    /*ngAfterViewInit(): void {
        //Required by Hung, 左邊不要margin(paddingLeft)
        setTimeout(()=>{
            if (this.eleRef && this.eleRef.nativeElement && this.eleRef.nativeElement.parentElement) {
                this.eleRef.nativeElement.parentElement.style.paddingLeft = '0px';
            }
        });
    }*/

	agInit(params:any): void {
		this.params = params;

		if (params.display){
			this.display = params.display; 
		}
		if (params.label){
			this.label = params.label; 
		}
		if (params.multiAssets !== undefined){
			this.multiAssets = params.multiAssets;
		}
		if(this.params.colDef.cellEditorParams && this.params.colDef.cellEditorParams.multiAssets){
			this.multiAssets = this.params.colDef.cellEditorParams.multiAssets;
		}
		if (this.multiAssets){
			this.renderer2.addClass(this.eleRef.nativeElement,'multi-assets');
		}

		if(!this.params.colDef.orgEditable)
			this.params.colDef.orgEditable = params.colDef.editable;
		params.colDef.editable = this.params.editable?this.params.editable:false; // 防止 edit component create

		this.realEditable = this._realEditable();
		this.showData = !params.hideIf || !params.hideIf(params);
		if (params.keyPress !== undefined){ //from keypress
			if (params.onFileAdd){
				params.onFileAdd(params);
			} else {
				params.onFileReplace(params);
			}
		}
	}

	refresh(params){
		this.agInit(params);
	}

	public _realEditable():boolean {
		if(isFunction(this.params.colDef.orgEditable))
			return this.params.colDef.orgEditable(this.params);
		return this.params.colDef.orgEditable;
	}

	public addFile(event):void {
		if(this.realEditable) {
			if(this.params.onSoundRecord) {
				this.whitePopupService.showSelection(
					event.target, [{
						hideIf: (data:any)=>false, titleKey: 'profile.uploadMedia',
						click: (data:any)=>this.params.onFileAdd(this.params)
					},{
						hideIf: (data:any)=>false, titleKey: 'profile.soundRecord',
						click: (data:any)=>this.params.onSoundRecord(this.params, null)
					}]
				).catch((reason)=>{
				});
		
			} else
				this.params.onFileAdd(this.params);
		}		
	}

	public openMenu(event, fileObj, index=null):void {
		this.curFileObj = fileObj;
		const lang = this.translate.currentLang;
		this.whitePopupService.showSelection(
			event.target, [{
				hideIf: (data:any)=>!this.params.onFilePreview,
				titleKey: 'profile.preview',
				click: (data:any)=>this.params.onFilePreview(this.params, this.curFileObj, this.params.jsonObj.files, index)
			},{
				hideIf: (data:any)=>!this.params.onFileReplace || !this.realEditable,
				titleKey: 'profile.replace',
				click: (data:any)=>this.params.onFileReplace(this.params, this.curFileObj, index)
			},{
				hideIf: (data:any)=>!this.params.onSoundRecord || !this.realEditable,
				titleKey: 'profile.soundRecord',
				click: (data:any)=>this.params.onSoundRecord(this.params, this.curFileObj, index)
			},{
				hideIf: (data:any)=>!this.params.onFileDownload,
				titleKey: 'profile.download',
				click: (data:any)=>this.params.onFileDownload(this.params, this.curFileObj, index)
			},{
				hideIf: (data:any)=>!this.params.onFileDelete || !this.realEditable,
				titleKey: 'profile.delete',
				click: (data:any)=>this.params.onFileDelete(this.params, this.curFileObj, index)
			},{
				hideIf: (data:any)=>!this.params.onAudioPlay || !this.realEditable,
				titleKey: {tc:'播放', sc:'播放', en:'Play'}[lang],
				click: (data:any)=>this.params.onAudioPlay(this.params, this.curFileObj, index)
			}]
		).catch((reason)=>{
		});
	}

	trimPath(url){
		let arr = url.split('/');
		let name = arr[arr.length - 1];
		name = name.replace(/time\(\d+\)-/g, '');
		return name;
	}

}
