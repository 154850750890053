import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from '@angular/router';
import {LessonResolver} from "../lesson.resolver";
import {TranslateModule} from '@ngx-translate/core';
import {LanguageFileSetting, TranslateModuleLoader} from "../../../common/TranslateModuleLoader";
import {WebLessonComponent} from "./web-lesson.component";
import {SelectBookPageComponent} from "./components/select-book-page/select-book-page.component";
import {CreateShareModule} from "../../../sharedModule/createShareModule/create-share.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {WebLessonSelectBookModalComponent} from "./components/web-lesson-select-book-modal/web-lesson-select-book-modal.component";
import {Modal2Module} from "../../../sharedModule/modal2Module/modal2.module";
import {PopupToggleModule} from "../../../sharedModule/popupToggleModule/popupToggle.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import {WebLessonShareListModalComponent} from "./components/web-lesson-share-list-modal/web-lesson-share-list-modal.component";
import {NetworkCheckModule} from "../../../sharedModule/networkCheckModule/network-check.module";
import {DashLineModule} from "./components/dash-line/DashLine.module";
import {WebLessonEditModalComponent} from "./components/web-lesson-edit-modal/web-lesson-edit-modal.component";
import {FormsModule} from "@angular/forms";
import {OkaPulldownModule} from "../../../sharedModule/okaPulldownModule/okaPulldown.module";
import {NumberInputModule} from "../../../sharedModule/numberInputModule/numberInput.module";
import {RadioBoxModule} from "../../../sharedModule/radioBoxModule/radioBox.module";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		TranslateModuleLoader.forRoot(),
		RouterModule.forChild([
			{path: ':lang/:appId/open/:lesson_id', component: WebLessonComponent, resolve: {init: LessonResolver}},
			{path: ':lang/:appId/open/:lesson_id/book/:book_id', component: WebLessonComponent, resolve: {init: LessonResolver}},
			{path: ':lang/:appId/open/:lesson_id/book/:book_id/share/:share_id', component: WebLessonComponent, resolve: {init: LessonResolver}},
		]),
		CreateShareModule,
		FontAwesomeModule,
		Modal2Module,
		PopupToggleModule,
		AngularSvgIconModule,
		NetworkCheckModule,
		DashLineModule,
		FormsModule,
		OkaPulldownModule,
		NumberInputModule,
		RadioBoxModule,
	],
	exports: [
		WebLessonSelectBookModalComponent,
		WebLessonShareListModalComponent,
	],
	declarations: [
		WebLessonComponent,
		SelectBookPageComponent,
		WebLessonSelectBookModalComponent,
		WebLessonShareListModalComponent,
		WebLessonEditModalComponent,
	],
	providers: [
		LessonResolver,
		{
			provide: LanguageFileSetting,
			useValue: {
				basePath: "./assets/langs/",
				languages: ["home-screen", "course", "targetSelector", "bookviewer", 'web-lesson']
			}
		}
	],
})
export class WebLessonModule {
}