import { AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { faCaretDown, faCaretUp,IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-duotone-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'src/app/service/theme.service';
import { AlertService } from 'src/app/service/alert.service';

@Component({
	selector: 'multiSelectPullDown',
	templateUrl: './multiSelectPullDown.component.html',
	styleUrls: ['./multiSelectPullDown.component.scss']
})

export class multiSelectPullDownComponent implements OnInit,OnChanges {
	@HostListener('document:mousedown', ['$event'])
	clickout(event) {
		if(!this.eRef.nativeElement.contains(event.target)) {
			if (this.isOpen) {
				this.isOpen = false
			}
		}
	}
	public select_list_box
	@ViewChild('select_list_box', { static: false }) set content(content: any) {
		if (content) { this.select_list_box = content; }
	}

	@Input() public preset = []
	@Input() public label = "title"
	@Input() public value = "id"
	@Input() public list = []
	@Input() public width = "200px"
	@Input() public disable = false;
	@Input() public min_choice = 0

	@Input() public default_style = 0 
	// 0 for default ,1 for page header,
	// if default_style = 1
	@Input() public placeholder = "pleaseSelect"
	@Input() public title = "Title"
	// style
	@Input() public placeholderStyle = {}
	// if default_style = 0
	@Input() public okaPulldownStyle: any = {};
	@Input() temp: TemplateRef<any> = null

	@Output() check_callback: EventEmitter<any> = new EventEmitter();
	public faCaretDown: IconDefinition = faCaretDown;
	public faCaretUp: IconDefinition = faCaretUp;
	public faSquare = faSquare
	public faSquareCheck = faSquareCheck

	public isOpen = false
	public checked_key = {}
	public checked_list = []

	constructor(public translate: TranslateService, private theme: ThemeService,private eRef: ElementRef ,private als:AlertService) {
		this.theme.applyStyleObj({
			"default": {
				"pulldown-bottomcolor": "#D0D0D0",
				"pulldown-bgcolor": "#F0F0F0",
				"pulldown-overbgcolor": "#E2E2E2",
				"pulldown-shadow-MSPD": "0px 9px 18px rgba(0,0,0,0.3)",
				"pulldown-displaytextcolor": "var(--section-maincolor, --common-textcolor)"
			},
			"dark": {
				"pulldown-bottomcolor": "#000000",
				"pulldown-bgcolor": "#38374F",
				"pulldown-overbgcolor": "#1E1E29",
				"pulldown-shadow-MSPD": "0px 9px 18px rgba(0,0,0,0.3)",
				"pulldown-displaytextcolor": "var(--common-textcolor)"
			}
		})
	}
	ngOnChanges(changes: SimpleChanges): void {
		console.log("changes : ",changes);
		// console.log("this.preset : ",this.preset);

		// 重置list時將重置preset
		if (changes.list) {
			this.init_preset();
		}
		// 當preset變化時重新初始化
		if (changes.preset) {
			this.init_preset();
		}
	}

	ngOnInit(): void {
		this.init_preset()
	}

	init_preset(){
		this.checked_key = {}
		this.checked_list = []
		if (this.preset && this.preset.length != 0) {
			for (const item of this.preset) {
				this.check(item)
			}
		}
	}
	get_checked_text() {
		var label:string = this.checked_list.map((item)=>{
			return item[this.label];
		}).join(", ");
		return label;
	}

	open(element:HTMLElement| any = null) {
		if(this.disable) return;
		this.isOpen = !this.isOpen
		
		if (element) {
			let elementDOM = element.getBoundingClientRect()
			console.log(elementDOM);
			let x = elementDOM.x
			let bottom_y = elementDOM.y+elementDOM.height

			
			setTimeout(() => {
				this.select_list_box.nativeElement.setAttribute(
					'style', 
					`top : ${bottom_y+1}px ;left : ${x}px;
					position:fixed; width : ${this.width}`
				);
			}, 0);
			
		}
		
	}

	check(item) {
		if(item) if(!this.checked_key[item[this.value]]) {
			this.checked_key[item[this.value]] = true
			this.checked_list.push(item)
		} else {
			if (this.checked_list.length <= this.min_choice) {
				this.als.toastError({
					'tc': `最少選${this.min_choice}個`,
					'sc': `最少选${this.min_choice}个`,
					'en': `choose at least ${this.min_choice}`
				}[this.translate.langs[0]] 
				)
				return
			}
			this.checked_key[item[this.value]] = false
			for (const checked_item_idx in this.checked_list) {
				if (this.checked_list[checked_item_idx][this.value] == item[this.value]) {
					this.checked_list.splice(parseInt(checked_item_idx), 1)
				}
			}
		}
		this.check_callback.emit(this.checked_list)
	}

	reset():void {
		this.checked_list = []
		this.checked_key = {}
	}

	resetPreset(preset: any[]):void {
		if (preset.length != 0) {
			for (const item of preset) {
				if (!this.checked_key[item[this.value]]) {
					this.checked_key[item[this.value]] = true
					this.checked_list.push(item)
				} 
			}
		}
	}
}