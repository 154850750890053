import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { AssessmentViewerComponent } from './AssessmentViewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CameraCaptureModule } from '../CameraCaptureModule/CameraCapture.module';
import { ScoringModule } from '../scoringModule/scoring.module';
import { OKDQBModule } from './OKDQB.module';
import { AudioModule } from '../audioModule/audio.module';
import { ROBookModule } from '../roBookModule/ROBook.module';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { WhitePopupModule } from '../whitePopupModule/whitePopup.module';
import { DirectiveModule } from 'src/app/directive/DirectiveModule';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';

@NgModule({
    imports: [
		FontAwesomeModule,CommonModule,TranslateModule
		,CameraCaptureModule, ScoringModule,FormsModule,OKDQBModule
		,AudioModule,ROBookModule, OkaPulldownModule, WhitePopupModule,
		DirectiveModule, UiSwitchModule, BubbleBox2Module
    ],
    declarations: [
        AssessmentViewerComponent
    ],
    exports: [
        AssessmentViewerComponent
    ],
    providers: [],
    bootstrap: []
})
export class AssessmentViewerModule { }
