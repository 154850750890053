import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DOMHelper } from "src/app/common/DOMHelper";
import { Subject, Subscription } from "rxjs";
import { SmoothDrawing } from "./SmoothDrawing";
import { DragManager } from "../DragManager";
import { StickerBase } from "./StickerBase";
import { ROContext } from "../ROContext";
import { faPen, faXmark, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { ByteArrayUtils } from "src/app/ro/hk/openknowledge/utils/ByteArrayUtils";
import { ByteArray, Endian } from "openfl";
import { ROPageComponent } from "../ROComponent";
import { ROBookConfig } from "../ROBookConfig";
import { CubicSpline } from "src/app/common/math/CubicSpline";
import { SmoothDrawingType } from "./SmoothDrawingType";
import { DrawingBase } from "./DrawingBase";
import { CorrrectionPhoto } from "./CorrectionPhoto";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";

@Component({
	selector: 'MarkingLayer',
	template:`
	
	<div #canvasContainer class="canvas-container" [style.transform]="canvasTransform" ></div>
	<!-- 
	<canvas 
	[attr.width]="pageW" [attr.height]="pageH"  
	#canvas></canvas>
	-->
	<!-- 
	<ng-container *ngFor="let epen of epenLayers">
		<div class="teacherEpenMarking" *ngFor="let line of epen.lines" [attr.createBy]="epen.creator">
			<svg *ngIf="line.dataType==1" [attr.viewBox]="viewBox" xmlns="http://www.w3.org/2000/svg">
				<path [attr.d]="line.path" [attr.stroke-width]="line.thickness" [attr.stroke]="line.hexColor" stroke-linecap="round" 
				[style.pointer-events]="elementCanSelect" fill="none" />
			</svg>
		</div>
	</ng-container>
	-->
	<!-- 
	<svg [attr.viewBox]="viewBox" xmlns="http://www.w3.org/2000/svg">
		<ng-container *ngFor="let epen of epenLayers">
			<g class="teacherEpenMarking" *ngFor="let line of epen.lines" [attr.createBy]="epen.creator">
				<g 
					*ngIf="line.dataType==1" >
					<path 
					[attr.d]="line.path" 
					[attr.stroke-width]="line.thickness" 
					[attr.stroke]="line.hexColor" stroke-linecap="round" 
					[style.pointer-events]="elementCanSelect" 
					fill="none" />
				</g>
			</g>
		</ng-container>
		
	</svg>
	-->

	<ng-container *ngFor="let obj of correctionPhotos;let i=index">
		<div class="corrPhoto" [class.canEdit]="!obj.readOnly" [style.left.px]="obj.x" [style.top.px]="obj.y" [attr.photoIndex]="i">
			<img [src]="obj.url" [style.width.px]="obj.width" [style.height.px]="obj.height">
			<fa-icon *ngIf="!obj.readOnly" class="delete" [icon]="faXmark" (tap)="delCorrectionPhoto(i, $event)"></fa-icon>
			<div *ngIf="!obj.readOnly" class="resizer" (pointerdown)="photoResize(i, $event)"></div>
		</div>
	</ng-container>

	<ng-container *ngFor="let obj of stickerLayer;let i=index">
		<div *ngIf="context && context.showSticker && ['starPublic','teacherStickerPublic','teacherStickerSchool'].indexOf(obj.sourceJSON.type)>=0" class="sticker" [style.left.px]="obj.x" [style.top.px]="obj.y" [attr.stickerIndex]="i">
			<!--
			<div class="icon noteFilter" [style.width.px]="obj.size" [style.height.px]="obj.size"
				[style.left.px]="-obj.size/2" [style.top.px]="-obj.size/2">
				<fa-icon *ngIf="!obj.icon" class="noImage" [icon]="faThumbsUp"
				[style.fontSize.px]="obj.size*50/60" [style.borderRadius.px]="obj.size/8"
				></fa-icon>
				<div *ngIf="obj.icon" class="bg" [style.backgroundImage]="obj.icon"></div>

				<div class="createBy" [style.backgroundImage]="obj.profileImg"></div>
				<fa-icon *ngIf="!obj.readOnly" class="delete" [icon]="faXmark"></fa-icon>
				<div *ngIf="obj.canResize" class="resizer"></div>
			</div>
			-->

			<div class="icon noteFilter" [style.width.px]="obj.size" [style.height.px]="obj.size"
				[style.left.px]="-obj.size/2" [style.top.px]="-obj.size/2">
				<fa-icon *ngIf="!obj.icon" class="noImage" [icon]="faThumbsUp"
				[style.fontSize.px]="obj.size*50/60" [style.borderRadius.px]="obj.size/8"
				></fa-icon>
				<div *ngIf="obj.icon" class="bg" [style.backgroundImage]="obj.icon"></div>

				<img class="createBy" *ngIf="obj.profileImg && obj.profileImg.type=='img'" [src]="obj.profileImg.data">
				<div class="createBy" *ngIf="obj.profileImg && obj.profileImg.type=='txt'"><span class="txt">{{obj.profileImg.data}}</span></div>
				<fa-icon *ngIf="!obj.readOnly" class="delete" [icon]="faXmark" (pointerdown)="$event.stopImmediatePropagation();delObject(i)"></fa-icon>
				<div *ngIf="obj.canResize" class="resizer" (pointerdown)="objectResize(i, $event)"></div>
			</div>
		</div>

		<div *ngIf="obj.sourceJSON.type=='teacherTextNote' || obj.sourceJSON.type=='teacherVoiceNote'" class="sticker" 
			[ngClass]="obj.sourceJSON.type" [style.left.px]="obj.x" [style.top.px]="obj.y" [attr.stickerIndex]="i">
			<div class="createBy noteFilter">
				<fa-icon *ngIf="!obj.readOnly" class="delete" [icon]="faXmark"></fa-icon>
			</div>
			<img class="img" *ngIf="obj.profileImg && obj.profileImg.type=='img'" [src]="obj.profileImg.data">
			<div class="img" *ngIf="obj.profileImg && obj.profileImg.type=='txt'"><span class="txt">{{obj.profileImg.data}}</span></div>
			<div class="createBy">
				<div (tap)="obj.togglePopup()"></div>
				<fa-icon *ngIf="!obj.readOnly" class="delete" [icon]="faXmark" 
				(pointerdown)="$event.stopImmediatePropagation();delObject(i)"></fa-icon>
			</div>

			<div class="stickerCtrl textPopup mini" *ngIf="obj.sourceJSON.type=='teacherTextNote' && obj.isOpenPopup && obj.miniPopup"
				[style.left.px]="obj.px" [style.top.px]="obj.py">
				<div class="bg noteFilter">
					<div class="tri" [ngClass]="obj.py>=0 ? 'up' : 'down'" [style.left.px]="-obj.px-9"></div>
				</div>
				
				<div class="row">
					<div class="statictext" [innerHTML]="context.service.domSanitizer.bypassSecurityTrustHtml(obj.editStr)"></div>
					<fa-icon *ngIf="!obj.readOnly && obj.isCreator" (tap)="obj.openPopup(false)" [icon]="faPen" (pointerdown)="$event.stopImmediatePropagation();"></fa-icon>
				</div>
			</div>
			<div class="stickerCtrl textPopup" *ngIf="obj.sourceJSON.type=='teacherTextNote'" [class.hide]="!obj.isOpenPopup || obj.miniPopup" 
				[style.left.px]="obj.px" [style.top.px]="obj.py">
				<div class="bg noteFilter">
					<div class="tri" [ngClass]="obj.py>=0 ? 'up' : 'down'" [style.left.px]="-obj.px-9"></div>
				</div>
				
				<div class="tools">
					<div class="b" [class.select]="obj.state && obj.state.bold" (click)="obj.setTextStyle($event, 'bold')"><b>B</b></div>
					<div class="i" [class.select]="obj.state && obj.state.italic" (click)="obj.setTextStyle($event, 'italic')"><i>I</i></div>
					<div class="u" [class.select]="obj.state && obj.state.underline" (click)="obj.setTextStyle($event, 'underline')"><u>U</u></div>
					
					<div class="c black" [class.select]="obj.state && obj.state.color=='#000000'" (click)="obj.setTextColor($event, '#000000')"></div>
					<div class="c red" [class.select]="obj.state && obj.state.color=='#d53961'" (click)="obj.setTextColor($event, '#d53961')"></div>
					<div class="c green" [class.select]="obj.state && obj.state.color=='#72af00'" (click)="obj.setTextColor($event, '#72af00')"></div>
					<div class="c blue" [class.select]="obj.state && obj.state.color=='#4795ec'" (click)="obj.setTextColor($event, '#4795ec')"></div>
				</div>
				<quill-editor [attr.contenteditable]="obj.isOpenPopup" class="textInput" spellcheck="false"
					[modules]="quillToolsbarSetting"
					[placeholder]="this.placeholder"
					[(ngModel)]="obj.editStr"
					(onEditorCreated)="obj.quill = $event"
					(onSelectionChanged)="obj.updateTextState($event)"
				></quill-editor>
				<div class="btns">
					<!--<div class="copy">{{"workspace.copy" | translate}}</div>
					-->
					<div (tap)="obj.contentUpdate()" class="ok">{{"alert.ok" | translate}}</div>
					<div (tap)="obj.closePopup()" class="cancel">{{"alert.cancel" | translate}}</div>
				</div>
			</div>

			<div class="stickerCtrl voicePopup" [ngClass]="obj.state" *ngIf="obj.sourceJSON.type=='teacherVoiceNote' && obj.isOpenPopup"
				[style.left.px]="obj.px" [style.top.px]="obj.py">
				<div class="bg noteFilter">
					<div class="tri" [ngClass]="obj.py>=0 ? 'up' : 'down'" [style.left.px]="-obj.px-9"></div>
				</div>
				<div class="ui">
					<div (tap)="obj.recordSound()" class="btn rec" [class.norec]="!obj.readOnly && obj.isCreator" [class.ready]="!obj.readOnly && obj.isCreator"></div>
					<div (tap)="obj.stop()" class="btn pause recording playing"></div>
					<div (tap)="obj.play()" class="btn play ready"></div>
					<div class="time norec recording ready playing">{{obj.timeStr}}</div>
					<!--<div class="copy ready playing">{{"workspace.copy" | translate}}</div>
					<fa-icon (tap)="obj.closePopup()" class="ready playing" [icon]="faXmark" (pointerdown)="$event.stopImmediatePropagation();"></fa-icon>
					-->
				</div>
			</div>


			<!--
			<div class="stickerCtrl textPopup" *ngIf="obj.sourceJSON.type=='teacherTextNote'" [class.hide]="!obj.isOpenPopup"
				[style.left.px]="obj.x" [style.top.px]="obj.py" 
				[attr.style]="context.service.domSanitizer.bypassSecurityTrustStyle('--x:'+obj.x+'px;--y:'+obj.y+'px')"
				>
			
				<div class="test">{{obj.px}},{{obj.py}}xxxxxxxxxxZ</div>
			</div>
			-->
		</div>

	</ng-container>

	<svg [attr.viewBox]="viewBox" xmlns="http://www.w3.org/2000/svg" *ngIf="sm">
		<path [attr.d]="sm.path" [attr.stroke-width]="strokeWidth" [attr.stroke]="sm.hexColor" stroke-linecap="round" 
		[style.pointer-events]="elementCanSelect" fill="none" />
	</svg>
	<div class="eraser" [style.display]="eraser.state" [style.left.px]="eraser.x" [style.top.px]="eraser.y"></div>
	`,
	styles:[
		`
		:host {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			overflow:hidden;
			pointer-events:none;
		}

		.canvas-container{
			transform-origin : top left;
		}
		.test{
			position: absolute; top:0;left:0;border-top: solid 1px #000;border-left: solid 1px #000;
			background:#fff;
			transform: translate(calc(min(max(-100% / 2, 0px - var(--x)), var(--pageW) - var(--x) - 100%)), 0);
			/*transform: translate(calc(min(max(-100% / 2, 0px - var(--x)), var(--pageW) - var(--x) - 100%)), calc(min(1px, max(-1px, 100%) ) * 100%));*/
			filter:drop-shadow(0 5px 10px #0000004D);
		}


		.tri{
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			display: inline-block;
			position: absolute;
		}
		.tri.up{
			top: -9px;
			border-bottom: 10px solid var(--bg);
			border-top: 0px solid transparent;
		}
		.tri.down{
			bottom: -9px;
			border-top: 10px solid var(--bg);
			border-bottom: 0px solid transparent;
		}

		.noteFilter{
			filter:drop-shadow(0 5px 10px #0000004D);
			transform: translate3d(0, 0, 0);
		}

		.textPopup.mini, .textPopup.mini .bg{
			height:50px;
		}
		.textPopup.mini .row{
			position: absolute;
			display: flex;
			flex-direction:row;
			left:0;
			top:0;
			width:100%;
			height:100%;
		}
		.textPopup.mini .row .statictext{
			padding: 0 10px;
			display: inline-flex;
			font-family: 'Noto Sans TC';
			font-size: 15px;
			line-height:1.2em;
			flex:auto;
		}
		.textPopup.mini .row fa-icon{display:flex;width:50px;height:50px;font-size:16px;color:#999999;}

		.textPopup{
			z-index:var(--popup-note-z-index);
			--bg:#F9EBD9;
			display: block;
			position: absolute;
			width:260px;
			height:180px;
		}
		.textPopup .bg{
			display: block;
			position: absolute;
			width:260px;
			height:180px;
			border-radius:10px;
			background:var(--bg);
		}
		.textPopup .textInput{
			position: absolute;
			top:40px;
			left:10px;
			width:240px;
			height:85px;
			background:#Fff;
			color:#000;
			border-radius:10px;resize: none;padding:7px;
			user-select: text;
			-webkit-user-select: text;
		}
		.textPopup .textInput::placeholder{color:#ccc;}
		.textPopup .tools{
			position: absolute;
			display: flex;
			flex-direction:row;
			top:5px;
			left:18px;
		}
		.textPopup .tools >div{
			display:block;
			text-align:center;
			width:28px;
			height:28px;
			margin: 0 2px;
			border-radius:5px;
			line-height:28px;
			text-align:center;
			font-family:Times New Roman;
		}
		.textPopup .tools >div.select{background:#ccc}
		.textPopup .c:before{content:'';width:16px;height:16px;border-radius:50%;margin:6px;display:block}
		.textPopup .black:before{background:#000;}
		.textPopup .red:before{background:#D53961;}
		.textPopup .green:before{background:#72AF00;}
		.textPopup .blue:before{background:#4795EC;}
		.textPopup .btns{
			position: absolute;
			top:135px;
			left:17.5px;
			display: flex;
			flex-direction:row;
		}
		.textPopup .btns >div{
			display:block;
			color:#fff;
			font-weight:bold;
			width:65px;
			height:30px;
			border-radius:5px;
			background:#FF6601;
			text-align:center;
			line-height:30px;
			margin:0 5px;
		}
		.textPopup .btns >div.cancel{background:none;color:#FF6601;}

		.textInput ::ng-deep .ql-container.ql-snow {border: none;}
		.textInput ::ng-deep .ql-container .ql-editor{
			font-family: 'Noto Sans TC';
			font-size: 15px;
			line-height:1.2em;
			padding:0;
		}
		.textInput ::ng-deep .ql-editor.ql-blank::before{left:0}

		.voicePopup{
			z-index:var(--popup-note-z-index);
			position: absolute;
			--bg:#D1ECEA;
		}
		.voicePopup .ui >*{display: flex;}
		.voicePopup .bg{
			position: absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			border-radius:10px;
			background:var(--bg);
		}
		.voicePopup .ui{
			display: flex;
			flex-direction:row;
			padding-left:5px;
			position: relative;
			height:60px;
			border-radius:10px;
			background:var(--bg);
			align-items: center;
		}
		.voicePopup .btn{
			width:38px;
			height:38px;
			background:#fff;
			margin:6px;
			border-radius:50%;
			align-items: center;
			box-shadow: #dddddd 0px 2px 0px 0px inset;
		}
		.voicePopup .rec:before{content:'';width:16px;height:16px;border-radius:50%;background:#C24945;margin: auto;}
		.voicePopup .pause:before{content:'';width:16px;height:14px;background:#999999;margin: auto;
			clip-path: polygon(7px 14px, 7px 0px, 0px 0px, 0px 14px, 7px 14px, 9px 14px, 16px 14px, 16px 0px, 9px 0px, 9px 14px);}
		.voicePopup .play:before{content:'';width:14px;height:18px;background:#00BBBB;margin-left:14px;clip-path: polygon(0% 0%, 100% 50%, 0% 100%);}
		.voicePopup .time{width:50px;color:#666666;text-align: center;display: block;margin-right:5px;}
		.voicePopup .copy{width:50px;height:30px;border-radius:5px;background:#00BBBB;
			text-align: center;display: block;line-height: 30px;color:#fff;font-weight:bold;}
		.voicePopup fa-icon{width:40px;height:40px;font-size:22px;color:#666666;}
		.voicePopup.norec .ui>*:not(.norec),
		.voicePopup.recording .ui>*:not(.recording),
		.voicePopup.ready .ui>*:not(.ready),
		.voicePopup.playing .ui>*:not(.playing)
		{display:none;}
		.voicePopup.norec .ui .time{visibility: hidden;}

		.teacherTextNote .createBy,.teacherVoiceNote .createBy,
		.teacherTextNote .img,.teacherVoiceNote .img{
			display: block;
			position: absolute;
			top:-25px;
			left:-25px;
			width:50px;
			height:50px;
			border-radius:50%;
		}
		.teacherTextNote .img{display: flex;background-color: #9b3400;}
		.teacherVoiceNote .img{display: flex;background-color: #006968;}
		.teacherTextNote .txt,.teacherVoiceNote .txt
		{
			margin:auto;
			font-size:20px;
			color:#fff;
			text-transform: capitalize;
		}
		.teacherTextNote .createBy>div,.teacherVoiceNote .createBy>div{
			position:absolute;
			display: block;
			width: 50px;
			height: 50px;
			border-radius:50%;
			position: absolute;
			top: -3px;
			left: -3px;
		}
		.teacherTextNote .createBy{border:solid 3px #FF6600;}
		.teacherVoiceNote .createBy{border:solid 3px #00BBBB;}
		.teacherTextNote .createBy:before,.teacherVoiceNote .createBy:before{
			display: block;
			content:'';
			width: 53px;
			height: 53px;
			position: absolute;
			top: -11px;
			left: -9px;
			background-size: cover;
		}
		.teacherTextNote .createBy:before{background-image: url(./assets/ro/app_icons/app_teacher_text_note.svg);}
		.teacherVoiceNote .createBy:before{background-image: url(./assets/ro/app_icons/app_teacher_voice_note.svg);}
		.sticker, .corrPhoto{
			display: block;
			position: absolute;
			pointer-events:all;
		}
		.sticker .icon{
			display: block;
			position: absolute;
			background-size: cover;
		}
		.sticker:not(.teacherTextNote):not(.teacherVoiceNote) .createBy{
			display: flex;
			position: absolute;
			top:-13px;
			left:-13px;
			width:26px;
			height:26px;
			border-radius:50%;
			background-color:#0071c5;
		}
		.sticker:not(.teacherTextNote):not(.teacherVoiceNote) .txt{
			margin:auto;
			font-size:12px;
			color:#fff;
			text-transform: capitalize;
		}

		.sticker .delete, .teacherTextNote .delete, .teacherVoiceNote .delete{
			display: flex;
			position: absolute;
			top:-9px;
			right:-9px;
			width:18px;
			height:18px;
			border-radius:50%;
			color:#ffffff;
			background-color:#FF6633;
			font-size: 12px;
			pointer-events: all;
		}
		.teacherTextNote .createBy .delete, .teacherVoiceNote .createBy .delete{
			top:-7px;
			right:-7px;
		}
		:host ::ng-deep fa-icon svg{
			margin:auto;
		}
		svg {
			position: absolute;
			top: 0;
			left: 0;
		}
		.sticker .hide{
			display: none;
		}
		.sticker .resizer{
			display: block;
			position: absolute;
			bottom:-9px;
			right:-9px;
			width:18px;
			height:18px;
			border-radius:50%;
			background-color:#cccccc;
		}
		.sticker .resizer:before{
			display:block;
			margin:5px;
			content: "";
			width:8px;
			height:8px;
			background-color:#ffffff;
			clip-path: polygon(0px 6.5px, 6.5px 0px, 0px 0px, 0px 6.5px, 2px 8px, 8px 8px, 8px 2px, 2px 8px);
		}
		.eraser{position: absolute;}
		.eraser:after{
			display: block;
			position: relative;
			margin-left:-50%;
			margin-top:-50%;
			content: "";
			width:10px;
			height:10px;
			border-radius:50%;
			border:solid 1px #999999;
			background-color:#ffffff;
		}

		fa-icon.noImage{
			color: #ead5d9;
			display: flex;
			width: 100%;
			height: 100%;
			background:#fff;
		}
		::ng-deep fa-icon.noImage svg{margin: auto;}
		.sticker .icon .bg{display: block;width: 100%;height: 100%;background-size: cover;}

		.teacherEpenMarking{position: absolute;top:0;left:0;width:100%;height:100%}
		.teacherEpenMarking:not(.createByMe){pointer-events:none}
		.corrPhoto .delete, .corrPhoto .resizer{
			display: flex;
			position: absolute;
			width:20px;
			height:20px;
			border-radius:50%;
			color:#ffffff;
			background-color:#EF3961;
			font-size: 12px;
			pointer-events: all;
		}
		.corrPhoto{display: flex;}
		.corrPhoto.canEdit{
			border:solid 1px #FF575A;
		}
		.corrPhoto .delete{
			top:-10px;
			right:-10px;
		}
		.corrPhoto .resizer{
			bottom:-10px;
			right:-10px;
		}
		.corrPhoto .resizer:before{
			display:block;
			margin:6px;
			content: "";
			width:8px;
			height:8px;
			background-color:#ffffff;
			clip-path: polygon(0px 6.5px, 6.5px 0px, 0px 0px, 0px 6.5px, 2px 8px, 8px 8px, 8px 2px, 2px 8px);
		}
		`
	]
})


export class MarkingLayer {
	
	// @ViewChild("canvas", {static: false}) canvasElementRef: ElementRef;
	@ViewChild("canvasContainer", {static: false}) canvasContainerElementRef: ElementRef;
	private canvas:HTMLCanvasElement;
	
	@Input() pageCom:ROPageComponent = null;
	@Input() context:ROContext = null;
	@Input() viewMode:string;
	@Output() public change:EventEmitter<any> = new EventEmitter();

	public faXmark:any = faXmark;
	public faPen:any = faPen;
	public faThumbsUp:any = faThumbsUp;

	public viewBox:string="0 0 1 1";
	// public strokeWidth:number=1.5;
	public strokeWidth:number = 0.972;
	public epenLayers:any[] = [];
	public correctionPhotos:any[] = [];
	protected myEpenLayer:any;
	public stickerLayer:any[] = [];
	public elementCanSelect:string;
	public eraser:any={state:"none",x:0,y:0,width:10};
	public epenUndoStep:any[] = [];
	public epenRedoStep:any[] = [];

	private dd:DragManager;
	public sm:DrawingBase;
	public selectTool:string;
	public isChange:boolean = false;

	protected actionSubject:Subject<any>;
	protected offset:any;

	// text input
	public quillToolsbarSetting:any = {toolbar: false};
	public placeholder:string = "";
	public pageW:number;
	public pageH:number;

	// public canvasScale:number = 4;
	// public canvasTransform:string = "scale(0.25)";
	public canvasScale:number = 1.5;
	public canvasTransform:string = "scale(0.667)";

	constructor(protected cdr:ChangeDetectorRef, public elementRef:ElementRef) {
		this.elementCanSelect = "none";
		this.selectTool = 'app-hand';
		this.dd = new DragManager();
	}

	private initCanvas():void
	{
		if(!this.canvas && this.pageW)
		{
			var canvasContainer:HTMLDivElement = this.canvasContainerElementRef.nativeElement;
			canvasContainer.innerHTML = "";
			this.canvas = document.createElement("canvas");
			this.canvas.setAttribute("width", this.pageW+"");
			this.canvas.setAttribute("height", this.pageH+"");
			canvasContainer.append(this.canvas);
		}
	}

	reattach() {
		this.cdr.reattach();
		this.redraw(true);
	}

	detach() {
		this.cdr.detach();
		this.removeCanvas();
	}

	removeCanvas():void
	{
		if(this.canvas)
		{
			var canvasContainer:HTMLDivElement = this.canvasContainerElementRef.nativeElement;
			canvasContainer.innerHTML = "";
			this.canvas = null;
		}
	}
	
	drawCubicSplineLineToCanvas(line, ctx)
	{
		ctx.beginPath();
		var xArray = [];
		var yArray = [];
		line.pts.forEach((pt)=> {
			xArray.push(pt.x * this.canvasScale);
			yArray.push(pt.y * this.canvasScale);
		});
		var xSpline = new CubicSpline(xArray);
		var ySpline = new CubicSpline(yArray);
		var fraction = 5;
		var scale = 1 / fraction;
		var max = (xArray.length - 1) * fraction;
		
		for (var i = 0; i <= max; i++) {
			var index = i * scale;
			var px = xSpline.at(index);
			var py = ySpline.at(index);
			if(i == 0)
			{
				ctx.moveTo(px, py);
			} else {
				ctx.lineTo(px, py);
			}
		}
		ctx.strokeStyle = line.hexColor;
		ctx.stroke();
	}
	

	
	drawQuadraticCurveLineToCanvas(line, ctx)
	{
		ctx.beginPath();
		var lPt:any= null;
		var lastIndex = line.pts.length - 1;
		var lMPt:any = null;
		line.pts.forEach((pt, index)=> {
			var newPt:any = {x:pt.x * this.canvasScale, y:pt.y * this.canvasScale};
			if(index == 0){
				ctx.moveTo(newPt.x, newPt.y);
				
			} else {
				var mPT = {x:(newPt.x + lPt.x)/2, y:(newPt.y + lPt.y)/2};
				if(index == 1){
					ctx.lineTo(mPT.x, mPT.y);
				} else {
					ctx.quadraticCurveTo(lPt.x, lPt.y, mPT.x, mPT.y);
				}
				if(index == lastIndex )
				{
					ctx.lineTo(newPt.x, newPt.y);
				}
				lMPt = mPT;
			}
			lPt = newPt;
		});
		ctx.strokeStyle = line.hexColor;
		ctx.stroke();
	}
	
	drawBezierCurveLineToCanvas(line, ctx)
	{
		// Bezier Curve
		this.drawQuadraticCurveLineToCanvas(line, ctx);
		
	}

	drawLineToCanvas(line, ctx)
	{
		ctx.beginPath();
		line.pts.forEach((pt, index)=> {
			var newPt:any = {x:pt.x * this.canvasScale, y:pt.y * this.canvasScale};
			if(index == 0){
				ctx.moveTo(newPt.x, newPt.y);
			} else {
				ctx.lineTo(newPt.x, newPt.y);
			}
		});
		ctx.strokeStyle = line.hexColor;
		ctx.stroke();
	}
	redraw(all:boolean = true):void
	{
		this.initCanvas();
		if(!this.canvas) return;
		const ctx = this.canvas.getContext("2d");
		if(all)
		{
			ctx.clearRect(0, 0, this.pageW, this.pageH);
		}
		var lineScale:number = this.canvasScale;
		this.epenLayers.forEach((epen)=>{
			epen.lines.forEach((line)=>{
			
				if(all || line.completed == false)
				{
					if(line.dataType == 1)
					{
						ctx.lineWidth = line.thickness * lineScale;
						ctx.lineJoin = "round";
						
						// CubicSpline
						// this.drawCubicSplineLineToCanvas(line, ctx);
						
						// Bezier Curve
						this.drawQuadraticCurveLineToCanvas(line, ctx);
						// Line To
						// this.drawLineToCanvas(line, ctx);
					}
					line.completed = true;
				}
			})

		})
	}
	
	ngOnChanges(changes: SimpleChanges) {
		if(!this.pageCom) return;
		this.pageW = this.pageCom.node.attributes.w * this.canvasScale;
		this.pageH = this.pageCom.node.attributes.h * this.canvasScale;
		
		if(changes.viewMode) {
			this.elementCanSelect = changes.viewMode.currentValue == "scoring" ? "all" : "none";
		}
		if(changes.context) {
			this.placeholder = this.context.service.translateService.instant("bookviewer.note_hints");
		}
		if(changes.pageCom) {
			var style = this.elementRef.nativeElement.style;
			style.width = this.pageCom.node.attributes.w+"px";
			style.height = this.pageCom.node.attributes.h+"px";
			style.setProperty('--pageW', this.pageCom.node.attributes.w+"px");
			style.setProperty('--pageH', this.pageCom.node.attributes.h+"px");
			this.viewBox = "0 0 "+this.pageCom.node.attributes.w+" "+this.pageCom.node.attributes.h;
		}
	}

	public reset():void {
		this.epenUndoStep = [];
		this.epenRedoStep = [];
		this.myEpenLayer = null;
		this.stickerLayer = [];
		this.isChange = false;
		this.correctionPhotos = [];
	}

	//protected decodeEpenData(b64Str:string):any[] {
	public decodeEpenData(epenObject:any):any {
		var b64Str:string = epenObject.data;
		var ary:any[] = epenObject.lines = [];
		try{
			if(b64Str) {
				var ba:ByteArray = ByteArrayUtils.fromBase64(b64Str);
				if(ba) {
					ba.uncompress();
					ba.position = 0;

					while (ba.bytesAvailable > 0) {
						var typeID:number = ba.readByte();
						if (typeID == 0) {
							// marking pen data
							var stroke = new DrawingBase().fromBytes(SmoothDrawingType.DT_EPEN, ba)
							// ary.push(SmoothDrawing.fromBytes(SmoothDrawing.DT_EPEN, ba));
							ary.push(stroke);
						} else if (typeID == 1) {
							// extera score data (skip)
							ba.readByte(); // score
							ba.readInt(); // x
							ba.readInt(); // y

						} else if (typeID == 2) {
							// skip data
							ba.readUnsignedInt();  // createBy
							ba.readInt(); // x
							ba.readInt(); // y
							ba.readShort(); // size

						} else if (typeID == 3) {
							// 將新 like sticker 轉返舊 (skip)
							ba.readUnsignedInt(); // sticker id
							ba.readUnsignedInt(); // createBy
							ba.readInt(); // x
							ba.readInt(); // y
							ba.readShort(); // size

						} else if ((typeID & 0x80)!=0) {
							// 舊 sticker (skip)
							ba.readInt(); // x
							ba.readInt(); // y
						}
					}
				}
			}
		} catch (err) {
			console.log("marking data1 error", err);
		}

		return epenObject;
	}

	protected encodeEpenData(lines:any[]):string {
		if (lines.length == 0) return null;

		var ba:ByteArray = new ByteArray();
		ba.endian = Endian.BIG_ENDIAN;
		lines.forEach(obj => {
			if(obj instanceof DrawingBase) {
				if (obj.pts.length > 1)
					ba.writeByte(0);
				obj.writeBytes(ba);
			}
		});
		
		ba.compress();
		return ByteArrayUtils.toBase64(ba);
	}

	// 電子筆資料
	/*
	分做 multi marking ，用 component id 去分那個老師 mark (#marking#uid)
	輸入格式：
	[
		{
			creator, // 舊 epen 會 mark 做 ""
			data, // base 64 encode string
			lines // decode data (create 出來)
		}, ...
	]
	*/
	public initEpenMarkingsAndCorrection(value:any[], correction:any, correctionPhoto:any):void {
		this.reset();

		value.forEach((d,index) => {
			this.decodeEpenData(d);

			if(d.creator == this.context.config.viewerID)
				this.myEpenLayer = d;
		});
		this.epenLayers = value;

		if(this.viewMode == "correction") {
			if(!correction)
				this.myEpenLayer = {tag:"#correction#", creator:this.context.config.viewerID, data:null, lines:[]};
			else {
				this.myEpenLayer = correction;
				this.decodeEpenData(this.myEpenLayer);
			}
			this.epenLayers.push(this.myEpenLayer);

		} else {
			if(correction)
				this.epenLayers.push(this.decodeEpenData(correction));
			
			if(!this.myEpenLayer) {
				this.myEpenLayer = {tag:"#marking#", creator:this.context.config.viewerID, data:null, lines:[]};
				this.epenLayers.push(this.myEpenLayer);
			}
		}

		if(correctionPhoto && correctionPhoto.data) {
			var ary:any[] = JSON.parse(correctionPhoto.data);
			ary.forEach(e=> {
				this.correctionPhotos.push(new CorrrectionPhoto(this, e, this.viewMode != "correction"));
			});
		}

		this.updateStep();
		this.redraw(true);
	}	

	public getChangedEpenMarkings():any {
		var data:string = this.encodeEpenData(this.myEpenLayer.lines);
		this.myEpenLayer.data = data;
		return this.myEpenLayer;
	}

	// 貼紙資料
	public set data2(value:any[]) {
		var creator:number = this.context.config.viewerID;
		var readOnly:boolean = this.context.config.readOnly;//this.viewMode == "review";
		value.forEach((d,index) => {
			if(!(d instanceof StickerBase)) {
				// 未轉換的 data
				if (typeof d.remark === 'string') {
					d.remark = JSON.parse(d.remark);
				}
				if(isNaN(d.remark.x) || d.remark.x == null)
					d.remark.x = 100;
				if(isNaN(d.remark.y) || d.remark.y == null)
					d.remark.y = 100;
				value[index] = new StickerBase(this, d, creator==d.remark.createBy, readOnly);
			}
		});

		this.stickerLayer = value;
	}

	public get data2():any[] {
		return this.stickerLayer;
	}

	public setSelectTool(tool:string):void {
		if(tool == 'app-clear') {
			this.epenUndoStep.push({
				action:"del",
				datas:this.myEpenLayer.lines,
				index:0
			});
			this.epenRedoStep = [];

			this.myEpenLayer.lines=[];
			this.sm = null;
			this.change.emit({type:"epen", redraw:true, from:"setSelectTool app-clear"});

		} else if(tool == 'app-undo') {
			if(this.epenUndoStep.length) {
				var d:any = this.epenUndoStep.pop();
				this.epenRedoStep.push(d);

				if(d.action == "add") {
					this.myEpenLayer.lines.pop();

				} else if(d.action == "del") {
					d.datas.forEach((e,i)=> {
						this.myEpenLayer.lines.splice(d.index+i, 0, e);
					});
				}

				this.updateStep();
				this.change.emit({type:"epen", redraw:true, from:"setSelectTool epen"});
			}

			
		} else if(tool == 'app-redo') {
			if(this.epenRedoStep.length) {
				var redrawFlag:boolean = false;
				var d:any = this.epenRedoStep.pop();
				this.epenUndoStep.push(d);

				if(d.action == "add") {
					var stroke = d.datas[0];
					stroke.completed = false;
					this.myEpenLayer.lines.push(stroke);
					redrawFlag = false;
				} else if(d.action == "del") {
					redrawFlag = true;
					this.myEpenLayer.lines.splice(d.index, d.datas.length);
				}
				this.updateStep();
				this.change.emit({type:"epen", redraw:redrawFlag, from:"setSelectTool app-redo"});
			}
		} else {
			this.selectTool = tool;
		}
	}

	public isSelectEpenTool():boolean {
		return ['app-red-epen','app-purple-epen','app-blue-epen','app-correction-epen','app-straight-line'].indexOf(this.selectTool)>=0;
	}

	public ePress2(event:any, actionType:string):void {
		// 自己取一次個 tools，因轉頁後個 tools 會不對
		this.context.subject.next({type:"getCurrentScoringTool", target:this});
		if(this.context.config.readOnly || !this.context.config.markingEnabled)
			this.selectTool  = "app-hand";

		// 如是擦膠和貼紙動作交由 pointer event 處理
		if(this.selectTool == 'app-eraser')
			return;
		if(!this.processSticker(event, actionType, false)) {
			if(this.processCorrectionPhoto(event, actionType))
				return; // processCorrectionPhoto 處理了
		} else
			return; // processSticker 處理了

		// 如多過一點時，會假設在 zoom ，會取消 tools 的動作
		if(event.touches.length>1) {
			if(this.actionSubject)
				this.actionSubject.next({type:"cancel"});
			return;
		}

		// 當是電子筆工具時，則進行畫線
		if(this.isSelectEpenTool()) {
			this.processEpen(event, 'touch');
		}

		// 檢查這一刻是否 assign focus 去 text input
		var hitElement:HTMLElement = event.target;
		while(hitElement && hitElement.nodeName!="QUILL-EDITOR") {
			hitElement = hitElement.parentElement;
		}

		// 如這一刻不是 assign focus 去 text input ，看之前是否 focus text input，如是將佢 focus out
		if(!(hitElement && hitElement.nodeName=="QUILL-EDITOR"))
			this.releaseTextInputFocus();
	}

	protected releaseTextInputFocus():void {
		// 如果有 focus text input，就將佢 focus out
		if(document.activeElement && document.activeElement.nodeName == "QUILL-EDITOR")
			(<HTMLElement>document.activeElement).blur();
	}

	public ePress(event:PointerEvent):void {
		// 自己取一次個 tools，因轉頁後個 tools 會不對
		this.context.subject.next({type:"getCurrentScoringTool", target:this});
		if(this.context.config.readOnly || !this.context.config.markingEnabled)
			this.selectTool  = "app-hand";

		var epenON:boolean = this.isSelectEpenTool();
		if(this.selectTool == 'app-eraser') {
			this.eraser.state = "block";
			// eraser 移動動作處理
			var epenChange:boolean = false;
			this.actionSubject = this.dd.pointerStart(event);
			var subscription:Subscription = this.actionSubject.subscribe((o:any)=>{
				
				if(o.type == "cancel" || o.type == "timeout" || o.type == "end") {
					subscription.unsubscribe();
					this.eraser.state = "none";
					if(o.type == "end" && epenChange) {
						this.change.emit({type:"epen", redraw:true, from:"ePress app-eraser"});
					}
					this.actionSubject = null;

				} else {
					var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
					this.eraser.x = Math.round(pt.x);
					this.eraser.y = Math.round(pt.y);

					var hasEPenChanged:boolean = false;
					for(var i:number=0; i<this.myEpenLayer.lines.length; i++) {
						var obj:any = this.myEpenLayer.lines[i];
						if(obj.hitTestEraser(this.eraser)) {
							// 刪除 marking epen
							this.removeEpen(i);
							i--;
							hasEPenChanged = true;
						}
					}
					
					for(var i:number=0; i<this.stickerLayer.length; i++) {
						var sticker:StickerBase = this.stickerLayer[i];
						if(sticker.hitTestEraser(this.eraser)) {
							this.delObject(i);
							i--;
						}
					}
					if(hasEPenChanged) {
						epenChange = true;
						this.redraw(true);
					}
				}
			});

			event.preventDefault();
			event.stopImmediatePropagation();

		} else if(!this.processCorrectionPhoto(event, 'pointer') && !this.processSticker(event, 'pointer', !epenON)  && epenON) { // 當不是 epen mode 時，就在 function 內 create sticker
			if(epenON) {
				// pen 畫線部份交比 touch event ，因 pointer event 會因特殊動作而少收 event 
				// mouse 畫線部份交比 pointer event 
				if(event.pointerType == 'mouse')
					this.processEpen(event, 'pointer');
			}

			this.releaseTextInputFocus();
		}
		
		// 由轉 tools 時去設定是否可 drag page
	}

	protected processEpen(event:any, actionType:string):void {
		// 寫 marking epen
		if(actionType == "touch")
			this.actionSubject = this.dd.touchStart(event);
		else if(actionType == "pointer")
			this.actionSubject = this.dd.pointerStart(event);
		this.change.emit({type:"epenStart", from:"processEpen"})
		this.context.subject.next({
			type:"notify", 
			notify:{
				type:"ePenStart"
			}
		});
		var subscription:Subscription = new Subscription(()=>{
			this.context.subject.next({
				type:"notify", 
				notify:{
					type:"ePenEnd"
				}
			});
		})
		subscription.add(this.actionSubject.subscribe((o:any)=>{
			if(o.type == "cancel" || o.type == "timeout") {
				subscription.unsubscribe();
				this.sm = null;
				this.actionSubject = null;

			} else {
				var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
				pt.x = Math.round(pt.x);
				pt.y = Math.round(pt.y);
				
				if(o.type == "start") {
					var strokeColor:number = 0xf4556a;
					if(this.selectTool == 'app-purple-epen')
						strokeColor = 0x6633cc;
					else if(this.selectTool == 'app-blue-epen')
						strokeColor = 0x24bdb8;
					else if(this.selectTool == 'app-correction-epen')
						strokeColor = 0x1755a9;
					// this.sm = new DrawingBase().setup(SmoothDrawing.DT_EPEN,this.strokeWidth,strokeColor,1);
					this.sm = new SmoothDrawing().setup(SmoothDrawingType.DT_EPEN,this.strokeWidth,strokeColor,1);
					// this.sm = new SmoothDrawing(SmoothDrawing.DT_EPEN,this.strokeWidth,strokeColor,1);
					// this.sm = new LineDrawing(SmoothDrawing.DT_EPEN,this.strokeWidth,strokeColor,1);
				}
				
				if(this.selectTool!='app-straight-line')
					this.sm.addPoint(pt, true);
				else
					this.sm.lineTo(pt, true);

				if(o.type == "end") {
					subscription.unsubscribe();
					this.sm.simplify();
					this.addEpen(this.sm, true);

					this.sm = null;
					this.actionSubject = null;
				}
			}
		}));

		event.preventDefault();
		event.stopImmediatePropagation();
	}

	protected processSticker(event:any, actionType:string, create:boolean):boolean {
		var ppt:any = event;
		if(actionType == "touch")
			ppt = {x:event.touches[0].pageX, y:event.touches[0].pageY};
		else
			ppt = {x:event.pageX, y:event.pageY};

		var sticker:StickerBase;
		var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, ppt);
		pt.x = Math.round(pt.x);
		pt.y = Math.round(pt.y);

		// 計算 hit 到的 component
		var hitElement:Element = document.elementFromPoint(ppt.x, ppt.y);
		var hitComElement:Element = hitElement;
		while(hitComElement && !hitComElement.classList.contains("ro-component") && 
			!hitComElement.classList.contains("sticker") && !hitComElement.classList.contains("stickerCtrl") && 
			hitComElement!=this.elementRef.nativeElement) {
			hitComElement = hitComElement.parentElement;
		}

		this.offset = {x:0,y:0};
		if(hitComElement) {
			if(hitComElement.classList.contains("sticker")) {
				// 移動現有 sticker
				sticker = this.stickerLayer[parseInt(hitComElement.getAttribute("stickerIndex"))];
				if(!sticker.readOnly) {
					this.offset.x = pt.x - sticker.x;
					this.offset.y = pt.y - sticker.y;
				} else {
					sticker = null;
				}

			} else if(hitComElement.classList.contains("stickerCtrl")) {
//				event.preventDefault();
				event.stopImmediatePropagation();
				return true;
			}
		}
		
		// 只做 detect hit sticker 時的處理
		if(!create && sticker==null)
			return false; // hit 唔到 sticker，又不是選建立 sticker 工具時就直接回傳 false
		if(actionType!="pointer")
			return true;
		
		if(!sticker) {
			// 無 drag 到舊 sticker
			if(this.selectTool == 'app-sticker-like') {
				// 新增 sticker
				sticker = this.addTeacherStickerPublic(2, pt.x, pt.y);
			} else if(this.selectTool == 'app-sticker-star') {
				sticker = this.addStarSticker(pt.x, pt.y);
			} else if(this.selectTool.indexOf("app-sticker-icon")>=0) {
				sticker = this.addTeacherStickerPublic(parseInt(this.selectTool.substr(16)), pt.x, pt.y);
			} else if(this.selectTool == 'app-teacherTextNote') {
				sticker = this.addTeacherTextNote(pt.x, pt.y);
			} else if(this.selectTool == 'app-teacherVoiceNote') {
				sticker = this.addTeacherVoiceNote(pt.x, pt.y);
			} else if(this.selectTool.indexOf('selectSchoolSticker#')==0) {
				sticker = this.addSchoolSticker(parseInt(this.selectTool.split("#")[1]), pt.x, pt.y);
			}
		} else if(sticker.dataID==-1)
			return true; // 未入 DB ，暫不處理其他動作

		if(sticker) {
			// sticker 移動動作處理
			this.actionSubject = this.dd.pointerStart(event);
			var subscription:Subscription = this.actionSubject.subscribe((o:any)=>{
				if(o.point) {
					var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
					sticker.x = Math.max(Math.round(pt.x) - this.offset.x,sticker.size/2);
					sticker.y = Math.max(Math.round(pt.y) - this.offset.y,sticker.size/2);
					var attr:any = this.pageCom.node.attributes;
					sticker.x = Math.min(attr.w-10-sticker.size/2, sticker.x);
					sticker.y = Math.min(attr.h-10-sticker.size/2, sticker.y);
				}
				
				if(o.type == "cancel" || o.type == "timeout" || o.type == "end") {
					subscription.unsubscribe();
					if(o.type == "end") {
						if(sticker.dataID==-1)
							sticker.openPopup();
						this.change.emit({type:(sticker.dataID==-1 ? "addSticker" : "updateSticker"), data:sticker, from:"processSticker"});
					}
					this.actionSubject = null;

				}

				sticker.updatePopupPosition();
			});

			event.preventDefault();
			event.stopImmediatePropagation();
		}
		return true;
	}

	protected addStudentMarking(sticker:StickerBase):StickerBase {
		this.stickerLayer.push(sticker);
		return sticker;
	}

	public addTeacherStickerPublic(id:number, x:number, y:number):StickerBase {
		return this.addSticker("teacherStickerPublic", id,x,y);
	}

	public addSchoolSticker(id:number, x:number, y:number):StickerBase {
		return this.addSticker(id<0 ? "teacherStickerPublic" : "teacherStickerSchool", id,x,y);
	}

	public addStarSticker(x:number, y:number):StickerBase {
		return this.addSticker("starPublic", 0,x,y);
	}

	public addSticker(type:string, id:number, x:number, y:number):StickerBase {
		let creator:number = this.context.config.viewerID;
		return this.addStudentMarking(new StickerBase(this, {
			id: -1, type: type, num:1, remark:{
				createBy:creator, x:x, y:y, w:80, h:80, id:id
			}
		}, true, false));
	}

	public addTeacherTextNote(x:number, y:number):StickerBase {
		let creator:number = this.context.config.viewerID;
		return this.addStudentMarking(new StickerBase(this, {
			id: -1, type: "teacherTextNote", num:1, remark:{
				createBy:creator, x:x, y:y, text:"", id:0
			}
		}, true, false));
	}

	public addTeacherVoiceNote(x:number, y:number):StickerBase {
		let creator:number = this.context.config.viewerID;
		return this.addStudentMarking(new StickerBase(this, {
			id: -1, type: "teacherVoiceNote", num:1, remark:{
				createBy:creator, x:x, y:y, id:0,
				resourceIndex:this.findCanUseResourceIndex("teacherVoiceNote") // 找可用 resource id
			}
		}, true, false));
	}

	protected findCanUseResourceIndex(type:string):number {
		var ary:any[];
		if(type=="teacherVoiceNote")
			ary = this.stickerLayer.filter(d => d.sourceJSON.type == type).map(d => d.sourceJSON.remark.resourceIndex);
		else if(type=="correction_photo")
			ary = this.correctionPhotos.map(d => d.sourceJSON.index);
		else
			return 0;
		ary = ary.sort((a,b) =>a - b);
		
		var index:number = this.context.config.index*1000; // 用 1000 去分隔不同提交 index 的記錄
		for (var i:number = 0; i < ary.length; i++) {
			if (ary[i] > index)
				break;
			index++;
		}
		return index;
	}

	public delObject(index:number):void {
		this.change.emit({type:"removeSticker", data:this.stickerLayer[index], from:"delObject"});
		this.stickerLayer.splice(index,1);
	}

	public objectResize(index:number, event:PointerEvent):void {
		event.preventDefault();
		event.stopImmediatePropagation();

		var sticker:StickerBase = this.stickerLayer[index];
		var subject:Subject<any> = this.dd.pointerStart(event);
		this.offset = {x:0,y:0};
		var subscription:Subscription = subject.subscribe((o:any)=>{
			var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
			pt.x = Math.round(pt.x);
			pt.y = Math.round(pt.y);
			if(o.type=="start") {
				this.offset.x = pt.x - (sticker.x + sticker.size/2);
				this.offset.y = pt.y - (sticker.y + sticker.size/2);
			}
			
			if(o.type == "cancel" || o.type == "timeout") {
				subscription.unsubscribe();
			} else {
				sticker.size = Math.max(Math.min(pt.x - this.offset.x - sticker.x, pt.y - this.offset.y - sticker.y, sticker.maxSize/2), 20)*2;
				if(o.type == "end") {
					subscription.unsubscribe();
					this.change.emit({type:"updateSticker", data:sticker, from:"objectResize"});
				}
					
			}
		});
	}

	public pasteSticker():void {

	}

	/*public drawOnCanvas (stroke:any[]):void {
		this.drawContext.strokeStyle = 'black'
		this.drawContext.lineCap = 'round'
		this.drawContext.lineJoin = 'round'
	  
		const l = stroke.length - 1
		if (stroke.length >= 3) {
		  const xc = (stroke[l].x + stroke[l - 1].x) / 2
		  const yc = (stroke[l].y + stroke[l - 1].y) / 2
		  this.drawContext.lineWidth = stroke[l - 1].lineWidth
		  this.drawContext.quadraticCurveTo(stroke[l - 1].x, stroke[l - 1].y, xc, yc)
		  this.drawContext.stroke()
		  this.drawContext.beginPath()
		  this.drawContext.moveTo(xc, yc)
		} else {
		  const point = stroke[l];
		  this.drawContext.lineWidth = point.lineWidth
		  this.drawContext.strokeStyle = point.color
		  this.drawContext.beginPath()
		  this.drawContext.moveTo(point.x, point.y)
		  this.drawContext.stroke()
		}
	  }*/

	public addEpen(d:any, update:boolean):void {
		this.epenUndoStep.push({
			action:"add",
			datas:[d]
		});
		this.epenRedoStep = [];

		this.myEpenLayer.lines.push(d);

		if(update)
			this.change.emit({type:"epen", redraw:false, from:"addEpen"});
		this.updateStep(); // update undo/redo icon
	}

	public removeEpen(index:number):void {
		this.epenUndoStep.push({
			action:"del",
			datas:this.myEpenLayer.lines.splice(index,1),
			index:index
		});
		this.epenRedoStep = [];
		this.updateStep();
	}

	protected updateStep():void {
		this.context.subject.next({
			type:"scoringEpenUndoRedo", 
			undo:this.epenUndoStep.length,
			redo:this.epenRedoStep.length
		});
	}


	// =======================================
	// correction photo
	// =======================================
	public addCorrectionPhoto(file:File, width:number, height:number):void {
		var resourceIndex:number = this.findCanUseResourceIndex("correction_photo");

		var cfg:ROBookConfig = this.context.config;
		var comID:string = "#correction_photo#";
		var ref:any = {key:GUID.create("OKD guid"), reference:this.context.createReference(), index:resourceIndex};
		this.context.assetUploader.uploadAsset(
			{bsid:cfg.share ? cfg.share.id : 0, bookId: cfg.book.id}, 
			{chapterId:this.pageCom.chapter.id, pageId:this.pageCom.douid, componentId:comID}, 
			ref, file).then(assetURL=>{
			
			ref= {
				key:ref.key,
				reference:ref.reference,
				index:resourceIndex,
				orgWidth:width,
				orgHeight:height,
				x:0,
				y:0,
				uid:cfg.viewerID
			}
			if(width>height) {
				ref.width = 300;
				ref.height = 300*height/width;
			} else {
				ref.height = 300;
				ref.width = 300*width/height;
			}

			this.correctionPhotos.push(new CorrrectionPhoto(this, ref, false, URL.createObjectURL(file)));
			this.change.emit({type:"correctionPhoto", from:"addCorrectionPhoto"});
		}); 

		/*
		var obj:any = {
			orgWidth:width,
			orgHeight:height,
			x:0,
			y:0,
			uid:this.context.config.viewerID,
			resourceIndex:0
		};
//		obj.url = this.context.service.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
		if(width>height) {
			obj.width = 300;
			obj.height = 300*height/width;
		} else {
			obj.height = 300;
			obj.width = 300*width/height;
		}

		var corrPhoto:CorrrectionPhoto = new CorrrectionPhoto(this, obj, false, URL.createObjectURL(file));
		this.correctionPhotos.push(corrPhoto);

		console.log(this.pageCom);
		*/
	}

	public getChangedCorrectionPhotos():string {
		var ary:any[] = [];
		this.correctionPhotos.forEach(e=>{
			ary.push(e.sourceJSON);
		});
		return JSON.stringify(ary);
	}

	public delCorrectionPhoto(index:number, event:any):void {
		this.correctionPhotos.splice(index,1);
		this.change.emit({type:"correctionPhoto", from:"delCorrectionPhoto"});
	}

	public photoResize(index:number, event:any):void {
		event.preventDefault();
		event.stopImmediatePropagation();

		var corrPhoto:CorrrectionPhoto = this.correctionPhotos[index];
		var originalPosition:any = {width:corrPhoto.width, height:corrPhoto.height};
		var attr:any = this.pageCom.node.attributes;
		
		this.dd.monitorMovement(event.target.parentElement, event, (type, movement)=> {
			if(type == "cancel" || type == "timeout"){
				// restore original position
				corrPhoto.width = originalPosition.width;
				corrPhoto.height = originalPosition.height;
			} else {
				corrPhoto.width = Math.max(20, Math.min(attr.w - 10 - corrPhoto.x, originalPosition.width + movement.x));
				corrPhoto.height = Math.max(20, Math.min(attr.h - 10 - corrPhoto.y, originalPosition.height + movement.y));

				if(type == "end") {
					this.change.emit({type:"correctionPhoto", from:"photoResize"});
				}
			}
		});
	}

	protected processCorrectionPhoto(event:any, actionType:string):boolean {
		var ppt:any = event;
		if(actionType == "touch")
			ppt = {x:event.touches[0].pageX, y:event.touches[0].pageY};
		else
			ppt = {x:event.pageX, y:event.pageY};

		var corrPhoto:CorrrectionPhoto;
		var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, ppt);
		pt.x = Math.round(pt.x);
		pt.y = Math.round(pt.y);

		// 計算 hit 到的 component
		var hitElement:Element = document.elementFromPoint(ppt.x, ppt.y);
		var hitComElement:Element = hitElement;
		while(hitComElement && !hitComElement.classList.contains("ro-component") && 
			!hitComElement.classList.contains("corrPhoto") &&
			hitComElement!=this.elementRef.nativeElement) {
			hitComElement = hitComElement.parentElement;
		}

		this.offset = {x:0,y:0};
		if(hitComElement) {
			if(hitComElement.classList.contains("corrPhoto")) {
				// 移動現有 correction photo
				corrPhoto = this.correctionPhotos[parseInt(hitComElement.getAttribute("photoIndex"))];
				if(!corrPhoto.readOnly) {
					this.offset.x = pt.x - corrPhoto.x;
					this.offset.y = pt.y - corrPhoto.y;
				} else {
					corrPhoto = null;
				}

			}
		}
		
		// 只做 detect hit correction photo 時的處理
		if(corrPhoto==null)
			return false; // hit 唔到 correction photo
		if(actionType!="pointer")
			return true;

		if(corrPhoto) {
			// correction photo 移動動作處理
			this.actionSubject = this.dd.pointerStart(event);
			var subscription:Subscription = this.actionSubject.subscribe((o:any)=>{
				if(o.point) {
					var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
					var attr:any = this.pageCom.node.attributes;
					corrPhoto.x = Math.min(attr.w-10-corrPhoto.width, Math.max(Math.round(pt.x) - this.offset.x, 10));
					corrPhoto.y = Math.min(attr.h-10-corrPhoto.height, Math.max(Math.round(pt.y) - this.offset.y, 10));
				}
				
				if(o.type == "cancel" || o.type == "timeout" || o.type == "end") {
					subscription.unsubscribe();
					if(o.type == "end") {
						this.change.emit({type:"correctionPhoto", from:"processCorrectionPhoto"});
					}
					this.actionSubject = null;

				}

			});

			event.preventDefault();
			event.stopImmediatePropagation();
		}
		return true;
	}
}