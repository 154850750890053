import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class InitResolver implements Resolve<any> {
	private checkAccountIsValid = false;//未使用
	constructor(private datas: DataService, private translate: TranslateService, private route: ActivatedRoute, private router: Router) {
	}

	resolve(aRoute: ActivatedRouteSnapshot) {
		if (!this.datas.getLang()) this.datas.setLang(); //登入後改用PersonalSetting內的lang

		this.route.params.subscribe((params) => {
			if (params['encoded']){
				let encoded = params['encoded'].replace('%2F','/').replace('%3F','?').replace('%3D','=').replace('%26','&');
				this.datas.lang = encoded.substr(0,2);
				this.datas.appId = encoded.substr(3, encoded.indexOf('?'))
			}
			if (params['appId']) {
				this.datas.appId = params['appId'];
				let sessionAppId = sessionStorage.getItem('appId');
				if (sessionAppId) {
					this.datas.appId = sessionAppId;
				}
			}

			// if (params['lang']) this.datas.lang = params['lang'];
			// if (this.translate.currentLang != this.datas.lang) this.translate.use(this.datas.lang);
			if (params['lang'] && params['lang']!=this.datas.lang) this.datas.setLang(params['lang']);
		});

		if (location.hostname.indexOf('readingstar.net') > -1 && this.datas.appId2 != 'readingstar'){
			this.datas.appId = 'readingstar';
			setTimeout(()=>{ 
				let href = location.href;
				href = href.replace(this.datas.appId2, 'readingstar');
				if (location.href != href) location.href = href;
			}, 10);
		}

		if (location.hostname.indexOf('rainbowse.fun') > -1 && this.datas.appId2 != 'rainbowse'){
			this.datas.appId = 'rainbowse';
			setTimeout(()=>{ 
				let href = location.href;
				href = href.replace(this.datas.appId2, 'rainbowse');
				if (location.href != href) location.href = href;
			}, 10);
		}

		//try {
		var currentJWT:string;
		if (aRoute.queryParams.jwt) {
			currentJWT = aRoute.queryParams.jwt;
			localStorage.setItem('common_jwt', currentJWT);
		} else {
			currentJWT = localStorage.getItem('common_jwt');
		}
		this.datas.jwt = currentJWT;
		/*
		if (this.datas.jwt) {
			// migrated into this.datas.jwt
			// let jwts: string[] = this.datas.jwt.split('.');
			// this.datas.jwtObj = JSON.parse(atob(jwts[1]));
			if (this.checkAccountIsValid == false){
				this.datas.post('ROHomeScreen.checkAccountIsValid').subscribe((res:any)=>{
					if (res.account.uid){
						this.checkAccountIsValid = true;
					} else {
						this.router.navigate(['login']);
					}
				});
			}
		}
		*/
		if (this.datas.userInfo == null && localStorage.getItem('homeScreen_data')){
			let json = localStorage.getItem('homeScreen_data');
			try{
				let obj = JSON.parse(json);
				this.datas.userInfo = obj.account;
				if (typeof this.datas.userInfo.personalSettings == 'string'){
					this.datas.userInfo.personalSettings = JSON.parse(this.datas.userInfo.personalSettings);
					if (this.datas.userInfo.personalSettings.lang) this.datas.setLang(this.datas.userInfo.personalSettings.lang);
				}
			} catch (e){

			}
		}
		/*} catch (e) {
			console.log('Fail to parse jwt.');
			console.log(e);
		}*/
		/*JWTOBJ = {
			expires: 1629870774
			ip: "172.16.1.1"
			school_id: "272"
			token: "40402-c828530f4fc9bb9577b61a3deab06c1472147e18d85822109d699bd9c05250a9"
			uid: "40402"
			user_role: "3"
		}*/
		//console.log(this.datas.jwtObj);
		// this.initLang();

		//this.initFavicon(aRoute.routeConfig.path);
		return this.translate.get('ready_before_render');
	}

	get imgUrl() {
		if (location.href.indexOf('/roWeb/') > -1) {
			return location.href.replace(/roWeb.*/, 'roWeb/assets/img/');
		} else {
			return '/assets/img/';
		}
	}

	initFavicon(key) {
		let favEl = <HTMLLinkElement>document.querySelector("link[type='image/x-icon']");
		if (this.datas.appId2 == 'readingstar'){
			favEl.href = '/RainbowOne/webapp/2.8/roWeb/assets/img/reading_star_favicon.png';
		} else if (this.datas.appId2 == 'rainbowstar') {
			favEl.href = '/RainbowOne/webapp/2.8/roWeb/assets/img/btn_logo_rs.png';
		}
	}

	// 改用PersonalSetting的lang，不再需要於url取lang
	// initLang() {
	// 	this.translate.addLangs(['tc', 'sc', 'en']);
	// 	let langArr = ['tc', 'sc', 'en'];
	// 	let href = location.href;
	// 	for (let i = 0; i < 3; i++) {
	// 		if (href.indexOf('/' + langArr[i] + '/') > -1) {
	// 			this.datas.lang = langArr[i];
	// 			this.translate.use(langArr[i]);
	// 		}
	// 	}

	// }

}
