import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive()
@Injectable()
export class StorageService {
  public appId;
  public lang;
  public uid;
  public role;
  public gender;
  public schoolId;
  public uname;
  public uphoto;
  public debug;
  public dev;
  public session;
  public lessons:any[] = [];
  public chats:any[] = [];
  public books:any[] = [];
  public isReady: boolean = false;
  public tempObj: any = {};
  public isJoining: boolean = false;
  public jwt:string;
  public jwtObj:any;
  public inBrowser = false;
  public inBranch = false;
  public homeSettings:any = [];
  public appVersion;
  public subjects:any[] = [];
  public pushNotificationSupport = 0;
  public pushNotificationEnabled = 0;
  public baseHref = '';
  @Output() event:EventEmitter<any> = new EventEmitter();
  constructor(private device: DeviceDetectorService) {
    this.lang = 'tc';
    this.dev = {
      info: device.getDeviceInfo(),
      isMobile: device.isMobile() || device.isTablet()
    };
    console.log(this.dev);
    this.initBaseHref();
    // this.createFakeJwt();
  }

  test() {

  }

  initBaseHref(){
    let href = window.location.href;
    let arr = href.split('/');
    let index = href.indexOf('webapp');
    if (index > -1){
      arr = arr.filter((e,i)=>{i < index + 2});
      this.baseHref = arr.join('/') + '/';
    } else {
      this.baseHref = '//ro2.azurewebsites.net/RainbowOne/webapp/2.8/roWeb/';
    }
  }

  public lockState() {
    this.isReady = false;
    setTimeout(() => { this.isReady = true }, 10);
  }

  public createFakeJwt($data = null){
    if (!$data){
      $data = {'uid': 511, school_id: 272, user_role: 3};
    }
    let arr = {};
    arr['typ'] = 'jwt';
    arr['alg'] = 'HS256';
    let seg = [];
    seg.push(JSON.stringify(arr));
    seg.push(JSON.stringify($data));
    seg = seg.map(s=>{
      s = btoa(s);
      s = s.replace(/\+/g,'-');
      s = s.replace(/\//g,'_');
      s = s.replace('=','');
      return s;
    });
    let output = seg.join('.');
    // console.log(output);
  }
}
