import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[retryImage]'
})

export class RetryImageDirective implements OnInit, OnDestroy {
  @Input() maxRetries: number = 3; // 最大重试次数
  private retryCount: number = 0; // 当前重试次数
  private imgElement: HTMLImageElement;
  private src = '';

  constructor(private el: ElementRef) {
    this.imgElement = this.el.nativeElement; // 获取图像元素
	
  }

  ngOnInit() {
	this.src = this.imgElement.src;
	if (this.src != ''){
		this.loadImage(); // 初始加载图像
	}
  }

  ngOnDestroy() {
    // 在指令销毁时可以执行任何清理操作
  }

  private loadImage() {
    this.retryCount = 0; // 重置重试计数
    this.imgElement.src = this.src + '?t=' + new Date().getTime(); // 加载图像并防止缓存

    this.imgElement.onerror = () => {
      console.log("图像加载失败，正在重试...");
      this.retryCount++;

      if (this.retryCount < this.maxRetries) {
        this.loadImage(); // 再次尝试加载图像
      }
    };
  }
}
