import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import * as moment from 'moment';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { DateTimePicker3Component } from 'src/app/sharedModule/dateTimePicker3Module/dateTimePicker3.component';
import { TargetSelectorComponent } from 'src/app/sharedModule/targetSelectorModule/targetSelector.component';
import { SubjectSelector3Component } from 'src/app/sharedModule/subjectSelector3Module/subjectSelector3.component';
import { SettingService } from 'src/app/service/setting.service';
import { CommonService } from 'src/app/service/common.service';
import { LearningObjectivePopupSelector } from 'src/app/sharedModule/subjectModule/node-selector.component';
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';
import { WhitePopupService } from 'src/app/sharedModule/whitePopupModule/whitePopup.service';
import downloadjs from "downloadjs";
import { Row } from 'src/app/coreModule/gameQuestionModule/row.class';
@Component({
	selector: 'create-game-modal',
	template: `
	<modal2
		#modal
		class="create-game-modal" [title]="modalTitle"
		[headerStyle]="{border: '0'}"
		[contentStyle]="{padding: '0'}"
		[confirmLabel]="(toggle != 'choose-game'?confirmLabel:{tc:'下一步',sc:'下一步',en:'Next'}[lang])"
		(confirmClick)="confirmClick()"
		(cancelClick)="beforeConfirm(false)"
		>

		<div class="game-container">
			<div class="game-container-1" *ngIf="games && toggle == 'choose-game'">
				<popup-toggle class="game-cat" 
				[(toggle)]="category" bindLabel="label" bindValue="value"
				(toggleChange)="categoryChange()"
				[items]="categories"></popup-toggle>
				<div class="game-container-1-wrapper">
					<div class="item" *ngFor="let game of filteredGames" (click)="gameSelectClick(game)">
						<img class="item-cover" 
							[class.selected]="selectedGame && selectedGame.id == game.id"
							[src]="game.cover" />
						<div class="item-title">
							<span>{{game.title}}</span>
						</div>
						<div class="item-keywords">
							<div [ngClass]="keyword.value" *ngFor="let keyword of game.keywords">{{keyword.label}}</div>
						</div>
						<okaPulldown2 class="item-subitem" [options]="game.subitems" bindLabel="label" bindValue="id" 
							[(ngModel)]="game.selectedSubitemId"></okaPulldown2>
						<div class="item-info">{{game.description}}</div>
						<fa-icon 
							[class.selected]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)" 
							[icon]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)"></fa-icon>
					</div>
				</div>
			</div>

			<div class="game-container-2" *ngIf="games && toggle != 'choose-game' && this.selectedGame">
				<popup-toggle class="game-setting"
					[(toggle)]="toggle" bindLabel="label" bindValue="value"
					(toggleChange)="toggleChange()"
					[items]="toggles"></popup-toggle>
				<div class="game-container-2-1" *ngIf="toggle == 'basic-info'">
					<div class="topic"> 
						{{ {tc:'遊戲', sc:'游戏', en: 'Game'}[lang] }}:
						<img class="topic-icon" />
						<ng-container *ngIf="selectedGame && selectedSubitem">{{ selectedGame.title + ' (' + selectedSubitem.label +')' }}</ng-container>
					</div>
					<div class="row first-row">
						<div class="row-label mark-required">{{ {tc:'遊戲名稱',sc:'游戏名称',en:'Name'}[lang] }}</div>
						<div class="row-data">
							<input type="text" [(ngModel)]="name" [class.required]="basicInvalid"
								(change)="basicInvalid = 0"
								[placeholder]="{tc:'請輸入遊戲名稱',sc:'请输入游戏名称',en:'Please input name'}[lang]"/>
						</div>
					</div>
					<div class="row main-row">
						<div class="row-label mark-required">{{ {tc:'提示',sc:'提示',en:'Hint'}[lang] }}</div>
						<div class="row-data">
							<textarea [(ngModel)]="hint" [class.required]="basicInvalid" (change)="basicInvalid = 0"
								[placeholder]="{tc:'請輸入提示',sc:'请输入提示',en:'Please input hint'}[lang]"></textarea>
						</div>
					</div>
					<div class="row" *ngIf="section == 'bookshelf'">
						<div class="row-label mark-required">{{ {tc: '組別', sc: '組別', en: 'Groups'}[lang] }} </div>
						<div class="row-data" [class.disabled]="data.bsid">
							<div class="tag-title" [class.required]="basicInvalid"
								(click)="targetSelectorOpen($event)">{{tags_title}}</div>
							<targetSelector #targetSelector [allowForAll]="false"
								memberRole="student" [(ngModel)]="targets" 
								></targetSelector>
						</div>
					</div>
					<div class="row">
						<div class="row-label">{{ {tc: '科目', sc: '科目', en: 'Subject'}[lang] }} </div>
						<div class="row-data">
							<div class="subject-title" (click)="subjectSelectorOpen($event)">{{subjects_title}}</div>
							<subjectSelector3 #subjectSelector 
								[(ngModel)]="subjects"
								[leftBgColor]="leftBgColor"
								></subjectSelector3>
						</div>
					</div>
					<div class="row in-progress-time" *ngIf="section == 'bookshelf'">
						<div class="row-label">{{ {tc:'「進行中」時段',sc:'「進行中」時段',en:'Open period'}[lang] }}:</div>
						<div class="row-data">
							<a class="datetime in-progress" #inProgressTimeEl (click)="dateTimeClick(inProgressTimeEl, 'in-progress')">
								<ng-container *ngIf="!startTime && !endTime">
									<span class="datetime-title">{{ {tc:'請選擇...',sc:'请选择...',en:'Select...'}[lang] }}</span>
								</ng-container>
								<ng-container *ngIf="startTime && endTime">
									<span class="datetime-title">{{ startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{endTime | myDate: wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
								</ng-container>
								<ng-container *ngIf="startTime != null && endTime == null">
									<span class="datetime-title">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} {{'workspace.up-to-forever' | translate}}</span>
								</ng-container>
							</a>
							<dateTimePicker3 #dateTimePicker 
								[showCustomMinutesOption]="true"
								[clearBtn]="true" [lang]="datas.lang"></dateTimePicker3>
						</div>
					</div>
					<div class="row last-row">
						<div class="row-label">{{ {tc:'學習重點',sc:'学习重点',en:'Objective'}[lang] }}:</div>
						<div class="row-data">
							<div class="no-lo lo-title" *ngIf="los.length == 0" (click)="loClick($event)">{{ {tc:'請選擇...',sc:'请选择...',en:'Select...'}[lang] }}</div>
							<ul class="lo-list" (click)="loClick($event)">
								<li *ngFor="let lo of los" 
									class="lo-title">{{lo.name}}</li>
							</ul>
							<learningObjectivePopupSelector type="LO" [options]="{acceptCustom: 1}" #learningObjectiveSelector></learningObjectivePopupSelector>
						</div>
					</div>
				</div>
				<div class="game-container-2-2" 
					[class.no-image]="imageSettingRows.length == 0"
					*ngIf="toggle == 'set-game'">
					<div class="game-container-2-2-left">
						<div class="row-topic"> {{ {tc:'基本設定', sc:'基本设定', en: 'General setting'}[lang] }}
							<span style="color: var(--common-errorcolor, #df3354);font-weight: 400;">{{ {tc:'( * 為必填項目)',sc:'( * 为必填项目)',en:'( * required)'}[lang] }}</span>
						</div>
						<div class="row row-type-{{row.dataType}}" 
							*ngFor="let row of basicSettingRows;let i=index;"
							[class.first-row]="i == 0"
							[class.last-row]="i == basicSettingRows.length - 1">
							<div class="row-label" [class.mark-required]="row.required">
								<span>{{ row.label }}</span>
								<additionRemarkBall *ngIf="row.info" [bubbleText]="row.info[lang]"></additionRemarkBall>
							</div>
							<div class="row-data" [class.invalid]="row.invalid">
								<input type="text" *ngIf="row.dataType == 'string'" [(ngModel)]="row.value"
									name="setting_{{i+1}}" (change)="row.invalid = 0" />
								<numberInput [min]="row.min || 0" [max]="row.max || 100" [step]="row.step || 1"
									[(ngModel)]="row.value"
									*ngIf="row.dataType == 'int'"></numberInput>
								<!--<input type="text" *ngIf="row.dataType == 'int'" [(ngModel)]="row.value"
									name="setting_{{i+1}}" (input)="numInput($event, 'int')" (change)="row.invalid = 0"/>-->
								<input type="text" *ngIf="row.dataType == 'float'" [(ngModel)]="row.value"
									name="setting_{{i+1}}" (keydown)="numInput($event, 'float')" (change)="row.invalid = 0"/>
								<okaPulldown2 *ngIf="row.dataType == 'select'" 
									[(ngModel)]="row.value"
									[options]="row.options" bindLabel="label" bindValue="value" 
								></okaPulldown2>
								<ui-switch class="useCssVar" *ngIf="row.dataType == '0|1'" [checked]="row.value == 1" 
									(change)="(row.value = $event?1:0)"></ui-switch>
								<textarea *ngIf="row.dataType == 'text'" name="setting_{{i+1}}"
									(change)="row.invalid = 0"></textarea>
								<colorPicker *ngIf="row.dataType == 'color'"
									[selectedColor]="row.value" (colorChange)="setColor($event, row)"></colorPicker>
							</div>
						</div>

						<ng-container *ngIf="advanceSettingRows.length > 0">

							<div class="row-topic"> {{ {tc:'進階設定', sc:'进阶设定', en: 'Advance setting'}[lang] }}
								<span style="color: var(--common-errorcolor, #df3354);font-weight: 400;">{{ {tc:'( * 為必填項目)',sc:'( * 为必填项目)',en:'( * required)'}[lang] }}</span>
							</div>
							<div class="row row-type-{{row.dataType}}" 
								*ngFor="let row of advanceSettingRows;let i=index;"
								[class.first-row]="i == 0"
								[class.last-row]="i == advanceSettingRows.length - 1">
								<div class="row-label" [class.mark-required]="row.required">
									<span>{{ row.label }}</span>
									<additionRemarkBall *ngIf="row.info" [bubbleText]="row.info[lang]"></additionRemarkBall>
								</div>
								<div class="row-data">
									<input type="text" *ngIf="row.dataType == 'string'" [(ngModel)]="row.value"
										name="setting_{{i+1}}" (change)="row.invalid = 0"/>
									<input type="text" *ngIf="row.dataType == 'int'" [(ngModel)]="row.value"
										name="setting_{{i+1}}" (input)="numInput($event, 'int')" (change)="row.invalid = 0"/>
									<input type="text" *ngIf="row.dataType == 'float'" [(ngModel)]="row.value"
										name="setting_{{i+1}}" (keydown)="numInput($event, 'float')" (change)="row.invalid = 0"/>
									<okaPulldown2 *ngIf="row.dataType == 'select'" 
										[(ngModel)]="row.value"
										[options]="row.options" bindLabel="label" bindValue="value" 
									></okaPulldown2>
									<ui-switch class="useCssVar" *ngIf="row.dataType == '0|1'" [checked]="row.value == 1" 
										(change)="(row.value = $event?1:0)"></ui-switch>
									<textarea *ngIf="row.dataType == 'text'" name="setting_{{i+1}}"
										(change)="row.invalid = 0"></textarea>
									<colorPicker *ngIf="row.dataType == 'color'"
										[selectedColor]="row.value" (colorChange)="setColor($event, row)"></colorPicker>
								</div>
							</div>
							
						</ng-container>
					</div>
					<div class="game-container-2-2-right" *ngIf="imageSettingRows.length > 0">
						<div class="image-row" *ngFor="let row of imageSettingRows">
							<ng-container *ngIf="row.dataType == 'image'">
								<div class="image-row-label" [class.mark-required]="row.required">
									<span>{{ row.label }}</span>
									<additionRemarkBall *ngIf="row.info" [bubbleText]="row.info[lang]"></additionRemarkBall>
								</div>
								<div class="image-row-row2">
									<div class="image-row-upload" (click)="imageUpload(row)">{{ {tc:'上載',sc:'上载',en:'Upload'}[lang] }}</div>
									<div class="image-row-clear" (click)="imageClear(row)"> {{ {tc:'清除', sc:'清除', en:'Clear'}[lang] }}</div>
									<div class="image-row-reset" *ngIf="row.default" (click)="imageSetDefault(row)">{{ {tc:'還原', sc:'还原', en:'Reset'}[lang] }}</div>
								</div>
								<div class="image-row-preview" [style.backgroundImage]="row.backgroundImage"
									[style.width.px]="row.width" [style.height.px]="row.height"
									[class.row-invalid]="row.invalid"
									[class.blank]="!row.value" (click)="imagePreviewClick($event, row)">
									<fa-icon *ngIf="!row.value" [icon]="faImage"></fa-icon>	
								</div>
							</ng-container>

							<ng-container *ngIf="row.dataType == 'images'">
								<div class="image-row-label" [class.mark-required]="row.required">
									<span>{{ row.label }}</span>
									<additionRemarkBall *ngIf="row.info" [bubbleText]="row.info[lang]"></additionRemarkBall>
								</div>
								<div class="image-row-row2">
									<div class="image-row-upload" (click)="imageUpload(row)">{{ {tc:'上載',sc:'上载',en:'Upload'}[lang] }}</div>
									<div class="image-row-reset" *ngIf="row.default" (click)="imageSetDefault(row)">{{ {tc:'還原', sc:'还原', en:'Reset'}[lang] }}</div>
								</div>
								<div class="image-row-pool"
									[style.minHeight.px]="row.height || 86">
									<ng-container *ngIf="row.value">
										<div class="image-row-pool-item" 
											[style.backgroundImage]="item" [class.blank]="!item" [class.row-invalid]="row.invalid"
											[style.width.px]="row.width || 86" [style.height.px]="row.height || 86"
											(click)="imagePreviewClick($event, row, i)"
											*ngFor="let item of row.backgroundImage;let i = index">
											<fa-icon *ngIf="!item" [icon]="faImage"></fa-icon>	
										</div>
									</ng-container>
								</div>
							</ng-container>
						</div>
					</div>
				</div>

				<question-aggrid #questionAggrid
					*ngIf="games && toggle == 'set-question' && selectedSubitem"
					[parent]="this"
					[questions]="questionRows" [format]="selectedSubitem.question_format"
					></question-aggrid>
			</div>

		</div>

		
  	</modal2>
  `
	, styleUrls: ['./create-game.modal.scss'],
})

export class CreateGameModal implements OnInit {
	@Input() data: any;
	@ViewChild('modal', { static: false }) modal;
	@ViewChild('nameRef', { static: false }) nameRef;
	@ViewChild('inProgressTimeEl', { static: false }) inProgressTimeEl;
	@ViewChild('dateTimePicker', { static: false }) dateTimePicker: DateTimePicker3Component;
	@ViewChild('subjectSelector', { static: false }) subjectSelector: SubjectSelector3Component;
	@ViewChild('targetSelector', { static: false }) targetSelector: TargetSelectorComponent;
	@ViewChild('learningObjectiveSelector', { static: false }) learningObjectiveSelector: LearningObjectivePopupSelector;
	@ViewChild('questionAggrid', { static: false }) questionAggrid;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	public toggles;
	public toggle = 'choose-game';
	public toggleOptions;
	public url = '';
	public name = '';
	public hint = '';
	public modalTitle = '';
	public lang = 'tc';
	public games: any = [];
	public filteredGames: any = [];
	public icons = { faCheckCircleLight, faCheckCircle };
	public selectedGame:any = null;
	public selectedSubitem:any = null;
	public basicSettingRows: any = [];
	public advanceSettingRows: any = [];
	public imageSettingRows: any = [];
	public questionRows: any = [];
	public subjects: any = [];
	public los: any = [];
	public targets: any = [];
	public hasScoring = true;
	public startTime: any = null;
	public endTime: any = null;
	public wholeDay = 0;
	private selectorPromise;
	public tags_title = '';
	public subjects_title = '';
	public confirmLabel;
	public section = 'bookshelf';
	public categories: any = [];
	public category = 'default';
	public faImage = faImage;
	public whitePopupOpened = false;
	public basicInvalid = 0;
	public leftBgColor = '#2c97d7';
	constructor(public datas: DataService, private script: ScriptService, private trans: TranslateService, private als: AlertService, public sans: DomSanitizer, public lds: LoadingService, public eleRef: ElementRef, public sts: SettingService, public coms: CommonService, public upload: UploadService, public wpp: WhitePopupService) {
	}

	ngOnInit() {
		const lang = this.datas.lang;
		this.lang = lang;
		if (location.href.indexOf('/course/') > -1 || (this.coms.sectionComponent && this.coms.sectionComponent.sectionName == 'course') ){
			this.section = 'course';
			this.leftBgColor = '#533da3';
		} else if (location.href.indexOf('/workspace/') > -1 || (this.coms.sectionComponent && this.coms.sectionComponent.sectionName == 'course') ){
			this.section = 'workspace';
			this.leftBgColor = '#812541';
		}
		this.categories = [
			//{ label: { tc: '預設', sc: '預設', en: 'Default' }[lang], value: 'default' },
			{ label: { tc: '小王子', sc: '小王子', en: 'Prince' }[lang], value: 'prince' },
			{ label: { tc: '英語遊戲', sc: '英语游戏', en: 'English game' }[lang], value: 'english' },
		];
		this.category = 'english';
		this.toggles = [
			{
				label: { tc: '基本資料', sc: '基本资料', en: 'Info' }[lang], value: 'basic-info', onLeave: () => {
					if (this.name == '' || this.hint == '') {
						this.als.toastError();
						this.basicInvalid = 1;
						return false;
					}
					if (this.section == 'bookshelf' && !this.data.bsid && this.targets.length == 0) {
						const msg = { tc: '請選擇組別', sc: '请选择组别', en: 'Please input the group.' }[lang];
						this.basicInvalid = 1;
						this.als.toastError(msg);
						return false;
					}
					return true;
				}
			},
			{ 	
				label: { tc: '遊戲設定', sc: '游戏设定', en: 'Setting' }[lang], value: 'set-game', onLeave: ()=>{
					const settings = [...this.basicSettingRows, ...this.advanceSettingRows, ...this.imageSettingRows];
					const requiredSettings = settings.filter(e=> e.required);
					const noValueRows = requiredSettings.filter(row=>{
						if (row.value === undefined){
							return true;
						} else if (row.dataType == 'images'){
							if (!row.count && !row.value.find(e=>e)){
								return true;
							} else if (row.count && row.count > row.value.filter(e=>e).length){
								return true;
							}
						}
					});
					if (noValueRows.length > 0){
						noValueRows.forEach(e=> e.invalid = 1);
						this.als.toastError();
					}
					return noValueRows.length == 0;
				}
			},
			{
				label: { tc: '設定題目', sc: '设定题目', en: 'Question' }[lang], value: 'set-question',
				onLeave: () => this.questionAggrid.validate()
			}
		];
		if (this.data.structure) {
			this.modalTitle = { tc: '編輯遊戲', sc: '编辑游戏', en: 'Edit game' }[this.lang];
			this.toggles = this.toggles.filter(e => e.value != 'choose-game');
			this.toggle = 'basic-info';
			this.confirmLabel = { tc: '確定', sc: '确定', en: 'Confirm' }[lang];
			this.loadExistGame();
			if (this.data.anyOneSubmitted) {
				const msg = { tc: '提示:分享已有學生提交紀錄，如果設定被修改或會出現成績錯配。', sc: '提示:分享已有学生提交纪录，如果设定被修改或会出现成绩错配。', en: 'Reminder: some student record were existed. Data may go wrong if setting change.' };
				this.als.toastSuccess(msg[this.lang]);
			}
		} else {
			this.modalTitle = { tc: '建立遊戲', sc: '建立游戏', en: 'Add game' }[this.lang];
			this.confirmLabel = { tc: '新增', sc: '新增', en: 'Add' }[lang];
			this.initTargets();
		}
		this.modalTitle = this.trans.instant(this.modalTitle);
		this.initSubject();
		this.getData();
	}

	ngAfterViewInit() {
		this.test();
		setTimeout(() => {
			this.modal.open();
		}, 10);
	}

	ngOnDestroy(): void {
	}

	test() {

	}

	getData() {
		this.lds.add('ROGame.list', 5000);
		this.datas.post('ROGame.list').subscribe((res: any) => {
			let games = res.items.map(game => this.formatGame(game, res.subitems));
			this.games = games;
			if (this.data.structure) {
				const structure = this.data.structure;
				this.selectedGame = games.find(e => e.id == structure.game_id);
				if (this.selectedGame) {
					this.selectedSubitem = this.selectedGame.subitems.find(e => e.id == structure.subItem_id);
				}
				this.initSettingForSubitem();
			} else {
				this.games = this.games.filter(e=> e.active == '1');
				this.category = 'english';
				this.categoryChange();
				this.gameSelectClick(this.filteredGames[0]);
			}
			
			this.lds.remove('ROGame.list');
		});
	}

	categoryChange() {
		this.filteredGames = this.games.filter(e => e.category == this.category);
	}

	confirmClick() {
		if (this.toggle == 'choose-game') {
			this.toggle = 'basic-info';
			this.selectedSubitem = this.selectedGame.subitems.find(e => e.id == this.selectedGame.selectedSubitemId);
			this.initSettingForSubitem();
		} else {
			this.beforeConfirm();
		}
	}

	beforeConfirm(isConfirm = true) {
		if (isConfirm) {
			if (this.section == 'bookshelf' && !this.data.bsid && this.targets.length == 0) {
				const msg = { tc: '請選擇組別', sc: '请选择组别', en: 'Please select group' }[this.datas.lang];
				this.als.toastError(msg);
				return;
			}
			if (this.questionRows.length == 0) {
				const msg = { tc: '請輸入題目', sc: '請輸入题目', en: 'Please input questions' }[this.lang];
				this.als.toastError(msg);
				return;
			}
			if (this.data.bsid) {
				this.updateGame();
			} else {
				const data = this.createGame();
			}
		} else {
			this.data.close();
		}
	}

	toggleChange() {
		const lang = this.datas.lang;
		if (this.toggle == 'choose-game') {

		} else if (this.toggle == 'set-game') {

		} else if (this.toggle == 'set-question') {

		}
	}

	/**
	 * Initializes settings for the selected subitem.
	 * This method retrieves various types of settings (image, basic, advanced) 
	 * from the selected subitem and prepares them for use in the application.
	 * It also sets up default questions and processes any game-specific settings 
	 * that may override default values.
	 */
	initSettingForSubitem() {
		// Retrieve the selected subitem and its settings
		const selectedSubitem = this.selectedSubitem;
		const settings = selectedSubitem.settings;
		if (selectedSubitem.info && !this.data.bsid){
			const defaultInfo = JSON.parse(selectedSubitem.info);
			this.name = defaultInfo.name?defaultInfo.name:'';
			this.hint = defaultInfo.hint?defaultInfo.hint:'';
			this.targets = defaultInfo.targets?[...defaultInfo.targets]:[];
			this.initTargets();
			this.subjects = defaultInfo.subjects?[...defaultInfo.subjects]:[];
			this.initSubject();
			this.los = defaultInfo.los?[...defaultInfo.los]:[];
			if (defaultInfo.startTime){
				this.startTime = moment(defaultInfo.startTime,"YYYY-MM-DD HH:mm:ss").toDate();
			}
			if (defaultInfo.endTime){
				this.endTime = moment(defaultInfo.endTime,"YYYY-MM-DD HH:mm:ss").toDate();
			}
		}
		// Filter settings into different categories based on their data types
		this.imageSettingRows = settings.filter(e => e.dataType == 'image' || e.dataType == 'images');
		this.basicSettingRows = settings.filter(e => e.isBasic && e.dataType.indexOf('image') === -1);
		this.advanceSettingRows = settings.filter(e => !e.isBasic && e.dataType.indexOf('image') === -1);

		// Initialize question rows from the default questions of the selected subitem
		this.questionRows = selectedSubitem.default_questions ? selectedSubitem.default_questions : [];

		// Prepare image settings, setting default values based on the count
		this.imageSettingRows.forEach(row => {
			if (row.dataType == 'image'){
				if (row.value){
					const url = (row.value.indexOf('//') === -1?'//oka.blob.core.windows.net/media/':'') + row.value;
					row.backgroundImage = this.sans.bypassSecurityTrustStyle("url('" + url +"')");
				}
			} else {
				if (!row.value){
					row.value = [null];
				}
				row.backgroundImage = row.value.map(e=>{
					if (!e){
						return null;
					} else if (e.indexOf('//') > -1){
						return this.sans.bypassSecurityTrustStyle("url('" + e + "')");
					} else {
						const url = '//oka.blob.core.windows.net/media/' + e;
						return this.sans.bypassSecurityTrustStyle("url('" + url + "')");
					}
				});
				
				if (row.count && row.value.length < row.count) {
					// Fill the value array with nulls up to the specified count
					for(let i=0;i < row.value.length - row.count;i++){
						row.value.push(null);
						row.backgroundImage.push(null);		
					}
				}
			}
		});

		// Check if there is data available to set game-specific settings
		if (this.data && this.data.structure) {
			const setting = this.data.structure.book.setting;
			const gameSetting = setting.game;

			// Update basic and advanced settings with values from game settings if available
			[...this.basicSettingRows, ...this.advanceSettingRows].forEach(row => {
				if (gameSetting[row.prop] !== undefined) {
					row.value = gameSetting[row.prop];
				}
			});

			// Process image settings to set their values and background images
			this.imageSettingRows.forEach(row => {
				if (gameSetting[row.prop]) {
					row.value = gameSetting[row.prop];
					if (row.dataType == 'image') {
						// Set background image for single image type
						const fullUrl = "//oka.blob.core.windows.net/media/" + row.value;
						row.backgroundImage = this.sans.bypassSecurityTrustStyle("url('" + fullUrl + "')");
					} else {
						// Handle multiple images
						if (row.count && row.value < row.count) {
							while (row.value.length < row.count) {
								row.value.push(null);
							}
						}
						row.backgroundImage = row.value.map(e => {
							if (!e) {
								return;
							}
							const fullUrl = "//oka.blob.core.windows.net/media/" + e;
							return this.sans.bypassSecurityTrustStyle("url('" + fullUrl + "')");
						});
					}
				}
			});

			// Map questions from the first chapter's components to the questionRows
			this.questionRows = this.data.structure.book.chapters[0].pages[0].components.map(comp => {
				let question: any = {};
				this.selectedSubitem.question_format.forEach(item => {
					question.cid = comp.cid; // Set the component ID
					if (comp[item.prop] !== undefined) {
						question[item.prop] = comp[item.prop];
					}
					// Handle multiple-choice questions
					if (item.dataType.indexOf('$mc') > -1) {
						question.answers = [...comp.answers];
						question.correctAnswerIndex = comp.correctAnswerIndex;
						let arr = item.dataType.split(':');
						arr[2] = parseInt(arr[2]);
						if (comp.answers !== undefined) {
							for (let i = 1; i <= arr[2]; i++) {
								question['$answer' + i] = comp.answers[i - 1];
							}
						}
					} else if (item.dataType == '$score') {
						question.score = comp.score.full; // Set the score
					}
				});
				return question; // Return the constructed question object
			});
		}

		// Finalize question rows by setting default values for each property
		this.questionRows.forEach((row, i) => {
			// Assign a sort index if not already defined
			if (row.$sort === undefined) {
				row.$sort = i + 1;
			}
			const assetTypes = ['image', 'images', 'audio', 'audios'];
			selectedSubitem.question_format.forEach(item => {
				const dataType = item.dataType;
				if (dataType == 'const') {
					row[item.prop] = Array.isArray(item.default)?[...item.default]:item.default; // Set constant value
				} else if (dataType.indexOf('$') == -1 && row[item.prop] === undefined) {
					// Set default values based on data type
					if (item.default !== undefined) {
						row[item.prop] = Array.isArray(item.default)?[...item.default]:item.default;
					} else if (dataType == 'int' || dataType == 'float') {
						row[item.prop] = 0; // Default to 0 for numeric types
					} else if (assetTypes.indexOf(dataType) == -1) {
						row[item.prop] = []; // Default to an empty array for non-asset types
					} else if (dataType == 'string' || dataType == 'text') {
						row[item.prop] = ''; // Default to an empty string for text types
					}
				}
			});
		});
	}


	gameSelectClick(game) {
		this.selectedGame = game;

	}

	subjectSelectorOpen($event) {
		if (this.selectorPromise && this.subjectSelector) {
			this.subjectSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			// this.shareModal.subjectSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.subjectSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.initSubject();
				}).catch(e => { //cancel will throw error
					this.selectorPromise = null;
				});
			}, 200);
		}
	}

	initSubject() {
		if (this.subjects.length > 0) {
			const subjectTitles = this.subjects.map(s => s.title);
			this.subjects_title = subjectTitles.join(', ');
		} else {
			this.subjects_title = { tc: '請選擇...', sc: '请选择...', en: 'Select...' }[this.lang];
		}
	}

	targetSelectorOpen($event) {
		this.basicInvalid = 0;
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			setTimeout(() => {
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.initTargets();
				});
			}, 200);
		}
	}

	initTargets() {
		if (this.targets.length > 0) {
			const targetTitles = this.targets.map(t => t.title);
			this.tags_title = targetTitles.join(', ');
		} else {
			this.tags_title = { tc: '請選擇...', sc: '请选择...', en: 'Select...' }[this.lang];
		}
	}

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let update$ = null;
		if (this.startTime) {
			const startMo = moment(this.startTime);
			const last8Dight = startMo.format('HH:mm:ss');
			wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			startTime = startMo.clone().toDate();
		}
		if (this.endTime) {
			const endMo = moment(this.endTime);
			const last8Dight2 = endMo.format('HH:mm:ss');
			wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			endTime = endMo.clone().toDate();
		}

		let needEndTime = !!endTime;
		let dtpOptions: any = { value: { startDateTime: startTime, endDateTime: endTime, isWholeDay: wholeDay, customMinutes: true, needEndTime: needEndTime }, skipLoadApi: true };
		dateTimePicker.open(el, dtpOptions).then(dt => {
			if (dt.startDateTime) {
				this.startTime = moment(dt.startDateTime).toDate();
			} else {
				this.startTime = null;
			}
			if (dt.endDateTime) {
				this.endTime = moment(dt.endDateTime).toDate();
			} else {
				this.endTime = null;
			}
			this.wholeDay = dt.wholeDay ? 1 : 0;
		});
	}

	formatGame(game, subitems) {
		const lang = this.lang;
		let url = location.href.indexOf('localhost') > -1 ? '//dev.openknowledge.hk' : '';
		url = url + '/RainbowOne/webapp/OKAGames/SelectGames/image/' + game.image;
		game.imageUrl = url;
		game.cover = this.sans.bypassSecurityTrustUrl(url);
		game.subitems = subitems.filter(e => e.item_id == game.id);
		if (this.data.structure){ //exist game有機會active=0
			game.subitems = subitems.filter(e => e.item_id == game.id);
		} else {
			game.subitems = subitems.filter(e => e.item_id == game.id && e.active === '1');
		}
		game.subitems.forEach(subitem => {
			subitem.label = subitem['title_' + lang];
			try {
				let settings = JSON.parse(subitem.settings);
				settings.forEach(s => {
					if (s.title) {
						if (typeof s.title === 'object') {
							s.label = s.title[lang];
						} else {
							s.label = s.title;
						}
						delete s.title;
					}
					if (s.options) {
						s.options = s.options.map(e => {
							if (e.title) {
								if (typeof e.title === 'object') {
									e.label = e.title[lang];
								} else {
									e.label = e.title;
								}
								delete e.title;
							}
							return e;
						});
						s.value = s.options[0].value;
					}
					if (s.default !== undefined){
						s.value = Array.isArray(s.default)?[...s.default]:s.default;
					} else {
						s.value = null;
					}
				});
				subitem.settings = settings;
			} catch (e) { }
			if (subitem.default_questions) {
				try { subitem.default_questions = JSON.parse(subitem.default_questions); } catch (e) { }
			} else {
				subitem.default_questions = [];
			}
			if (subitem.question_format) {
				try { subitem.question_format = JSON.parse(subitem.question_format); } catch (e) { }
			} else {
				subitem.question_format = [];
			}
		});
		if (game.subitems.length == 0) {
			game.subitems = [{ label: { tc: '預設', sc: '預設', en: 'Default' }[lang], id: '0', settings: [], default_questions: [] }];
		}
		game.selectedSubitemId = game.subitems[0].id;
		game.title = game['title_' + this.lang];
		if (game.keywords) {
			let keywordArr = game.keywords.split(',');
			game.keywords = keywordArr.map(e => {
				let obj: any = { label: e, value: e };
				if (e == 'motion') {
					obj.label = { tc: 'Motion game', sc: 'Motion game', en: 'Motion game' }[lang];
				} else if (e == 'others') {
					obj.label = { tc: 'Others', sc: 'Others', en: 'Others' }[lang];
				}
				return obj;
			});
		}
		return game;
	}

	async createGame() {
		const pageid = GUID.create("OKD guid");
		let setting: any = { id: this.selectedGame.id, url: this.selectedGame.url, image: this.selectedGame.imageUrl, hint: this.hint, game: {} };
		let settingRows = [...this.basicSettingRows, ...this.advanceSettingRows, ...this.imageSettingRows];
		settingRows.forEach(r => {
			if (r.prop && r.value !== undefined) {
				setting.game[r.prop] = r.value;
			}
		});
		let maxScore = 0;
		let components: any = this.questionRows.map((r, i) => {
			let score = null;
			const scoreFromGameSetting = settingRows.find(e => e.prop == 'score');
			if (scoreFromGameSetting) {
				score = scoreFromGameSetting.value;
			}
			if (r.score !== undefined) {
				score = r.score;
			}
			score = score === null ? 0 : score;
			let scoreObj: any = { full: score, n: r.n ? r.n : 1, unit: r.unit ? r.unit : 1 };
			maxScore += scoreObj.full;
			let star = null;
			const starFromGameSetting = settingRows.find(e => e.prop == 'star');
			if (starFromGameSetting) {
				star = starFromGameSetting.value;
			}
			star = star === null ? 0 : star;
			let comp: any = { type: 'Rogame', pageNumber: 1, hasScoring: !!score, order: i, page: pageid, name2: 'Q' + (i + 1) };
			const exceptedProp = ['score', 'n', 'unit'];
			for (const prop in r) {
				if (exceptedProp.indexOf(prop) === -1) {
					comp[prop] = r[prop];
				}
			}
			comp.score = scoreObj;
			comp.star = star;
			comp.cid = GUID.create("OKD guid");
			comp.name = '' + (i + 1);
			comp.learningObjective = this.los.length > 0 ? this.los.map(e => e.key) : [];
			return comp;
		});

		let page: any = { pageNumber: 1, id: pageid, title: 'Page 1', components: components };
		let chapter: any = { pageCount: 1, title: 'Game', pages: [page] };
		let structure: any = { book: { title: this.name, maxScore: maxScore, chapters: [chapter], setting: setting } };
		structure.game_id = this.selectedGame.id;
		structure.subItem_id = this.selectedSubitem.id;
		let entryOptions:any = { subjects: this.subjects };
		if (this.data.col){
			entryOptions.column_id = this.data.col.id; 
		}
		const resEntry: any = await this.datas.post('ROGame.createGame', [structure, entryOptions]).toPromise();
		if (resEntry.book_id === undefined) {
			this.als.toastError(JSON.stringify(resEntry));
			return;
		}
		if (this.section == 'course' || this.section == 'workspace') {
			resEntry.name = this.name;
			this.data.name = this.name;
			this.data.bookId = resEntry.book_id;
			this.data.close(resEntry);
			return;
		}
		const startTime = this.startTime ? moment(this.startTime, 'YYYY-MM-DD HH:mm:ss').toISOString() : null;
		const endTime = this.endTime ? moment(this.endTime, 'YYYY-MM-DD HH:mm:ss').toISOString() : null;
		const starForGameSetting = setting.game && setting.game.star ? setting.game.star : 0;
		const shareOptions = { type: 'custom', star: starForGameSetting, start_time: startTime, end_time: endTime };
		const targets = this.targets.filter(t => t.tType == 'tag');
		const individualUids = this.targets.filter(t => t.tType == 'people').map(e => e.value);
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYearId = this.sts.current_year_id;
			const json: any[] = [schoolId, currentYearId, schoolId, title, tagType, 'main', individualUids];
			let virtualTagRes: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({ tType: 'tag', value: virtualTagRes.tid, title: title });
		}
		const targetIds = targets.map(e => e.value);
		let o = { entries: [{ id: resEntry.book_id, type: 'game', title: this.name }], tags: targetIds, options: shareOptions, subjects: this.subjects };
		const shareRes = await this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [o] } });
		if (shareRes) {
			let shares = shareRes.shares.map(sh => {
				sh.short_url = null;
				sh.tagTitle = sh.tags ? sh.tags[0].title : 'n/a';
				return sh;
			});
			this.data.shares = shares;
			this.data.bsids = shares.map(e => e.id);
			this.data.close(this.data);
		};
	}

	numInput($event, numType = 'int') {
		const char = $event.key;
		const value = $event.target.value + char;
		if (char.length > 1) {
			return true;
		}
		if (numType == 'float') {
			return /^[0-9.]+$/.test(char) && !isNaN(value);
		} else if (numType == 'float+') {
			return /^[0-9+-.]+$/.test(char) && !isNaN(value);
		} else if (numType == 'int+') {
			return /^[0-9+-]+$/.test(char) && !isNaN(value);
		} else {
			return /^[0-9]+$/.test(char) && !isNaN(value);
		}
	}

	loClick($event) {
		this.learningObjectiveSelector.open($event.target, this.eleRef.nativeElement).then(los => {
			const modalSelectedItems = this.learningObjectiveSelector.treeContent.modalSelectedItems;
			this.los = modalSelectedItems.map(e => {
				return { key: e.key, name: e.name };
			});
		});
	}

	imageUpload(row, index=0, shouldReplace=0) {
		const dataType = row.dataType;
		let options: any = { toMediaServer: 'game', fileType: 'image' };
		if (row.width) {
			options.width = row.width;
		}
		if (row.height) {
			options.height = row.height;
		}
		if (dataType == 'images' && row.count && !shouldReplace) {
			const uploadedNum = row.value.filter(e => e).length;
			if (uploadedNum >= row.count) {
				let msg = { tc: '圖片上載數目上限是{count}張', sc: '圖片上載數目上限是{count}張', en: 'Maximum images is {count}.' }[this.lang];
				msg = msg.replace('{count}', row.count);
				this.als.toastError(msg);
				return;
			}
		}
		this.upload.upload(options).then((data: any) => {
			let value = data.url;
			row.invalid = false;
			const fullUrl = "//oka.blob.core.windows.net/media/" + value;
			const bgStyle = this.sans.bypassSecurityTrustStyle("url('" + fullUrl + "')");
			if (dataType == 'image') {
				row.value = value;
				row.backgroundImage = bgStyle;
			} else if (index > 0){
				row.value[index] = value;
				row.backgroundImage[index] = bgStyle;
			} else {
				row.value = row.value ? row.value : [null]; //如果無就放fake圖所以null
				row.backgroundImage = row.backgroundImage ? row.backgroundImage : [null];
				const uploadedNum = row.value.filter(e => e).length;
				if (uploadedNum == 0 || (row.count && row.value.length < row.count)) {
					row.value[uploadedNum] = value;
					row.backgroundImage[uploadedNum] = bgStyle;
				} else if (row.count && row.value.length < row.count && row.value[0] != null){
					row.value.push(value);
					row.backgroundImage.push(bgStyle);
				} else if (row.value.length > index + 1 || shouldReplace){
					row.value[index] = value;
					row.backgroundImage[index] = bgStyle;
				} else {
					row.value.push(value);
					row.backgroundImage.push(bgStyle);
				}
			}
		});
	}

	imageClear(row) {
		row.value = null;
		row.backgroundImage = null;
	}

	imageSetDefault(row){
		if (Array.isArray(row.value)){
			row.value = [...row.default];
			row.backgroundImage = row.value.map(e=>{
				if (!e){
					return null;
				} else if (e.indexOf('//') > -1){
					return this.sans.bypassSecurityTrustStyle("url('" + e + "')");
				} else {
					const url = '//oka.blob.core.windows.net/media/' + e;
					return this.sans.bypassSecurityTrustStyle("url('" + url + "')");
				}
			});
		} else {
			row.value = row.default;
			const url = (row.value.indexOf('//') === -1?'//oka.blob.core.windows.net/media/':'') + row.value;
			row.backgroundImage = this.sans.bypassSecurityTrustStyle("url('" + url +"')");
		}
	}

	imagePreviewClick(event, row, index = 0) {
		const whitePopEle = document.querySelector('.create-game-whitepopup');
		if (whitePopEle){
			whitePopEle.remove();
			return;
		}
		let uploadTitle = { tc: '上載', sc: '上载', en: 'Upload' }[this.lang];
		if (!row.value){
			return;
		} else if (row.value[index]){
			uploadTitle = { tc: '取代', sc: '取代', en: 'Replace' }[this.lang];
		}
		const lang = this.lang;
		const dataType = row.dataType;
		
		const whitePopupItems = [
			{
				titleKey: uploadTitle,
				click: () => {
					let shouldReplace = 0;
					if (Array.isArray(row.value) && row.value[index]){
						shouldReplace = 1;
					}
					this.imageUpload(row, index, shouldReplace);
				}
			},
			{
				titleKey: { tc: '下載', sc: '下載', en: 'Download' }[lang],
				click: () => {
					let value = dataType == 'image' ? row.value : row.value[index];
					let arr = value.split('/');
					let name = arr[arr.length - 1];
					name = name.replace(/time\(\d+\)-/g, '');
					const fullUrl = '//oka.blob.core.windows.net/media/' + value;
					this.datas.get(fullUrl, { responseType: 'blob' }).subscribe(blob=>{
						downloadjs(blob, name, blob.type);
					});
				}
			},
			{
				titleKey: { tc: '刪除', sc: '刪除', en: 'Delete' }[lang],
				click: () => {
					if (dataType == 'image') { 
						row.value = null; row.backgroundImage = null; 
					} else {
						if (row.count || index == 0) { 
							row.value[index] = null; 
							row.backgroundImage[index] = null; 
						} else { 
							row.value.splice(index, 1); 
							row.backgroundImage.splice(index, 1); 
						}
					}
				},
			}
		];
		this.wpp.showSelection(event.target, whitePopupItems, {elClass: 'create-game-whitepopup'}).catch(e => e);
	}

	loadExistGame() {
		this.name = this.data.name;
		if (typeof this.data.structure === 'string'){
			try{
				this.data.structure = JSON.parse(this.data.structure);
			} catch (e){
				this.als.toastError('data error');
			}
		}
		if (this.data.subjects){
			this.subjects = [... this.data.subjects];
		}
		const structure = this.data.structure;
		this.hint = structure.book.setting.hint;
		let loArray = structure.book.chapters[0].pages[0].components[0].learningObjective;//ex. ['1-1']
		if (loArray.length > 0) {
			this.coms.waitFor(() => this.learningObjectiveSelector, 100, 100).then(() => {
				this.learningObjectiveSelector.selectedItems = loArray;
			});
			this.datas.post('AssessmentForm.get_points_only', [loArray]).subscribe((res: any) => {
				this.los = loArray.map(e => {
					let arr = e.split('-');
					const pointDetail = res.points.find(e => e.assessment_form_id == arr[0] && e.point_id == arr[1]);
					const name = pointDetail ? pointDetail.name : '';
					return { key: e, name: name };
				});
			});
		}
		if (this.data.bsid){
			this.tags_title = this.data.tag_name;
			let wholeDay = 0;
			if (this.data.start_time) {
				const startMo = moment(this.data.start_time, 'YYYY-MM-DD HH:mm:ss');
				this.startTime = startMo.toDate();
				const last8Dight = startMo.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			}
			if (this.data.end_time) {
				const endMo = moment(this.data.end_time, 'YYYY-MM-DD HH:mm:ss');
				this.endTime = endMo.toDate();
				const last8Dight2 = endMo.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			}
			this.wholeDay = wholeDay;
		}
	}

	async updateGame() {
		const pageid = this.data.structure.book.chapters[0].pages[0].id;
		let setting: any = { id: this.selectedGame.id, url: this.selectedGame.url, image: this.selectedGame.imageUrl, hint: this.hint, game: {} };
		let settingRows = [...this.basicSettingRows, ...this.advanceSettingRows, ...this.imageSettingRows];
		settingRows.forEach(r => {
			if (r.prop && r.value !== undefined) {
				setting.game[r.prop] = r.value;
			}
		});
		let maxScore = 0;
		let components: any = this.questionRows.map((r, i) => {
			let score = null;
			const scoreFromGameSetting = settingRows.find(e => e.prop == 'score');
			if (scoreFromGameSetting) {
				score = scoreFromGameSetting.value;
			}
			if (r.score !== undefined) {
				score = r.score;
			}
			score = score === null ? 0 : score;
			let scoreObj: any = { full: score, n: r.n ? r.n : 1, unit: r.unit ? r.unit : 1 };
			maxScore += scoreObj.full;
			let star = null;
			const starFromGameSetting = settingRows.find(e => e.prop == 'star');
			if (starFromGameSetting) {
				star = starFromGameSetting.value;
			}
			star = star === null ? 0 : star;
			let comp: any = { type: 'Rogame', pageNumber: 1, hasScoring: !!score, order: i, page: pageid, name2: 'Q' + (i + 1) };
			const exceptedProp = ['score', 'n', 'unit'];
			for (const prop in r) {
				if (exceptedProp.indexOf(prop) === -1) {
					comp[prop] = r[prop];
				}
			}
			comp.score = scoreObj;
			comp.star = star;
			comp.cid = comp.cid ? comp.cid : GUID.create("OKD guid");
			comp.name = '' + (i + 1);
			comp.learningObjective = this.los.length > 0 ? this.los.map(e => e.key) : [];
			return comp;
		});

		let page: any = { pageNumber: 1, id: pageid, title: 'Page 1', components: components };
		let chapter: any = { pageCount: 1, title: 'Game', pages: [page] };
		let structure: any = { book: { title: this.name, maxScore: maxScore, chapters: [chapter], setting: setting } };
		structure.game_id = this.selectedGame.id;
		structure.subItem_id = this.selectedSubitem.id;
		this.data.structure = structure;
		const startTime = this.startTime ? moment(this.startTime, 'YYYY-MM-DD HH:mm:ss').toISOString() : null;
		const endTime = this.endTime ? moment(this.endTime, 'YYYY-MM-DD HH:mm:ss').toISOString() : null;
		const starForGameSetting = setting.game && setting.game.star ? setting.game.star : 0;
		const shareOptions = { star: starForGameSetting, start_time: startTime, end_time: endTime, name: this.name, subjects: this.subjects };
		const resEntry: any = await this.datas.post('ROGame.updateGame', [structure, this.data.bookId]).toPromise();
		if (this.section == 'bookshelf') {
			const shareEntry = await this.datas.post('ROGame.updateGameOptions', [shareOptions, this.data.bsid]).toPromise();
			this.data.close(this.data);
			return;
		}
		this.data.close(this.data);
		// this.als.toastError('under construction');
	}

	setColor(event, row){
		row.value = event;
	}

}
