import { NgModule } from '@angular/core';
import { HomeBtnComponent } from './homeBtn.component';


@NgModule({
    imports: [
    ],
    declarations: [
        HomeBtnComponent
    ],
    exports: [
        HomeBtnComponent
    ],
    providers: [],
    bootstrap: []
})
export class HomeBtnModule { }
