
import { ByteArray, Endian } from "openfl";
import { ArrayUtils } from "src/app/common/ArrayUtils";

import { ByteArrayUtils } from "../utils/ByteArrayUtils";
import { RODoc } from "./RODoc";

class QuestionComponentType
{
	public static NONE:string = "";
	public static MC:String = "OKDMC_2";
	public static DRAGDROP:String = "OKDDRAGDROP";
	public static CAMERA:String = "OKDCAMERA";
	public static RECORDER:String = "OKDSOUNDRECORDER";
	public static REORDER:String = "OKDREORDER";
	public static RADIOANSWER:String = "OKDRADIOANSWER";
	public static FILLING:String = "OKDFILLING2";
	public static DRAGLINE:String = "OKDMULTILINEDRAGLINE";
	public static GRAPHICMCOLD:String = "OKDMC";
	public static GRAPHICMC:String = "OKDGRAPHICMC";
	public static LONGQUESTION:String = "OKDLONGQUESTION_3";
	public static PINYINFILLING:String = "OKDPINYINFILLING";
	public static PINYINFILLINGTEXT:String = "OKDPINYINFILLINGTEXT";//PinYinFillingText
	public static COMBO:String = "OKDCOMBO";
	public static VIDEOCAMERA:String = "OKDVIDEOCAMERA";
	public static STROKEORDER:String = "OKDSTROKEORDER";
	public static MINDMAP:String = "OKDNEWMINDMAP";
	public static EPEN:String = "OKDEPEN";
	public static SC:String = "SC";
	public static EXCHANGECOMMENT:String = "OKDEXCHANGECOMMENT";
	public static MAZE:String = "OKDMAZE";
	public static LEGO:String = "OKDLEGO";
	public static PINBOARD:String = "OKDPINBOARD";
	public static TOGGLEOPTIONS:String = "OKDTOGGLEOPTIONS";
	public static CHART:String = "OKDCHART2";
	public static SHAPE:String = "OKSHAPE";
	public static SYMBOL:String = "OKDBASICSYMBOL";
	public static SLIDE:String = "OKDSLIDE2";
	public static CLOCK:String = "OKDWATCH";
	public static PHOTOHUNT:String = "OKDPHOTOHUNT";
	public static WEBGAME_CONTAINER:String = "OKDWEBGAMECONTAINER";
	public static DRAGITEM:String = "OKDDRAGITEM";
	public static MATHSTRAIGHT:String = "OKDMATHSTRAIGHT";
	public static OTHER:String = "OTHER";
	public static ANALYSIS_A:String = "OKDANALYSIS_A";
}

var componentNameList:Object =
{
	Reorder:QuestionComponentType.REORDER,
	"Recorder": QuestionComponentType.RECORDER,
	"RECORDER": QuestionComponentType.RECORDER,
	//"PinYin": QuestionComponentType.PINYINFILLING, // Text highlight
	"MultipleChoice": QuestionComponentType.MC, // old(PTH)
	"MultipleChoice2": QuestionComponentType.MC, // old(PTH)
	"MC": QuestionComponentType.MC,
	"MC2": QuestionComponentType.MC,
	"DragDropA": QuestionComponentType.DRAGDROP,
	"DragDropB": QuestionComponentType.DRAGDROP,
	"DragDropC": QuestionComponentType.DRAGDROP,
	"DragDropD": QuestionComponentType.DRAGDROP,
	"DragDropE": QuestionComponentType.DRAGDROP,
	"DragDropF": QuestionComponentType.DRAGDROP,
	"Camera": QuestionComponentType.CAMERA,
	"RadioAnswer": QuestionComponentType.RADIOANSWER,
	//"Filling2": QuestionComponentType.FILLING,
	"Filling": QuestionComponentType.FILLING,
	"MultiDragLine": QuestionComponentType.DRAGLINE,
	"SimpleDragLine": QuestionComponentType.DRAGLINE,
	"GraphicMC": QuestionComponentType.GRAPHICMCOLD,
	"LongQuestion3": QuestionComponentType.LONGQUESTION,
	//"PinYinFilling": QuestionComponentType.PINYINFILLING,
	"PinYinFillingText": QuestionComponentType.PINYINFILLING,
	"Combo": QuestionComponentType.COMBO,
	"VideoCamera": QuestionComponentType.VIDEOCAMERA,
// 	"StrokeOrder": QuestionComponentType.STROKEORDER,
	//"NewMindMap2": QuestionComponentType.MINDMAP,
	"NewMindMap": QuestionComponentType.MINDMAP,
	"Epen": QuestionComponentType.EPEN,
	"ExchangeComment": QuestionComponentType.EXCHANGECOMMENT,
	"Maze": QuestionComponentType.MAZE,
	"Lego": QuestionComponentType.LEGO,
	"PinBoard": QuestionComponentType.PINBOARD,
	//"SymbolCounter": QuestionComponentType.SC,
	"ToggleOptions": QuestionComponentType.TOGGLEOPTIONS,
	//"Chart2": QuestionComponentType.CHART,
	//"BlockChart": QuestionComponentType.CHART,
	//"Pictogram": QuestionComponentType.CHART,
	//"Watch": QuestionComponentType.CLOCK,
	"PhotoHunt": QuestionComponentType.PHOTOHUNT,
	"dragItem3": QuestionComponentType.DRAGITEM,
	"WebGameContainer": QuestionComponentType.WEBGAME_CONTAINER
};



export class ROBookStructureReader
{
	constructor()
	{

	}
	getBookStructure(obj:any):any
	{

		var output:any = {};
		var book:any = obj.book;
		var docs:any [] = obj.chapters;
		
		var len:number = docs.length;
		var chapters:any [] = [];
		var output:any = {
			version:"0.0.1",
			book:{
				title:book.title,
				chapters:chapters
			}
		};
		var pageIndex:number = 0;
		var parser:DOMParser = new DOMParser();
		for (var i:number = 0; i < len; i++)
		{
			var doc:any = docs[i];
			var xmlString:string = doc.xml;
			
			var dom:any = parser.parseFromString(xmlString, "text/xml");
			// var xml:string = doc.xml;
			var xml = dom.querySelector("Doc");
			
			// var docID:string = doc.id;
			// var url:string = docID;
			
			var title:string = doc.title;
			
			var chapter:any = this.readChapter(doc.id, /*docID, url, */title, xml, pageIndex);
			if (chapter)
			{
				pageIndex += chapter.pageCount;
				chapters.push(chapter);
			}
		}
		return output;
	}

	public readChapter(id:string, /*docID:string, url:string, */title:string, xml:HTMLElement, pageIndex:number):any
	{
		var pages:any [] = [];
		var chapter:any = {
			id:id,
			title:title,
			// docID:docID,
			// url:url,
			pages:pages
		};
		
		var pageCount:number = 0;
		
		// var pagesXML :any [] = xml.Chapter.Page;
		var pagesXML :NodeListOf<HTMLElement> = xml.querySelectorAll("Chapter>Page");

		var pageLen:number = pagesXML.length;
		for (var p:number = 0; p < pageLen; p++)
		{
			var pageXML:HTMLElement = pagesXML[p];
			var isBroadcast = pageXML.getAttribute("broadcast");
			if (isBroadcast == "true")
			{
				var pageInfo:any = this.extractPageInfo(pageXML);
				if (pageInfo)
				{
					pages.push(pageInfo);
					pageInfo.pageNumber = pageIndex +1;
				}
				pageIndex++;
				pageCount++;
			}
			
			
			
		}
		chapter.pageCount = pageCount;
		return chapter;
	}
	
	public extractPageInfo(pageXML:any):any
	{
		
		
		var componentsList:any [] = this.getDocAnswerComponentFromPageXML(pageXML,  0);
	
		if (componentsList.length)
		{
			var len:number = componentsList.length
			for (var i:number = 0; i < len; i++)
			{
				var com:any = componentsList[i];
				delete com.node;
				delete com.componentName;
				delete com.xml;
			}
			
			var page:any = {
				id:pageXML.getAttribute("douid"),
				title:pageXML.getAttribute("slideTitle"),
				components:componentsList
			}
			
			return page;
		} else {
			return {
				id:pageXML.getAttribute("douid"),
				title:pageXML.getAttribute("slideTitle"),
				components:[]
			};
		}
		
	}
	public getDocAnswerComponentFromPageXML(pageXML:any, p:number):any []
	{
		var sorter:ComponentSortHelper = new ComponentSortHelper();
		var pageQueue:any [] = [];
		this.searchAnswerComponent(p, pageXML.getAttribute("douid"), pageXML, pageQueue, pageXML.children);
		pageQueue.forEach((element:any)=>
		{
			
			var cXML:HTMLElement = element.xml;
			element.qIndex = cXML.getAttribute("questionIndex");
			element.qSection = cXML.getAttribute("questionSection");
			element.q = this.fromJSON(cXML.getAttribute("q"));
			element.qInfo = this.fromJSON(cXML.getAttribute("qInfo"));
			element.s = this.fromJSON(cXML.getAttribute("s"));
			element.score = {
				type:cXML.getAttribute("scoringType"), //   .@scoringType,
				n:cXML.getAttribute("scoreN"),
				unit:cXML.getAttribute("unitScore"),
				full:cXML.getAttribute("fullScore")
			}
			if (element.score.full == 0)
			{
				element.score.full = 1;
			}
			var title:string = cXML.getAttribute("questionLabelTitle");
			if (title)
			{
				element.title = title;
			}
			if (element.s && element.s.hasOwnProperty("enable"))
			{
				var hasScoring:Boolean = element.s.enable ? true : false;
				if (hasScoring != element.hasScoring)
				{
					element.hasScoring = hasScoring
					//cXML.@hasScoring = hasScoring;
				}
			}
		});
		pageQueue = sorter.sortComponents(pageQueue, parseInt(pageXML.getAttribute("questionStartIndex")));
		return pageQueue;
	}

	public searchAnswerComponent(pageNumber:number, page:string, pageXML:any, queue:any [], cXML:any):void
	{
		// var className:String;
		var componentName:String;
		// var info:Object;
		var len:number = cXML.length;
		for (var i:number = 0; i < len; i++)
		{
			var comXML:any = cXML[i];
			var n:string = comXML.localName;
			if (n)
			{
				var isQuestion = false;
				var isQuestionComponentAttribute:any = comXML.getAttribute("isQuestionComponent");
				if (
					componentNameList.hasOwnProperty(n) || 
					isQuestionComponentAttribute === "true" || 
					isQuestionComponentAttribute === true
				)
				{
					if(n == "ExamPaper")
					{
						comXML.setAttribute("isQuestionComponent", false)
					} else {
						isQuestion = true;
					}
				} else {
					isQuestion = false;
				}
				if(isQuestion)
				{
					queue.push( 
						{ 
							xml:comXML,
							learningObjective:this.getComponentLearningObjective(comXML),
							qInfo:this.fromJSON(comXML.getAttribute("qInfo")),
							q:this.fromJSON(comXML.getAttribute("q")),
							pageNumber:pageNumber, 
							page:page, 
							cid:comXML.getAttribute("douid"), 
							type: n, 
							hasScoring:comXML.getAttribute("hasScoring") == "true",
							score:comXML.getAttribute("scoreN"),
							componentName:componentName,
							ce:comXML.getAttribute("coordinateExpression")
						}
					);
				}
				else
				{

					this.searchAnswerComponent(pageNumber, page, pageXML, queue, cXML[i].children);
				}
			}
		}
		
		//return result;
	}

	public getComponentLearningObjective(xml:HTMLElement):any
	{
		var value:string = xml.getAttribute("learningObjective");
		if (value)
		{
			return JSON.parse(value);
		} else {
			return null;
		}
	}
	
	public fromJSON(str:string):any
	{
		try
		{
			return JSON.parse(str);
		} catch (err)
		{
			
		}
		return null;
	}
	
}


class ComponentSortHelper
{
	constructor()
	{
		
	}
	
	public  sortComponents(queue:any[], start:number):any []
	{
		var i:number;
		var len:number;
		var allNodes:any [] = [];
		var com:any;
		var self:any 
		len = queue.length;
		
		var components:any [] = queue;
		// components = Underscore.sortKey(components, ["qInfo.index", "qInfo.order"]);
		len = components.length;
		var root:any = {
			fullName:null,
			children:[]
		}
	
		var map:any = {
			
			0:root
		};
		var nodes:any [] = [];
		// build node
		for (i = 0; i < len; i++)
		{
			com = components[i];
			var o:any = {
				com:com,
				children:[]
			};
			if (com.qInfo)
			{
				map[com.qInfo.id] = o;
			}
			nodes.push(o);
			com.node = o;
		}
		// link nodes and name
		for (i = 0; i < len; i++)
		{
			com = components[i];
			
			if (com.qInfo)
			{
				self = map[com.qInfo.id];
				var parent:any = map[com.qInfo.pid];
				parent.children.push(self);
			}
		}
		for (i = 0; i < len; i++)
		{
			com = components[i];
			
			if (com.qInfo)
			{
				self = map[com.qInfo.id];
				//self.subfix = "";
				self.level = 0;
				//self.hasChildren = false;
				if (com.q && com.q.show)
				{
					if (com.title)
					{
						self.name = com.title;
					} else {
						var level:number = com.qInfo.level;
						var index:number =  com.qInfo.index + 1 ;
						//trace(com.pageNumber, com.tag, level, index, JSON.stringify(com.q), JSON.stringify(com.qInfo));
						//trace(com.xml.toXMLString());
					
						if (level == 0)
						{
							self.name = (start + index).toString() ;
						} else if(level == 1){
							self.name = (index + 1) ; // TextStyleCreator.lowerAlphaString(index + 1)
						} else if (level == 2)
						{
							self.name = index ; // TextStyleCreator.lowerRomanString(index)
						} else if (level == 3)
						{
							self.name = index; // TextStyleCreator.cjkHeavenlyStemString(index)
						} else if (level == 4)
						{
							self.name = index ; // TextStyleCreator.lowerAlphaString(index)
						}
						self.level = level;
					}
				} else {
					self.name = "";
					self.level = 0;
				}
			} else {
				self = com.node;
				root.children.push(self);
				self.name = "";
			}
		}
		
		this.processName(root, {order:1});
		// var sortedNodes:any [] = nodes.sortOn("order", Array.NUMERIC);
		var sortedNodes:any [] = ArrayUtils.sortOn(
			nodes,
			[
				{
					key:"order",
					order:"ASC",
					type:"numeric"
				}
		   ]
		);
			
		this.appendItems(allNodes, sortedNodes); 
		
		allNodes = allNodes.map((element:any)=>{
			element.com.name = element.fullName;
			element.com.order = element.order;
			return element.com;
		});
		// allNodes.sortOn("order", Array.NUMERIC);
		
		ArrayUtils.sortOn(
			allNodes,
			[
				{
					key:"order",
					order:"ASC",
					type:"numeric"
				}
		   ]
		);
		return allNodes;
		
		
	}

	
	private processName(node:any, reference:any):void
	{
		//trace(node);
		var parentName:String = node.parentName;
		var len:number = node.children.length;
		node.order = reference.order;
		reference.order++;
		
		for (var i:number = 0; i < len; i++)
		{
			var childNode:any = node.children[i];
			
			if (parentName)
			{
				childNode.fullName = childNode.parentName = parentName + " " + childNode.name;
			} else {
				childNode.parentName = childNode.name ;
				if (childNode.children.length)
				{
					childNode.fullName = childNode.name  + " a";
				} else {
					childNode.fullName = childNode.name;
				}
			}
			
			this.processName(childNode, reference);
		}
	}
	
	private  fromJSON(str:string):any
	{
		try
		{
			return JSON.parse(str);
		} catch (err)
		{
			
		}
		return null;
	}
		
	private  appendItems(queue:any [], items:any []):void
	{
		var len:number = items.length;
		for (var i:number = 0; i < len; i++)
		{
			queue.push(items[i]);
		}
	}
}



export class RODocumentDecoder
{
	constructor()
	{
		
	}

	decodeBookSetting(base64Content:string):any
	{
		if(!base64Content) return null;
		ByteArray.defaultEndian = Endian.BIG_ENDIAN;
		var bookVariables:any = {};
		var buffer:ArrayBuffer = ByteArrayUtils.fromBase64ToArrayBuffer(base64Content);
		try{
			var bytes:ByteArray = this.uint8ArrayToBytes(new Uint8Array(buffer));
			var bookSetting = JSON.parse(bytes.readUTF());
			console.log("======== get book setting =========");
			console.log(bookSetting);
		} catch (err) {
			console.log("======== get book setting fail =========");
			console.log(err);
		}
		return bookSetting;
	}
	decodeContent(base64Content:string):string
	{
		if(!base64Content) return null;
		var bytes:ByteArray = ByteArrayUtils.fromBase64(base64Content);
		return RODoc.decryptBytes(bytes);
	}

	private uint8ArrayToBytes(u8s:Uint8Array):ByteArray
	{
		var bytes:ByteArray = new ByteArray();
		u8s.forEach(b => bytes.writeByte(b));
		bytes.position = 0;
		return bytes;
	}
}
