import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {WebLessonService} from "../../services/WebLesson.service";
import {CommonService} from "../../../../../service/common.service";
import {ScriptService} from "../../../../../service/script.service";
import {DigitalTimerComponent} from "./digital-timer.component";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../../../../service/data.service";

@Component({
	selector: 'timer-modal',
	template: `
		<div #modal class="timer-modal" [class.is-student]="!web_lesson.is_teacher"
			 (mouseup)="start_drag = false"
			 *ngIf="is_show && is_ready"
			 [class.time-is-up]="web_lesson.lesson_state.timer.time_is_up"
		>
			<div class="timer-section">
				<div #move_handle
					 (mousedown)="start_dragging($event)"
					 (touchstart)="start_dragging($event)"
					 class="timer-move-handle"></div>
				<div class="timer">
					<digital-timer
						#timer
						(time_up)="execute_time_up_action()"
						(time_passed)="update_student_timer($event)"
						[is_student]="!web_lesson.is_teacher"
					></digital-timer>
				</div>
				<div class="btn-close" (click)="close()"></div>
			</div>
			
			<div class="action-section">
				<div *ngIf="!is_timer_start" class="btn-start" (click)="start();"></div>
				<div *ngIf="is_timer_start" class="btn-stop" (click)="stop();"></div>
				<div class="btn-reset" (click)="reset();"></div>
				<div class="btn-increment" (click)="increment_popup.open($event.target)"></div>
				<div class="btn-time-is-up-action" (click)="time_is_up_popup.open($event.target)">
					<span style="font-size: 12px">{{ 'web-lesson-tools.timer.time_is_up' | translate }}</span>
					<br/>
					<span style="font-size: 15px;line-height: 26px;">{{ 'web-lesson-tools.timer.' + time_is_up_action | translate }}</span>
				</div>
			</div>
		</div>
		<whitePopup #increment_popup [menuItems]="time_increment_menu_items"></whitePopup>
		<whitePopup #time_is_up_popup [menuItems]="time_is_up_menu_items"></whitePopup>
	`,
	styleUrls: ['./timer-modal.component.scss']
})

export class TimerModalComponent implements OnInit {

	bounds = null;
	start_drag = false;

	@ViewChild('modal', {static: false}) modal: ElementRef;
	@ViewChild('timer', {static: false}) timer: DigitalTimerComponent;

	last_point: any = {x: null, y: null};
	is_ready = false;

	is_timer_start = false;
	audio_player = null;

	time_increment_menu_items = [];
	time_is_up_menu_items = [];
	time_is_up_action = "no_action";

	before_destroy_list = [];

	constructor(
		public web_lesson: WebLessonService,
		public eleRef: ElementRef,
		public coms: CommonService,
		public script: ScriptService,
		public trans: TranslateService,
		public datas: DataService,
	) {
		let init_audio = () => {
			this.init_audio();
		};
		document.body.addEventListener('click', init_audio);
		this.before_destroy_list.push(() => {
			document.body.removeEventListener('click', init_audio);
		})

		this.script.load('swiper-web-component/swiper-element-bundle.min.js').then(() => {
			this.is_ready = true;
		});
		this.trans.use(this.datas.lang).subscribe(() => {
			this.time_increment_menu_items = [
				{title: this.trans.instant('web-lesson-tools.timer.add_sec', {value: 10}), click: () => this.timer.add_time(10)},
				{title: this.trans.instant('web-lesson-tools.timer.add_sec', {value: 30}), click: () => this.timer.add_time(30)},
				{title: this.trans.instant('web-lesson-tools.timer.add_min', {value: 1}), click: () => this.timer.add_time(1 * 60)},
				{title: this.trans.instant('web-lesson-tools.timer.add_min', {value: 2}), click: () => this.timer.add_time(2 * 60)},
				{title: this.trans.instant('web-lesson-tools.timer.add_min', {value: 3}), click: () => this.timer.add_time(3 * 60)},
				{title: this.trans.instant('web-lesson-tools.timer.add_min', {value: 5}), click: () => this.timer.add_time(5 * 60)},
			];
			this.time_is_up_menu_items = [
				{
					title: this.trans.instant('web-lesson-tools.timer.no_action'), click: () => {
						this.time_is_up_action = "no_action";
						localStorage.setItem("web_lesson_timer_time_is_up_action", "no_action");
					}
				},
				{
					title: this.trans.instant('web-lesson-tools.timer.black_screen'), click: () => {
						this.time_is_up_action = "black_screen";
						localStorage.setItem("web_lesson_timer_time_is_up_action", "black_screen");
					}
				},
				{
					title: this.trans.instant('web-lesson-tools.timer.disable_input'), click: () => {
						this.time_is_up_action = "disable_input";
						localStorage.setItem("web_lesson_timer_time_is_up_action", "disable_input");
					}
				},
			];
		})
		let sub = this.web_lesson.on_message.subscribe(async (message) => {
			if (!this.web_lesson.is_teacher) {
				if (message.content.action === 'update_lesson_state') {
					if (!this.is_show) {
						this.open();
					}
					if (message.content.lesson_state.timer.time_is_up) {
						console.log('time is up!!!');
						this.play_sound();
					}
					if (this.web_lesson.lesson_state.timer.student_time >= 0) {
						await this.coms.waitFor(() => this.timer, 9999999, 100);
						let time = this.timer.sec_to_time(this.web_lesson.lesson_state.timer.student_time);
						this.timer.set_time(time);
					}
				}
			}
		});
		this.before_destroy_list.push(() => {
			sub.unsubscribe();
		})
		this.time_is_up_action = localStorage.getItem("web_lesson_timer_time_is_up_action") || "no_action";
	}

	ngOnInit() {
		this.bounds = document.body;
	}

	//before destroy
	ngOnDestroy() {
		this.before_destroy_list.forEach((cb) => cb());
		this.before_destroy_list = [];
	}

	get is_show() {
		if (this.web_lesson.is_teacher) {
			if (this.web_lesson.lesson_state.timer.show) {
				return true;
			}
		} else {
			if (this.web_lesson.lesson_state.timer.student_time > 0) {
				return true;
			}
		}

		return false;
	}

	async start() {
		this.is_timer_start = true;
		await this.timer.start();
	}

	init_audio() {
		if (!this.audio_player) {
			this.audio_player = new Audio();
			this.audio_player.src = 'assets/ro/sound/AlarmClockBeep.mp3';
			this.audio_player.load();
			this.audio_player.loop = true;
		}
	}

	stop() {
		if(this.timer.is_time_up) {
			return;
		}
		this.is_timer_start = false;
		this.timer.stop();
		this.web_lesson.lesson_state.timer.student_time = 0;
		this.web_lesson.update_lesson_state();
	}

	reset() {
		this.is_timer_start = false;
		if (this.web_lesson.is_teacher) {
			this.web_lesson.lesson_state.timer.time_is_up = false;
			this.web_lesson.update_lesson_state();
		}
		this.timer.reset();
	}

	start_dragging(event) {
		this.start_drag = true;
		let is_touch = event instanceof TouchEvent;
		if (is_touch) {
			event.clientX = event.touches[0].clientX;
			event.clientY = event.touches[0].clientY;
		}

		this.last_point = {x: event.clientX, y: event.clientY};

		document.addEventListener('mousemove', this.on_move.bind(this));
		document.addEventListener('mouseup', this.on_stop.bind(this));
		// touch support
		document.addEventListener('touchmove', this.on_move.bind(this));
		document.addEventListener('touchend', this.on_stop.bind(this));

	}

	on_move(event) {
		let is_touch = event instanceof TouchEvent;
		if (is_touch) {
			event.clientX = event.touches[0].clientX;
			event.clientY = event.touches[0].clientY;
		}
		if (this.start_drag) {
			this.positionChange(event);
		}
	}

	on_stop() {
		this.start_drag = false;
		document.removeEventListener('mousemove', this.on_move);
		document.removeEventListener('mouseup', this.on_stop);
		// touch support
		document.removeEventListener('touchmove', this.on_move);
		document.removeEventListener('touchend', this.on_stop);
	}

	async update_modal_position(top, left) {
		if (!this.modal || !this.modal.nativeElement) {
			await this.coms.waitFor(() => this.modal && this.modal.nativeElement, 100000, 10);
		}
		this.modal.nativeElement.style.top = top;
		this.modal.nativeElement.style.left = left;

		localStorage.setItem('web_lesson_timer_modal_top', top);
		localStorage.setItem('web_lesson_timer_modal_left', left);
	}

	positionChange(event) {
		// follow the mouse
		let top = this.modal.nativeElement.style.top;
		top = top.replace('px', '');
		top = parseInt(top);
		let left = this.modal.nativeElement.style.left;
		left = left.replace('px', '');
		left = parseInt(left);
		let diff_x = event.clientX - this.last_point.x;
		let diff_y = event.clientY - this.last_point.y;
		// check if out of bounds
		if (top + diff_y < 0) {
			diff_y = -top;
		}
		if (left + diff_x < 0) {
			diff_x = -left;
		}
		if (this.bounds) {
			if (top + diff_y + this.modal.nativeElement.clientHeight > this.bounds.clientHeight) {
				diff_y = this.bounds.clientHeight - top - this.modal.nativeElement.clientHeight;
			}
			if (left + diff_x + this.modal.nativeElement.clientWidth > this.bounds.clientWidth) {
				diff_x = this.bounds.clientWidth - left - this.modal.nativeElement.clientWidth;
			}
		}
		if (diff_x === 0 && diff_y === 0) {
			return;
		}
		this.last_point = {x: event.clientX, y: event.clientY};
		this.update_modal_position(top + diff_y + 'px', left + diff_x + 'px');
	}

	close() {
		this.web_lesson.lesson_state.timer.show = false;
		this.start_drag = false;
		this.last_point = {x: null, y: null};
	}

	open() {
		this.web_lesson.lesson_state.timer.show = true;
		this.update_modal_position(
			localStorage.getItem('web_lesson_timer_modal_top') || '10px',
			localStorage.getItem('web_lesson_timer_modal_left') || '10px'
		)
	}

	async execute_time_up_action() {
		this.web_lesson.send_alert_to_student(this.trans.instant('web-lesson-tools.timer.time_is_up_alert_msg'));
		this.web_lesson.lesson_state.timer.time_is_up = true;
		this.web_lesson.update_lesson_state();

		if (this.time_is_up_action === "black_screen") {
			this.web_lesson.toggle_student_black_screen(true);
		}
		if (this.time_is_up_action === "disable_input") {
			this.web_lesson.toggle_student_lock_screen(true);
		}
		this.play_sound();
	}

	async play_sound() {
		console.log('play sound!!!')
		this.init_audio();

		this.audio_player.currentTime = 0;
		this.audio_player.play();
		await this.coms.timeout(3000);
		this.audio_player.pause();

	}

	update_student_timer($event) {
		this.web_lesson.lesson_state.timer.student_time = $event;
		this.web_lesson.update_lesson_state();
	}
}