import {AfterViewInit, Component,Input,OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { faCamera, faTimes, faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
// import { RoService } from 'src/app/service/ro.service';
import { isObject } from 'util';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { AlertService } from 'src/app/service/alert.service';

// import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';

@Component({
  selector: 'message-panel',
  templateUrl: "./message-panel.component.html",
  styleUrls: ['./message-panel.component.scss']
})

export class MessagePanelComponent implements OnInit, AfterViewInit{
  @Input() public data:any;//{status: string,id: int, image: safeStyle, title: string, author: string, messages: [], recordAllow: bool, imageAllow: bool}
  @Input() public parent;
  @Input() public practiceSetting;
  @Output() emojiSelect:EventEmitter<any> = new EventEmitter();
  @Output() studentSpeaking:EventEmitter<any> = new EventEmitter();
  @Output() sentMesaage:EventEmitter<any> = new EventEmitter();
  @Output() getHint:EventEmitter<any> = new EventEmitter();
  @ViewChild('audioRecorder', {static:false}) audioRecorder: any;  
  @ViewChild('messageInput', {static:false}) messageInput: any;  
	public timer;

  public showRecordingTime = false;
  public textInput = '';
  public uploadPath;
  public area;
  public subscription;
  public recordingTime = 0;
  public tempContent;

  public emojiStyle = {
    position: 'absolute', bottom: '52px', left: '10px', 'overflow-x': 'hidden',width: '360px'
  };
  public emojiCloseX;
  public underWidth200 = false;
  public emojiSelecting = false;
  public inTouch;
  public os;
  public hintQuota: number = 1;
  public colors = ['#128487','#5a1287','#124387','#875812','#a4bb30'];
  public faImage = faImage;
  public faTimes = faTimes;
  public faCamera = faCamera;
  public faMicrophone = faMicrophone;

  get disableTextInput(){
	if(this.audioRecorder && this.audioRecorder.status == "recording"){
		return true;
	}
	return false;
  }


  constructor(private datas:DataService, private theme:ThemeService, public als: AlertService) {
    this.uploadPath = 'environment.uploadPath';
    this.underWidth200 = window.innerWidth < 200;
  }

  ngOnInit() {
	var usedHintCount = this.data.messages.filter(message => message.content_type === "hint").length;
	console.log("this.practiceSetting.ai_hint_times", this.practiceSetting.ai_hint_times);
	if(this.practiceSetting && this.practiceSetting.ai_hint_times){
		this.hintQuota = Math.max(0, this.practiceSetting.ai_hint_times - usedHintCount);
	}
  }

  ngAfterViewInit(){
    const area = <HTMLElement>document.querySelector('textarea');
    this.adjHeight(area);
	setTimeout(() => {
		var usedHintCount = this.data.messages.filter(message => message.content_type === "hint").length;
		console.log("this.practiceSetting.ai_hint_times", this.practiceSetting.ai_hint_times);
		if(this.practiceSetting && this.practiceSetting.ai_hint_times){
			this.hintQuota = Math.max(0, this.practiceSetting.ai_hint_times - usedHintCount);
		}
		
	}, 1000);
  }

  public toggleEmoji($e) {
    this.emojiSelecting = !this.emojiSelecting;
  }

  public addEmoji($event,area) {
    this.textInput = (this.textInput === undefined ? '' : this.textInput) + $event.emoji.native;
    this.emojiSelecting = false;
    if (area.createTextRange) {
      let tmp = area.createTextRange();
      tmp.select();
    } else {
      area.focus();
    }
  }

  public keypress($event,area) {
    if ($event.keyCode == 13 && $event.shiftKey) {
      this.adjHeight(area);
    } else if ($event.charCode == 13) {
      $event.preventDefault();
      this.sendTextOrVoice();
    //   area.blur();
      const el = <HTMLElement>document.querySelector('app-thread');
      if (el){
        el.focus();
      }
    } else {
      this.adjHeight(area);
    }
  }

  public sendTextOrVoice(type = 'text') {
	//if it is recording, send the voice message. otherwise, send text message
	this.clearTimer();

	if(this.audioRecorder && this.audioRecorder.status == "recording" ){
		this.studentSpeaking.emit(false);
		this.showRecordingTime = false;
		this.audioRecorder.onStopBtnClick();
		return false;
	}
    if (this.textInput == null) {
      this.textInput = '';
    }
    if (this.textInput.replace(/\s/g, "") == '') {
      this.textInput = '';
      return false;
    }
    const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
    const text = this.textInput.replace(/\r?\n|\r/g, '<br/>');
    if (this.containProhibited(text)){
      this.als.toastError('訊息包含不當字眼');
      return;
    }
    const msgObj = {id: 0, content_type:'text', data: text, voice_text: text, quote: this.data.quote, author: this.datas.userInfo.uid, created_at: createdAt};
	if(type == 'text'){
		this.updateMsgObj(msgObj);
	}
	console.log("msgObj", msgObj);
	this.sentMesaage.emit(text);
    this.textInput = '';
    const area = <HTMLElement>document.querySelector('textarea');
	setTimeout(() => {
		this.parent.scrollToBottom();
	}, 20);
    // this.adjHeight(area);
  }

  public isBSpress(code,area){
    if (code == 8){
      this.adjHeight(area);
    }
  }

  public adjHeight(area) {
    if (area){
      area.style.height = '40px';
      let newHeight = area.scrollHeight == 51?46:area.scrollHeight;
      area.style.height = newHeight + 'px';
      area.style.scrollTop = 0;
      setTimeout(()=>{
        if (area.offsetHeight > 40){
          console.log('offsetHeight:>40;set padTop:6');
          area.style.paddingTop = '6px';
        } else {
          console.log('offsetHeight:40;set padTop:14');
          area.style.paddingTop = '11px';
        }
        // this.moveScrollTop(0);
      },50);
    } else {
      console.log('no-area');
    }
  }

  public imgIconClick(event) {
    // event.stopPropagation();
    // if (this.data.messages.find(m=> m.id == 0)){
    //   console.log('cancel:updateMsgObj b/c id=0 exist');
    //   return;
    // }
    // this.emojiSelecting = false;
    // clearTimeout(this.parent.timer$);
    // this.parent.timer$ = null;
	// this.upload.upload({fileType: 'image'}).then((res:any)=>{
	// 	if (res.url){
	// 		const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
	// 		const msgObj = {id: 0, content_type:'image', data: res.url, quote: this.data.quote, author: this.storage.uid, created_at: createdAt};
	// 		this.updateMsgObj(msgObj);
	// 	}
	// });
  }

  str2quote(str){
    if (str.length > 20){
      return str.substr(0,20) + '...';
    } else {
      return str;
    }
  }

  public updateMsgObj(msgObj){
    // if (this.data.messages.find(m=> m.id == 0)){
    //   console.log('cancel:updateMsgObj b/c id=0 exist');
    //   return;
    // }
    clearTimeout(this.parent.timer$);
    this.parent.timer$ = null;
    if (msgObj.content_type =='voice'){
    //   this.common.addLoading('add-voice');
    } else {
      this.data.messages.push(msgObj);
    }
    this.data.quote = {};
    if (msgObj.quote.content_type == 'image'){
      delete msgObj.quote.image;
    }
    const postTime = this.data.messages.map(e=> +moment(e.created_at).toDate() )
      .filter((e,i)=> this.data.messages.length - i < 500)
      .join(',');
    setTimeout(()=>{
      this.datas.post('ROBookshelfNew.post_chat_message',[ msgObj, this.data.thread.id, postTime]).subscribe((res:any)=>{

        if (res.insert_id){
          if (msgObj.content_type == 'voice'){
            try{
              if (isObject(res.msgObj.data)){
                msgObj.data = res.msgObj.data;
              } else {
                msgObj.data = JSON.parse(res.msgObj.data);
              }
			  console.log("msgObj", msgObj);
              this.data.messages.push(msgObj);
            //   this.moveScrollTop(1);
            } catch(e){
              const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
              this.data.messages.push({id: 0, content_type:'text', data:'err:' + res.msgObj.data, author: 0,created_at: createdAt, quote: {}});
            }
            // this.common.removeLoading('add-voice');
          }
          msgObj.id = res.insert_id;
        } else {
          const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
          this.data.messages.push({id: 0, content_type:'text', data:'err: not-found-id0', author: 0, quote: {} });
        }
        this.parent.checkNeedUpdate();
		setTimeout(() => {
			this.parent.scrollToBottom();
		}, 200);
      });
    },100);

    // this.moveScrollTop(1000);
  }



  moveScrollTop(delay = 0){
    setTimeout(()=>{
      const header = <HTMLElement>document.querySelector('.thread-header');
      const board = <HTMLElement>document.querySelector('.thread-board');
      const content = <HTMLElement>document.querySelector('.thread-content');
      const panel = <HTMLElement>document.querySelector('message-panel > .container');
      const windowRoot = <HTMLElement>document.querySelector('window-root');
      const vh = windowRoot.offsetHeight;
      if (header && board && panel){
        const contentHeight = content?(content.offsetHeight > 30?120:30):0;
        const height = vh - header.offsetHeight - contentHeight - panel.offsetHeight - 17;
        board.style.height = height + 'px';
        setTimeout(()=>{board.scrollTop = board.scrollHeight;},50);
      }
    }, delay);
  }

  quoteCloseClick(){
    this.data.quote = {};
    setTimeout(()=>{
      const header = <HTMLElement>document.querySelector('.thread-header');
      const board = <HTMLElement>document.querySelector('.thread-board');
      const content = <HTMLElement>document.querySelector('.thread-content');
      const panel = <HTMLElement>document.querySelector('message-panel > .container');
      const windowRoot = <HTMLElement>document.querySelector('window-root');
      const vh = windowRoot.offsetHeight;
      if (header && board && panel){
        const contentHeight = content?(content.offsetHeight > 30?120:30):0;
        const height = vh - header.offsetHeight - contentHeight - panel.offsetHeight - 17;
        board.style.height = height + 'px';
        setTimeout(()=>{board.scrollTop = board.scrollHeight;},50);
      }
    },50);
  }

  containProhibited(text){
    let output = false;
    const text2 = text.toLowerCase();
    const prohibited = ['𨳒','𨶙','閪','𨳊','老母','仆街', 'diu','fuck','damn','shit','wtf','asshole', 'suck', 'bitch', 'cock', '柒', '撚','鳩','冚家剷', '屌', '操你媽', '幹你娘', '他媽的', '雞掰'];
    prohibited.forEach(w=>{
      if (text2.indexOf(w) > -1){
        output = true;
      }
    });
    return output;
  }

  public hintButtonClicked(){
	if(this.hintQuota > 0){
		this.hintQuota--;
		this.getHint.emit();
	}
  }

  public formatTime(seconds: number): string {
	const minutes: number = Math.floor(seconds / 60);
	const remainingSeconds: number = seconds % 60;
	return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  public recording(){
	if(!this.showRecordingTime){
		this.studentSpeaking.emit(true);
		this.messageInput.placeholder = "";
		this.tempContent = this.textInput;
		this.textInput = "";
		setTimeout(() => {
			this.showRecordingTime = true;
		}, 50);
		this.timer = setInterval(() => {
			if(this.recordingTime >= 60){
				this.showRecordingTime = false;
			}else{
				this.recordingTime++;
			}
		  }, 1000);
	}
	// console.log("messageInput", this.messageInput);
  }

  public clearTimer(){
	console.log("clear timer");
	if(this.timer){
		clearInterval(this.timer);
	}
	this.recordingTime = 0;
	this.showRecordingTime = false;
  }

}
