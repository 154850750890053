import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitResolver } from './service/init.resolver';
import { JwtResolver } from './service/jwt.resolver';
import { TeacherRoleGuard } from './service/teacherRole.guard';

const routes: Routes = [
    { path: '', redirectTo: '/home-screen/default', pathMatch: 'full' },
    { path: 'displayBoard', loadChildren: () => import('./coreModule/DisplayBoardModule/displayBoard.module').then(m => m.DisplayBoardModule), resolve: { init: InitResolver } },
    { path: 'mediaLibrary', loadChildren: () => import('./coreModule/mediaLibraryModule/mediaLibrary.module').then(m => m.MediaLibraryModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'mediaLibraryNew', loadChildren: () => import('./coreModule/mediaLibraryNewModule/mediaLibraryNew.module').then(m => m.MediaLibraryNewModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'mediaSelect', loadChildren: () => import('./coreModule/mediaSelectModule/mediaSelect.module').then(m => m.MediaSelectModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard] },
    { path: 'bookTemplate', loadChildren: () => import('./coreModule/bookTemplateModule/bookTemplate.module').then(m => m.BookTemplateModule), resolve: { init: InitResolver } },
    { path: 'gameQuestion', loadChildren: () => import('./coreModule/gameQuestionModule/gameQuestion.module').then(m => m.gameQuestionModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: '4choicegameQuestion', loadChildren: () => import('./coreModule/4choicegameQuestionModule/gameQuestion.module').then(m => m.choicesgameQuestionModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'mc2gameQuestion', loadChildren: () => import('./coreModule/mc2gameQuestionModule/gameQuestion.module').then(m => m.mc2gameQuestionModule), resolve: { init: InitResolver } },
    { path: 'newsDisplay', loadChildren: () => import('./coreModule/newsDisplayModule/newsDisplay.module').then(m => m.NewsDisplayModule), resolve: { init: InitResolver } },
    { path: 'newsEdit', loadChildren: () => import('./coreModule/newsEditModule/newsEdit.module').then(m => m.NewsEditModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'chatEdit', loadChildren: () => import('./coreModule/chatEditModule/chatEdit.module').then(m => m.ChatEditModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'redeem', loadChildren: () => import('./coreModule/redeemModule/redeem.module').then(m => m.RedeemModule), resolve: { init: InitResolver } },
    { path: 'redeemNew', loadChildren: () => import('./coreModule/redeemModule-new/redeem.module').then(m => m.RedeemModule), resolve: { init: InitResolver } },
    { path: 'pdfConverter', loadChildren: () => import('./coreModule/pdfConverterModule/pdfConverter.module').then(m => m.PdfConverterModule), resolve: { init: InitResolver } },
    { path: 'report', loadChildren: () => import('./coreModule/reportModule/report.module').then(m => m.ReportModule), resolve: { init: InitResolver, jwt:JwtResolver }, canActivate: [TeacherRoleGuard] },
    { path: 'workspace', loadChildren: () => import('./coreModule/workspaceModule/workspace.module').then(m => m.WorkspaceModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'voiceTool', loadChildren: () => import('./coreModule/voiceToolOldModule/voiceTool.module').then(m => m.VoiceToolModule), resolve: { init: InitResolver } },
    { path: 'voiceToolNew', loadChildren: () => import('./coreModule/voiceToolModule/voiceTool.module').then(m => m.VoiceToolModule), resolve: { init: InitResolver } },
    { path: 'videoChat', loadChildren: () => import('./coreModule/videoChatModule/videoChat.module').then(m => m.VideoChatModule), resolve: { init: InitResolver } },
    { path: 'oralDemo', loadChildren: () => import('./coreModule/oralDemoModule/oralDemo.module').then(m => m.OralDemoModule), resolve: { init: InitResolver } },
    { path: 'readingTool', loadChildren: () => import('./coreModule/readingToolModule/readingTool.module').then(m => m.ReadingToolModule), resolve: { init: InitResolver } },
    { path: 'userAndGroup', loadChildren: () => import('./coreModule/userAndGroupModule/userAndGroup.module').then(m => m.UserAndGroupModule), resolve: { init: InitResolver, jwt:JwtResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'grouping', loadChildren: () => import('./coreModule/groupingModule/grouping.module').then(m => m.GroupingModule), resolve: { init: InitResolver, jwt:JwtResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'appModal', loadChildren: () => import('./coreModule/appModalModule/appModal.module').then(m => m.AppModalModule), resolve: { init: InitResolver } },
    { path: 'login', loadChildren: () => import('./coreModule/loginModule/login.module').then(m => m.LoginModule) },
	{ path: 'oup', loadChildren: () => import('./coreModule/oupModule/oup.module').then(m => m.OupModule) },
	{ path: 'redirect', loadChildren: () => import('./coreModule/redirectModule/redirect.module').then(m => m.RedirectModule) },
	{ path: 'deeplink', loadChildren: () => import('./coreModule/deepLinkModule/deepLink.module').then(m => m.DeepLinkModule) },
	{ path: 'eula', loadChildren: () => import('./coreModule/eulaModule/eula.module').then(m => m.EulaModule), resolve: { init: InitResolver, jwt:JwtResolver } },
    { path: 'home-screen', loadChildren: () => import('./coreModule/HomeScreenModule/home-screen.module').then(m => m.HomeScreenModule), resolve: { init: InitResolver } },
    { path: 'buCourse', loadChildren: () => import('./coreModule/buCourseModule/buCourse.module').then(m => m.BuCourseModule), resolve: { init: InitResolver } },
    { path: 'profile', loadChildren: () => import('./coreModule/profileModule/profile.module').then(m => m.ProfileModule), resolve: { init: InitResolver, jwt:JwtResolver } },
    { path: 'assessmentEdit', loadChildren: () => import('./coreModule/AssessmentEditModule/AssessmentEdit.module').then(m => m.AssessmentEditModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'eBookEditor', loadChildren: () => import('./coreModule/EBookEditor/EBookEditor.module').then(m => m.EBookEditorModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'bookEdit', loadChildren: () => import('./coreModule/BookEditModule/BookEdit.module').then(m => m.BookEditModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'questionBank', loadChildren: () => import('./coreModule/QuestionBankModule/QuestionBank.module').then(m => m.QuestionBankModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
    { path: 'bookViewer', loadChildren: () => import('./coreModule/BookViewerModule/BookViewer.module').then(m => m.BookViewerModule), resolve: { init: InitResolver, jwt:JwtResolver } },
	{ path: 'school-setting', loadChildren: () => import('./coreModule/SchoolSettingModule/SchoolSetting.module').then(m => m.SchoolSettingModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'course', loadChildren: () => import('./coreModule/courseModule/course.module').then(m => m.CourseModule), resolve: { init: InitResolver } },
	{ path: 'enroll', loadChildren: () => import('./coreModule/enrollModule/enroll.module').then(m => m.EnrollModule), resolve: { init: InitResolver } },
	{ path: 'bookmark', loadChildren: () => import('./coreModule/BookMarkModule/bookmark.module').then(m => m.BookmarkModule), resolve: { init: InitResolver } },
    { path: 'analytics', loadChildren: () => import('./coreModule/analyticsModule/analytics.module').then(m => m.AnalyticsModule), resolve: { init: InitResolver, jwt:JwtResolver } },
    { path: 'uiExample', loadChildren: () => import('./coreModule/uiExampleModule/uiExample.module').then(m => m.UiExampleModule), resolve: { init: InitResolver, jwt:JwtResolver } },
    { path: 'calendar', loadChildren: () => import('./coreModule/calendarModule/calendar.module').then(m => m.CalendarModule), resolve: { init: InitResolver, jwt:JwtResolver } },
    { path: 'library', loadChildren: ()=> import('./coreModule/LibraryModule/library.module').then(m => m.LibraryModule),resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard] },
    { path: 'objectives', loadChildren: () => 	import('./coreModule/EvaluationFormModule/EvaluationForm.module').then(m => m.EvaluationFormModule), resolve: { init: InitResolver } },
    { path: 'learningPoints', loadChildren: () => 	import('./coreModule/LearningPointsModule/LearningPoints.module').then(m => m.LearningPointsModule), resolve: { init: InitResolver } },
    { path: 'lpf', loadChildren: () => 	import('./coreModule/LPFModule/LPF.module').then(m => m.LPFModule), resolve: { init: InitResolver } },
    { path: 'bookcomment', loadChildren: () => import('./coreModule/BookCommentModule/bookcomment.module').then(m => m.BookCommentModule), resolve: { init: InitResolver } },
	{ path: 'ranking', loadChildren: () => import('./coreModule/RankingModule/ranking.module').then(m => m.RankingModule), resolve: { init: InitResolver, jwt:JwtResolver } },
	{ path: 'chatGPT', loadChildren: () => import('./coreModule/chatGPTModule/chatGPT.module').then(m => m.ChatGPTModule), resolve: { init: InitResolver, jwt: JwtResolver } },
	{ path: 'bookshelf', loadChildren: () => import('./coreModule/bookshelfModule/bookshelf.module').then(m => m.BookshelfModule), resolve: { init: InitResolver } },
	{ path: 'teachingPlan', loadChildren: () => import('./coreModule/teachingPlanModule/teachingPlan.module').then(m => m.TeachingPlanModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'transcript', loadChildren: () => import('./coreModule/transcriptModule/transcript.module').then(m => m.TranscriptModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'avatar', loadChildren: () => import('./coreModule/AvatarModule/avatar.module').then(m => m.AvatarModule), resolve: { init: InitResolver } },
	{ path: 'lesson', loadChildren: () => import('./coreModule/lessonModule/lesson.module').then(m => m.LessonModule), resolve: { init: InitResolver } },
	{ path: 'webLesson', loadChildren: () => import('./coreModule/lessonModule/WebLessonModule/WebLesson.module').then(m => m.WebLessonModule), resolve: { init: InitResolver } },
    { path: 'test', loadChildren: () => import('./coreModule/TestModule/test.module').then(m => m.TestModule), resolve: { init: InitResolver } },
    { path: 'erp', loadChildren: () => import('./coreModule/ErpModule/Erp.module').then(m => m.ErpModule), resolve: { init: InitResolver } },
	{ path: 'schedule', loadChildren: () => import('./coreModule/ScheduleModule/schedule.module').then(m => m.ScheduleModule), resolve: { init: InitResolver, jwt:JwtResolver } },
	{ path: 'studentLearningProfile', loadChildren: () => import('./coreModule/studentLearningProfileModule/studentLearningProfile.module').then(m => m.studentLearningProfileModule), resolve: { init: InitResolver } },
    { path: 'objectiveManagerForBookROPopup', loadChildren: () => import('./sharedModule/objectiveManagerForBookModule/objectiveManagerForBookROPopup.module').then(m => m.ObjectiveManagerForBookROPopupModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  },
	{ path: 'oupManage', loadChildren: () => import('./coreModule/OUPManageModule/OUPManage.module').then(m => m.OUPManageModule), resolve: { init: InitResolver }, canActivate: [TeacherRoleGuard]  }
    ,//{ path: '**', redirectTo: 'mediaLibrary', pathMatch: 'full' },
	{ path: 'contentManagement', loadChildren: () => import('./coreModule/contentManagementModule/contentManagement.module').then(m => m.ContentManagementModule),  resolve: { init: InitResolver, jwt: JwtResolver }},
	{ path: 'messageManagement', loadChildren: () => import('./coreModule/messageManagementModule/messageMgModule.module').then(m => m.MessageManagementModule),  resolve: { init: InitResolver, jwt: JwtResolver }},

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [ InitResolver, JwtResolver, TeacherRoleGuard ]
})

export class AppRoutingModule { }
