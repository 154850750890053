import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { DOMHelper } from "src/app/common/DOMHelper";
 
export class HammerTransformControl{
	public panAction:String = "page";
	
	private sub:Subscription;
	public panEnable:boolean = true;
	public pinZoomEnable:boolean = false;
	public wheelZoomEnabled:boolean = false;
	public tranformInfo:any;
	public enabled:boolean = true;

	public transformStart:Subject<any>;
	public transform:Subject<any>;
	public transformEnd:Subject<any>;
	public transformCancel:Subject<any>;
	
	public maxScale = 4;
	public minScale = 0.5;

	constructor(private touch:HTMLElement, private targetElement:HTMLElement, public dragBoundary = null, public capture: boolean = false)
	{
		this.transformStart = new Subject();
		this.transform = new Subject();
		this.transformEnd = new Subject();
		this.transformCancel = new Subject();
		// touch.addEventListener("mousedown", this.onMouseDown.bind(this));
		
		touch.addEventListener("pointerdown", this.onMouseDown.bind(this), capture);
		// touch.addEventListener("touchstart", this.onTouchStart.bind(this));
		touch.addEventListener("wheel", this.onWheel.bind(this));
	}
	public swithcTarget(newTarget:HTMLElement):void
	{	
		this.targetElement = newTarget;
	}
	
	public destroy():void
	{
		this.transform.unsubscribe();
		this.transformEnd.unsubscribe();
	}

	setTransform(x:number,y:number, scale:number):void
	{
		this.targetElement.style.transform = `translate3d(${x}px, ${y}px, 0px) scale(${scale})`;
	}
	
	setZoom(scale: number) {
		var originalTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
		scale = Math.max(this.minScale, scale);
		scale = Math.min(this.maxScale, scale);
		originalTransform.scale = scale;
		this.targetElement.style.transform = `translate3d(${originalTransform.x}px, ${originalTransform.y}px, 0px) scale(${originalTransform.scale})`;
	}

	scaleUp(scaleUp: number):number{

		var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
		var originalTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
		originalTransform.scale *= scaleUp;
		originalTransform.scale = Math.max(this.minScale, originalTransform.scale);
		originalTransform.scale = Math.min(this.maxScale, originalTransform.scale);
		this.enforceBounds(originalTransform, globalScale);
		this.targetElement.style.transform = `translate3d(${originalTransform.x}px, ${originalTransform.y}px, 0px) scale(${originalTransform.scale})`;
		return originalTransform.scale;
	}

	protected dontCatchTheFocus(event:any):boolean {
		if((event.target instanceof HTMLInputElement) ||
			(event && event.defaultPrevented))
			return true;

		var _t:HTMLElement = event.target;
		while(_t ) {
			if(_t.hasAttribute("contenteditable") && _t.getAttribute("contenteditable")!="false")
				return true;
			if(_t.classList.contains("ro-component"))
				return false;
			if(_t.classList.contains("ng-resizable-handle") || _t.classList.contains("rotate-handle") )
				return true;

			if(_t===this.touch)
				return false;
			_t = _t.parentElement;
			
		}
		return false;
	}
	private onMouseDown(event:any):void
	{
		if(this.dontCatchTheFocus(event)) return;
		if(event.button != 0) return;
		// this.sendInfo(event.target, "action", "clear");
		if(!this.enabled) return;
		// this.blockEvent(event);
		event.preventDefault();
		if(this.sub) return;
		var point:any = {x:event.x, y:event.y};
		this.startMonitor(event.target, point);
		this.transformStart.next(null);
		// this.sendInfo(event.target, "type", "mouseDown");
		// this.sendInfo(event.target, "start", point);
		// this.sendInfo(event.target, "target", event.target.innerHTML.substr(0, 50));
	}
	
	private onWheel(event:any):void
	{
		if(!this.wheelZoomEnabled) return;
		//this.blockEvent(event);
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();



		if(!this.enabled) return;
		var keys = [event.ctrlKey, event.shiftKey, event.altKey];
		this.transformStart.next(null);
		if(ArrayUtils.equivalent(keys, [true, false, false]))
		{
			// zoom
			var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
			var info:any = DOMHelper.getElementTransform3D(this.targetElement);
			var tmp = 400; 
			info.scale *= event.wheelDelta > 0 ? (event.wheelDelta + tmp) / tmp : tmp / (tmp - event.wheelDelta) ;
			info.scale = Math.max(this.minScale, info.scale);
			info.scale = Math.min(this.maxScale, info.scale);
			this.enforceBounds(info, globalScale);

			this.transform.next(info);
			this.targetElement.style.transform = `translate3d(${info.x}px, ${info.y}px, 0px) scale(${info.scale})`;
			this.transformEnd.next({
				transform:info,
				evt:event
			});

		} else if(ArrayUtils.equivalent(keys, [false, false, false]))
		{
			// vertical scroll
			var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
			var info:any = DOMHelper.getElementTransform3D(this.targetElement);
			info.y += event.wheelDelta;
			this.enforceBounds(info, globalScale);
			this.transform.next(info);
			this.targetElement.style.transform = `translate3d(${info.x}px, ${info.y}px, 0px) scale(${info.scale})`;
			this.transformEnd.next({
				transform:info,
				evt:event
			});
		} else if(ArrayUtils.equivalent(keys, [false, true, false]))
		{
			// horizontal scoll
			var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
			var info:any = DOMHelper.getElementTransform3D(this.targetElement);
			info.x += event.wheelDelta;
			this.enforceBounds(info, globalScale);
			this.transform.next(info);
			this.targetElement.style.transform = `translate3d(${info.x}px, ${info.y}px, 0px) scale(${info.scale})`;
			this.transformEnd.next({
				transform:info,
				evt:event
			});
		}
		// this.sendInfo(event.target, "type", "wheel");
	}
	verticalScrollDistance:number = 100;
	horizontalScrollDistance:number = 100;
	onTouchStart(event:any):void
	{
		this.blockEvent(event);
		if(!this.enabled) return;
		if(this.sub) return;
		var touch:any = event.touches[0];
		var point:any = {x:touch.pageX, y:touch.pageY};
		this.startMonitor(event.target, point);
		this.transformStart.next(null);
		// this.sendInfo(event.target, "type", "start");
		// this.sendInfo(event.target, "start", point);
		// this.sendInfo(event.target, "target", event.target.innerHTML.substr(0, 50));
	}

	private sendInfo(target:any, key:string, value:any)
	{
		var customEvent:CustomEvent = new CustomEvent("info", {bubbles:true, cancelable:true, detail:{key:key, value:value}});
		target.dispatchEvent(customEvent);
	}
	
	public panOnlyForOversize:boolean = false;

	private enforceBounds(position:any, globalScale:number):void
	{
		if(this.dragBoundary)
		{
			let boundaryEle:HTMLElement = document.querySelector(this.dragBoundary);
			if (boundaryEle){
				let boundaryEleRect = boundaryEle.getBoundingClientRect();
				let targetEleRect = this.targetElement.getBoundingClientRect(); // global bound
				var xOverSize:boolean = targetEleRect.width >= boundaryEleRect.width;
				var yOverSize:boolean = targetEleRect.height >= boundaryEleRect.height;
				var currentTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
				let xMax = (boundaryEleRect.right - targetEleRect.right ) / globalScale + currentTransform.x ;
				let xMin = (boundaryEleRect.left - targetEleRect.left ) / globalScale + currentTransform.x ;
				let yMax = (boundaryEleRect.bottom  - targetEleRect.bottom )  / globalScale + currentTransform.y ;
				let yMin = (boundaryEleRect.top  - targetEleRect.top )  / globalScale + currentTransform.y ;
				if(xOverSize)
				{
					xMax = -xMax;xMin = -xMin;
				} else if(this.panOnlyForOversize)
				{
					xMax = xMin = 0;
				}
				
				if(yOverSize)
				{
					yMax = -yMax;yMin = -yMin;
				} else if(this.panOnlyForOversize)
				{
					yMax = yMin = 0;
				}
			
				position.x = Math.max(Math.min(xMax, position.x), xMin);// range from 0 to boundaryWidth
				position.y = Math.max(Math.min(yMax, position.y), yMin);
			}
		}
	}

	public setPanEnable(value:boolean):void {
		this.release();
		this.panEnable = value;
	}

	private startMonitor(target:any, startPoint:any):void
	{
		if(this.sub == null && (this.panEnable || this.pinZoomEnable)) 
		{
			var isTransforming:boolean = false;
			this.sub = new Subscription(()=>{
				if(isTransforming == false)
				{
					setTimeout(()=>{
						this.transformCancel.next(null);
					});
				}
			});
			var originalTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
			
			var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
			// var scaleArray:any [] = DOMHelper.getElementScale2(this.targetElement.parentElement);
			// this.sendInfo(target, "gScale", scaleArray);
			var panStartPosition:any;
			var accMove:any;
			this.sub.add(
					fromEvent(this.touch, "press").subscribe((evt:any)=>{
						this.release();
					}
				)
			);
			this.sub.add(
					fromEvent(this.touch, "tap").subscribe((evt:any)=>{
						this.release();
					}
				)
			);
			if(this.panEnable && this.panAction!="no")
			{
				this.sub.add(
					// fromEvent(window, "panstart").subscribe((evt:any)=>{
					fromEvent(this.touch, "panstart").subscribe((evt:any)=>{
						// this.sendInfo(evt.target, "type", "panstart");
						isTransforming = true;
						// this.onPanStart(evt);
						this.blockEvent(evt);
						var detail:any = this.getEventDetail(evt);
						panStartPosition = {
							x:detail.center.x - detail.deltaX ,
							y:detail.center.y - detail.deltaY
						}
					})
				);
				this.sub.add(
					fromEvent(window, "panmove").subscribe((evt:any)=>{
						if(isTransforming)
						{
							// this.sendInfo(evt.target, "type", "panmove");
							this.blockEvent(evt);
							
							var detail:any = this.getEventDetail(evt);
							var px:number = (detail.center.x - panStartPosition.x) / globalScale + originalTransform.x;
							var py:number = (detail.center.y - panStartPosition.y) / globalScale + originalTransform.y;
							var tmpTransform:any = {
								x:px,
								y:py,
								scale:originalTransform.scale,
								rotate:originalTransform.rotate
							}
							accMove = tmpTransform;
							this.enforceBounds(tmpTransform, globalScale);
							
							if(this.panAction == "page")
								this.transform.next(tmpTransform);
							this.targetElement.style.transform = `translate3d(${tmpTransform.x}px, ${tmpTransform.y}px, 0px) scale(${tmpTransform.scale}) rotate(${tmpTransform.rotate})`;
							// this.sendInfo(evt.target, "move", tmpTransform);
						}
					})
				);
				this.sub.add(
					fromEvent(window, "panend").subscribe((evt:any)=>{
						if(isTransforming)
						{
							// this.sendInfo(event.target, "type", "panend");
							this.transformEnd.next({
								transform:DOMHelper.getElementTransform3D(this.targetElement),
								evt:evt,
								accMove:accMove
							});
						}
						this.release();
					})
				);
				this.sub.add(
					fromEvent(window, "pancancel").subscribe((evt:any)=>{
						this.release();
					})
				);
			} else {
				this.sub.add(
					fromEvent(window, "panend").subscribe((evt:any)=>{
						this.release();
					})
				);
				this.sub.add(
					fromEvent(window, "pancancel").subscribe((evt:any)=>{
						this.release();
					})
				);
			}
			var pinchStartInfo:any;
			if(this.pinZoomEnable)
			{
				this.sub.add(fromEvent(this.touch, "pinchstart").subscribe((evt:any)=>{
					// this.sendInfo(evt.target, "type", "pinchstart");
					isTransforming = true;
					pinchStartInfo = {
						x:evt.gesture.center.x,
						y:evt.gesture.center.y
					}
					this.blockEvent(evt);
				}));
				this.sub.add(fromEvent(window, "pinchmove").subscribe((evt:any)=>{
					if(isTransforming)
					{
						// this.sendInfo(evt.target, "type", "pinchmove");
						var detail:any = this.getEventDetail(evt);
						// console.log("scale", detail.scale , "dx",  detail.deltaX, "dy", detail.deltaY);
						var px:number = originalTransform.x + detail.deltaX;
						var py:number = originalTransform.y + detail.deltaY;
						var tmpScale:number = originalTransform.scale * detail.scale;
						var tmpTransform:any = {
							x:px,y:py,scale:tmpScale
						};
						this.enforceBounds(tmpTransform, globalScale);
						this.transform.next(tmpTransform);
						this.targetElement.style.transform = `translate3d(${tmpTransform.x}px, ${tmpTransform.y}px, 0px) scale(${tmpTransform.scale})`;
					}
				}));
				this.sub.add(fromEvent(window, "pinchend").subscribe((evt:any)=>{
					if(isTransforming)
					{
						// this.sendInfo(evt.target, "type", "pinchend");
						this.transformEnd.next({
							transform:DOMHelper.getElementTransform3D(this.targetElement),
							evt:evt
						});
					}
					this.release();
				}));

				this.sub.add(fromEvent(window, "pinchcancel").subscribe((evt:any)=>{
					this.release();
				}));
			} else {
				this.sub.add(fromEvent(window, "pinchend").subscribe((evt:any)=>{
					this.release();
				}));

				this.sub.add(fromEvent(window, "pinchcancel").subscribe((evt:any)=>{
					this.release();
				}));
			}
		}
	}

	public release():void
	{
		if(this.sub)
		{
			this.sub.unsubscribe();
			this.sub = null;
		}
	}

	
	public onPanStart(evt:any):void{
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		this.tranformInfo = null;
		if(!this.enabled) return;
		this.tranformInfo = {
			transform:this.getElementTransformInfo(this.targetElement),
			scale:DOMHelper.getElementScale(this.targetElement.parentElement),
			start:{
				x:detail.center.x - detail.deltaX,
				y:detail.center.y - detail.deltaY
			}
		};
	}
	// @HostListener('window:panmove', ['$event'])
	public onPanMove(evt:any):void
	{
		if(!this.tranformInfo) return;
		if(!this.enabled) return;
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt;
		// var detail:any = evt.detail;
		this.updateTransform(detail.center.x, detail.center.y, 1);
	}

	// @HostListener('window:panend', ['$event'])
	public onPanEnd(evt:any):void
	{
		if(!this.tranformInfo) return;
		if(!this.enabled) return;
		this.blockEvent(evt);
		// var detail:any = evt;
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt.detail;
		var info:any = this.updateTransform(detail.center.x, detail.center.y, this.tranformInfo.scale);
		info.width = this.targetElement.clientWidth;
		info.height = this.targetElement.clientHeight;
		// this.hammerTranformEnd.emit(info);
		this.tranformInfo = null;
	}
	private updateTransform(globalX:number, globalY:number, scale:number):any
	{
		var px:number = (globalX - this.tranformInfo.start.x) / this.tranformInfo.scale + this.tranformInfo.transform.x;
		var py:number = (globalY - this.tranformInfo.start.y) / this.tranformInfo.scale + this.tranformInfo.transform.y;
		this.targetElement.style.transform = `translate3d(${px}px, ${py}px, 0px)`;
		return {
			x:px,
			y:py
		};
	}
	private blockEvent(evt:any):void
	{
		// evt.stopImmediatePropagation();
		// evt.stopPropagation();
		// evt.preventDefault();
	}
	private getEventDetail(evt:any):any
	{
		return evt.gesture;
	}
	
	private getElementTransformInfo(el:HTMLElement)
	{
		return DOMHelper.getElementTransform3D(el);
		
	}
	/*
	
	private startMonitor(startPoint:any):void
	{
		if(this.sub == null && (this.panEnable || this.pinZoomEnable)) 
		{
			this.sub = new Subscription(()=>{});
			var originalTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
			var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
			var panStartPosition:any;
			if(this.panEnable)
			{
				this.sub.add(fromEvent(window, "panstart").subscribe((evt:any)=>{
					console.log(evt);
					var detail:any = evt.gesture;
					panStartPosition = {
						x:detail.center.x - detail.deltaX ,
						y:detail.center.y - detail.deltaY
					}
				}))
				this.sub.add(fromEvent(window, "panmove").subscribe((evt:any)=>{
					console.log(evt);
					var detail:any = evt.gesture;
					var px:number = (detail.center.x - panStartPosition.x) / originalTransform.scale + originalTransform.x;
					var py:number = (detail.center.y - panStartPosition.y) / originalTransform.scale + originalTransform.y;
					this.targetElement.style.transform = `translate3d(${px}px, ${py}px, 0px)`;
					
				}))
				this.sub.add(fromEvent(window, "panend").subscribe((evt:any)=>{
					console.log(evt);
					this.cancel();
				}));
				this.sub.add(fromEvent(window, "pancancel").subscribe((evt:any)=>{
					console.log(evt);
					// this.cancel();
				}));
			}
			if(this.pinZoomEnable)
			{
				this.sub.add(fromEvent(window, "pinchstart").subscribe((evt:any)=>{
					console.log(evt);
				}));
				this.sub.add(fromEvent(window, "pinchmove").subscribe((evt:any)=>{
					console.log(evt);
				}));
				this.sub.add(fromEvent(window, "pinchend").subscribe((evt:any)=>{
					console.log(evt);
					this.cancel();
				}));
			}
			
		}
	}
	private cancel():void
	{
		if(this.sub)
		{
			this.sub.unsubscribe();
			this.sub = null;
		}
	}
	*/
}