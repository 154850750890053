import { Injectable } from '@angular/core';
import { DataService } from './data.service';


@Injectable({ providedIn: 'root' })
export class PermissionService {

	constructor(public datas: DataService
		){
			if (this.datas.userInfo){
				const userId = this.datas.userInfo.uid;
				this.getPermissions(userId);
			}
	}

	public role_id;
	public role_premission_list = [];
	public loaded = false;


	public getPermissions(userId):Promise<any[]>{
		return this.datas
		    .post2({
				data:{api:"ROSchoolSettingRole.get_all_user_role"},
				loading:true,
			})
			.then((res)=>{
				// console.log("res",res);
				const mapping_list = res.roles.mapping_list;
                const operation_list = res.roles.operations
				const operation_id_array = [];

				res.user_role.forEach((row)=>{
					if(row.uid == userId){
                        this.role_id = row.role_id
						return;
					} 

					
					
					
				})

				mapping_list.forEach((row)=>{
					if (row.role_id == this.role_id){
                        operation_id_array.push(row.operation_id)
					}
				})

				operation_id_array.forEach((id)=>{
					operation_list.forEach((row)=>{
						if(row.id == id){
							this.role_premission_list.push(row.name);
						}
					})
				})

                console.log("premission", this.role_premission_list)
				if (this.role_premission_list.length == 0){
                    console.log("role no premission")
				}
				this.loaded = true;
				return this.role_premission_list;
			})

	}

	can(permission, default_value = true) {
		if (!this.role_id) {
			return default_value;
		}

		return this.role_premission_list.indexOf(permission) !== -1;
	}
}
