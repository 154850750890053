import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PopupToggleComponent } from './popupToggle.component';

@NgModule({
    imports: [
        CommonModule,TranslateModule
    ],
    declarations: [
        PopupToggleComponent
    ],
    exports: [
        PopupToggleComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class PopupToggleModule {
}

export { PopupToggleComponent };
