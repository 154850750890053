import { Component, Input } from "@angular/core";
import { ThemeService } from "src/app/service/theme.service";

@Component({
	selector: 'progress-bar',
	template: `
	<div style="display: inline-block;background-color:var(--progress-bar-bgcolor,#C3C3C3);" 
		[style.width.px]="maxWidth"
		[style.height.px]="height"
	>
		<div 
			style="background-color:var(--section-maincolor, #724AB5);height:100%"
			[style.width.px]="boxWidth"
			></div>
	</div>
	`
})

export class ProgressBarComponent{
	@Input() total: number = 100;
	@Input() value: any;
	@Input() bgColor:any;
	@Input() barColor:any;
	@Input() maxWidth: number = 100;
	@Input() height: number = 12;
	public boxWidth:number
	constructor(public theme: ThemeService){}

	ngOnChanges(changes: any): void {
		this.boxWidth = Math.min(this.maxWidth, this.value / this.total * (this.maxWidth) );

		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		
	}

	ngOnInit(): void {
		this.boxWidth = Math.min(this.maxWidth, this.value / this.total * (this.maxWidth) );
		this.theme.applyStyleObj(
			{
				default: {
					"progress-bar-bgcolor": "#C3C3C3",
				},
				dark: {
					"progress-bar-bgcolor": "#2D2C44",
				},
			},
			document.body
		);
	}
}
