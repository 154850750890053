//---------------------------------------------------------------------------------------------
export class Common {
    static tagColor:any[] = [
        { key:'red', code:'#E27E7E' , cTitle:'紅', eTitle:'Red' },
        { key:'orange', code:'#FFC279' , cTitle:'橙', eTitle:'Orange' },
        { key:'yellow', code:'#FFE883' , cTitle:'黃', eTitle:'Yellow' },
        { key:'green', code:'#A5CD62' , cTitle:'綠', eTitle:'Green' },
        { key:'blue', code:'#65B4D0' , cTitle:'藍', eTitle:'Blue' },
        { key:'purple', code:'#9187D9' , cTitle:'紫', eTitle:'Purple' },
        { key:'grey', code:'#D4D4D4' , cTitle:'灰', eTitle:'Grey' },
        { key:'magenta', code:'#E790D3' , cTitle:'桃紅', eTitle:'Magenta' },
        { key:'brown', code:'#C9A570' , cTitle:'啡', eTitle:'Brown' },
        { key:'peach', code:'#F3B38C' , cTitle:'杏', eTitle:'Peach' },
        { key:'beige', code:'#84CDAE' , cTitle:'碧綠', eTitle:'Beige' },
        { key:'aqua', code:'#7A9CD7' , cTitle:'紫藍', eTitle:'Aqua' },
        { key:'navy', code:'#ABB494' , cTitle:'灰綠', eTitle:'Navy' },
        { key:'olive', code:'#A2D28E' , cTitle:'青綠', eTitle:'Olive' },
        { key:'lavender', code:'#B38CCB' , cTitle:'灰紫', eTitle:'Lavender' },
        { key:'paleRed', code:'#F1BFBF' , cTitle:'粉紅', eTitle:'PaleRed' },
        { key:'paleOrange', code:'#FFE1BC' , cTitle:'粉橙', eTitle:'PaleOrange' },
        { key:'paleYellow', code:'#FFF4C1' , cTitle:'粉黃', eTitle:'PaleYellow' },
        { key:'paleGreen', code:'#D2E6B1' , cTitle:'粉綠', eTitle:'PaleGreen' },
        { key:'paleBlue', code:'#B2DAE8' , cTitle:'粉藍', eTitle:'PaleBlue' },
        { key:'palePurple', code:'#C8C3EC' , cTitle:'粉紫', eTitle:'PalePurple' },
        { key:'paleGrey', code:'#EAEAEA' , cTitle:'粉灰', eTitle:'PaleGrey' },
        { key:'paleMagenta', code:'#F3C8E9' , cTitle:'粉桃紅', eTitle:'PaleMagenta' },
        { key:'paleBrown', code:'#E4D2B8' , cTitle:'粉啡', eTitle:'PaleBrown' },
        { key:'palePeach', code:'#F9D9C6' , cTitle:'粉杏', eTitle:'PalePeach' },
        { key:'paleBeige', code:'#C2E6D7' , cTitle:'粉碧綠', eTitle:'PaleBeige' },
        { key:'paleAqua', code:'#BDCEEB' , cTitle:'粉紫藍', eTitle:'PaleAqua' },
        { key:'paleNavy', code:'#D5DACA' , cTitle:'粉灰綠', eTitle:'PaleNavy' },
        { key:'paleOlive', code:'#D1E9C7' , cTitle:'粉青綠', eTitle:'PaleOlive' },
        { key:'paleLavender', code:'#D9C6E5' , cTitle:'粉灰紫', eTitle:'PaleLavender' },
    ];
}