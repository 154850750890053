import { Component, HostBinding, Input, Output, EventEmitter, HostListener, TemplateRef, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { RoService } from 'src/app/service/ro.service';
import { ThemeService } from 'src/app/service/theme.service';
import { DataService } from 'src/app/service/data.service';

////////////////////////////////////////
@Component({
    selector: 'alert2',
    template: `
<div
    class="container" [ngStyle]="containerStyle"
    [style.maxWidth]="(templateContent ? '100%' : containerMaxWidth)"
>
    <div class="alert-header" *ngIf="options.header" [ngStyle]="options.header.style">
		<span *ngIf="options.header.text">{{ options.header.text }}</span>
	</div>
	<span *ngIf="message" [ngStyle]="msgStyle" class="message">{{message}}</span>
    <ng-container *ngTemplateOutlet="templateContent"></ng-container>

    <div *ngFor="let row of btns" class="btnArea" [ngStyle]="btnAreaStyle">
        <div
            *ngFor="let btn of row; let first=first; let last=last;"
            class="btn"
            [class.single]="first && last"
            [ngStyle]="btn.ngStyle"
            (click)="btnClick.emit(btn.key); close();"
        >
            {{btn.label || (btn.labelKey | translate)}}
        </div>
    </div>
    
</div>
    `,
    styleUrls: ['./alert2.component.scss']
})

export class Alert2Component {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @HostBinding('style.backgroundColor') public hostBackgroundColor:string;
    @HostBinding('style.zIndex') public hostZIndex = 0;
	@Input() options:any = {};
    
    public defBtns:any = {
      ok: { key:'ok', labelKey:"alert.ok", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"} },
      yes: { key:'yes', labelKey:"alert.yes", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"} },
      no: { key:'no', labelKey:"alert.no", ngStyle:{backgroundColor:"var(--alert-cancelbuttoncolor)"} },
      edit: { key:'edit', labelKey:"alert.edit", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"} },
      later: { key:'later', labelKey:"alert.later", ngStyle:{backgroundColor:"var(--alert-cancelbuttoncolor)"} },
      cancel: { key:'cancel', labelKey:"alert.cancel", ngStyle:{backgroundColor:"var(--alert-cancelbuttoncolor)"} },
      delete: { key:'delete', labelKey:"alert.delete", ngStyle:{backgroundColor:"var(--alert-deletebuttontcolor)"} },
      archive: { key:'archive', labelKey:"alert.archive", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"} },
	  leave: { key:'leave', labelKey:"alert.leave", ngStyle:{backgroundColor:"var(--section-maincolor)"} },
      "save&leave": { key:'save&leave', labelKey:"alert.save&leave", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"} },
      "discard&leave": { key:'discard&leave', labelKey:"alert.discard&leave", ngStyle:{backgroundColor:"var(--alert-cancelbuttoncolor)"} }
    }
    
    @Input() public message:string = "";
	@Input() public msgStyle:any = {};
	@Input() public containerStyle:any = {};
	@Input() public btnAreaStyle: any = {};
    @Input() public btns:any = [
      [ this.defBtns.ok, this.defBtns.cancel ]
    ];
    
    public templateContent:TemplateRef<any> = null;
    public _showShadow:boolean = true;
	@Input() public containerMaxWidth = '300px';
    @Output() btnClick:EventEmitter<any> = new EventEmitter();

    private showingShadow:boolean = false;

    private isReady:boolean = false;
    constructor(
      private coms:CommonService,
      private dcs:DynamicComponentService,
      public translate:TranslateService,
      private ros:RoService,
      public themeService:ThemeService,
      public eleRef:ElementRef,
	  public datas: DataService
    ) {
      this.themeService.getThemeJson("alert2Module.json").then((styleObj:any)=>{
		this.themeService.reloadSetting();
        this.themeService.applyStyleObj(styleObj, this.eleRef.nativeElement);
        this.isReady = true;
      });
    }

    public open(appendEle:HTMLElement=document.body, retry:number=50):void {
      if (this.isReady) {
        this.hostBackgroundColor = this._showShadow ? 'rgba(0, 0, 0, 0.5)' : 'none';
        this.hostZIndex = this.coms.getZIndex();
        this.setDefBtns();
        this.dcs.open(this, appendEle);
        if (this._showShadow) {
            if (localStorage.getItem('in-web') == '0' || !this.datas.isInWeb) this.ros.request("showShadow", null, false);
            this.showingShadow = true;
        }
      } else if (retry>0) {
        setTimeout(()=>this.open(appendEle, retry-1), 100);
      }
    }

    private setDefBtns():void {
      for (let row of this.btns) {
        for (let i=0; i<row.length; i++) {
          let btn = row[i];
          if ((typeof btn == 'string') && this.defBtns[btn]) {
            row[i] = this.defBtns[btn];
          }
        }
      }
    }

    public close():void {
        this.dcs.close(this);
        if (this.showingShadow && (localStorage.getItem('in-web') == '0' || this.datas.isInWeb) ) this.ros.request("hideShadow", null, false);
    }

}
