import { Injectable } from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import { categories } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {UserManager} from "@oup/oidc-client";
// @ts-ignore
import {MiddleLayerClient} from '@oup/oupc-middle-layer-client';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';
import {Md5} from "ts-md5";
import { AlertService } from './alert.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OupService {

	public userManager = null;
	/**
	 * @type {MiddleLayerClient}
	 */
	public mlClient = null;
	//將isOupUser改為BehaviorSubject，可照舊用oups.isOupUser，亦可subscript isOupUser$
	public isOupUser$:BehaviorSubject<boolean> = new BehaviorSubject(false);
	public get isOupUser() { return this.isOupUser$.getValue() };
	public set isOupUser(value) { (this.isOupUser$.getValue()!=value) && this.isOupUser$.next(value) };

	public oup_school: any = {};
	public teachers: any = [];
	public classes: any = [];
	public students: any = [];

	public teacher_map = {};
	public class_map = {};
	public student_map = {};
	/**
	 * @description uid => oup_user_id
	 * @type {Object}
	 */
	public user_id_map = {};

	private _is_fetching_all_data: boolean = false;
	private _isRunningOupTokenLogin:boolean = false;
	private _isRunningLoginPostProcess:boolean = false;
	public entitlement_result:any = [];

	constructor(
		private coms: CommonService,
		private datas: DataService,
		private router: Router,
		private aRoute: ActivatedRoute,
		private als:AlertService
	) {
		this.init();
		
		this.checkIsOupUser();

		this.datas.jwt$.subscribe(()=>{
			this.checkIsOupUser();
		});

		this.datas.userInfo$.subscribe(()=>{
			if (this.isOupUser) this.loginPostProcess();
		});

		this.router.events.subscribe((val) => {
			// console.log('router events', val);
			let can_fetch_data = val instanceof NavigationEnd && this.isOupUser;
			// console.log('can fetch data ', can_fetch_data);
			if (can_fetch_data) {
				// console.log('fetch_all_data from router events');
				this.fetch_all_data();
			}
		});
	}

	get isOupSchool() {
		return !!(this.isOupUser || ["272", "47559"].includes(`${this.datas.userInfo.school_id}`));
	}
	
    private createUserManager(endpoint: string, clientID: string):UserManager|null {
        if (!endpoint || !clientID) return null;

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);

        return new UserManager({
            authority: `${endpoint}/auth/v1`,
            client_id: clientID,
            redirect_uri: `${url.origin}/redirect`,
            popup_redirect_uri: `${url.origin}/redirect`,
            post_logout_redirect_uri: `${url.origin}/login`,
            response_type: 'code',
            scope: 'openid',
            // Set automaticSilentRenew to true
            // The client library would renew access token with refresh token when the access token is about to expire
            //
            // When user is idle for one minute, the renewal would be paused
            // It would resume once the user is active again
            automaticSilentRenew: true,
            // Uncomment next line to customize the idle time of pausing token renewal, 60 * 1000 = 60s
            // pauseSilentRenewIdleTimeout: 60 * 1000
        });
    }

	public init():void {
		
		let endpoint:string = "https://dev.account.oupchina.com.hk/";
		let clientID:string = "dev/activityplatform";
		let apiKey:string = "dev/activityplatform.e0kzc5aKPBCRnKWXqjIhrGmeJivjwnVB";

		this.userManager = this.createUserManager(endpoint, clientID);
		this.mlClient = this.getMLClient(endpoint, this.userManager, apiKey);
	}

    public getMLClient(endpoint: string, userManager: null | UserManager, apiKey: string):MiddleLayerClient{
        if (endpoint === '' || endpoint == null || userManager == null) {
            return null;
        }
    
        const mlClient = new MiddleLayerClient({
            dataEndpoint: `${endpoint}/data/v1`,
            authEndpoint: `${endpoint}/auth/v1`,
            apiKey: apiKey,
            language: 'zh-hant'
        });
        mlClient.linkOIDCUserManager(userManager);
        return mlClient;
    }

	public checkIsOupUser():Promise<boolean> {
		return new Promise((resolve, reject)=>{
			this.coms.waitFor(()=>this.datas && this.datas.userInfo && this.datas.userInfo.school).then(()=>{
				if (this.datas.userInfo.school.plan_type=='oup' && !this.isOupUser) {
					this.isOupUser = true;
					// this.signInRedirectCallback();

					this.userManager.getUser().then((user:any)=>{
						// console.log(user);
						// console.log(this.mlClient);
						if (user && !user.expired) {
							//如果token未expire，可繼續用
							this.mlClient.accessToken = user.access_token;

							this.oupTokenLogin(
								user.profile["https://oupchina.com.hk/school_id"],
								user.profile.userID,
								user.profile["https://oupchina.com.hk/user_type"],
								user.access_token
							);
						} else {
							this.userManager.signinRedirect({
								prompt: 'none',
								state: window.location.toString()
							});
						}
			
			
					});

				} else if (this.datas.userInfo.school.plan_type!='oup' && this.isOupUser) {
					this.isOupUser = false;
					this.oupLogout();
				}
			}).catch(()=>{
				this.isOupUser = false;
				// this.oupLogout();
			}).finally(()=>{
				resolve(this.isOupUser);
			});
		});
	}

	public signInRedirectCallback():void {
		this.userManager
		.signinRedirectCallback()
		.then((user: any) => {
			// console.log(user);
			// {
			// 	"id_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ii0yT0lnYjNLdENJWTJqMnZOdTQtUFJtSXZUNnRIVkphaDBZa25NZzhHY0EifQ.eyJjbGllbnRJRCI6ImRldi9hY3Rpdml0eXBsYXRmb3JtIiwibm9uY2UiOiJhOGVhNWJkYTNhMzM0NTAxYTI4NDQ1ODk5NmUwZDViYSIsImlhdCI6MTcyNjgyODMyOCwiZXhwIjoxNzI2ODMxOTI4LCJhdWQiOiJkZXYvYWN0aXZpdHlwbGF0Zm9ybSIsImlzcyI6Imh0dHBzOi8vZGV2LmFjY291bnQub3VwY2hpbmEuY29tLmhrIiwic3ViIjoiZDljYTg4ODgtNWNhMi00OWM4LTg1YWMtNTFlYmZkZTFlNTVjIn0.YLpeyZyu8pPPX8FRjyUK8Z1wQtCKx-9wZssKgIFdjMviJYgYucGvyDCKkXhv8XUrJQEci3Kd0sb3wyay-MrW6Sxj2AL1aJNV2lrwahivo6r-wu4hNqEqhwuWMzDA4oVi2dCnZVqphDx_9Y56s9kLpWlJ3PjQX9LT-zvdoBgLuqYu36E6YQnWJmLOsJhARxUsNfr-qcGH-WqDiDmfqyHFVei-cIKoHdsnIxG8LiBZuMVu3nfYVZrSco-jbVI6oD43ricjecV0o9n9hHv87LHj0zCKhI8GEwlXgEqvETuLCG-1JOBGd2pwqR4PUwUHrABHlx_LBO4HO5UGrE1tcqNcXw",
			// 	"access_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ii0yT0lnYjNLdENJWTJqMnZOdTQtUFJtSXZUNnRIVkphaDBZa25NZzhHY0EifQ.eyJzY29wZSI6Im9wZW5pZCIsImdyYW50X3R5cGUiOiJhdXRob3JpemF0aW9uX2NvZGUiLCJpYXQiOjE3MjY4MjgzMjgsImV4cCI6MTcyNjgyODQxOCwiYXVkIjoiZGV2L2FjdGl2aXR5cGxhdGZvcm0iLCJpc3MiOiJodHRwczovL2Rldi5hY2NvdW50Lm91cGNoaW5hLmNvbS5oayIsInN1YiI6ImQ5Y2E4ODg4LTVjYTItNDljOC04NWFjLTUxZWJmZGUxZTU1YyJ9.b-uj85wFDLpIochEsDuIHMoKnJLYDTG4a-Mx50T3X5Y9rEgKrBlFcSNiyuTaTvGAYXkp4_GXp0zkKUgGsGWJn8f8gwd4wZPGfHKoNIeWY_VWwy6fzsDFUoQwhI2s0d3y2jRU7o2srB1QK4YXG6YYWSryuLN3xCMolm6Q2-ma8Nh9b58AE0XDlqm0bDbfEEaRDTfB-ZCOyQROUiCYzq7y82XbqKX6lyX-4-nUvek1IFFF5Kvz1SNanbdwO2l3i-3yHF-q18bHPUXuap72N5N0CIzeIp-DVK-uf41L1Q10WAUP8OQp1N--frn_inpFYloed9dXNl1ciqRM7tI1CQXcLw",
			// 	"refresh_token": "ZbACmByhZZ9tdZTfkn1P45lLwq4k0oRB.UpzB9dj7fFbGrsPvKn6zZIeU7VPzPjfL",
			// 	"token_type": "bearer",
			// 	"scope": "openid",
			// 	"profile": {
			// 		"clientID": "dev/activityplatform",
			// 		"sub": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 		"userID": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 		"https://oupchina.com.hk/identity_provider": "esas",
			// 		"https://oupchina.com.hk/identity_provider_user_data": {
			// 			"usertoken": 20033912,
			// 			"loginID": "act_test_t1"
			// 		},
			// 		"https://oupchina.com.hk/school_id": "5ec9a3c2-32d4-4adf-8176-41d145c2a770",
			// 		"https://oupchina.com.hk/school#en": "(demo) Activity School",
			// 		"https://oupchina.com.hk/school#zh-Hant": "(demo) Activity School",
			// 		"https://oupchina.com.hk/user_type": "teacher",
			// 		"https://oupchina.com.hk/name#zh-Hant": "act_test_t1",
			// 		"https://oupchina.com.hk/name#en": "act_test_t1",
			// 		"https://oupchina.com.hk/academic_year": "2024-2025",
			// 		"https://oupchina.com.hk/teacher_position": "Teacher",
			// 		"https://oupchina.com.hk/keep_me_signed_in": false,
			// 		"https://oupchina.com.hk/initial_login_client_id": "dev/activityplatform"
			// 	},
			// 	"expires_at": 1726828417
			// }
			if (user.state != null) {
				// if user.state is not null
				// it carries the state set when signin start
				//
				// if the signin is triggered by userManager.signinRedirectWithESASToken
				// the user.state would be the url object of the location right before signin
			}

			this.isOupUser = true;

			this.oupTokenLogin(
				user.profile["https://oupchina.com.hk/school_id"],
				user.profile.userID,
				user.profile["https://oupchina.com.hk/user_type"],
				user.access_token
			);
			
		})
		.catch((error: any) => console.log('login error', error));
	}

	public signinRedirectWithESASToken():void {
		this.userManager
		.signinRedirectWithESASToken()
		.then((user: any) => {
			if (user.state != null) {
				// if user.state is not null
				// it carries the state set when signin start
				//
				// if the signin is triggered by userManager.signinRedirectWithESASToken
				// the user.state would be the url object of the location right before signin
			}

			this.isOupUser = true;

			this.oupTokenLogin(
				user.profile["https://oupchina.com.hk/school_id"],
				user.profile.userID,
				user.profile["https://oupchina.com.hk/user_type"],
				user.access_token
			);
			
		})
		.catch((error: any) => console.log('login error', error));
	}

	public oupTokenLogin(schoolId:string, userId:string, userType:string, token:string):void {
		if (this._isRunningOupTokenLogin) return;
		this._isRunningOupTokenLogin = true;

		let apiData:any = [
			"oup",
			{
				"school_id": schoolId,
				"user_id": userId,
				"user_type": userType,
				"token": token
			}

		];
		this.datas.post2({ data: { api: 'Account.login_by', json: apiData } }).then((res:any)=>{
			if (res.account) {
				this.datas.userInfo = res.account;
				if (typeof this.datas.userInfo.personalSettings == 'string'){
					this.datas.userInfo.personalSettings = JSON.parse(this.datas.userInfo.personalSettings);
				}
				this.datas.jwt = res.account.jwt;
				let homeLayout: any = res.webHomeLayout ? res.webHomeLayout : res.homeLayout;
				if (localStorage) {
					localStorage.setItem('common_jwt', this.datas.jwt);
					localStorage.setItem('common_uid', res.account.uid);
					localStorage.setItem('common_schoolId', res.account.school_id);
					// if (this.schoolModal) {
					// 	let found = this.schoolModal.items.find(item => item.uid == uid);
					// 	if (found && found.url) {
					// 		localStorage.setItem('common_photo', found.url);
					// 	}
					// }
					let found = res.settings.find(s => s.key == 'HOME_PAGE_THEME_COLOR');
					if (found) {
						let obj = JSON.parse(homeLayout);
						obj.homePageThemeColor = found.value.replace(/[\\\"]/g, '');
						homeLayout = JSON.stringify(obj);
					}
					let homeScreenData = { account: res.account, homeLayout: homeLayout, homeSettings: res.homeSettings };
					localStorage.setItem('homeScreen_data', JSON.stringify(homeScreenData));
				}
				try { this.datas.jwtObj = JSON.parse(this.datas.jwt); } catch (e) { }
				// this.storage.insertLoginRecord(res.account.uid);
				// let switchAccount = this.datas.post('RODevice.switch_account', [this.data.jwtDeviceToken, this.datas.jwt]);
				// zip(this.tg.loadSchoolYear(), switchAccount).subscribe((res: any) => {
				// 	try {
				// 		homeLayout = JSON.parse(homeLayout);
				// 	} catch (e) { }
				// 	// if (this.eruda.commands) {
				// 	// 	this.tg.resetDataifSchoolChange(); //reset school year
				// 	// 	let path = this.eruda.commands.path;
				// 	// 	this.eruda.commands = null;
				// 	// 	if (path.indexOf('login') == -1) {
				// 	// 		this.router.navigate([path]);
				// 	// 		return;
				// 	// 	}
				// 	// }
				// 	let homeScreen = 'icon';
				// 	if (homeLayout.options) {
				// 		if (homeLayout.options.main_page_mode) {
				// 			homeScreen = homeLayout.options.main_page_mode;
				// 		}
				// 	}
				// 	this.storage.redirectMain(homeScreen);
				// });
				// } else if (res.code == 10) {
				// 	this.lds.remove('load');
				// 	this.alertModal = {
				// 		type: 'confirm', msg: 'confirm-force-login', confirm: () => {
				// 			this.login(1);
				// 		}
				// 	};
				// } else if (res.code == 16) {
				// 	this.lds.remove('load');
				// 	this.als.alert('login.login-msg-16', null, { btns: [['ok']] });
				// } else if (res.code == 11) {
				// 	this.lds.remove('load');
				// 	this.als.alert('login.msg-account-not-exist', null, { btns: [['ok']] });
				// } else {
				// 	this.lds.remove('load');
				// 	this.als.alert('Error code:' + res.code, null, { btns: [['ok']] });
				// }
				// });
				if (res.message=="LoginSuccess") {
					// console.log('redirect to home-screen', this.router.url);
					if (this.router.url.startsWith("/redirect")) {
						this.router.navigate(['../../home-screen'], {relativeTo: this.aRoute});
						this.loginPostProcess();
					}
				}
			}
			this._isRunningOupTokenLogin = false;
		});
	}

	public oupLogout():void {
		// Clear local storage
        // localStorage.clear();

        // // Clear cookies
        // const cookies = document.cookie.split(';');
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i];
        //     const eqPos = cookie.indexOf('=');
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        // }

        // // Clear session storage
        // sessionStorage.clear();

		// window.location.href = "login";
        // navigate('/')
        // Refresh the page
        // window.location.reload();

		this.userManager.signoutRedirect();
	}

	private logout():void {
		this.datas.post('Account.logout').subscribe((res: any) => {
			setTimeout(()=>{ //avoid error 
				this.datas.userInfo = null;
				this.datas.jwt = '';
				this.datas.jwtObj = null;
				if (localStorage) {
					localStorage.removeItem('common_jwt');
					localStorage.removeItem('common_uid');
					localStorage.removeItem('common_schoolId');
					localStorage.removeItem('common_schoolLogo');
					localStorage.removeItem('common_photo');
					sessionStorage.removeItem('common_photo_done');
					sessionStorage.removeItem('common_schoolLogo_done');
					localStorage.removeItem('homeScreen_data');
				}
				this.oupLogout();
			},10);
		});
	}

	private loginPostProcess():void {
		if (this._isRunningLoginPostProcess) return;
		this._isRunningLoginPostProcess = true;

		this.updateUserInfo().then((oupUser:any)=>{

			//取得User的Entitlements
			//https://dev.account.oupchina.com.hk//data/v1/users/d9ca8888-5ca2-49c8-85ac-51ebfde1e55c/entitlements?categories%5B%5D=D6&year=2024-2025
			this.mlClient.fetchEntitlementsByUserID(oupUser.userID, {
				year: oupUser["https://oupchina.com.hk/academic_year"],
				categories: ["D6"]
			}).then((result:any) => {
				// Roy/OUP: 如果沒有entitlement即代表無權使用，要馬上登出
				if (!result || !result[0]) {
					let msg:string =
					this.datas.lang == "sc" ? "你的帐户没有此产品的使用权限。如有查询,请联繫我们的客户服务。" :
					this.datas.lang == "en" ? "You don't have rights to access this product. For enquiry, please contact our customer services." :
					"你的帳戶沒有此產品的使用權限。如有查詢,請聯繫我們的客戶服務。";

					let spanNodeList:NodeList = document.querySelectorAll('span.message');
					let alertExists:boolean = false;
					spanNodeList.forEach((node:HTMLSpanElement)=>{
						if (node.innerHTML==msg) alertExists = true;
					});
					if (!alertExists) this.als.alert2(msg, null, { btns: [['ok']] }).then(()=>{
						this.logout();
					});
				} else {
					this.entitlement_result = result;
				}
			});
	
			//取得學校的classes
			//https://dev.account.oupchina.com.hk//data/v1/schools/5ec9a3c2-32d4-4adf-8176-41d145c2a770/classes?year=2024-2025
			// this.mlClient.fetchClassesBySchoolID(oupUser["https://oupchina.com.hk/school_id"], {
			// 	year: oupUser["https://oupchina.com.hk/academic_year"]
			// }).then((result:any) => {
			// 	// console.log(result);
			// });

			// console.log('fetch_all_data from loginPostProcess');
			return this.fetch_all_data();
		}).catch((e:any)=>{
			console.log(e);
		}).finally(()=>{
			this._isRunningLoginPostProcess = false;
		});


	}
	
	// public fetchUserInfo():Promise<any> {
	// 	return new Promise((resolve:Function, reject:Function):void=>{
	// 		if (this._apiData.fetchUserInfo!=undefined) {
	// 			resolve(this._apiData.fetchUserInfo);
	// 		} else {
	// 			this.mlClient.fetchUserInfo().then((oupUser:any)=>{
	// 				this._apiData.fetchUserInfo = oupUser;
	// 				resolve(this._apiData.fetchUserInfo);
	// 			});
	// 		}
	// 	});
	// }

	public updateUserInfo():Promise<void> {
		if (!this.isOupUser) return Promise.reject("notOupUser");

		return this.mlClient.fetchUserInfo().then((oupUser:any)=>{
			// console.log(userInfo);
			// userInfo: {
			// 	"userID": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 	"https://oupchina.com.hk/identity_provider": "esas",
			// 	"https://oupchina.com.hk/identity_provider_user_data": {
			// 		"usertoken": 20033912,
			// 		"loginID": "act_test_t1"
			// 	},
			// 	"https://oupchina.com.hk/school_id": "5ec9a3c2-32d4-4adf-8176-41d145c2a770",
			// 	"https://oupchina.com.hk/school#en": "(demo) Activity School",
			// 	"https://oupchina.com.hk/school#zh-Hant": "(demo) Activity School",
			// 	"https://oupchina.com.hk/user_type": "teacher",
			// 	"https://oupchina.com.hk/name#zh-Hant": "act_test_t1",
			// 	"https://oupchina.com.hk/name#en": "act_test_t1",
			// 	"https://oupchina.com.hk/academic_year": "2024-2025",
			// 	"https://oupchina.com.hk/teacher_position": "Teacher",
			// 	"clientID": "dev/activityplatform",
			// 	"https://oupchina.com.hk/keep_me_signed_in": false,
			// 	"https://oupchina.com.hk/last_login_client_id": "dev/activityplatform",
			// 	"https://oupchina.com.hk/initial_login_client_id": "dev/activityplatform",
			// 	"sub": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c"
			// }

			this.datas.userInfo.ename = oupUser["https://oupchina.com.hk/name#en"];
			this.datas.userInfo.cname = oupUser["https://oupchina.com.hk/name#zh-Hant"];
			this.datas.userInfo.first_name = oupUser["https://oupchina.com.hk/name#en"];
			this.datas.userInfo.c_first_name = oupUser["https://oupchina.com.hk/name#zh-Hant"];
			this.datas.userInfo.last_name = "";
			this.datas.userInfo.c_last_name = "";
			this.datas.userInfo.gender = 1;
			this.datas.user_role = oupUser["https://oupchina.com.hk/teacher_position"] == "Teacher" ? 3 : 2;

			if (this.datas.getLang() == "en") {
				this.datas.userInfo.school.title = oupUser["https://oupchina.com.hk/school#en"];
				this.datas.userInfo.school_name = oupUser["https://oupchina.com.hk/school#en"];
			} else {
				this.datas.userInfo.school.title = oupUser["https://oupchina.com.hk/school#zh-Hant"];
				this.datas.userInfo.school_name = oupUser["https://oupchina.com.hk/school#en"];
			}
			
			//供OUP用
			this.datas.userInfo.oup = oupUser;

			return this.datas.userInfo.oup;
		});
	}

	async fetch_school() {
		this.oup_school = await this.mlClient.fetchSchoolByID(this.datas.userInfo.oup["https://oupchina.com.hk/school_id"]);
	}

	get_school_name() {
		if(!this.oup_school) {
			return '';
		}
		let lang = this.datas.lang;
		if (['sc', 'tc'].includes(lang)) {
			return this.oup_school.nameChi;
		}
		if (['en'].includes(lang)) {
			return this.oup_school.nameEng;
		}
		return '';
	}

	async fetch_entitlements() {
		let oupUser = this.datas.userInfo.oup;
		//取得User的Entitlements
		// https://dev.account.oupchina.com.hk//data/v1/users/d9ca8888-5ca2-49c8-85ac-51ebfde1e55c/entitlements?categories%5B%5D=D6&year=2024-2025
		this.entitlement_result = await	this.mlClient.fetchEntitlementsByUserID(oupUser.userID, {
			year: oupUser["https://oupchina.com.hk/academic_year"],
			categories: ["D6"]
		});
	}

	async fetch_teachers() {
		this.teachers = await this.mlClient.fetchTeachersBySchoolID(this.datas.userInfo.oup["https://oupchina.com.hk/school_id"]);
		await Promise.all(this.teachers.map(async (t) => {
			let teacher_class = await this.mlClient.fetchTeacherClasses(t.userID);
			// console.log('teacher_class', teacher_class);
			t.classes = teacher_class;
			this.teacher_map[t.userID] = t;
		}));
		return this.teachers;
	}

	async fetch_classes() {
		this.classes = await this.mlClient.fetchClassesBySchoolID(this.datas.userInfo.oup["https://oupchina.com.hk/school_id"], {
			year: this.datas.userInfo.oup["https://oupchina.com.hk/academic_year"]
		});
		// sort by name
		this.classes.sort((a, b) => {
			let nameA = a.nameEng || a.nameChi;
			let nameB = b.nameEng || b.nameChi;
			return nameA.localeCompare(nameB);
		});
		this.classes.forEach((c) => {
			this.class_map[c.classID] = c;
		});
		return this.classes;
	}

	async fetch_students() {
		let students = [];
		let p_list = this.classes.map(async (c) => {
			c.students = await this.mlClient.fetchStudentsByClassID(c.classID);
			c.students = c.students.filter(s => !!s);
		});

		await Promise.all(p_list);
		this.classes.forEach((c) => {
			students = students.concat(c.students);
		});
		// filter duplicate students
		let map = {};
		students = students.filter((s) => {
			if (map[s.userID]) {
				return false;
			}
			map[s.userID] = true;
			this.student_map[s.userID] = s;
			return true;
		});
		this.students = students;
		return this.students;
	}

	public async fetch_all_data() {
		if (this._is_fetching_all_data) {
			return;
		}
		// console.log('fetch_all_data start', this);
		this._is_fetching_all_data = true;

		if (!this.datas.userInfo.oup) {
			await this.updateUserInfo();
		}
		// console.log('fetch_all_data start call api', this);

		this.fetch_school();
		this.fetch_entitlements();

		await Promise.all([
			this.fetch_teachers(),
			this.fetch_classes(),
		]);

		await this.fetch_students();

		await this.sync_all_data();

		// console.log('fetch_all_data done', this);
		this._is_fetching_all_data = false;
	}

	public async sync_all_data() {
		let teachers = this.teachers;
		let classes = this.classes;
		let students = this.students;

		let data = {
			teachers,
			classes,
			students,
		};
		console.log('sync_all_data', this);
		let data_json = JSON.stringify(data);
		// md5 data
		let hash = Md5.hashStr(data_json);

		let response = await this.datas.call('OUPDataSync.sync', data_json, hash);
		if (response.code !== 0) {
			console.error('sync_all_data error', response);
			return;
		}
		this.user_id_map = await this.get_user_id_map();
	}

	public async get_user_id_map(oup_user_ids = null) {
		let response = await this.datas.call('OUPDataSync.get_user_id_map', oup_user_ids);

		if (response.code !== 0) {
			console.error('get_user_id_map error', response);
			return;
		}

		return response.map;
	}

	public get_user_display_name(uid) {
		let oup_user_id = this.user_id_map[uid];
		if (!oup_user_id) {
			return '';
		}
		let user = null;
		if (this.teacher_map[oup_user_id]) {
			user = this.teacher_map[oup_user_id];
		}
		if (this.student_map[oup_user_id]) {
			user = this.student_map[oup_user_id];
		}

		if (!user) {
			return '';
		}

		let lang = this.datas.lang;

		if (['sc', 'tc'].includes(lang)) {
			return user.nameChi;
		}
		if (['en'].includes(lang)) {
			return user.nameEng;
		}

		return '';
	}

	/**
	 *
	 * @param {string[]|string} class_ids
	 */
	get_class_name(class_ids: string[] | string) {
		if (typeof class_ids === 'string') {
			class_ids = [class_ids];
		}
		let lang = this.datas.lang;

		let class_name = class_ids
			.map((class_id) => {
				if (this.class_map[class_id]) {
					if (['sc', 'tc'].includes(lang)) {
						return this.class_map[class_id].nameChi;
					} else if (['en'].includes(lang)) {
						return this.class_map[class_id].nameEng;
					}
				}
				return null;
			})
			.filter((name) => !!name)
			.join(',');

		return class_name;

	}

	get_lesson_name(uid) {
		let display_name = this.get_user_display_name(uid);
		return {
			tc: `${display_name}的課堂`,
			sc: `${display_name}的课堂`,
			en: `${display_name}'s Lesson`,
		}[this.datas.lang];
	}
}
