import { Directive, HostListener, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { DataService } from '../service/data.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[textHover]'
})
export class TextHoverDirective {
	private lastChar: string | null = null;
	private originalText: string | null = null; // 儲存原始文本
	private currentHighlightedWord: string | null = null; // 當前高亮的詞語
	private hoverSubject: Subject<MouseEvent>;

	@Input() disable: boolean = false; // 新增 disable 屬性

	constructor(private el: ElementRef, public datas: DataService) {
	
	}

	@Output() public hoverCallback: EventEmitter<any> = new EventEmitter<any>();
	@Output() public leaveCallback: EventEmitter<any> = new EventEmitter<any>();

	@Output() public textHoverClick: EventEmitter<any> = new EventEmitter<any>();

	ngAfterViewInit() {
		// 在指令初始化時保存原始文字
		if (this.disable){
			return;
		}
		this.hoverSubject = new Subject(); // 用於防抖的主題
		this.hoverSubject.pipe(debounceTime(10)).subscribe(event => {
			this.handleMouseMove(event);
		});
		this.originalText = this.el.nativeElement.innerHTML;
	}

	@HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent): void {
		if (this.disable){
			return;
		}
		this.hoverSubject.next(event); // 發送鼠標事件到主題
	}

	@HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent): void {
		if (this.disable){
			return;
		}
		this.removeHighlight(); // 移除高亮
		const leaveData = this.getLeaveData(event);
		if (leaveData) {
			this.leaveCallback.emit(leaveData);
		}
		this.lastChar = null; // 清除最後的字符
	}

	@HostListener('click', ['$event']) onClick(event: MouseEvent): void {
		if (this.disable){
			return;
		}
		if (this.currentHighlightedWord) {
			console.log('textHoverClick');
			this.textHoverClick.emit(this.currentHighlightedWord);
		}
	}

	private handleMouseMove(event: MouseEvent): void {
		if (this.disable) return; // 檢查是否禁用高亮

		const hoverData = this.getHoverData(event);
		if (!hoverData) return;

		// 當字符改變時才觸發回調
		if (hoverData.char !== this.lastChar && hoverData.index > 0) {
			this.lastChar = hoverData.char;
			let index = hoverData.index;

			this.datas.post("NLP.analyze_chinese_text_array", [[hoverData.fullText]]).subscribe((res: any) => {
				if (res.output && res.output[0].items) {
					const items = res.output[0].items;
					let matchedIndex = 0;
					let scanned = 0;
					for (let item of items) {
						const itemIndex = hoverData.fullText.indexOf(item.text, scanned - 1);
						scanned += item.text.length;
						if (item.text.indexOf(hoverData.char) > -1){
							if (itemIndex !== -1 && index >= itemIndex && index < itemIndex + item.text.length) {
								const occurence = this.countOccurrencesUpToIndex(hoverData.fullText, item.text, index);
								this.highlightWord(item.text, occurence); // 高亮整個詞語
								break;
							}
						}
					}
				}
			});

			if (hoverData) {
				this.hoverCallback.emit(hoverData);
			}
		}
	}

	private countOccurrencesUpToIndex(text, word, charIndex) {
		let count = 0;
		let currentIndex = 0;
	
		while (currentIndex <= charIndex) {
			const foundIndex = text.indexOf(word, currentIndex);
			if (foundIndex === -1 || foundIndex > charIndex) {
				break; // 找不到或超出範圍
			}
			count++;
			currentIndex = foundIndex + word.length;
		}
	
		return count;
	}

	private highlightWord(word: string, occurence): void {
		if (!this.originalText) return;

		let highlightedText = this.originalText;
		if (['(',')',')','?',',','.','/',':',';'].indexOf(word) === -1) {
			let count = 0;
			try{
				highlightedText = this.originalText.replace(
					new RegExp(word, 'g'),
					(match) => {
						count++;
						if (count === occurence) {
							return `<span class="hovered" style="cursor: pointer;">${match}</span>`;
						} else {
							return match;
						}
					}
				);
			} catch(e){
				console.error(e);
			}
			
		}
		this.el.nativeElement.innerHTML = highlightedText; // 更新元素內容
		this.currentHighlightedWord = word; // 保存當前高亮的詞語
	}
	

	private removeHighlight(): void {
		this.currentHighlightedWord = null; // 清除當前高亮的詞語
		if (this.originalText) {
			this.el.nativeElement.innerHTML = this.originalText; // 恢復原始文本
		}
	}

	private getLeaveData(event: MouseEvent): {
		event: MouseEvent;
		char: string;
		index: number;
		fullText: string;
	} | null {
		return {
			event,
			char: this.lastChar || '',
			index: -1,
			fullText: ''
		};
	}

	private getHoverData(event: MouseEvent): {
		event: MouseEvent;
		char: string;
		index: number;
		fullText: string;
	} | null {
		const range = document.caretRangeFromPoint(event.clientX, event.clientY);
		if (!range) return null;
		let el:any = event.target;
		el = el.parentElement?el.parentElement:el;
		let realFullText = el.innerText;
		const textNode = range.startContainer;
		if (textNode.nodeType === Node.TEXT_NODE) {
			let fullText = textNode.textContent || '';
			let offset = range.startOffset;
			if (fullText != realFullText){
				offset = offset + realFullText.indexOf(fullText);
				fullText = realFullText;
			}
			const char = fullText[offset] || '';
			return {
				event,
				char,
				index: offset,
				fullText
			};
		}
		return null;
	}
}
