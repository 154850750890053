import { Component, OnInit, Input, OnDestroy, HostListener, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// =======================================
// icon
// =======================================
import { faUp } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import { ROBookConfig } from '../roBookModule/ROBookConfig';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';

@Component({
	selector: 'SubmitResponse',
	template:`
	<div [ngClass]="[ani_type, response_type]">{{response_msg}}</div>
	`,
	styleUrls: ['./SubmitResponse.component.scss']
})

export class SubmitResponseComponent{
	protected timerObj:any = -1;
	public ani_type:string = "";
	public response_type:string = "";
	public response_msg:string = "";
	protected au:any;
	protected text:any ={
		"tc":{
			"incorrect":"答錯了!",
			"try_again":"仍需努力!",
			"correct":"答對了!",
			"got_it":"已提交",
			"well_done":"做得好!",
			"unfinished":"未完成"
		},
		"sc":{
			"incorrect":"答错了!",
			"try_again":"仍需努力!",
			"correct":"答对了!",
			"got_it":"已提交",
			"well_done":"做得好!",
			"unfinished":"未完成"
		},
		"en":{
			"incorrect":"Incorrect!",
			"try_again":"Try again!",
			"correct":"Correct!",
			"got_it":"Got it.",
			"well_done":"Well done!",
			"unfinished":"Unfinished."
		}
	};
	
	constructor(public translate:TranslateService, private alertService:AlertService, private dataService: DataService) {
	}

	public show(bookConfig:ROBookConfig, pageResult:any):void {
		console.log(bookConfig.book.id)
		if (bookConfig.book.id === '2973103' || bookConfig.book.id === '2973105' || bookConfig.book.id === '2973111' || bookConfig.book.id === '2973113' || bookConfig.book.id === '2973117' || bookConfig.book.id === '2984105' || bookConfig.book.id === '2984109' || bookConfig.book.id === '2984113' || bookConfig.book.id === '2984119' || bookConfig.book.id === '2984132') {
			return;
		}
		if(bookConfig.viewMode == "correction" && bookConfig.share.books[0].type == "pdf-paper") {
			// pdf paper 在改正時，只出提交提示
			this.alertService.toastSuccess(this.text[this.translate.currentLang]["got_it"]);
			return;
		}

		// todo 未有學校的提交反應設定值
		var animationType:any = bookConfig.setting.bookAnimationType;
		
		console.log("animationType",animationType,pageResult)

		// todo 根據動畫類型做反應
		if(animationType==null)
			animationType = -1;

		this.ani_type = "";
		this.response_type = "";
		this.response_msg = "";
		if(this.timerObj!=-1) {
			clearTimeout(this.timerObj);
			this.timerObj = -1;
		}
		if(this.au)
			this.au.pause();

		let pattern = [
			//							錯, 半對, 對, 有交, 冇交
			{value:-1,				match:[0, 0, 0, 0, 0]}, // 無題目(應該不會進來)
			{value:"unfinished",	match:["*","*", "*", "*", 1]}, // 未完成
			{value:"correct",		match:[0, 0, 1, "*", 0]}, // 答對了
			{value:"try_again",		match:["*",1, "*", "*", 0]}, // 仍需努力
			{value:"try_again",		match:[1, "*", 1, "*", 0]}, // 仍需努力
			{value:"incorrect",		match:[1, 0, 0, "*", 0]}, // 答錯了
			{value:"got_it",		match:["*","*", "*", 1, 0]} // 已提交
		];

		if (bookConfig.share && bookConfig.share.live_verify === '0') {
			pattern = pattern.map(p => {
				if(![-1, "unfinished", "got_it"].includes(p.value)) {
					p.value = "got_it";
				}
				return p;
			})
		}

		//錯, 半對, 對, 有交, 冇交
		let submit_response:any = this.findBestMatch([
			pageResult.incorrectCount.length ? 1 : 0, 
			pageResult.partialCorrectCount.length ? 1 : 0, 
			pageResult.correctCount.length ? 1 : 0, 
			pageResult.submitted.length ? 1 : 0,
			pageResult.unfinished.length ? 1 : 0
		], pattern);


		console.log({
			pageResult,
			bookConfig,
			submit_response,
		})

		if(submit_response && submit_response.value!=-1) {

			let type:string = "text";
			let lang:string = "tc";
			let sound:string = "sfx";
			let speechPracticeResponse = { tc: "", sc: "", en: ""};

			this.au = new Audio("assets/sound/submit/sfx/sfx_"+submit_response.value+".mp3");
			this.au.addEventListener("playing", ()=> {
				this.ani_type = "sa_type_"+"text";
				

				this.response_type = "sa_"+submit_response.value;
				if(type == "text") {
					let result = pageResult.submitted[0];
					if (result && result.ctx && result.ctx.type && result.ctx.type === "speechPractice") {
						console.log(result.correctAnswer)
						if (result.subType === "word" || result.subType === "fillIn") {
							if (result.pronScore >= result.ctx.passLevel && result.userRecordAnswer === result.answerWithoutContractions) {
								speechPracticeResponse.en = "Well Done!"
							} else {
								speechPracticeResponse.en = "Try Again!"
							}
						} else {
							if (result.pronScore >= result.ctx.passLevel) {
								speechPracticeResponse.en = "Well Done!"
							} else {
								speechPracticeResponse.en = "Try Again!"
							}
						}
						
						this.response_msg = speechPracticeResponse[this.dataService.lang];
					} else {
						this.response_msg = this.text[lang][submit_response.value];
					}
				}

				this.timerObj = setTimeout(()=>{
					this.ani_type = '';
					this.response_type = '';
					this.response_msg = '';
					this.timerObj = -1;
				}, 3000);
			});
			this.au.play();
		} else {
			let response = { tc: "", sc: "", en: ""};

			this.au = new Audio("assets/sound/submit/sfx/sfx_"+submit_response.value+".mp3");
			this.au.addEventListener("playing", ()=> {
				this.ani_type = "sa_type_"+"text";
				this.response_type = "sa_"+submit_response.value;
				response.en = "Try Again!"
				this.response_msg = response[this.dataService.lang];
				console.log(this.response_msg)

				this.timerObj = setTimeout(()=>{
					this.ani_type = '';
					this.response_type = '';
					this.response_msg = '';
					this.timerObj = -1;
				}, 3000);
			});
			this.au.play();
		}
	}

	protected isMatch(input:any[], pattern:any[]):boolean {
		return input.find((e,i) => (pattern[i] != "*" && e != pattern[i]))==null;
	}

	protected findBestMatch(match:any[], pattern:any[]):any {
		return pattern.find((e,i) => this.isMatch(match, e.match));
	}

}