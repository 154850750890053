import { Injectable } from '@angular/core';

import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Open Knowledge Association Ltd.,LicensedApplication=rainbowone,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-023409,ExpiryDate=14_December_2022_[v2]_MTY3MDk3NjAwMDAwMA==4908735a2d122919f4e91a9613fb03e1");
import * as tc from '../../assets/langs/tc.json';
import * as sc from '../../assets/langs/sc.json';
import * as en from '../../assets/langs/en.json';
import { DataService } from './data.service';
import { ColumnApi } from 'ag-grid-community';
@Injectable({ providedIn: 'root' })
export class AgGridService {
	private tc:any = tc;
	private sc:any = sc;
	private en:any = en;
	public localeObj;
	constructor(public datas: DataService) {
		this.loadLocale();
	}

	public loadLocale(){
		const lang = this.datas.lang;
		if (lang === 'sc') {
			this.localeObj = this.sc.default.agGrid;
		} else if (lang === 'en') {
			this.localeObj = this.en.default.agGrid;
        } else {
			this.localeObj = this.tc.default.agGrid;
		}
	}

	public registColumnStages(options, key=''){
		const stateKey = key + '.gridColumnState';
		const callbackOnGridReady = options.onGridReady? options.onGridReady: ()=>{};
		options.onGridReady = (event)=>{
			callbackOnGridReady(event);
			const columnState = JSON.parse(localStorage.getItem(stateKey));
			if (columnState){
				event.columnApi.applyColumnState({ state: columnState, applyOrder: true});
			}
		};

		let savingStage = false;

		const saveColumnStage = (columnApi:ColumnApi)=>{
			if (savingStage){
				return;
			}
			savingStage = true;
			setTimeout(()=> { 
				const state = columnApi.getColumnState();
				localStorage.setItem(stateKey, JSON.stringify(state) );
				savingStage = false;
			}, 100);
		};

		const callbackOnColumnResized = options.onColumnResized?options.onColumnResized: ()=>{};
		options.onColumnResized = (event)=>{
			callbackOnColumnResized(event);
			if (event.columnApi){
				saveColumnStage(event.columnApi);
			}
		};

		const callbackOnDisplayedColumnsChanged = options.callbackOnDisplayedColumnsChanged?options.callbackOnDisplayedColumnsChanged:()=>{};
		options.onDisplayedColumnsChanged = (event)=>{
			callbackOnDisplayedColumnsChanged(event);
			if (event.columnApi){
				saveColumnStage(event.columnApi);
			}
		};

	}


}
