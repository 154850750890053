import {NgModule} from '@angular/core';
import {GoodWorkPostModalComponent} from "./good-work-post-modal.component";
import {Modal2Module} from "../../../../../sharedModule/modal2Module/modal2.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RadioBoxModule} from "../../../../../sharedModule/radioBoxModule/radioBox.module";
import {FormsModule} from "@angular/forms";
import {GoodWorkPrintModalComponent} from "./good-work-print-modal.component";

@NgModule({
	imports: [
		Modal2Module,
		CommonModule,
		TranslateModule,
		RadioBoxModule,
		FormsModule,
	],
	exports: [
		GoodWorkPostModalComponent,
		GoodWorkPrintModalComponent,
	],
	declarations: [
		GoodWorkPostModalComponent,
		GoodWorkPrintModalComponent,
	],
	providers: [],
})
export class GoodWorkModule {
}
