import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { speechBox } from "./speechBox";
import { AlertService } from "src/app/service/alert.service";
import { ChatService } from "src/app/service/chatGPT.service";
import { DataService } from "src/app/service/data.service";
import { StorageService } from "src/app/service/storage.service";
import { LoadingService } from "../../loadingModule/loading.service";
import * as RecordRTC from 'recordrtc';
import { UploadService } from "../../uploadModule/upload.service";
import { ThemeService } from "src/app/service/theme.service";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import * as moment from 'moment';
import { faTrashCanXmark } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "conversationPractice",
	templateUrl: "./conversationPractice.html",
	styleUrls: ["./conversationPractice.scss"],
})
export class conversationPractice implements OnChanges, OnDestroy {
	@Input() public parent;
	@Input() public context;
	@Input() public redoConversation;
	@Input() public objective;
	@Input() public EDBScore;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	@ViewChild("speechBox", { static: false }) speechBoxContainer: speechBox;
	@ViewChild('dialogContainer', { static: false }) dialogContainer!: any;
	@ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

	public is_objective_box_open = false;
	public studentLevel = "p4";

	// For media
	public recorder: any = null;
	public stream: any = null;
	public soundWaveStream: any = null;
	public timer;
	public recordingTime = 30;
	public recordTimer;
	public isResReceived = false;
	public status: string = "idle";							// "idle" / "recording" / "loading" / "playing"
	public robotImageStatus: string = "idle";				// "idle" / "talk" / "correct" / "wrong" / "wrong_last"
	public hintQuota = 3;
	public audio: HTMLAudioElement = new Audio();
	public speed = 1.1;
	public AiSpeedOptions: any[] = [
		{ label: "Slow", value: 0.9 },
		{ label: "Normal", value: 1.1 },
		{ label: "Fast", value: 1.3 },
	];

	// For EDB Question
	public objectiveUsed = [];

	// For Default Question
	public questionIndex = 0;

	// Config
	public userName;
	public instruction = "";
	public starNumberInExercise = 0;
	public totalStarNumber;
	public audioInfo = [];
	public promptLog = [];
	public conversation = [];
	public eachQuestionScore = [];
	public questionNumber = 1;
	public result = [];

	public recordEnable = false;
	public isAnswerDefaultQuestion = false;
	public userGreetingPass = false;
	public userGreetingLimit = Math.floor(Math.random() * 2) + 3;
	public userGreetingCounter = 1;
	public userFirstEDBPass = false;
	public userEDBLimit = Math.floor(Math.random() * 2) + 3;
	// public userEDBLimit = 1;
	public userEDBCounter = 1;
	// public userEDB_retry_limit = 2;
	public userEDB_retry_limit = 0;
	public userEDB_retry = 0;
	public userSecondEDBPass = false;
	public userDefaultQuestion_retry_limit = 3;
	public userDefaultQuestion_retry = 0;
	public defaultQuestionFinish = false;
	public receiveMyData = false;
	public isInstructionShown = false;
	public isContentShown = false;
	public isConfirmButtonShown = false;

	public studentChar = '1';
	public teacherChar = '1';
	public voiceLibrary = [
		{ gender: "F", name: "en-GB-LibbyNeural"},
		{ gender: "M", name: "en-US-RogerNeural"},
		{ gender: "F", name: "en-US-AriaNeural"},
		{ gender: "F", name: "en-US-AnaNeural"},
		{ gender: "F", name: "en-GB-AdaMultilingualNeural"},
		{ gender: "M", name: "en-US-AdamMultilingualNeural"},
		{ gender: "M", name: "en-GB-RyanNeural"}
	]
	public voiceSelection;
	public networkCheckOn: boolean = false;

	// For Sound Wave
	private audioContext: AudioContext | null = null;
	private analyserNode: AnalyserNode | null = null;
	private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode | null = null;
	private animationFrameId: number | null = null;
	private frameCount: number = 0;
	private framesToSkip: number = 4;
	private isDestroyed:boolean= false;

	public cancelBtn = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/btn_myaibuddy_del.svg')`;

	constructor(
		private chat: ChatService, public dataService: DataService, public storage: StorageService, private lds: LoadingService,
		private alert: AlertService, public upload: UploadService, public datas: DataService, private eleRef: ElementRef, private theme: ThemeService
	) {
		this.theme.applyStyleObj({
			"default": { "section-maincolor": "#0052AF" },
			"dark": { "section-maincolor": "#0052AF" }
		}, this.eleRef.nativeElement);
	}

	async ngOnInit() {
		try {
			this.eleRef.nativeElement.parentElement.classList.forEach((value) => {
				if (value.includes('p4')) this.studentLevel = "p4";
				else if (value.includes('p5')) this.studentLevel = "p5";
				else if (value.includes('p6')) this.studentLevel = "p6";
				else if (value.includes('s1')) this.studentLevel = "s1";
				else if (value.includes('s2')) this.studentLevel = "s2";
				else if (value.includes('s3')) this.studentLevel = "s3";
			});

			let viewMode = this.context.config.viewMode;
			this.userName = this.dataService.userInfo.nickname;
			this.studentChar = sessionStorage.getItem('studentChar') || '1';
			this.teacherChar = sessionStorage.getItem('teacherChar') || '1';
			this.preloadImage();
			this.setInstruction();
			
			this.voiceSelection = this.voiceLibrary[this.teacherChar].name;
			console.log(this.voiceSelection)

			if (viewMode !== "scoring" && viewMode !== "preview" && viewMode !== "review") {
				this.soundWaveStream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.audioContext = new AudioContext();
				this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.soundWaveStream);
				this.analyserNode = this.audioContext.createAnalyser();
				this.analyserNode.fftSize = 1024;
				this.mediaStreamAudioSourceNode.connect(this.analyserNode);

				this.isInstructionShown = true;
				this.setEDBObjective();
				this.setDefaultQuestion();
			} else if (viewMode === "review") {
				console.log(this.parent.myData)
				this.conversation = this.parent.myData.conversationRecord;
				this.isInstructionShown = false;
				this.isContentShown = true;
			}
		} catch (err) {
			console.error('Error accessing microphone:', err);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes)
		let redo = changes.redoConversation;
		if (redo && redo.previousValue === false && redo.currentValue === true) {
			this.hintQuota = 3;
			this.objectiveUsed = [];
			this.EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

			this.audioInfo = [];
			this.promptLog = [];
			this.conversation = [];
			this.eachQuestionScore = [];
			this.questionNumber = 1;

			this.isAnswerDefaultQuestion = false;
			this.userGreetingPass = false;
			this.userGreetingLimit = Math.floor(Math.random() * 2) + 3;
			this.userGreetingCounter = 1;
			this.userFirstEDBPass = false;
			this.userEDBLimit = Math.floor(Math.random() * 2) + 3;
			this.userEDBCounter = 1;
			// this.userEDB_retry_limit = 2;
			this.userEDB_retry_limit = 0;
			this.userEDB_retry = 0;
			this.userSecondEDBPass = false;
			this.userDefaultQuestion_retry_limit = 3;
			this.userDefaultQuestion_retry = 0;
			this.defaultQuestionFinish = false;
			this.isConfirmButtonShown = false;
			// this.intro();
			this.greeting();
		} else if (this.context.config.viewMode === "scoring") {
			this.conversation = this.parent.myData.conversationRecord;
			this.isContentShown = true;
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.audio.pause();
		this.audio.currentTime = 0;
		if (this.audioContext) this.closeMicrophone();
	}

	/////////////////////////////////////////
	//             Media function          //
	/////////////////////////////////////////
	public record(): void {
		this.status = "recording";
		navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
			let options = {
				mimeType: "audio/wav",
				numberOfAudioChannels: 1,
				desiredSampRate: 16000,
			};
			//Start Actuall Recording
			let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
			this.recorder = new StereoAudioRecorder(stream, options);
			this.stream = stream;
			this.recorder.record();
		});

		if (this.timer) {
			clearInterval(this.timer)
		}
		this.timer = setInterval(() => {
			if (this.recordingTime === 0) {
				this.finishRecording();
			} else {
				this.recordingTime = this.recordingTime - 1;
			}
		}, 1000);
	}

	startChecking() {
		this.status = "recording";
		if (this.analyserNode) {
			const canvas = this.canvas.nativeElement;
			const canvasCtx = canvas.getContext('2d');
			const data = new Float32Array(this.analyserNode.fftSize);

			const drawWaveform = () => {
				this.frameCount++;
				if (this.frameCount % this.framesToSkip === 0) {
					this.analyserNode.getFloatTimeDomainData(data);
					canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
					const barWidth = (canvas.width / data.length) * 80;
					const barGap = 15;
					let x = 0;
					for (let i = 0; i < data.length; i++) {
						const v = data[i] * 1000.0;
						canvasCtx.fillStyle = 'rgb(255, 255, 255)';
						canvasCtx.fillRect(x, canvas.height / 2 - v / 2, barWidth, v);
						x += barWidth + barGap;
					}
				}
				this.animationFrameId = requestAnimationFrame(drawWaveform);
			};
			drawWaveform();
		}
	}

	recordAction() {
		this.record();
		this.startChecking();
	}

	public finishRecording(): void {
		this.status = "loading";
		this.recordEnable = false;

		this.recordingTime = 30;
		clearInterval(this.timer);
		this.recorder.stop((blob) => {
			let file: File = new File([blob], "record.mp3");

			let reference: string = this.context.createReference();
			let uid = this.context.service.dataService.userInfo.uid;

			if (this.parent.myData == null) {
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
				this.parent.myData = {
					user: uid,
					key: this.parent.fileReference.key,
					reference: this.parent.fileReference.reference
				}
			} else {
				this.parent.myData.user = uid;
				this.parent.myData.reference = reference;
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
			}

			this.parent.answerChanged = true;

			this.upload.uploadFileObject(file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;
				if (this.parent.subType === "oralAI") {
					//free conversation
					let file: File = new File([blob], "test.mp3");
					this.getSttApi(file, filePath);
				} else {
					this.ttsApi(filePath);
				}
			});

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
		this.status = "idle";
	}

	stopChecking() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}
	}

	stopAction() {
		this.finishRecording();
		this.stopAction();
	}

	cancelAction() {
		this.status = "idle";
		this.recordingTime = 30;
		clearInterval(this.timer);
		this.stopChecking();
	}

	async closeMicrophone() {
		if (!this.soundWaveStream) return;
		try {
			console.log("closeMicrophone")
			await this.audioContext.close();
			this.soundWaveStream.getTracks().forEach(track => track.stop());			
		} catch (error) {
			console.error('Error accessing microphone:', error);
		}
	}

	private getSttApi(wavFile: File, filePath): Promise<void> {
		let lang = "en-US";
		let data: FormData = new FormData();
		data.append('api', 'ROSpeechRecognition.recognize_tts');
		data.append("json", JSON.stringify([filePath, "", lang]));
		const delayTime = 2000;

		return this.datas.post2({ data: data, loading: false }).then(async (res: any) => {
			console.log("delayTime", delayTime);
			var message: string = "";

			if (!res.result || !res.result.DisplayText) {
				setTimeout(() => {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
				}, 2000);
			} else {
				message = res.result.DisplayText;

			}
			this.promptLog.push({ role: "user", content: message })
			this.conversation.push({ role: "user", content: message, audioPath: filePath })

			let promptInput = [...this.promptLog].filter(message => !message.isHint).slice(-4);
			promptInput.unshift(this.promptLog[0])
			const robotRes = await this.sentPromptApi(promptInput);
			this.scrollToBottom();
			let content = JSON.parse(robotRes).choices[0].message.content;

			let audio_res = await this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			await audio_res.is_finish;
			this.recordEnable = true;
			return;
		}).catch((err: any) => {
			return;
		});
	}

	async ttsApi(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		if (this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.parent.defaultQuestion[this.questionIndex].answer, lang]));
		} else {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.parent.defaultQuestion[this.questionIndex].name, lang]));
		}
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			this.isResReceived = false;
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && !this.isResReceived) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);

			if (this.isAnswerDefaultQuestion) {
				const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: false });
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([firstRequest, secondRequest]).then(async ([firstRequest, secondRequest]) => {
					if (!firstRequest.result || !secondRequest.result ||
						firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
						secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						let foulLanguageCheck = await this.parent.foulLanguageCheck(firstRequest.result.NBest[0]);
						console.log(foulLanguageCheck)
						let userAnswer = this.handleUserAnswer(secondRequest.result);
						if (foulLanguageCheck) userAnswer = "****";
						// console.log(userAnswer)
						if (this.parent.subType === "oralSentence") {
							this.conversation.push(
								{
									role: "user",
									content: this.parent.defaultQuestion[this.questionIndex].name,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else if (this.parent.subType === "oralParagraph") {
							this.conversation.push(
								{
									role: "user",
									content: this.parent.defaultQuestion[this.questionIndex].name,
									isParagraph: true,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else {
							this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath })
						}
						this.promptLog.push({ role: "user", content: firstRequest.result.NBest[0].ITN })

						// console.log(firstRequest.result)
						this.eachQuestionScore[this.questionIndex] = {
							PronScore: Math.round(firstRequest.result.NBest[0].PronScore),
							AccuracyScore: Math.round(firstRequest.result.NBest[0].AccuracyScore),
							CompletenessScore: Math.round(firstRequest.result.NBest[0].CompletenessScore),
							FluencyScore: Math.round(firstRequest.result.NBest[0].FluencyScore)
						};

						this.scrollToBottom();
						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
							// console.log(secondRequest.result)
							let result = secondRequest.result;
							let score = secondRequest.result.NBest[0];
							this.checkPronunciation(result, score);
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							// console.log(firstRequest.result)
							let result = firstRequest.result;
							let score = firstRequest.result.NBest[0];
							this.checkPronunciation(result, score);
						}
					}
				})
			} else {
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([secondRequest]).then(async ([secondRequest]) => {
					if (!secondRequest.result || secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						console.log(secondRequest)
						let text = secondRequest.result.DisplayText;
						let score = secondRequest.result.NBest[0];

						let foulLanguageCheck = await this.parent.foulLanguageCheck(secondRequest.result.NBest[0]);
						console.log(foulLanguageCheck)
						let userAnswer = this.handleUserAnswer(secondRequest.result);
						if (foulLanguageCheck) userAnswer = "****";

						this.promptLog.push({ role: "user", content: secondRequest.result.NBest[0].ITN });
						this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath });

						this.scrollToBottom();
						if (!this.userGreetingPass) this.checkUserGreeting(text, score);
						else if (!this.userFirstEDBPass) this.checkUserFirstEDB(text, score);
					}
				})
			}
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	/////////////////////////////////////////
	//           GPT chat function         //
	/////////////////////////////////////////
	async sentPromptApi(prompt: any[]) {
		let res = await this.chat.oralChat(prompt, "EP_AI_ENG_CHAT");
		if (JSON.parse(res)['error']) {
			this.alert.alert2(this.translateText(
				"偵測到不適合用詞，請重新再試。",
				"侦测到不适合用词，请重新再试。",
				"Inappropriate word detected, please try again."
			), null, { btns: [["ok"]] });
			this.recordEnable = true;
			return `{"choices":[{"message":{"content":"Inappropriate word detected, please try again.","error":true}}]}`
		}
		return res
	}

	async greeting() {
		if (this.parent.subType !== "oralAI") {
			let background = { role: "system", content: "" };
			if (this.studentLevel === "p4") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of primary 4 student. The topic is "Food and Drink: Favorite food and drink". From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
			} else if (this.studentLevel === "p5") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of primary 5 student. The topic is "We Love Hong Kong: Traveling around". From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
			}  else if (this.studentLevel === "p6") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of primary 6 student. The topic is "The Magic of Nature: Taking Care of our Earth". From now on, all the sentences that you generate must be within 15 words and each word within 3 syllables and 6 characters.`;
			} else if (this.studentLevel === "s1") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of secondary 1 student. The topic is "Study, School Life, and Work: Study problems". From now on, all the sentences that you generate must be within 20 words.`;
			} else if (this.studentLevel === "s2") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of secondary 2 student. The topic is "The Individual and Society: Crime". From now on, all the sentences that you generate must be within 20 words.`;
			} else if (this.studentLevel === "s3") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of secondary 3 student. The topic is "Wonderful Things: Successful People". From now on, all the sentences that you generate must be within 20 words.`;
			} 

			let scenario = {
				role: "system",
				content: `If the student answer you in a good response, please smoothly start the topic. The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.`
			};
			this.promptLog.push(background);
			this.promptLog.push(scenario);
			let show_datetime = false;
			if(Math.random() < 0.3) {
				show_datetime = true;
			}

			this.promptLog.push({
				role: "system",
				content: `
					Please greet the student and include his/her name ${this.userName} without starting the topic.
					${show_datetime ? `Current Date Time: ${moment().format('YYYY-MM-DD HH:mm A')}
					You can make various greetings according to the time.` : ''}
					Do not use emoji. Be more creative on the greeting. 
					Let the student respond to your greeting.`
			});
		}

		if (this.parent.subType === "oralAI") {
			if (this.studentLevel === "p4") {
				this.promptLog.push({
					role: "system",
					content: `Assistant is user's best friend John, Assistant has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. Assistant misses the local food in Hong Kong and wants user to take him to user's favorite restaurant. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "p5") {
				this.promptLog.push({
					role: "system",
					content: `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Assistant is going to ask user, is there any disadvantages of this suggestion. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "p6") {
				this.promptLog.push({
					role: "system",
					content: `Imagine the Earth could talk, what do you think it would most likely say to you? What can you do to make the world a better place? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s1") {
				this.promptLog.push({
					role: "system",
					content: `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Assistant is going to ask user, which way is more effective and how do the user like to study? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s2") {
				this.promptLog.push({
					role: "system",
					content: `Do you think criminals deserve forgiveness? Should prisons be punitive or rehabilitative? What if the criminal committed a very serious crime, such as murder? Do such criminals deserve a second chance? Where do you draw the line between punishment and rehabilitation? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s3") {
				this.promptLog.push({
					role: "system",
					content: `Share your perspectives on a famous successful person. What traits did that person have? Of that person's journey to success, how much of it was that person's own will and perseverance, and how much of it was luck? What can you take away from their journey to add to your own? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			}
		}
		try {
			let content = "";
			if (this.parent.subType === "oralAI") {
				if (this.studentLevel === "p4") {
					content = `Hi ${this.userName}, It's so great to be back in Hong Kong! I've really missed the food here. What restaurant do you recommend we go to?`
				} else if (this.studentLevel === "p5") {
					content = `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Given this, what do you think are the disadvantages of this suggestion?`;
				} else if (this.studentLevel === "p6") {
					content = `Imagine the Earth could talk, what do you think it would most likely say to you? What can you do to make the world a better place?`;
				} else if (this.studentLevel === "s1") {
					content = `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Which do you think is more effective? How do you like to study?`;
				} else if (this.studentLevel === "s2") {
					content = `Do you think criminals deserve forgiveness? Should prisons be punitive or rehabilitative? What if the criminal committed a very serious crime, such as murder? Do such criminals deserve a second chance? Where do you draw the line between punishment and rehabilitation?`;
				} else if (this.studentLevel === "s3") {
					content = `Share your perspectives on a famous successful person. What traits did that person have? Of that person's journey to success, how much of it was that person's own will and perseverance, and how much of it was luck? What can you take away from their journey to add to your own?`;
				} 

				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
				await audioRes.is_finish;
				this.recordEnable = true;
			} else {
				const res = await this.sentPromptApi(this.promptLog);
				content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserGreeting(content: string, score: any) {
		const prompt = {
			role: "system",
			content: `
				The student response is "${content}".
				Please determine whether the student's response correctly or not. 

				If the student gave you a correct greet, please reply <correctGreet>. 
				If the student gave you a wrong greet and said something inappropriate or irrelevant or you cannot understand or not even a sentence, reply <wrongGreet>. 
				If the student did not greet and say something that need caring, please reply <correctGreet>.

				Please give your reason and then reply <correctGreet>, <wrongGreet>.`,
		}
		this.promptLog.push(prompt);
		try {
			const res = await this.sentPromptApi(this.promptLog);

			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) {
				return;
			}

			const content = res_obj.choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

			let result = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			let resPrompt = { role: "system", content: "", };
			if (result.includes("wrongGreet")) {
				if (this.userGreetingCounter === this.userGreetingLimit) {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. Also tell the student that he/she can not talk more like this and we must start our discussion topic now. (important) Do not use interrogative sentence or ask any question.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.userGreetingPass = true;
								this.updateResult(100, score, null);
								this.firstEDBQuestion();
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. If you don't understand what the student is talking about, please ask the student to explain, otherwise try to encourage the student to greet again. Do not start the topic.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.recordEnable = true;
								this.userGreetingCounter++;
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} else if (result.includes("correctGreet")) {
				resPrompt.content = `Please respond to the student's greeting without questions. Also, answer their question if you can. If the student needs attention, use a sentence or two to comfort him or her. Finally, describe the topic in one sentence without asking the student any questions. No question allowed. Please respond to the student smoothly. Please maintain coherence and fluency in the overall content.`
				this.promptLog.push(resPrompt);

				try {
					const res = await this.sentPromptApi(this.promptLog);
					const content = JSON.parse(res).choices[0].message.content;
					this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

					if (res) {
						let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
						if (audioRes) setTimeout(() => {
							this.userGreetingPass = true;
							this.updateResult(100, score, null);
							this.firstEDBQuestion();
						}, audioRes.duration * 1000);
					}
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async firstEDBQuestion() {
		let curriculum = ``;
		let training_material = ``;
		if (this.studentLevel === 'p4' || this.studentLevel === 'p5' || this.studentLevel === 'p6') {
			curriculum = `
				1. use appropriate register when speaking to familiar interlocutors such as teachers and peers (e.g. May I go to the toilet?)
				2. apply grammar rules such as subject-verb agreement correctly (e.g. Peter plays football every Sunday.)
				3. connect ideas by using cohesive devices (e.g. also, at last, before)
				4. use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings
				5. open an interaction by greeting someone in an appropriate manner
				6. open an interaction by introducing oneself giving some details
				7. open an interaction by eliciting a response by asking questions or providing information on a topic (e.g. I've borrowed three very interesting books. Would you like to have a look?)
				8. maintain an interaction by controlling participation in an interaction or group activities, e.g. taking one's turn at the right moment
				9. maintain an interaction by recognizing others' desire to speak (e.g. It's my turn . . . It's your turn now.)
				10. maintain an interaction by asking and responding to others' opinions (e.g. Do you like that book? What do you think of (name of a character in the book)?)
				11. maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate
				12. maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood
				13. maintain an interaction by predicting the likely development of a conversation and responding accordingly
				14. close an interaction by using appropriate formulaic expressions (e.g. See you tomorrow.)
				15. close an interaction by giving reasons (e.g. Sorry, I have to see my teacher now.)
			`;

			if (this.studentLevel === 'p4') {
				training_material = `
					Introducing their own favourite restaurants: Students can introduce their favourite restaurants with the AI while given hints on various aspects they are expected to share, such as their favourite menu, the restaurant's location, the atmosphere of the restaurant, etc. They can be provided with interesting opportunities to talk about their familiar restaurants and elaborate on their everyday observations, strengthening their ability to express their feelings and describe a certain object, hence developing their language skills.
					Grammar:
					-Use “would” and “could” to politely express offers, requests, and ask for permission
					-Present tense + future tense because the scene is taking place while speaking, rather than describing a past restaurant experience
					Vocabulary Exercise (Easy)
					Restaurant Waiter Breakfast Lunch Dinner
					Cup Bottle Glass Plate Taste
					Sweet Spicy Juice Coffee Egg
					Can you name 5 more things you can see in a restaurant?
					Vocabulary Exercise (Difficult)
					Chef a professional cook in a restaurant
					Organic produced without the use of chemicals
					Menu the list of food available
					Condiment a substance that is used to add flavour to food
					Brunch a late morning meal between breakfast and lunch
					Omelette a dish made by mixing eggs together and frying them, often with small pieces of other food such as cheese or vegetables
					Stir-fry to fry small pieces of meat, vegetables, etc. quickly while mixing them around
					Order to ask for something to be made, supplied, or delivered, especially in a restaurant or shop
					Savour to enjoy food slowly, in order to enjoy it as much as possible
					Feast a special meal with very good food or a large meal for many people
					Serve to provide food or drinks; to work for
					Etiquette the set of rules or customs that tells you what you should do in a particular situation, e.g. at the dinner table
					Tender (of meat or vegetables) easy to cut or chew
					Crispy very crunchy in a pleasant way, like potato chips
					Buffet a meal where people serve themselves different types of food
					Short Phrases Exercise
					This restaurant is always open and serves breakfast, lunch and dinner.
					Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?
					This special curry dish tastes both sweet and spicy.
					Careful, sir. The plate is hot.
					There are 2 bottles.
					There is an extra charge if you wish to bring your own bottle of wine.
					Short Phrases Exercise (Difficult)
					I would like to order some crispy chicken to go along with my stir-fried.
					The brunch menu says the omelette is their specialty.
					This buffet is a feast, and I intend to savour every bite.
					My compliments to the chef, for preparing such a tender steak.
					It is typical restaurant etiquette to be seated before ordering.
					Single Word Answer Exercise (Easy)
					My wife and I would like a table for (讀答案) please. Two
					May I have a () of water, please? Glass
					Careful, sir. The () is hot. Plate
					We just stopped () breakfast, sir. Serving
					Enjoy your (), sir. Meal
					Single Word Answer Exercise (Difficult)
					I'd like some deep-fried (停一陣) chicken. Crispy
					All our foods are sourced locally, completely () without chemicals. Organic
					We do not serve () to minors. Alcohol
					Ketchup, mustard, and mayonnaise are different kinds of (). Condiments
					It's bad (____) to chew with your mouth open. Etiquette
					Short Phrase Answer Exercise (Easy) (
					Good morning. Would you like some water? (聽題目)
					A: I don't drink water. (不用聽)
					B: Yes, I would like some water.*
					Are you ready to order?
					A: Not yet. Could you give me a few more minutes?*
					B: I don't want to order.
					How is your meal?
					A: It is yum yum, so no thank you.
					B: It's delicious, thank you for asking.*
					Is there anything else I can get you?
					A: No, thank you.*
					B: No, I want more to drink.
					Would you like the bill?
					A: Yes, please. I would like to pay by credit card.*
					B: Yes, but I don't want to pay.
					Short Phrase Answer Exercise (Difficult)
					I'm sorry sir, but we are fully booked.
					A: I don't care. I demand to be seated!
					B: We didn't have time to make a reservation. Can you help us out?
					C: That's alright. How long do I need to wait, then?*
					D: What kind of terrible service is this?
					We don't start serving lunch until noon.
					A: I see. I'll have some breakfast instead, then.*
					B: Can't you make an exception for me?
					C: Let me speak to your manager.
					D: Give me lunch, or give me death!
					Excuse me, sir, but your credit card has been declined.
					A: That's your problem, not mine.
					B: I will pay by cash, then.*
					C: There must be something wrong with your machine.
					D: Run! We need to run now!
					AI Conversation Exercise (Easy)
					The AI is the cashier at a fast food restaurant, and the student has to order a meal. The menu would be provided on the screen, and students can choose what meal to order, as long as they successfully make their order.
					AI Conversation Exercise (Difficult)
					John, your best friend has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. He misses the local food in Hong Kong and wants you to take him to your favorite restaurant. Which restaurant would you recommend? What aspects particularly appeal to you?
					Speaking Exercise
					Read the following Passage
					Tonight Mum taught me how to make pumpkin soup.
					First, I heated the butter on a saucepan over medium heat. When it melted, Mum added some chopped onion and cooked them for two minutes. Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat. I did what happy Mum asked me to do and she prepared a small bowl for me.
					I stirred the flour, ginger, corn and some salt together in the bowl. Mum mixed some milk and an egg together, then added the mixture to the soup. We cooked the soup over a low heat for 10 minutes until it became hot.
					We also made some garlic bread, peach salad and vegetable spaghetti. We had a tasty meal tonight!
					Mum and Dad clapped their hands and called me 'Cherry the Best Cook !' I am so happy!
				`;
			} else if (this.studentLevel === 'p5') {
				training_material = `
					Discovering special places in Hong Kong: There are many special and hidden places that we have never gone to, or even heard of. Students in P4 can be provided with more information about different places that are rarely visited, such as Shek Kwu Chau, Jade Market Hong Kong, etc. hence knowing more about the place they live in.
					Grammar:
					- Ability to use "directional words" and correctly identify and describe attractions or locations
					- Present tense and future tense: the questions answered should be based on facts (to give directions to pedestrians) and need to plan future trips and place orders
					Vocabulary exercise (easy)
					Market Street Island Tour Mountain
					Hill Walk Map Journey Trip
					Ferry Attractions Tourist Crowded Countryside
					Can you name 5 methods of travel in Hong Kong?
					Vocabulary exercise (difficult)
					Reservoir a place for storing liquid, especially a natural or artificial lake providing water for a city or other area
					Shed a small building, usually made of wood, used for storing things
					Promenade a path for walking on, especially one built next to the sea
					Avenue a wide road with trees or tall buildings on both sides or a wide country path or road with trees on both sides
					Peninsula a long piece of land that sticks out from a larger area of land into the sea or into a lake
					Amble to walk in a slow and relaxed way
					Stroll to walk in a slow, relaxed manner, especially for pleasure
					Itinerary a detailed plan or route of a journey
					Destination the place where someone is going
					Budget the amount of money you are available to spend
					Landmark a building or place that is easily recognized, especially one that you can use to judge where you are
					Barren a place that is unable to produce plants or fruit
					Urban relating to a city or town
					Remote far away in distance or time, or not closely related
					Vacation a time when someone is free to do what they want, such as travel or relax
					Short phrase exercise (easy)
					The market is at the end of the street.
					Let's take a walk around the countryside.
					I hope the attractions aren't too crowded.
					The island can only be reached by ferry.
					I can't find the main street on the map.
					Short phrase exercise (difficult)
					The peninsula is very remote and difficult to reach.
					I managed to plan an itinerary for our vacation according to our budget.
					Taking a stroll on the promenade clears my head.
					The shed over there is an urban landmark.
					We have finally arrived at our destination.
					Single word answer (easy)
					The only way to get to Cheung Chau is by (_____). ferry
					It's not the destination so much as the (_______). journey
					Causeway Bay is in Hong Kong (______). Island
					We can buy traditional clothes in Stanley (_____). Market
					The International Commerce (_____) is the tallest building in Hong Kong. Centre
					Single word answer (difficult)
					The area over there is a (_______) wasteland. barren
					People get their arms stuck in Cheung Po Tsai (_____) Cave. Pirate
					You can find delicious cuisine in Chungking (______). Mansion
					The Victoria (______) is a unique scenery of Hong Kong. Harbour
					I have never actually visited the Plover Cove (______). Reservoir
					Short Phrase answer exercise (easy)
					Should we take the bus or the MTR?
					A: It doesn't matter.
					B: Let's take the bus.*
					The Tai Tong EcoPark is beautiful.
					A: Yes, the trees are terrible.
					B: I agree. The view is stunning.*
					Are you tired yet?
					A: Yes, this trail is too steep.*
					B: Yes, let's walk faster.
					Should we take some photos?
					A: Yes, please. Did you bring your camera?*
					B: No! It's dumb to take photos.
					Can you see the sunset?
					A: Yes, it's breathtaking.*
					B: No, I don't really care about it.
					Short Phrase answer exercise (difficult)
					Excuse me, can you show me the way to the promenade?
					A: Ask another person.
					B: Yes, sure. Just turn left and walk across the park, and you will see it.*
					C: I'm busy, just go away.
					D: The Big Buddha is very near.
					What can we buy at the Jade Market?
					A: We can buy accessories there.*
					B: I don't know.
					C: Do your own research, I'm not going to answer your question.
					D: We can buy some Marvel movie tapes.
					Sorry, you are not allowed to enter this building unless you have a permit.
					A: No way! I've planned this trip for so long.
					B: Okay, bye-bye.
					C: I forgot about this. Can you teach me how?*
					D: Are you kidding me?
					Please do not touch any exhibits with your hand.
					A: I paid for the ticket! I have the right!
					B: Those exhibits are disgusting. Ew!
					C: Ok, Thank you for the reminder.*
					D: You are welcome.
					There are a lot of interactive devices here in Tai Kwun.
					A: Ok. The devices are shut down.
					B: Wow! That is impressive. Should we give them a try?*
					C: Tai Kwun is too far. I'm lazy.
					D: Yes. Tai Kwun used to be a police station.
					AI Conversion exercise (easy)
					The AI is a pedestrian who lost their way. A map of a small area near Tsim Sha Tsui Promenade is provided. The AI will ask for directions to a random place. Students should be able to guide them from their spot to their destination.
					AI Conversion exercise (difficult)
					Speaking of Hong Kong's famous attractions, people always think of the attractions in the city center, such as Victoria Peak and the Avenue of Stars. To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Given this, what do you think are the disadvantages of this suggestion?
					Speaking Exercise 
					Read the following passage
					Whenever I'm on holiday, I want to see the sights.
					So I find the mode of transport, to take me where I like.
					Sometimes it's an aeroplane, if I'm going far.
					But if it's a short distance, I may just take a car.
					For the times I'm in New York, I'll take a yellow cab.
					Or if I go to Hong Kong, It's the MTR I'll grab.
					In the Alps in Switzerland, it snows, so I can ski.
					But if I'm going hiking, My feet are good enough for me!
				`;
			} else if (this.studentLevel === 'p6') {
				training_material = `
					Environmental Awareness: This topic allows students to learn about environmental problems and issues. It encourages critical thinking and conversational debating skills while fostering environmental responsibility. Additionally, discussing environmental solutions develops innovative thinking and creativity.
					Grammar: 
					-the use of “should”, “would” and imperative to give suggestions and make requests  
					-Ability to use different tenses, such as past tense and present tense, to describe past events, facts, challenges, phenomena, expected results, etc.  
					Vocabulary Exercise (Easy) 
					Recycle
					Waste
					Environment
					Awareness
					Plastic
					Pollution
					Issue
					Industry
					Plastic
					Litter
					Save
					Extinction
					Rinse
					Temperature
					Habitat 
					Name 3 ways you can help the environment.
					Vocabulary Exercise (Difficult)
					Conservation protection or restoration of the natural environment 
					Replenish restore something to a former level 
					Climate weather conditions 
					Disposal the process of getting rid of garbage 
					Activism action to bring about social and political change 
					Deforestation the cutting down of trees in a large area 
					Felling cutting down a tree 
					Volunteer a person who does something, especially helping other people, willingly and without being forced or paid to do it 
					Globalization the increase in trade around the world, especially by large companies producing and trading goods in many different countries 
					Impact a powerful effect that something, especially something new, has on a situation or person 
					Biodiversity the number and types of plants and animals that exist in a particular area or the world generally, or the problem of protecting this 
					Campaign a planned activity that is intended to achieve a particular aim 
					Consumption the act of using, eating, or drinking something 
					Plantation a large farm, especially in a hot part of the world, on which a particular type of crop is grown 
					Alleviate to make something bad such as pain or problems less severe 
					Short Phrases Exercise (Easy) 
					We should all recycle. 
					Global warming is a serious issue. 
					There is so much litter in the oceans. 
					Is there something we can do to save animals from extinction? 
					Waste not, want not. 
					Short Phrases Exercise (Difficult) 
					It would take years for marine life to replenish. 
					There is a campaign for the building of a natural conservation park. 
					By reducing consumption, we also reduce the amount of disposal. 
					Climate change may yet prove to be reversible. 
					During weekends, I volunteer to work at plantations. 
					Single Word Answer Exercise (Easy) 
					For December, the (_______) is unusually high. temperature 
					Those (_____) bins have different colours for different waste. recycle 
					Instead of (______) straws, we now serve bamboo straws. plastic 
					We should turn off the lights when we leave to (____) energy. save 
					The coal mining (________) is on a timer. industry 
					Single Word Answer Exercise (Difficult) 
					Climate (______) and protests must be purposeful, not just loud. activism 
					We don't realize the (______) we have on the environment. impact 
					Is there a way to (_______) the symptoms of global warming? alleviate 
					(______) is when we destroy forests to use the wood and the land. Deforestation 
					Our entire research facility is at your (______). disposal 
					Short Phrase Answer Exercise (Easy) 
					Would you take a leaflet? 
					A: Get your hand out of my face! 
					B: Yes, I would. Thank you.* 
					You left the tap running yesterday. 
					A: So what? Who cares? 
					B: I'm sorry, it won't happen again.* 
					Should we buy energy-saving light bulbs? 
					A: Yes, we should go buy some.* 
					B: Yes, we should not use any. 
					Our electric bill this month is too high! 
					A: Let's think of ways to save electricity.* 
					B: Let's make it go even higher next month! 
					Make sure you use the correct bag for recycling. 
					A: Don't worry, I will be careful.* 
					B: Do worry, I will be careful. 
					Short Phrase Answer Exercise (Difficult) 
					I think it's too late to save the Earth, we should go to Mars instead. 
					A: You're right. Let's buy tickets for the next space shuttle. 
					B: It's not too late, as long as we all do our part.* 
					C: I'm from Mars, and it's amazing there. We should all go! 
					D: Mars is too far away. Let's go to the moon instead.  
					Do you think scientists can find a way to reverse global warming? 
					A: No, scientists are all paid off by companies to spout nonsense. 
					B: Yes, they are all incompetent and cannot get anything done. 
					C: No, scientists are crazy and don't have Earth's best interest at heart. 
					D: Yes, but devising a way is simple. Implementing it is the hard part.*  
					If animals cannot survive without their habitats, then they shouldn't survive at all. 
					A: I agree. I believe in the survival of the fittest. 
					B: I disagree. Our right to live does not give us the right to take away animals' right to survive. 
					C: I agree. Animals have to adapt or become food for our bellies. 
					D: I disagree. Animals can survive anyway, so it doesn't matter.  
					AI Conversation (Easy) 
					The student is handing out pamphlets to people, and the AI is a person receiving the pamphlet. The student has to convince and persuade the AI to read the pamphlet and be aware of the environmental impact humans have on Earth. 
					AI Conversation (Difficult)
					Imagine the Earth could talk, what do you think it would most likely say to you? What can you do to make the world a better place? 
					Speaking Exercise 
					Read the following passage 
					Dear Editor, 
					My name is Barry. I study Primary Six in Sunrise Primary School. I am writing this letter to make more people aware of the air quality in Hong Kong.  
					I have been reading information about pollution in Hong Kong for a science project. Hong Kong has serious pollution. Sometimes we cannot see across the harbour. My grandfather has some breathing problems due to the poor air quality. I hope the government can do something to make the air quality better.  
					We can use the public transport more instead of cars. Cars will give out a lot of harmful gases. Hong Kong has a beautiful environment, so let's save Hong Kong.  
					Yours sincerely, 
					Barry Kwok 
				`;
			}
		} else if (this.studentLevel === 's1' || this.studentLevel === 's2' || this.studentLevel === 's3') {
			curriculum = `
				1. convey ideas and information in conversations or discussions
				2. use words and expressions appropriate to the context
				3. use appropriate discourse markers
				4. use correct pronunciation, intonation and register for different purposes
				5. seek and give clarification, explain what information one requires and why, rephrase one's questions when necessary, sum up points made and redirect the discussion when the need arises
				6. make a balanced contribution without either dominating the discussion or being too reticent
				7. express, elicit and respond to ideas, opinions and feelings in a group discussion
			`;

			if (this.studentLevel === 's1') {
				training_material = `
					This topic helps students better navigate academic challenges and develop problem-solving skills. It also encourages students to discuss study obstacles and solutions, as well as explore peer pressure and seek help from senior figures. It would also enhance rational thinking by analyzing problems critically and developing effective solutions.
					Grammar: Use of SVO
					Vocabulary Exercise (Easy)
					Examinations Homework Assignment Sponsorship Stressful
					Anxiety Peer Grades Tuition Failure
					Expectation Competition Support Exercises Break
					Name 5 types of school work you encountered.
					Vocabulary Exercise (Difficult)
					Productivity The rate at which a person, company, or country does useful work
					Motivation Enthusiasm or willingness to do something
					Distraction Something that prevents someone from giving their attention to something else
					Procrastination The act of delaying something that must be done
					Time-management The practice of using the available time you have in a useful and effective way
					Schedule A plan that gives events or activities and the times that they will happen or be done
					Counselor Someone who is trained to listen to people and give them advice about their problems
					Communication The act of talking about your thoughts and feelings to help people to understand them
					Meditation The act of giving your attention to only one thing, either as a religious activity or as a way of becoming calm and relaxed
					Guidance Help and advice about how to do something or to deal with problems
					Concentration The act of focusing all your attention on a particular object or activity
					Relaxation A pleasant activity that makes you calm and less worried
					Burnout Extreme tiredness or mental or physical illness caused by working too hard or trying to do too much
					Reward Something that is given in return for good service, effort, or achievement
					Visualization The process of forming a picture of something or someone in your mind
					Short Phrases (Easy)
					Pay attention in class.
					I enjoy English lessons.
					I am nervous about the exams.
					We couldn't agree on a topic for our group project.
					My dog ate my homework.
					Short Phrases (Difficult)
					My teachers will regard me as a failure if I don't do well on the test.
					I have scheduled an appointment for you with the school counselor.
					Stop procrastinating and get on with your work!
					You're distracting your sister from her studies.
					Sometimes, I relax by visualizing what I'd like to be in the future.
					Single Word Answer Exercises (Easy)
					We should not give in to _____ pressure. peer
					The teacher gave us too much _________. homework
					You should ______ your friends when they are down. support
					I need to get more _______ so I can get stronger. exercises
					Having good ______ isn't everything. grades
					Single Word Answer Exercises (Difficult)
					Some students need the proper ________ to succeed. motivation
					A _______ calms your mind and focuses your senses. meditation
					Don't work too hard, or you'll suffer from ______. burnout
					The problem we have is a lack of proper _________. communication
					I would like to claim the _______ for finding your lost wallet. Reward
					Short Phrase Answer Exercises (Easy)
					We've been studying for an hour. Would you like a rest?
					A: Yes, a short break will do me wonders.*
					B: No, I can keep going until my eyes bleed!
					Can you afford this university? It has a high tuition fee.
					A: I think so after I apply for sponsorship.*
					B: Don't worry, we're gonna be rich after I hit the casino.
					How should I deal with my anxiety levels?
					A: Just ignore it, it will go away.
					B: Try taking deep breaths and talking with someone.*
					It's Friday. Should I do my homework now or later?
					A: Do it now, so you can enjoy your weekend.*
					B: It's Friday, enjoy yourself! Your homework will still be there tomorrow.
					I had good grades last time. Does that mean I don't need to study?
					A: Why study when you can play video games instead?
					B: You need to study in order to keep up your grades.*
					Short Phrases Answers (Difficult)
					How could I communicate better as a group leader?
					A: Just keep quiet and leave everyone else alone.
					B: Be as loud as possible and shout if you want.
					C: Make sure everyone is heard and let them feel important.*
					D: Who wants to be a leader? Step down!
					I'm looking for a guidance counselor. Do you know one?
					A: Her office is back there at the end of the hallway.*
					B: I can be your guidance counselor if you want.
					C: You don't need one. Just keep being you!
					D: Do you need guidance? What a lost little lamb.
					I think I'm suffering from burnout. I feel mentally exhausted.
					A: Be quiet or I will literally burn you.
					B: You need a vacation to clear your mind and relax.*
					C: I don't care. Do whatever you want.
					D: Push through it, and you won't feel exhausted anymore.
					We need to communicate better. I don't understand what you mean half the time!
					A: I don't understand what you mean either!
					B: That just means we understand each other fine the other half.
					C: You're right. We need to be clear and ask questions if we're not sure.*
					D: We're better off not communicating at all.
					How do I find my motivation in life?
					A: You don't. Just give up.
					B: Just wander through life aimlessly. No one cares.
					C: Copy what other people do, and you'll be fine.
					D: Take your time, explore your interests, and don't give up.*
					Conversation
					Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Which do you think is more effective? How do you like to study?
					Speaking Exercise
					Read the following passage
					Classroom Rules for 1A
					1. Be polite, courteous and respectful at all times. Treat others the way you want to be treated.
					2. If you have something to share with the class or a question to ask during the lesson, raise your hand. Wait until the teacher calls on you.
					3. Respect other students' opinions. Listen quietly when others are speaking. You will have your turn to speak, too.
					4. Come to class on time and be prepared for each lesson. Have what you need for each lesson on your desk before the teacher comes in.
					5. Complete all homework assignments neatly and on time.
					6. Respect each other's property. Do not touch what is not yours.
					7. Help keep our classroom clean and tidy. Put away things after you use them and throw rubbish into the bin.
					8. Do your best and have fun!
				`;
			} else if (this.studentLevel === 's2') {
				training_material = `
					This topic promotes awareness of justice and legal and ethical decision-making. Students can discuss different types of crime, their causes, and their consequences. Students can analyze real cases and debate crime prevention, as well as understand the impact of crime on individuals and societies. It also provides ample opportunity to discuss punishment, rehabilitation, and societal responses to crime. 
					Grammar: Understand Parts of Sentences: subjects, predicates, clauses, and objects 
					Vocabulary Exercise (Easy) 
					Criminal 
					Gang  
					Peer  
					Murder  
					Theft  
					Rape  
					Drugs  
					Assault  
					Burglary  
					Imprisonment  
					Kidnapping  
					Commit  
					Abuse  
					Guilty  
					Robbery  
					Name 3 kinds of victimless crime. 
					Vocabulary Exercise (Difficult) 
					Mimic To copy how a particular person usually speaks and moves 
					Reintegration The act of rejoining or mixing with a group of people or society  
					Sentence A punishment given to a person or organization after they have been found guilty of doing something wrong 
					Juvenile A young person who is not yet old enough to be considered an adult  
					Trafficking The act of buying or selling goods or people illegally  
					Smuggling  The act of taking things or people to or from a place secretly and often illegally 
					Rehabilitation The process of returning to a healthy or good way of life  
					Death-penalty The punishment of death given by a court for very serious crimes 
					Fine The amount of money that has to be paid as a punishment for not obeying a rule or law 
					Confinement The situation in which a person or animal is kept somewhere, usually by force  
					Forfeiture The loss of rights, property, or money, especially as a result of breaking a legal agreement 
					Compensation Money that is paid to someone in exchange for something that has been lost or damaged or for some problem 
					Probation A period of time when a criminal must behave well and not commit any more crimes to avoid being sent to prison 
					Prevention The act of stopping something from happening or of stopping someone from doing something 
					Victim A person harmed, injured, or killed as a result of a crime, accident, or other event or action 
					Short Phrases Exercise (Easy) 
					We should obey the law. 
					He becomes a gang member. 
					You have to bear the consequences. 
					Don't do bad things because of peer pressure. 
					There was a theft at my neighbor's house. 
					Short Phrases Exercise (Difficult) 
					The man was arrested for smuggling gold across the border. 
					She was tried again for breaching her probation order.  
					He is the victim in this case and needs psychological treatment. 
					Please do prevention work before something happens. 
					He was the first person in this town who was given death-penalty.  
					Single Word Answer Exercise (Easy)
					We felt (________) skipping class today. guilty  
					This lady has an extensive (_________) record. criminal 
					We should never try (______) in any situation. drugs 
					Police charged her with common (________). assault  
					They divorced because the husband (______) her. abused 
					Single Word Answer Exercise (Difficult) 
					The doctor is required to provide (____________) for the client's incident. compensation 
					The (___________) Court is set up for persons under the age of sixteen. Juvenile  
					If you are caught smoking, you may have to pay a (_____) of HK$1,500. fine 
					The judge has (__________) him to five years in prison. sentenced 
					He finally completed alcohol (____________) with determination. rehabilitation. 
					Short Phrase Answer Exercise (Easy) 
					Excuse me, did you witness the robbery?   
					A: Leave me alone.   
					B: Yes, I can offer help. * 
					You are my friend, you should keep the theft a secret.  
					A: I'm sorry but I have to call 999. * 
					B: Of course, I don't want to lose you!  
					No matter how hard life is, we should never commit a crime.  
					A: You are right, or else we will lose a bright future. * 
					B: Why? I think it will be exciting. 
					What are you doing to the car?  
					A: There is some dirt on the door. I am cleaning it.  * 
					B: It's none of your business.  
					Some peers ask me to join the gang. Should I?  
					A: You can. It is a symbol of power.  
					B: I don't think so. You should consider it twice.* 
					Short Phrase Answer Exercise (Difficult) 
					Everyone deserves a second chance. We should help prisoners with reintegration. 
					A: We should always remember who they are to provide more support.  
					B: People make mistakes. Don't discriminate against them.* 
					C: I agree but it's not on me. The government will do its part.  
					D: Remember, a leopard can't change its spots.  
					The company claimed to provide us with free travel. It seems attractive to me.  
					A: You should remain cautious as trafficking cases are on the rise. * 
					B: You should give it a try as it is a very rare opportunity.   
					C: You should share the information with me so that we can go together.  
					D: You should be grateful for such pennies from heaven.   
					Do you think confinement is a great penalty? 
					A: Yes, I can play video games at home 24/7 and do whatever I want. 
					B: Yes, it's not a big deal to me.  
					C: No, it is worse than being in prison as there would be no social interaction at all.*  
					D: No, releasing and pardoning everyone is a great penalty. 
					What can we do to protect our communities from crime? 
					A: Keep our door open to create a friendly environment.  
					B: Each man is equipped with a gun.
					C: Lock everyone down and cannot go out.
					D: Carry out prevention work, such as increasing police patrols.* 
					What should we do if we witness a murder? 
					A: Scream loudly to scare him away. 
					B: Grab a weapon and rush into the crime scene to stop him. 
					C: Pretend nothing happened and walk away. 
					D: Stay calm and report the crime in a safe place.* 
					AI Conversation  
					Do you think criminals deserve forgiveness? Should prisons be punitive or rehabilitative? What if the criminal committed a very serious crime, such as murder? Do such criminals deserve a second chance? Where do you draw the line between punishment and rehabilitation? 
					Speaking Exercise 
					Read the following passage 
					A strange and mysterious thing was happening in Paris. The famous French city is home to some of the most expensive items in the world. Lately, however, the items had been disappearing. First, the famous oil portrait of Mona Lisa housed in the Louvre museum went missing. Next, the world's largest diamond vanished from a jewellery shop. Detective Beaumont had a hunch that a thief was stealing these items.
					Detective Beaumont believed the next item to be taken would be the oldest bottle of wine in France. Bottled in the year 1789, when the French Revolution broke out, this bottle had survived a lot of events, including two World Wars. Acting on his hunch, Detective Beaumont waited outside the famous restaurant where the bottle was kept. Soon enough, a man dressed in all black entered the restaurant. Detective Beaumont surprised the thief on his way out with the bottle.
					A chase began as the thief ran away as fast as he could. Detective Beaumont was not happy to be chasing the thief through the narrow streets of Paris. Soon, the famous Eiffel Tower was up ahead. Not knowing where to turn, the thief began to climb up the structure measuring three hundred and twenty-four metres high. Detective Beaumont hated climbing even more than running. Luckily, the thief couldn't climb up any further than a few metres. More police officers arrived on the scene and arrested the man. Detective Beaumont received commendation for his work and all the items were safely returned to where they belonged.
				`;
			} else if (this.studentLevel === 's3') {
				training_material = `
					This topic allows students to draw inspiration from impactful persons and understand the traits of success. It provides opportunities to discuss successful individuals and their journeys to success, as well as explore the diversity of success and personal achievements. Students can analyze the factors contributing to success and set personal goals for themselves. 
					Grammar: Use Combination Pronouns Correctly (He and I vs He and Me) 
					Vocabulary Exercise (Easy) 
					Passion 
					Confidence 
					Goals 
					Belief 
					Overcome  
					Creativity  
					Improvement  
					Effort  
					Patience  
					Brave  
					Opportunities  
					Positive  
					Responsible   
					Desire  
					Learn  
					Name 5 antonyms of the above words. 
					Vocabulary Exercise (Difficult) 
					Persistence The fact of continuing to try to do something despite difficulties  
					Risk-taking Taking actions that might have unpleasant or undesirable results   
					Resilience The ability to be happy again or recover from difficult or bad things that have happened  
					Optimistic Hoping or believing that good things will happen in the future  
					Self-discipline The ability to control yourself and to make yourself work hard or behave in a particular way 
					Satisfaction   The pleasure you feel when you do something you want or need to do  
					Dedicate To give all of your energy, time, etc. 
					Prioritize  Arrange in order of importance so that you can deal with the most important things before the others  
					Commitment Willingness to give your time and energy to a job, activity, or something that you believe in 
					Leadership The set of characteristics that make a good leader 
					Problem-solving The process or act of finding a solution to a problem 
					Profession A job that required advanced education and training, and involved intellectual skills 
					Master To learn how to do something well  
					Accomplish To finish something successfully or to achieve something 
					Milestone An important event in the development or history of something or someone's life  
					Short Phrases (Easy) 
					You need to build up your confidence. 
					Ms. Ho's lessons aren't very creative. 
					I will brave the sea and face its harm. 
					Stay positive, your opportunity will come soon. 
					She has vowed to overcome her weakness and keep improving. 
					Short Phrases (Difficult) 
					You have shown great resilience by standing up to your bullies. 
					It's difficult to stay optimistic in the face of impending danger. 
					I take pride in my profession. 
					This is an important milestone in his career. 
					It takes a lot of discipline and commitment to master a new skill. 
					Single Word Answers (Easy) 
					It is my (______) that whatever doesn't kill you makes you stronger. belief 
					A mother would (_______) her children's well-being over her own. prioritize 
					The mayor has (_________) a lot of time and money to build a dam. dedicated 
					It brings me great (________) when a plan comes together. satisfaction 
					I have no (______) to be the leader of a country. desire 
					Single Word Answers (Difficult) 
					It took forever, but I finally (________) my goals. accomplished 
					Don't be afraid of (___________), as long as the benefits outweigh it. risk-taking 
					Donald wants to be in charge, but he has no (_________) skills. leadership 
					How can Mark solve a Rubik's Cube when he has no (__________) skills? problem-solving 
					Lindsay never (______) and tends to give up easily. persists 
					Short Phrase Answers (Easy) 
					Was that man brave for driving over the speed limit without wearing a seatbelt? 
					A. Yes! A brave man is not afraid of anything! 
					B. No, that wasn't brave, that was stupid.* 
					Please be patient. The doctor will see you soon. 
					A. Alright, I guess I will have to wait then.* 
					B. It hurts too much! Let me see the doctor now! 
					Can you be responsible for your sister? 
					A. Yes, I will look after her well-being.* 
					B. No, don't blame me if she hurts herself. 
					Kevin here is of the belief that the Earth is flat. 
					A. He is right! Have you actually seen that the Earth is round? 
					B. He will learn otherwise if he opens his mind and thinks rationally.* 
					My son is very creative, but he takes things too far. 
					A. Punish him! Make him conform! 
					B. Let him explore, but also teach him about proper boundaries.* 
					Short Answers Exercise (Difficult) 
					I think I dedicated too much time to my work commitments. 
					A. Nonsense! Keep working harder and harder! 
					B. Quit your job, then. Relax and have fun all year round! 
					C. You should try and find a balance between your work and life.* 
					D. That's because you're a workaholic, and I'm filing for divorce. 
					How does she stay so optimistic all the time? 
					A. Because she's a featherbrain and an airhead. 
					B. It's because she sees the best in people and every situation.* 
					C. Maybe she's on drugs and is high all the time. 
					D. That's because you're a downer yourself. 
					What do you feel when you finally accomplish something? 
					A. I feel a tired sort of satisfaction.* 
					B. Nothing, really. I feel empty. 
					C. I never accomplished anything, so I don't know. 
					D. I feel like I want to smash and destroy something. 
					Do you think Joe is a good leader? 
					A. Yes, even though I'm not sure who Joe is. 
					B. Yes, he can do no wrong and everything he says is scripture. 
					C. No, he is so ugly that I just want to punch his face. 
					D. No, he doesn't have good leadership and communication skills.* 
					Which profession requires a lot of problem-solving? 
					A. Engineering. They need to solve a lot of architectural problems.* 
					B. Taxi drivers. They need to figure out how to drive a car. 
					C. Waiters. They need to deal with rude customers. 
					D. Fashion designers. They need to create new clothes. 
					Conversation 
					Share your perspectives on a famous successful person. What traits did that person have? Of that person's journey to success, how much of it was that person's own will and perseverance, and how much of it was luck? What can you take away from their journey to add to your own?  
					Speaking Exercise 
					Read the following passage 
					Dear Jackie Robinson, 
					You are the most famous baseball legend in history. In addition to being a great baseball player, you broke the colour barrier in American sports as the first black baseball player on a major league team. Living in a time of segregation, it must have been frustrating to be denied a position on a major league team because of the colour of your skin. Only white players were allowed to play on the well-known teams. Black players had to play on separate teams that paid their players a lot less than those on the white teams. 
					Additionally, the all-black teams did not receive as much sports coverage as the white teams, even though the members of those teams played equally as well as those of the all-white 
					teams. Luckily, the Brooklyn Dodgers finally recruited you to play for their team in 1947. With your playing, you showed that people of any colour deserve the chance to play together.  
					As the first black player on a major league baseball team, you have inspired a lot of people. You did not let other people's opinions and behaviour prevent you from pursuing your dream. Overcoming many challenges, you led the way for other black athletes to play in the major leagues. Children have someone to look up to as a role model. Thanks to your dedication and 
					persistence, American sports teams are no longer segregated, and people of any colour can play on the teams. You should be very proud.  
					Yours truly, 
					Rick 
				`;
			}
		}

		let background = {
			role: "system",
			content: `This is the English Speaking skills curriculum from the Education Bureau in Hong Kong (Known as the curriculum) please remember it. Present information, ideas and feelings clearly and coherently: ${curriculum}`
		};
		let material = {
			role: "system",
			content: `This is the training material. ${training_material}`
		};
		let prompt = {
			role: "system",
			content: `Generate 1 question base on the curriculum point number you chose and the topic. Please refer to the training material, be creative. Put the curriculum point number inside <> and then put your question after that.`
		};
		this.promptLog.push(background);
		this.promptLog.push(material);
		
		if (this.objectiveUsed.length > 0) {
			let limitation = {
				role: "system",
				content: `Choose a curriculum point number according to the training material. Do not choose the curriculum point number which is included in ${this.objectiveUsed}. Try to use different English speaking skills curriculum.`
			};
			this.promptLog.push(limitation);
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			const content = JSON.parse(res).choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			this.objectiveUsed.push(EDBNumber)
			console.log(EDBNumber, this.objectiveUsed)
			let question = content.replace(/<.*?>\s*/g, '');

			if (res) {
				let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
				if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserFirstEDB(reply: string, score: any) {
		const prompt = {
			role: "system",
			content: `Rate students performance within 10 to 100 according to the point your selected, at least 10 marks should be given and only give the mark.`,
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);

			const curriculumScore = JSON.parse(res).choices[0].message.content;
			let error = JSON.parse(res).choices[0].message.error
			if (error) {
				this.promptLog.pop()
				return
			}

			this.EDBScore[this.objectiveUsed[this.objectiveUsed.length - 1] - 1] = parseInt(`${curriculumScore}`);
			let curriculum = [
				{number: this.objectiveUsed[this.objectiveUsed.length - 1]},
				{score: Math.round(parseInt(`${curriculumScore}`) / 10)}
			]
			console.log(this.EDBScore, curriculum);

			let resPrompt = { role: "system", content: "", };
			if (res) {
				if (this.userEDBCounter >= this.userEDBLimit + this.userEDB_retry) {
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, you must add *{retry} in your reply and encourage student to retry.
							Your reply should related to the last question you asked. No question allowed.
							Do not give any advice here. For example: "Many prefer chicken nuggets instead" should be avoided.
						`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can. 
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) Do not ask student to retry and end this section. 
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, tell him tactfully that we need to move forward to another section.
						`;
					}
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						if (content.includes("*{retry}")) {
							content = content.replace("*{retry}", "");
							this.userEDB_retry++
							need_retry = true
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == true) {
									resPrompt = {
										role: "system",
										content: `Try to rephrase previous question you asked and make it instructive. For example:"What is your favourite food? How do you think of fried chicken and burgers?". Put the curriculum point number inside <> and then put your question after that.`
									}
								}
								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								console.log(EDBNumber, this.objectiveUsed)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						} else {
							this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
							if (res) {
								let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
								if (audioRes) setTimeout(() => {
									this.userFirstEDBPass = true;
									this.updateResult(curriculumScore, score, curriculum);
									this.defaultQuestionSession();
								}, audioRes.duration * 1000);
							}
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					console.log("hi")
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, you must add *{retry} in your reply and encourage student to retry.
							Do not give any advice here. For example: "Many prefer chicken nuggets instead" should be avoided.`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) Do not ask student to retry.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, tell him tactfully that we need to move forward to another question.`;
					}

					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						if (content.includes("*{retry}")) {
							content = content.replace("*{retry}", "");
							this.userEDB_retry++
							need_retry = true
						}
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == false) {
									resPrompt = {
										role: "system",
										content: `Generate 1 question base on the English speaking skills curriculum. Please refer to the training material, be creative. Do not use the same question in the training material. Put the curriculum point number inside <> and then put your question after that.`
									}
									// Share your idea on that question.
									// this.userEDB_retry_limit++
								} else {
									resPrompt = {
										role: "system",
										content: `Try to rephrase previous question you asked and make it instructive. For example:"What is your favourite food? How do you think of fried chicken and burgers?". Put the curriculum point number inside <> and then put your question after that.`
									}
								}

								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								console.log(EDBNumber, this.objectiveUsed)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									this.updateResult(curriculumScore, score, curriculum);
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async defaultQuestionSession() {
		this.isAnswerDefaultQuestion = true;
		try {
			if (this.questionIndex < 1) {
				const prompt = {
					role: "system",
					content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start the exercise about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 15 words.`
				};
				this.promptLog.slice(0, 1);
				this.promptLog.push(prompt);

				const res = await this.sentPromptApi(this.promptLog);
				const content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.askDefaultQuestion(), audioRes.duration * 1000);
				}
			} else {
				this.askDefaultQuestion();
			}
		} catch (error) {
			console.log(error)
		}
	}

	async askDefaultQuestion() {
		let intro = "";
		let question = this.parent.defaultQuestion[this.questionIndex].name;
		let reading = this.parent.defaultQuestion[this.questionIndex].reading;
		let audioContent;

		if (this.parent.subType === "oralFillIn") intro = `Please answer the missing part of the phrase:`
		else if (this.parent.subType === "oralMc") intro = `Which response would you choose, if someone says:`
		else if (this.parent.subType === "oralParagraph") intro = `Please read this:`
		else if (this.parent.subType === "oralSentence") intro = `Please read the phrase:`
		else if (this.parent.subType === "oralVocab") intro = `Please read the vocab:`
		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

		if (this.parent.subType === "oralFillIn") {
			const split = question.split("(");
			let firstPartReadingText = split[0].trim();
			let secondPartReadingText = split[1].split(")")[1].trim();
			let fillInTypeTopic = `${intro} ${firstPartReadingText} ${''} ${secondPartReadingText}`;

			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
			}
		} else if (this.parent.subType === "oralMc") {
			audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
		} else {
			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: intro, question: `${intro} ${question}` };
			}
		}

		let audioRes = await this.playAudio(audioContent.intro, audioContent.question, true);
		if (audioRes) setTimeout(async () => {
			this.parent.totalPageNumber = this.parent.defaultQuestion.length;
			this.parent.pageIndex = this.questionNumber;
			if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {

					for (let content of this.get_display_choices(this.parent.defaultQuestion[this.questionIndex].choice)) {
						let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
						await audioRes.is_finish;
					}

					this.recordEnable = true;

					// let content = `A:${this.parent.defaultQuestion[this.questionIndex].choice[0]}`
					// let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					// if (audioRes) setTimeout(async () => {
					// 	let content = `B:${this.parent.defaultQuestion[this.questionIndex].choice[1]}`
					// 	let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					//
					// 	if (audioRes) setTimeout(async () => {
					// 		this.recordEnable = true;
					// 	}, audioRes.duration * 1000)
					// }, audioRes.duration * 1000)
				} else {
					for (let content of this.get_display_choices(this.parent.defaultQuestion[this.questionIndex].choice)) {
						this.conversation.push({role: "assistant", content: content});
					}
					// let contentA = `A:${this.parent.defaultQuestion[this.questionIndex].choice[0]}`
					// this.conversation.push({ role: "assistant", content: contentA });
					// let contentB = `B:${this.parent.defaultQuestion[this.questionIndex].choice[1]}`
					// this.conversation.push({ role: "assistant", content: contentB });

					this.recordEnable = true;
					this.scrollToBottom();
				}
			} else {
				this.recordEnable = true;
			}
		}, audioRes.duration * 1000);
	}

	async checkPronunciation(content: any, score: any) {
		let d_prompt = { role: "system", content: "", };
		let correct = false
		if (content.DisplayText.replace(".", "") == this.parent.defaultQuestion[this.questionIndex].name || content.DisplayText == this.parent.defaultQuestion[this.questionIndex].name) {
			correct = true
		} else {
			d_prompt.content = `
				(important) reply "<correct>" if the student's answer is correct else reply "<wrong>".
				Correct answer does not have to be exactly the same as question answer.
				reply "<correct>" or "<wrong>" only. Do not rely anything else.
				The question answer is "${this.parent.defaultQuestion[this.questionIndex].name}"
				The student's answer is "${content.DisplayText}"
			`
			this.promptLog.push(d_prompt)
			const d_res = await this.sentPromptApi(this.promptLog);
			let d_res_obj = JSON.parse(d_res);
			if (d_res_obj.choices[0].message.error) {
				return
			}
			let d_content = d_res_obj.choices[0].message.content;
			if (d_content.includes("<correct>")) {
				correct = true
			}
		}
		this.parent.defaultQuestion[this.questionIndex]['pass_status'] = (correct ? 'pass' : 'failed')

		let prompt = { role: "system", content: "", };
		if (this.parent.subType === "oralVocab") {
			prompt.content = `
			The question type is oral vocabulary.
			The question answer is "${this.parent.defaultQuestion[this.questionIndex].name}"
			The student's answer is "${content.DisplayText}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralSentence") {
			prompt.content = `
			The question type is oral sentence.
			The question is "${this.parent.defaultQuestion[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content.DisplayText}"
			The student's pronunciation score is "${content.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralFillIn") {
			let userAnswer = content.NBest[0].Lexical.charAt(0).toUpperCase() + content.NBest[0].Lexical.slice(1);

			prompt.content = `
			The question type is oral fill in.
			The question is "${this.parent.defaultQuestion[this.questionIndex].name}"
			The question answer is "${this.parent.defaultQuestion[this.questionIndex].answer}"
			The student's answer is "${userAnswer}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralMc") {
			let choices = this.get_display_choices(this.parent.defaultQuestion[this.questionIndex].choice).join("\n");

			prompt.content = `
			The question type is oral multiple choice.
			The question is "${this.parent.defaultQuestion[this.questionIndex].name}"
			The choices are: ${choices}
			The question answer is "${this.parent.defaultQuestion[this.questionIndex].answer}"
			The student's answer is "${content.DisplayText}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralParagraph") {
			prompt.content = `
			The question type is oral paragraph.
			The question answer is "${this.parent.defaultQuestion[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content.DisplayText}"
			The student's pronunciation score is "${content.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		}
		let is_last_question = this.questionIndex === this.parent.defaultQuestion.length - 1;
		if (is_last_question) {
			this.promptLog.push({
				role: "system",
				content: `This is the last question of this exercise.`
			});
		}
		this.promptLog.push(prompt);
		this.promptLog.push({
			role: "system",
			content: `
				The student answer is determined as ${correct?"correct":"incorrect"}.
				If you need to encourage the student, do not use the same words before to encourage the student. Please try to use different words or sentences to encourage or point out the mistake of the student. If the student needs attention, use a sentence or two to comfort him or her.The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.
				Don't ask student to retry
			`
		})

		try {
			const res = await this.sentPromptApi(this.promptLog);
			console.log(this.promptLog);
			console.log(res);
			// debugger
			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) {
				this.askDefaultQuestion();
				return;
			}
			let content = res_obj.choices[0].message.content;

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
			if (res) {
				this.updateResult(score.PronScore, score, null);
				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				if (audioRes) setTimeout(() => {
					if (this.questionIndex < this.parent.defaultQuestion.length - 1) {
						this.questionIndex++;
						this.questionNumber++;
						this.defaultQuestionSession();
					} else {
						this.finishing();
					}
				}, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error)
		}
	}
	
	public async finishing() {
		try {
			let content = "Your exercise is finished! Here is your result."
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
			await audioRes.is_finish;
			this.submission();
			console.log(this.result)
		} catch (error) {
			console.log(error);
		}
	}

	submission() {
		this.parent.result = JSON.stringify(this.result);

		this.parent.myData.conversationData = this.eachQuestionScore;
		this.parent.myData.objectiveUsed = [...(new Set(this.objectiveUsed)).values()];
		this.parent.myData.EDBScore = this.EDBScore;

		let passStatus = this.parent.defaultQuestion.map(item => item.pass_status);
		let stringifyStatus = JSON.stringify(passStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(this.conversation);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.answerChanged = true;
		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	testSubmission() {
		let result = [
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{
				score: 2, 
				pronunciationScore: [{pronunciation: 2}, {accuracy: 4}, {completeness: 3}, {fluency: 2}],
				curriculumScore: [{number: 10}, {score: 3}]
			},
			{
				score: 3, 
				pronunciationScore: [{pronunciation: 3}, {accuracy: 3}, {completeness: 3}, {fluency: 3}],
				curriculumScore: [{number: 11}, {score: 5}]
			},
			{
				score: 5,
				pronunciationScore: [{pronunciation: 6}, {accuracy: 7}, {completeness: 5}, {fluency: 6}],
				curriculumScore: [{number: 12}, {score: 1}]
			},
			{
				score: null, 
				pronunciationScore: [],
				curriculumScore: []
			},
			{
				score: null, 
				pronunciationScore: [],
				curriculumScore: []
			},
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
		]
		this.parent.result = JSON.stringify(result);

		let conversationData = [
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 99,
				"AccuracyScore": 98,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 3,
				"AccuracyScore": 14,
				"CompletenessScore": 0,
				"FluencyScore": 0
			},
			{
				"PronScore": 87,
				"AccuracyScore": 78,
				"CompletenessScore": 100,
				"FluencyScore": 100
			}
		];
		let objectiveUsed = ['10', '11', '12'];
		let EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 10, 0, 0, 0];
		let questionPassStatus = ['pass', 'pass', 'pass', 'failed', 'failed'];
		let conversationRecord = [
			{
				"role": "assistant",
				"content": "Good evening, Mary Lam! How has your day gone?",
				"loading": false
			},
			{
				"role": "user",
				"content": "Hello, how are you?",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181331-671f63cb-1671f63cb9e827-5c891a4e.mp3"
			},
			{
				"role": "assistant",
				"content": "I'm doing well, thank you! Today, we will talk about food and drink, especially our favorite dishes and beverages. It's a fun topic that everyone enjoys! 🙂",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you like most about your favorite restaurant?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181401-671f63e9-1671f63e9d032e-7f988147.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's chat about something else, then.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you think makes food really delicious?",
				"loading": false,
				"EDBNumber": "11"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181436-671f640c-1671f640c4d9b4-4330797c.mp3"
			},
			{
				"role": "assistant",
				"content": "That's fine! Let's switch to a new topic now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Can you share your thoughts on your favorite snack?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181610-671f646a-1671f646ab0479-64523c15.mp3"
			},
			{
				"role": "assistant",
				"content": "No worries! Let's turn to a new question now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What is your favorite drink and why do you like it?",
				"loading": false,
				"EDBNumber": "12"
			},
			{
				"role": "user",
				"content": "Test.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181648-671f6490-1671f64901e9fc-252d68d7.mp3"
			},
			{
				"role": "assistant",
				"content": "It looks like we need to change topics now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Now, we will begin the exercise about our favorite food and drink. Let's enjoy!",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Chef",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
			},
			{
				"role": "user",
				"content": "Chef.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181740-671f64c4-1671f64c467751-216e6d17.mp3"
			},
			{
				"role": "assistant",
				"content": "Great job, Mary! You are doing well.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Organic",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
			},
			{
				"role": "user",
				"content": "Organic.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181753-671f64d1-1671f64d176b05-2803cc12.mp3"
			},
			{
				"role": "assistant",
				"content": "Well done, Mary! Keep it up.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Menu",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
			},
			{
				"role": "user",
				"content": "Menu.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181807-671f64df-1671f64df4ef1b-adb6754.mp3"
			},
			{
				"role": "assistant",
				"content": "Nice work, Mary! You're excelling today.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Condiment",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
			},
			{
				"role": "user",
				"content": "Ha ha.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181820-671f64ec-1671f64ec874cb-4e157540.mp3"
			},
			{
				"role": "assistant",
				"content": "No problem at all! Let's move on.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Brunch",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
			},
			{
				"role": "user",
				"content": "Lunch.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181841-671f6501-1671f6501e50e7-746975dc.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's continue onward now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Your exercise is finished! Here is your result.",
				"loading": false
			}
		];

		this.eachQuestionScore = conversationData;
		this.parent.myData.conversationData = conversationData;
		this.parent.myData.objectiveUsed = [...(new Set(objectiveUsed)).values()];;
		this.parent.myData.EDBScore = EDBScore;

		let stringifyStatus = JSON.stringify(questionPassStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(conversationRecord);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	/////////////////////////////////////////
	//          js handling function       //
	/////////////////////////////////////////
	scrollToBottom(): void {
		if (this.dialogContainer) {
			const container = this.dialogContainer.nativeElement;
			setTimeout(() => {
				container.scrollTop = container.scrollHeight;
			}, 500);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.parent.lang];
		return text;
	}

	setRobotImageStatus(status: string) {
		if (this.robotImageStatus === status) return;
		this.robotImageStatus = status;
		if (status === "wrong") {
			setTimeout(() => {
				this.setRobotImageStatus("wrong_last");
			}, 3000);
		} else if (status === "correct") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			});
		} else if (status === "wrong_last") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			}, 3000);
		}
	}

	startGreeting() {
		this.isInstructionShown = false;
		this.isContentShown = true;
		this.emitter.emit({ action: "loadAudio" });
		this.greeting();
		// this.firstEDBQuestion();
		// this.defaultQuestionSession();
		// this.submission();
		// this.testSubmission();
	}

	async playAudio(text: string, conversationContent: string, askDefaultQuestion: boolean, EDBNumber?: null | string) {
		if (this.isDestroyed) return;
		let preLoad = { role: "assistant", content: "", loading: true };
		this.conversation.push(preLoad);
		this.scrollToBottom();

		try {
			let res = await this.getAudioDuration(text)
			if (res) {
				preLoad.loading = false;
				this.audioInfo.push({ url: res.url, duration: res.duration })

				if (EDBNumber) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), EDBNumber: EDBNumber }, preLoad
					);
				} else if (this.parent.subType === "oralParagraph" && this.isAnswerDefaultQuestion) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), isParagraph: true }, preLoad
					);
				} else {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent) }, preLoad
					);
				}

				if (askDefaultQuestion) {
					if (this.parent.subType === "oralVocab" && this.parent.defaultQuestion[this.questionIndex].url !== '') {
						this.conversation.push({ role: "assistant", content: this.parent.defaultQuestion[this.questionIndex].url });
					}
				}

				this.scrollToBottom();
				this.status = 'playing';
				this.setRobotImageStatus("talk");
				this.audio.setAttribute("src", res.url);
				this.audio.play();
				res.is_finish = new Promise((resolve) => {
					setTimeout(() => resolve(true), res.duration * 1000);
				})

				setTimeout(() => {
					this.setRobotImageStatus("idle");
					this.status = 'idle';
				}, res.duration * 1000);

				return (res);
			}
		} catch (error) {
			console.log(error)
		}
	}

	getAudioDuration(text: string): Promise<any> {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

		let data: any = {
			gender: this.voiceLibrary[this.teacherChar].gender,
			txt: text,
			speed: this.speed,
			lang: this.voiceSelection.includes("en-GB") ? "en-GB" : "en-US",
			pitch: 1,
			name: this.voiceLibrary[this.teacherChar].name,
			style: "",
		};
		this.setRobotImageStatus("talk");

		return new Promise((resolve) => {
			this.datas.get2({ url: url, data: data, jwt: false, loading: false }).then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
					this.datas.get2({ url: statusUrl, jwt: false, loading: false }).then((res: any) => {
						if (res.url) {
							const audio = new Audio(`${res.url}`);
							audio.addEventListener('loadedmetadata', () => {
								const url = res.url;
								const duration = Math.round(audio.duration);
								resolve({ url, duration });
							});
						}
					});
				}
			});
		});
	}

	updateResult(score: number, pronunciationScore: any, curriculumScore: any) {
		let result: any = {
			score: Math.round(score / 10),
			pronunciationScore: [
				{pronunciation: Math.round(pronunciationScore.PronScore / 10)},
				{accuracy: Math.round(pronunciationScore.AccuracyScore / 10)},
				{completeness: Math.round(pronunciationScore.CompletenessScore / 10)},
				{fluency: Math.round(pronunciationScore.FluencyScore / 10)}
			]
		};
		if (curriculumScore) result.curriculumScore = curriculumScore;
		this.result.push(result);

		if (this.userFirstEDBPass) {
			let fulfil = 6 - this.result.length;
			for (let i = 0; i < fulfil; i++) {
				this.result.push({score: null, pronunciationScore: [], curriculumScore: []})
			}
		}
		if (this.questionIndex === this.parent.defaultQuestion.length - 1) {
			let fulfil = 16 - this.result.length;
			for (let i = 0; i < fulfil; i++) {
				this.result.push({score: null, pronunciationScore: [], curriculumScore: []})
			}
		}
	}

	listenButtonClicked(filePath) {
		this.audio.setAttribute("src", filePath);
		this.audio.play();
	}

	replaceSymbol(content) {
		try {
			if (content.includes('’')) content = content.replace(/’/g, "'");
			if (content.includes('"')) content = content.replace(/"/g, "");
			if (content.includes(';')) content = content.replace(/-/g, ",");
			return content;
		} catch (error) {
			console.error(error);
			return "";
		}
	}

	handleUserAnswer(userAnswer: any) {
		if (this.parent.subType === "oralFillIn") {
			let checkingNumber = parseInt(userAnswer.NBest[0].ITN);
			let question = this.parent.defaultQuestion[this.questionIndex].reading;
			let answer = this.parent.defaultQuestion[this.questionIndex].answer;
			let isIncluded = question.toLowerCase().includes(answer.toLowerCase());
			let index = question.toLowerCase().indexOf(answer.toLowerCase());
			let isUppercase = false;
			if (index !== -1) {
				isUppercase = question[index] === question[index].toUpperCase();
			}

			if (isUppercase) {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical.charAt(0).toUpperCase() + userAnswer.NBest[0].Lexical.substring(1)}.`;
				}
				return userAnswer.DisplayText;
			} else {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical}.`;
				}
				return userAnswer.DisplayText.charAt(0).toLowerCase() + userAnswer.DisplayText.slice(1);
			}
		} else if (this.parent.subType === "oralMc") {
			return userAnswer.DisplayText;
		} else {
			return userAnswer.DisplayText;
		}
	}

	handleRecognitionWord(word: Array<any>) {
		let recognitionWordArray = []
		for (let item of word) {
			recognitionWordArray.push(item);
		}
		// console.log(recognitionWordArray);
		return recognitionWordArray;
	}

	handlePronunciationScore(scoreType: string) {
		return Math.round(this.eachQuestionScore.reduce((sum, item) => sum + item[scoreType], 0) / this.eachQuestionScore.length);
	}

	readText(text: string): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: this.voiceLibrary[this.teacherChar].gender,
			txt: text,
			speed: this.speed,
			lang: this.voiceSelection.includes("en-GB") ? "en-GB" : "en-US",
			pitch: 1,
			name: this.voiceLibrary[this.teacherChar].name,
			style: "",
		};
		this.setRobotImageStatus("talk");

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
							setTimeout(() => {
								this.setRobotImageStatus("idle");
							}, 5000);

							//});
						}
					});
			}
		});
	}

	getHint() {
		if (this.hintQuota <= 0) {
			return
		}
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].filter(message => !message.isHint).slice(-4);
		tempConversation.push(
			{
				role: 'user',
				content: "How should I respond your question? give me an example in 20 words"
			}
		)

		this.chat.oralChat(tempConversation).then(res => {
			let content = JSON.parse(res).choices[0].message.content;
			this.conversation.push({ role: "user", content: this.replaceSymbol(content), isHint: true });
			this.hintQuota--;
			this.scrollToBottom();
		});
	}

	not_repeat_hint(hint, hint_idx) {
		for (const index in this.objectiveUsed) {
			if (index >= hint_idx) {
				break
			}
			if (this.objectiveUsed[index] == hint) {
				return false
			}
		}
		return true
	}

	get_display_choices(choices) {
		let choice_letter = "A";
		choices = choices.map(choice => {
			let display_choice = `${choice_letter}: ${choice}`;
			choice_letter = String.fromCharCode(choice_letter.charCodeAt(0) + 1);
			return display_choice;
		})
		return choices;
	}

	preloadImage() {
		let url = [
			'oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/robot_waiter_talk.png',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_correct.png',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong.gif',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong_last.png'
		];
		if (this.studentChar == '2') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/panda_talking.png');
		} else if (this.studentChar == '3') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/robot_talk.png');
		} else {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/student_talk.png');
		}
		url.forEach(e => {
			const imgEle = new Image();
			imgEle.src = '//' + e;
		});
	}

	setInstruction() {
		if (this.parent.subType === "oralVocab") {
			this.instruction = "Speak aloud the vocabulary asked by the bot.";
		} else if (this.parent.subType === "oralSentence") {
			this.instruction = "Speak aloud the phrase asked by the bot.";
		} else if (this.parent.subType === "oralFillIn") {
			this.instruction = "Speak aloud your answer when the bot asks you questions.";
		} else if (this.parent.subType === "oralMc") {
			this.instruction = "Speak aloud your answer when the bot asks you questions. No need to speak out the alphabet.";
		} else if (this.parent.subType === "oralParagraph") {
			this.instruction = "Speak aloud the paragraph asked by the bot.";
		} else if (this.parent.subType === "oralAI") {
			this.instruction = "Press the close button when you want to finish.";
		}
	}

	setEDBObjective() {
		if (this.studentLevel === "p4" || this.studentLevel === "p5" || this.studentLevel === "p6") {
			this.objective = [
				{number: 1, content: "Use appropriate register when speaking to familiar interlocutors such as teachers and peers"},
				{number: 2, content: "Apply grammar rules such as subject-verb agreement correctly"},
				{number: 3, content: "Connect ideas by using cohesive devices"},
				{number: 4, content: "Use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings"},
				{number: 5, content: "Open an interaction by greeting someone in an appropriate manner"},
				{number: 6, content: "Open an interaction by introducing oneself giving some details"},
				{number: 7, content: "Open an interaction by eliciting a response by asking questions or providing information on a topic"},
				{number: 8, content: "Maintain an interaction by controlling participation in an interaction or group activities"},
				{number: 9, content: "Maintain an interaction by recognizing others' desire to speak"},
				{number: 10, content: "Maintain an interaction by asking and responding to others' opinions"},
				{number: 11, content: "Maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate"},
				{number: 12, content: "Maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood"},
				{number: 13, content: "Maintain an interaction by predicting the likely development of a conversation and responding accordingly"},
				{number: 14, content: "Close an interaction by using appropriate formulaic expressions"},
				{number: 15, content: "Close an interaction by giving reasons"},
			];
		} else if (this.studentLevel === 's1' || this.studentLevel === 's2' || this.studentLevel === 's3') {
			this.objective = [
				{number: 1, content: "convey ideas and information in conversations or discussions"},
				{number: 2, content: "use words and expressions appropriate to the context"},
				{number: 3, content: "use appropriate discourse markers"},
				{number: 4, content: "use correct pronunciation, intonation and register for different purposes"},
				{number: 5, content: "seek and give clarification, explain what information one requires and why, rephrase one's questions when necessary, sum up points made and redirect the discussion when the need arises"},
				{number: 6, content: "make a balanced contribution without either dominating the discussion or being too reticent"},
				{number: 7, content: "express, elicit and respond to ideas, opinions and feelings in a group discussion"},
			];
		}
	}

	setDefaultQuestion() {
		if (this.studentLevel === 'p4') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Restaurant",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/restaurant.png"
						},
						{
							name: "Waiter",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/waiter.png"
						},
						{
							name: "Breakfast",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/breakfast.png"
						},
						{
							name: "Lunch",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/lunch.png"
						},
						{
							name: "Dinner",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/dinner.png"
						},
						// {
						// 	name: "Cup",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/cup.png"
						// },
						// {
						// 	name: "Bottle",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/bottle.png"
						// },
						// {
						// 	name: "Glass",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/glass.png"
						// },
						// {
						// 	name: "Plate",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/plate.png"
						// },
						// {
						// 	name: "Taste",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/taste.png"
						// },
						// {
						// 	name: "Sweet",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/sweet.png"
						// },
						// {
						// 	name: "Spicy",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/spicy.png"
						// },
						// {
						// 	name: "Juice",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/juice.png"
						// },
						// {
						// 	name: "Coffee",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/coffee.png"
						// },
						// {
						// 	name: "Egg",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P4/egg.png"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Chef",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/chef.png"
						},
						{
							name: "Organic",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/organic.png"
						},
						{
							name: "Menu",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/menu.png"
						},
						{
							name: "Condiment",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/condiment.png"
						},
						{
							name: "Brunch",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/brunch.png"
						},
						// {
						// 	name: "Omelette",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/omelette.png"
						// },
						// {
						// 	name: "Stir-fry",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/stir-fry.png"
						// },
						// {
						// 	name: "Order",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/order.png"
						// },
						// {
						// 	name: "Savour",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/savour.png"
						// },
						// {
						// 	name: "Feast",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/feast.jpg"
						// },
						// {
						// 	name: "Serve",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/serve.png"
						// },
						// {
						// 	name: "Etiquette",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/etiquette.png"
						// },
						// {
						// 	name: "Tender",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/tender.png"
						// },
						// {
						// 	name: "Crispy",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/crispy.png"
						// },
						// {
						// 	name: "Buffet",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P4/buffet.png"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "This restaurant is always open and serves breakfast, lunch and dinner."
						},
						{
							name: "Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?"
						},
						{
							name: "This special curry dish tastes both sweet and spicy."
						},
						{
							name: "Careful, sir. The plate is hot."
						},
						{
							name: "There is an extra charge if you wish to bring your own bottle of wine."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "I would like to order some crispy chicken to go along with my stir-fried."
						},
						{
							name: "The brunch menu says the omelette is their specialty."
						},
						{
							name: "This buffet is a feast, and I intend to savour every bite."
						},
						{
							name: "My compliments to the chef, for preparing such a tender steak."
						},
						{
							name: "It is typical restaurant etiquette to be seated before ordering."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "My wife and I would like a table for (____) please.",
							reading: "My wife and I would like a table for two please.",
							answer: "Two"
						},
						{
							name: "Careful, sir. The (____) is hot.",
							reading: "Careful, sir. The plate is hot.",
							answer: "Plate"
						},
						{
							name: "Enjoy your (____), sir.",
							reading: "Enjoy your meal, sir.",
							answer: "Meal"
						},
						{
							name: "May I have a (____) of water, please?",
							reading: "May I have a glass of water, please?",
							answer: "Glass"
						},
						{
							name: "We just stopped (____) breakfast, sir.",
							reading: "We just stopped serving breakfast, sir.",
							answer: "Serving"
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "I would like some deep-fried (____) chicken.",
							reading: "I would like some deep-fried crispy chicken.",
							answer: "Crispy"
						},
						{
							name: "All our foods are sourced locally, completely (____) without chemicals.",
							reading: "All our foods are sourced locally, completely organic without chemicals.",
							answer: "Organic"
						},
						{
							name: "We do not serve (____) to minors.",
							reading: "We do not serve alcohol to minors.",
							answer: "Alcohol"
						},
						{
							name: "Ketchup, mustard, and mayonnaise are different kinds of (____).",
							reading: "Ketchup, mustard, and mayonnaise are different kinds of condiments.",
							answer: "Condiments"
						},
						{
							name: "It's bad (____) to chew with your mouth open.",
							reading: "It's bad etiquette to chew with your mouth open.",
							answer: "Etiquette"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Good morning. Would you like some water?",
							choice: ["I don't drink water.", "Yes, I would like some water."],
							answer: "Yes, I would like some water."
						},
						{
							name: "Are you ready to order?",
							choice: ["Not yet. Could you give me a few more minutes?", "I don't want to order."],
							answer: "Not yet. Could you give me a few more minutes?"
						},
						{
							name: "How is your meal?",
							choice: ["It is yum yum, so no thank you.", "It's delicious, thank you for asking."],
							answer: "It's delicious, thank you for asking."
						},
						{
							name: "Is there anything else I can get you?",
							choice: ["No, thank you.", "No, I want more to drink."],
							answer: "No, thank you."
						},
						{
							name: "Would you like the bill?",
							choice: ["Yes, please. I would like to pay by credit card.", "Yes, but I don't want to pay."],
							answer: "Yes, please. I would like to pay by credit card."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "I'm sorry sir, but we are fully booked.",
							choice: ["I don't care. I demand to be seated!", "That's alright. How long do I need to wait, then?"],
							answer: "That's alright. How long do I need to wait, then?"
						},
						{
							name: "We don't start serving lunch until noon.",
							choice: ["I see. I'll have some breakfast instead, then.", "Can't you make an exception for me?"],
							answer: "I see. I'll have some breakfast instead, then."
						},
						{
							name: "Excuse me, sir, but your credit card has been declined.",
							choice: ["That's your problem, not mine.", "I will pay by cash, then."],
							answer: "I will pay by cash, then."
						}
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `Tonight Mum taught me how to make pumpkin soup.`,
					},
					{
						name: `First, I heated the butter on a saucepan over medium heat.`,
					},
					{
						name: `When it melted, Mum added some chopped onion and cooked them for two minutes.`,
					},
					{
						name: `Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat.`,
					},
				]
			}
		} else if (this.studentLevel === 'p5') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Market",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/market.jpg"
						},
						{
							name: "Street",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/street.jpg"
						},
						{
							name: "Island",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/island.jpg"
						},
						{
							name: "Tour",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/tour.jpg"
						},
						{
							name: "Mountain",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/mountain.jpg"
						},
						// {
						// 	name: "Hill",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/hill.jpg"
						// },
						// {
						// 	name: "Walk",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/walk.jpg"
						// },
						// {
						// 	name: "Map",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/map.jpg"
						// },
						// {
						// 	name: "Journey",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/journey.jpg"
						// },
						// {
						// 	name: "Trip",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/trip.jpg"
						// },
						// {
						// 	name: "Ferry",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/ferry.jpg"
						// },
						// {
						// 	name: "Attractions",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/attractions.jpg"
						// },
						// {
						// 	name: "Tourist",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/tourist.jpg"
						// },
						// {
						// 	name: "Crowded",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/crowded.jpg"
						// },
						// {
						// 	name: "Countryside",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P5/countryside.jpg"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Reservoir",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Reservoir.jpg"
						},
						{
							name: "Shed",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Shed.jpg"
						},
						{
							name: "Promenade",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Promenade.jpg"
						},
						{
							name: "Avenue",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Avenue.jpg"
						},
						{
							name: "Peninsula",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Peninsula.jpg"
						},
						// {
						// 	name: "Amble",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Amble.jpg"
						// },
						// {
						// 	name: "Stroll",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Stroll.jpg"
						// },
						// {
						// 	name: "Itinerary",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Itinerary.jpg"
						// },
						// {
						// 	name: "Destination",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Destination.jpg"
						// },
						// {
						// 	name: "Budget",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Budget.jpg"
						// },
						// {
						// 	name: "Landmark",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Landmark.jpg"
						// },
						// {
						// 	name: "Barren",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Barren.jpg"
						// },
						// {
						// 	name: "Urban",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Urban.jpg"
						// },
						// {
						// 	name: "Remote",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Remote.jpg"
						// },
						// {
						// 	name: "Vacation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P5/Vacation.jpg"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "The market is at the end of the street."
						},
						{
							name: "Let's take a walk around the countryside."
						},
						{
							name: "I hope the attractions aren't too crowded."
						},
						{
							name: "The island can only be reached by ferry."
						},
						{
							name: "I can't find the main street on the map."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "The peninsula is very remote and difficult to reach."
						},
						{
							name: "I managed to plan an itinerary for our vacation according to our budget."
						},
						{
							name: "Taking a stroll on the promenade clears my head."
						},
						{
							name: "The shed over there is an urban landmark."
						},
						{
							name: "We have finally arrived at our destination."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "The only way to get to Cheung Chau is by (____).",
							reading: "The only way to get to Cheung Chau is by ferry.",
							answer: "Ferry"
						},
						{
							name: "It's not the destination so much as the (____).",
							reading: "It's not the destination so much as the journey.",
							answer: "Journey"
						},
						{
							name: "Causeway Bay is in Hong Kong (____).",
							reading: "Causeway Bay is in Hong Kong Island.",
							answer: "Island"
						},
						{
							name: "We can buy traditional clothes in Stanley (____).",
							reading: "We can buy traditional clothes in Stanley Market",
							answer: "Market"
						},
						{
							name: "The International Commerce (____) is the tallest building in Hong Kong.",
							reading: "The International Commerce Centre is the tallest building in Hong Kong.",
							answer: ""
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "The area over there is a (____) wasteland.",
							reading: "The area over there is a barren wasteland.",
							answer: "Barren "
						},
						{
							name: "People get their arms stuck in Cheung Po Tsai (____) Cave.",
							reading: "People get their arms stuck in Cheung Po Tsai Pirate Cave.",
							answer: "Pirate"
						},
						{
							name: "You can find delicious cuisine in Chungking (____).",
							reading: "You can find delicious cuisine in Chungking Mansion.",
							answer: "Mansion"
						},
						{
							name: "The Victoria (____) is a unique scenery of Hong Kong.",
							reading: "The Victoria Harbour is a unique scenery of Hong Kong.",
							answer: "Harbour"
						},
						{
							name: "I have never actually visited the Plover Cove (____).",
							reading: "I have never actually visited the Plover Cove Reservoir.",
							answer: "Reservoir"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Should we take the bus or the MTR?",
							choice: ["It doesn't matter.", "Let's take the bus."],
							answer: "Let's take the bus."
						},
						{
							name: "The Tai Tong EcoPark is beautiful.",
							choice: ["Yes, the trees are terrible.", "I agree. The view is stunning."],
							answer: "I agree. The view is stunning."
						},
						{
							name: "Are you tired yet?",
							choice: ["Yes, this trail is too steep.", "Yes, let's walk faster."],
							answer: "Yes, this trail is too steep."
						},
						{
							name: "Should we take some photos?",
							choice: ["Yes, please. Did you bring your camera?", "No! It's dumb to take photos."],
							answer: "Yes, please. Did you bring your camera?"
						},
						{
							name: "Can you see the sunset?",
							choice: ["Yes, it's breathtaking.", "No, I don't really care about it."],
							answer: "Yes, it's breathtaking."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Excuse me, can you show me the way to the promenade?",
							choice: ["Ask another person.", "Yes, sure. Just turn left and walk across the park, and you will see it.", "I'm busy, just go away.", "The Big Buddha is very near."],
							answer: "Yes, sure. Just turn left and walk across the park, and you will see it."
						},
						{
							name: "What can we buy at the Jade Market?",
							choice: ["We can buy accessories there.", "I don't know.", "Do your own research, I'm not going to answer your question.", "We can buy some Marvel movie tapes."],
							answer: "We can buy accessories there."
						},
						{
							name: "Sorry, you are not allowed to enter this building unless you have a permit.",
							choice: ["No way! I've planned this trip for so long.", "Okay, bye-bye.", "I forgot about this. Can you teach me how?", "Are you kidding me?"],
							answer: "I forgot about this. Can you teach me how?"
						},
						{
							name: "Please do not touch any exhibits with your hand.",
							choice: ["I paid for the ticket! I have the right!", "Those exhibits are disgusting. Ew!", "Ok, Thank you for the reminder.", "You are welcome."],
							answer: "Ok, Thank you for the reminder."
						},
						{
							name: "There are a lot of interactive devices here in Tai Kwun.",
							choice: ["Ok. The devices are shut down.", "Wow! That is impressive. Should we give them a try?", "Tai Kwun is too far. I'm lazy.", "Yes. Tai Kwun used to be a police station."],
							answer: "Wow! That is impressive. Should we give them a try?"
						}
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `Whenever I'm on holiday, I want to see the sights.`,
					},
					{
						name: `So I find the mode of transport to take me where I like.`,
					},
					{
						name: `Sometimes it's an aeroplane, if I'm going far.`,
					},
					{
						name: `But if it's a short distance, I may just take a car.`,
					},
					{
						name: `For the times I'm in New York, I'll take a yellow cab.`,
					},
					{
						name: `Or if I go to Hong Kong, it's the MTR I'll grab.`,
					},
					// {
					// 	name: `In the Alps in Switzerland, it snows, so I can ski.`,
					// },
					// {
					// 	name: `But if I'm going hiking, my feet are good enough for me!`,
					// }
				]
			}
		} else if (this.studentLevel === 'p6') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Recycle",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Recycle.jpg"
						},
						{
							name: "Waste",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Waste.jpg"
						},
						{
							name: "Environment",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Environment.jpg"
						},
						{
							name: "Awareness",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Awareness.jpg"
						},
						{
							name: "Pollution",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Pollution.jpg"
						},
						// {
						// 	name: "Issue",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Issue.jpg"
						// },
						// {
						// 	name: "Industry",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Industry.jpg"
						// },
						// {
						// 	name: "Plastic",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Plastic.jpg"
						// },
						// {
						// 	name: "Litter",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Litter.jpg"
						// },
						// {
						// 	name: "Save",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Save.jpg"
						// },
						// {
						// 	name: "Extinction",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Extinction.jpg"
						// },
						// {
						// 	name: "Rinse",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Rinse.jpg"
						// },
						// {
						// 	name: "Temperature",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Temperature.jpg"
						// },
						// {
						// 	name: "Habitat",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/P6/Habitat.jpg"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Conservation",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Conservation.jpg"
						},
						{
							name: "Replenish",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Replenish.jpg"
						},
						{
							name: "Climate",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Climate.jpg"
						},
						{
							name: "Disposal",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Disposal.jpg"
						},
						{
							name: "Activism",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Activism.jpg"
						},
						// {
						// 	name: "Deforestation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Deforestation.jpg"
						// },
						// {
						// 	name: "Felling",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Felling.jpg"
						// },
						// {
						// 	name: "Volunteer",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Volunteer.jpg"
						// },
						// {
						// 	name: "Globalization",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Globalization.jpg"
						// },
						// {
						// 	name: "Impact",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Impact.jpg"
						// },
						// {
						// 	name: "Biodiversity",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Biodiversity.jpg"
						// },
						// {
						// 	name: "Campaign",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Campaign.jpg"
						// },
						// {
						// 	name: "Consumption",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Consumption.jpg"
						// },
						// {
						// 	name: "Plantation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Plantation.jpg"
						// },
						// {
						// 	name: "Alleviate",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/P6/Alleviate.jpg"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "We should all recycle."
						},
						{
							name: "Global warming is a serious issue."
						},
						{
							name: "There is so much litter in the oceans."
						},
						{
							name: "Is there something we can do to save animals from extinction?"
						},
						{
							name: "Waste not, want not."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "It would take years for marine life to replenish."
						},
						{
							name: "There is a campaign for the building of a natural conservation park."
						},
						{
							name: "By reducing consumption, we also reduce the amount of disposal."
						},
						{
							name: "Climate change may yet prove to be reversible."
						},
						{
							name: "During weekends, I volunteer to work at plantations."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "For December, the (_______) is unusually high.",
							reading: "For December, the temperature is unusually high.",
							answer: "temperature"
						},
						{
							name: "Those (_____) bins have different colours for different waste.",
							reading: "Those recycle bins have different colours for different waste.",
							answer: "recycle"
						},
						{
							name: "Instead of (______) straws, we now serve bamboo straws.",
							reading: "Instead of plastic straws, we now serve bamboo straws.",
							answer: "plastic"
						},
						{
							name: "We should turn off the lights when we leave to (____) energy.",
							reading: "We should turn off the lights when we leave to save energy.",
							answer: "save"
						},
						{
							name: "The coal mining (________) is on a timer.",
							reading: "The coal mining industry is on a timer.",
							answer: "industry"
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Climate (______) and protests must be purposeful, not just loud.",
							reading: "Climate activism and protests must be purposeful, not just loud.",
							answer: "activism"
						},
						{
							name: "We don't realize the (______) we have on the environment.",
							reading: "We don't realize the impact we have on the environment.",
							answer: "impact"
						},
						{
							name: "Is there a way to (_______) the symptoms of global warming?",
							reading: "Is there a way to alleviate the symptoms of global warming?",
							answer: "alleviate"
						},
						{
							name: "(______) is when we destroy forests to use the wood and the land.",
							reading: "Deforestation is when we destroy forests to use the wood and the land.",
							answer: "Deforestation"
						},
						{
							name: "Our entire research facility is at your (______).",
							reading: "Our entire research facility is at your disposal.",
							answer: "disposal"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Would you take a leaflet?",
							choice: ["Get your hand out of my face!", "Yes, I would. Thank you."],
							answer: "Yes, I would. Thank you."
						},
						{
							name: "You left the tap running yesterday.",
							choice: ["So what? Who cares?", "I'm sorry, it won't happen again."],
							answer: "I'm sorry, it won't happen again."
						},
						{
							name: "Should we buy energy-saving light bulbs?",
							choice: ["Yes, we should go buy some.", "Yes, we should not use any."],
							answer: "Yes, we should go buy some."
						},
						{
							name: "Our electric bill this month is too high!",
							choice: ["Let's think of ways to save electricity.", "Let's make it go even higher next month!"],
							answer: "Let's think of ways to save electricity."
						},
						{
							name: "Make sure you use the correct bag for recycling.",
							choice: ["Don't worry, I will be careful.", "Do worry, I will be careful."],
							answer: "Don't worry, I will be careful."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "I think it's too late to save the Earth, we should go to Mars instead.",
							choice: ["You're right. Let's buy tickets for the next space shuttle.", "It's not too late, as long as we all do our part.", "I'm from Mars, and it's amazing there. We should all go!", "Mars is too far away. Let's go to the moon instead."],
							answer: "It's not too late, as long as we all do our part."
						},
						{
							name: "Do you think scientists can find a way to reverse global warming?",
							choice: ["No, scientists are all paid off by companies to spout nonsense.", "Yes, they are all incompetent and cannot get anything done.", "No, scientists are crazy and don't have Earth's best interest at heart.", "Yes, but devising a way is simple. Implementing it is the hard part."],
							answer: "Yes, but devising a way is simple. Implementing it is the hard part."
						},
						{
							name: "If animals cannot survive without their habitats, then they shouldn't survive at all.",
							choice: ["I agree. I believe in the survival of the fittest.", "I disagree. Our right to live does not give us the right to take away animals' right to survive.", "I agree. Animals have to adapt or become food for our bellies.", "I disagree. Animals can survive anyway, so it doesn't matter."],
							answer: "I disagree. Our right to live does not give us the right to take away animals' right to survive."
						},
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `I am writing this letter to make more people aware of the air quality in Hong Kong.`,
					},
					{
						name: `I have been reading information about pollution in Hong Kong for a science project.`,
					},
					{
						name: `Hong Kong has serious pollution. Sometimes we cannot see across the harbour.`,
					},
					{
						name: `My grandfather has some breathing problems due to the poor air quality.`,
					},
					{
						name: `I hope the government can do something to make the air quality better.`,
					}
				]
			}
		} else if (this.studentLevel === 's1') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Examinations",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/examination.png"
						},
						{
							name: "Homework",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/homework.png"
						},
						{
							name: "Assignment",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/assignment.png"
						},
						{
							name: "Sponsorship",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/sponsorship.png"
						},
						{
							name: "Stressful",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/stressful.png"
						},
						// {
						// 	name: "Anxiety",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/anxiety.png"
						// },
						// {
						// 	name: "Peer",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/peer.png"
						// },
						// {
						// 	name: "Grades",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/grades.png"
						// },
						// {
						// 	name: "Tuition",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/tuition.png"
						// },
						// {
						// 	name: "Failure",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/failure.png"
						// },
						// {
						// 	name: "Expectation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/expectation.png"
						// },
						// {
						// 	name: "Competition",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/competition.png"
						// },
						// {
						// 	name: "Support",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/support.png"
						// },
						// {
						// 	name: "Exercises",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/exercises.png"
						// },
						// {
						// 	name: "Break",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S1/break.png"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Productivity",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/productivity.png"
						},
						{
							name: "Motivation",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/motivation.png"
						},
						{
							name: "Distraction",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/distraction.png"
						},
						{
							name: "Procrastination",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/procrastination.png"
						},
						{
							name: "Time-management",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/time-management.png"
						},
						// {
						// 	name: "Schedule",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/schedule.png"
						// },
						// {
						// 	name: "Counselor",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/counselor.png"
						// },
						// {
						// 	name: "Communication",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/communication.png"
						// },
						// {
						// 	name: "Meditation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/meditation.png"
						// },
						// {
						// 	name: "Guidance",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/guidance.png"
						// },
						// {
						// 	name: "Concentration",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/concentration.png"
						// },
						// {
						// 	name: "Relaxation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/relaxation.png"
						// },
						// {
						// 	name: "Burnout",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/burnout.png"
						// },
						// {
						// 	name: "Reward",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/reward.png"
						// },
						// {
						// 	name: "Visualization",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S1/visualization.png"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Pay attention in class."
						},
						{
							name: "I enjoy English lessons."
						},
						{
							name: "I am nervous about the exams."
						},
						{
							name: "We couldn't agree on a topic for our group project."
						},
						{
							name: "My dog ate my homework."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "My teachers will regard me as a failure if I don't do well on the test."
						},
						{
							name: "I have scheduled an appointment for you with the school counselor."
						},
						{
							name: "Stop procrastinating and get on with your work!"
						},
						{
							name: "You're distracting your sister from her studies."
						},
						{
							name: "Sometimes, I relax by visualizing what I'd like to be in the future."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "We should not give in to (_____) pressure.",
							reading: "We should not give in to peer pressure.",
							answer: "peer"
						},
						{
							name: "The teacher gave us too much (_________).",
							reading: "The teacher gave us too much homework.",
							answer: "homework"
						},
						{
							name: "You should (______) your friends when they are down.",
							reading: "You should support your friends when they are down.",
							answer: "support"
						},
						{
							name: "I need to get more (_______) so I can get stronger.",
							reading: "I need to get more exercises so I can get stronger.",
							answer: "exercises"
						},
						{
							name: "Having good (______) isn't everything.",
							reading: "Having good grades isn't everything.",
							answer: "grades"
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Some students need the proper (________) to succeed.",
							reading: "Some students need the proper motivation to succeed.",
							answer: "motivation"
						},
						{
							name: "A (_______) calms your mind and focuses your senses.",
							reading: "A meditation calms your mind and focuses your senses.",
							answer: "meditation"
						},
						{
							name: "Don't work too hard, or you'll suffer from (______).",
							reading: "Don't work too hard, or you'll suffer from burnout.",
							answer: "burnout"
						},
						{
							name: "The problem we have is a lack of proper (_________).",
							reading: "The problem we have is a lack of proper communication.",
							answer: "communication"
						},
						{
							name: "I would like to claim the (_______) for finding your lost wallet.",
							reading: "I would like to claim the reward for finding your lost wallet.",
							answer: "reward"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "We've been studying for an hour. Would you like a rest?",
							choice: ["Yes, a short break will do me wonders.", "No, I can keep going until my eyes bleed!"],
							answer: "Yes, a short break will do me wonders."
						},
						{
							name: "Can you afford this university? It has a high tuition fee.",
							choice: ["I think so after I apply for sponsorship.", "Don't worry, we're gonna be rich after I hit the casino."],
							answer: "I think so after I apply for sponsorship."
						},
						{
							name: "How should I deal with my anxiety levels?",
							choice: ["Just ignore it, it will go away.", "Try taking deep breaths and talking with someone."],
							answer: "Try taking deep breaths and talking with someone."
						},
						{
							name: "It's Friday. Should I do my homework now or later?",
							choice: ["Do it now, so you can enjoy your weekend.", "It's Friday, enjoy yourself! Your homework will still be there tomorrow."],
							answer: "Do it now, so you can enjoy your weekend."
						},
						{
							name: "I had good grades last time. Does that mean I don't need to study?",
							choice: ["Why study when you can play video games instead?", "You need to study in order to keep up your grades."],
							answer: "You need to study in order to keep up your grades."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "How could I communicate better as a group leader?",
							choice: [
								"Just keep quiet and leave everyone else alone.",
								"Be as loud as possible and shout if you want.",
								"Make sure everyone is heard and let them feel important.",
								"Who wants to be a leader? Step down!",
							],
							answer: "Make sure everyone is heard and let them feel important."
						},
						{
							name: "I'm looking for a guidance counselor. Do you know one?",
							choice: [
								"Her office is back there at the end of the hallway.",
								"I can be your guidance counselor if you want.",
								"You don't need one. Just keep being you!",
								"Do you need guidance? What a lost little lamb.",
							],
							answer: "Her office is back there at the end of the hallway."
						},
						{
							name: "I think I'm suffering from burnout. I feel mentally exhausted.",
							choice: [
								"Be quiet or I will literally burn you.",
								"You need a vacation to clear your mind and relax.",
								"I don't care. Do whatever you want.",
								"Push through it, and you won't feel exhausted anymore.",
							],
							answer: "You need a vacation to clear your mind and relax."
						},
						{
							name: "We need to communicate better. I don't understand what you mean half the time!",
							choice: [
								"I don't understand what you mean either!",
								"That just means we understand each other fine the other half.",
								"You're right. We need to be clear and ask questions if we're not sure.",
								"We're better off not communicating at all.",
							],
							answer: "You're right. We need to be clear and ask questions if we're not sure.\""
						},
						{
							name: "How do I find my motivation in life?",
							choice: [
								"You don't. Just give up.",
								"Just wander through life aimlessly. No one cares.",
								"Copy what other people do, and you'll be fine.",
								"Take your time, explore your interests, and don't give up.",
							],
							answer: "Take your time, explore your interests, and don't give up."
						},
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `Classroom Rules for 1A`,
					},
					{
						name: `1. Be polite, courteous and respectful at all times. Treat others the way you want to be treated.`,
					},
					{
						name: `2. If you have something to share with the class or a question to ask during the lesson, raise your hand. Wait until the teacher calls on you.`,
					},
					{
						name: `3. Respect other students' opinions. Listen quietly when others are speaking. You will have your turn to speak, too.`,
					},
					{
						name: `4. Come to class on time and be prepared for each lesson. Have what you need for each lesson on your desk before the teacher comes in.`,
					},
					{
						name: `5. Complete all homework assignments neatly and on time.`,
					},
					{
						name: `6. Respect each other's property. Do not touch what is not yours.`,
					},
					{
						name: `7. Help keep our classroom clean and tidy. Put away things after you use them and throw rubbish into the bin.`,
					},
					{
						name: `8. Do your best and have fun!`,
					},
				]
			}
		} else if (this.studentLevel === 's2') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Criminal",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Criminal.png"
						},
						{
							name: "Gang",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Gang.png"
						},
						{
							name: "Peer",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Peer.png"
						},
						{
							name: "Murder",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Murder.png"
						},
						{
							name: "Theft",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Theft.png"
						},
						// {
						// 	name: "Rape",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Rape.png"
						// },
						// {
						// 	name: "Drugs",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Drugs.png"
						// },
						// {
						// 	name: "Assault",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Assault.png"
						// },
						// {
						// 	name: "Burglary",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Burglary.png"
						// },
						// {
						// 	name: "Imprisonment",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Imprisonment.png"
						// },
						// {
						// 	name: "Kidnapping",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Kidnapping.png"
						// },
						// {
						// 	name: "Commit",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Commit.png"
						// },
						// {
						// 	name: "Abuse",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Abuse.png"
						// },
						// {
						// 	name: "Guilty",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Guilty.png"
						// },
						// {
						// 	name: "Robbery",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S2/Robbery.png"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Mimic",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Mimic.jpg"
						},
						{
							name: "Reintegration",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Reintegration.jpg"
						},
						{
							name: "Sentence",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Sentence.jpg"
						},
						{
							name: "Juvenile",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Juvenile.jpg"
						},
						{
							name: "Trafficking",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Trafficking.jpg"
						},
						// {
						// 	name: "Smuggling",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Smuggling.jpg"
						// },
						// {
						// 	name: "Rehabilitation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Rehabilitation.jpg"
						// },
						// {
						// 	name: "Death-penalty",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Death-penalty.jpg"
						// },
						// {
						// 	name: "Fine",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Fine.jpg"
						// },
						// {
						// 	name: "Confinement",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Confinement.jpg"
						// },
						// {
						// 	name: "Forfeiture",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Forfeiture.jpg"
						// },
						// {
						// 	name: "Compensation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Compensation.jpg"
						// },
						// {
						// 	name: "Probation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Probation.jpg"
						// },
						// {
						// 	name: "Prevention",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Prevention.jpg"
						// },
						// {
						// 	name: "Victim",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S2/Victim.jpg"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "We should obey the law."
						},
						{
							name: "He becomes a gang member."
						},
						{
							name: "You have to bear the consequences."
						},
						{
							name: "Don't do bad things because of peer pressure."
						},
						{
							name: "There was a theft at my neighbor's house."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "The man was arrested for smuggling gold across the border."
						},
						{
							name: "She was tried again for breaching her probation order."
						},
						{
							name: "He is the victim in this case and needs psychological treatment."
						},
						{
							name: "Please do prevention work before something happens."
						},
						{
							name: "He was the first person in this town who was given death-penalty."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "We felt (________) skipping class today.",
							reading: "We felt guilty skipping class today.",
							answer: "guilty"
						},
						{
							name: "This lady has an extensive (_________) record.",
							reading: "This lady has an extensive criminal record.",
							answer: "criminal"
						},
						{
							name: "We should never try (______) in any situation.",
							reading: "We should never try drugs in any situation.",
							answer: "drugs"
						},
						{
							name: "Police charged her with common (________).",
							reading: "Police charged her with common assault.",
							answer: "assault"
						},
						{
							name: "They divorced because the husband (______) her.",
							reading: "They divorced because the husband abused her.",
							answer: "abused"
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "The doctor is required to provide (____________) for the client's incident.",
							reading: "The doctor is required to provide compensation for the client's incident.",
							answer: "compensation"
						},
						{
							name: "The (___________) Court is set up for persons under the age of sixteen.",
							reading: "The Juvenile Court is set up for persons under the age of sixteen.",
							answer: "Juvenile"
						},
						{
							name: "If you are caught smoking, you may have to pay a (_____) of HK$1,500.",
							reading: "If you are caught smoking, you may have to pay a fine of HK$1,500.",
							answer: "fine"
						},
						{
							name: "The judge has (__________) him to five years in prison.",
							reading: "The judge has sentenced him to five years in prison.",
							answer: "sentenced"
						},
						{
							name: "He finally completed alcohol (____________) with determination.",
							reading: "He finally completed alcohol rehabilitation with determination.",
							answer: "rehabilitation"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Excuse me, did you witness the robbery?",
							choice: ["Leave me alone.", "Yes, I can offer help."],
							answer: "Yes, I can offer help."
						},
						{
							name: "You are my friend, you should keep the theft a secret.",
							choice: ["I'm sorry but I have to call 999.", "Of course, I don't want to lose you!"],
							answer: "I'm sorry but I have to call 999."
						},
						{
							name: "No matter how hard life is, we should never commit a crime.",
							choice: ["You are right, or else we will lose a bright future.", "Why? I think it will be exciting."],
							answer: "You are right, or else we will lose a bright future."
						},
						{
							name: "What are you doing to the car?",
							choice: ["There is some dirt on the door. I am cleaning it.", "It's none of your business."],
							answer: "There is some dirt on the door. I am cleaning it."
						},
						{
							name: "Some peers ask me to join the gang. Should I?",
							choice: ["You can. It is a symbol of power.", "I don't think so. You should consider it twice."],
							answer: "I don't think so. You should consider it twice."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Everyone deserves a second chance. We should help prisoners with reintegration.",
							choice: ["We should always remember who they are to provide more support.", "People make mistakes. Don't discriminate against them.", "I agree but it's not on me. The government will do its part.", "Remember, a leopard can't change its spots."],
							answer: "People make mistakes. Don't discriminate against them."
						},
						{
							name: "Do you think confinement is a great penalty?",
							choice: ["Yes, I can play video games at home 24/7 and do whatever I want.", "Yes, it's not a big deal to me.", "No, it is worse than being in prison as there would be no social interaction at all.", "No, releasing and pardoning everyone is a great penalty."],
							answer: "No, it is worse than being in prison as there would be no social interaction at all."
						},
						{
							name: "What can we do to protect our communities from crime?",
							choice: ["Keep our door open to create a friendly environment.", "Each man is equipped with a gun.", "Lock everyone down and cannot go out.", "Carry out prevention work, such as increasing police patrols."],
							answer: "Carry out prevention work, such as increasing police patrols."
						},
						{
							name: "What should we do if we witness a murder?",
							choice: ["Scream loudly to scare him away.", "Grab a weapon and rush into the crime scene to stop him.", "Pretend nothing happened and walk away.", "Stay calm and report the crime in a safe place."],
							answer: "Stay calm and report the crime in a safe place."
						}
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `A strange and mysterious thing was happening in Paris.`,
					},
					{
						name: `The famous French city is home to some of the most expensive items in the world.`,
					},
					{
						name: `Lately, however, the items had been disappearing.`,
					},
					{
						name: `First, the famous oil portrait of Mona Lisa housed in the Louvre museum went missing.`,
					},
					{
						name: `Next, the world's largest diamond vanished from a jewellery shop.`,
					},
					{
						name: `Detective Beaumont had a hunch that a thief was stealing these items.`,
					}
				]
			}
		} else if (this.studentLevel === 's3') {
			if (this.parent.subType === "oralVocab") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Passion",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Passion.png"
						},
						{
							name: "Confidence",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Confidence.png"
						},
						{
							name: "Goals",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Goals.png"
						},
						{
							name: "Belief",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Belief.png"
						},
						{
							name: "Overcome",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Overcome.png"
						},
						// {
						// 	name: "Creativity",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Creativity.png"
						// },
						// {
						// 	name: "Improvement",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Improvement.png"
						// },
						// {
						// 	name: "Effort",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Effort.png"
						// },
						// {
						// 	name: "Patience",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Patience.png"
						// },
						// {
						// 	name: "Brave",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Brave.png"
						// },
						// {
						// 	name: "Opportunities",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Opportunities.png"
						// },
						// {
						// 	name: "Positive",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Positive.png"
						// },
						// {
						// 	name: "Responsible",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Responsible.png"
						// },
						// {
						// 	name: "Desire",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Desire.png"
						// },
						// {
						// 	name: "Learn",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/S3/Learn.png"
						// },
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "Persistence",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Persistence.png"
						},
						{
							name: "Risk-taking",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Risk-taking.png"
						},
						{
							name: "Resilience",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Resilience.png"
						},
						{
							name: "Optimistic",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Optimistic.png"
						},
						{
							name: "Self-discipline",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Self-discipline.png"
						},
						// {
						// 	name: "Satisfaction",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Satisfaction.png"
						// },
						// {
						// 	name: "Dedicate",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Dedicate.png"
						// },
						// {
						// 	name: "Prioritize",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Prioritize.png"
						// },
						// {
						// 	name: "Commitment",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Commitment.png"
						// },
						// {
						// 	name: "Leadership",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Leadership.png"
						// },
						// {
						// 	name: "Problem-solving",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Problem-solving.png"
						// },
						// {
						// 	name: "Profession",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Profession.png"
						// },
						// {
						// 	name: "Master",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Master.png"
						// },
						// {
						// 	name: "Accomplish",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Accomplish.png"
						// },
						// {
						// 	name: "Milestone",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/S3/Milestone.png"
						// },
					]
				}
			} else if (this.parent.subType === "oralSentence") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "You need to build up your confidence."
						},
						{
							name: "Ms. Ho's lessons aren't very creative."
						},
						{
							name: "I will brave the sea and face its harm."
						},
						{
							name: "Stay positive, your opportunity will come soon."
						},
						{
							name: "She has vowed to overcome her weakness and keep improving."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "You have shown great resilience by standing up to your bullies."
						},
						{
							name: "It's difficult to stay optimistic in the face of impending danger."
						},
						{
							name: "I take pride in my profession."
						},
						{
							name: "This is an important milestone in his career."
						},
						{
							name: "It takes a lot of discipline and commitment to master a new skill."
						}
					]
				}
			} else if (this.parent.subType === "oralFillIn") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "It is my (______) that whatever doesn't kill you makes you stronger.",
							reading: "It is my belief that whatever doesn't kill you makes you stronger.",
							answer: "belief"
						},
						{
							name: "A mother would (_______) her children's well-being over her own.",
							reading: "A mother would prioritize her children's well-being over her own.",
							answer: "prioritize"
						},
						{
							name: "The mayor has (_________) a lot of time and money to build a dam.",
							reading: "The mayor has dedicated a lot of time and money to build a dam.",
							answer: "dedicated"
						},
						{
							name: "It brings me great (________) when a plan comes together.",
							reading: "It brings me great satisfaction when a plan comes together.",
							answer: "satisfaction"
						},
						{
							name: "I have no (______) to be the leader of a country.",
							reading: "I have no desire to be the leader of a country.",
							answer: "desire"
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "It took forever, but I finally (________) my goals.",
							reading: "It took forever, but I finally accomplished my goals.",
							answer: "accomplished"
						},
						{
							name: "Don't be afraid of (___________), as long as the benefits outweigh it.",
							reading: "Don't be afraid of risk-taking, as long as the benefits outweigh it.",
							answer: "risk-taking"
						},
						{
							name: "Donald wants to be in charge, but he has no (_________) skills.",
							reading: "Donald wants to be in charge, but he has no (_________) skills.",
							answer: "leadership"
						},
						{
							name: "How can Mark solve a Rubik's Cube when he has no (__________) skills?",
							reading: "How can Mark solve a Rubik's Cube when he has no problem-solving skills?",
							answer: "problem-solving"
						},
						{
							name: "Lindsay never (______) and tends to give up easily.",
							reading: "Lindsay never persists and tends to give up easily.",
							answer: "persists"
						},
					]
				}
			} else if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					this.parent.defaultQuestion = [
						{
							name: "Was that man brave for driving over the speed limit without wearing a seatbelt?",
							choice: ["Yes! A brave man is not afraid of anything!", "No, that wasn't brave, that was stupid."],
							answer: "No, that wasn't brave, that was stupid."
						},
						{
							name: "Please be patient. The doctor will see you soon.",
							choice: ["Alright, I guess I will have to wait then.", "It hurts too much! Let me see the doctor now!"],
							answer: "Alright, I guess I will have to wait then."
						},
						{
							name: "Can you be responsible for your sister?",
							choice: ["Yes, I will look after her well-being.", "No, don't blame me if she hurts herself."],
							answer: "Yes, I will look after her well-being."
						},
						{
							name: "Kevin here is of the belief that the Earth is flat.",
							choice: ["He is right! Have you actually seen that the Earth is round?", "He will learn otherwise if he opens his mind and thinks rationally."],
							answer: "He will learn otherwise if he opens his mind and thinks rationally."
						},
						{
							name: "My son is very creative, but he takes things too far.",
							choice: ["Punish him! Make him conform!", "Let him explore, but also teach him about proper boundaries."],
							answer: "Let him explore, but also teach him about proper boundaries."
						}
					]
				} else {
					this.parent.defaultQuestion = [
						{
							name: "I think I dedicated too much time to my work commitments.",
							choice: ["Nonsense! Keep working harder and harder!", "Quit your job, then. Relax and have fun all year round!", "You should try and find a balance between your work and life.", "That's because you're a workaholic, and I'm filing for divorce."],
							answer: "You should try and find a balance between your work and life."
						},
						{
							name: "How does she stay so optimistic all the time?",
							choice: ["Because she's a featherbrain and an airhead.", "It's because she sees the best in people and every situation.", "Maybe she's on drugs and is high all the time.", "That's because you're a downer yourself."],
							answer: "It's because she sees the best in people and every situation."
						},
						{
							name: "What do you feel when you finally accomplish something?",
							choice: ["I feel a tired sort of satisfaction.", "Nothing, really. I feel empty.", "I never accomplished anything, so I don't know.", "I feel like I want to smash and destroy something."],
							answer: "I feel a tired sort of satisfaction."
						},
						{
							name: "Do you think Joe is a good leader?",
							choice: ["Yes, even though I'm not sure who Joe is.", "Yes, he can do no wrong and everything he says is scripture.", "No, he is so ugly that I just want to punch his face.", "No, he doesn't have good leadership and communication skills."],
							answer: "No, he doesn't have good leadership and communication skills."
						},
						{
							name: "Which profession requires a lot of problem-solving?",
							choice: ["Engineering. They need to solve a lot of architectural problems.", "Taxi drivers. They need to figure out how to drive a car.", "Waiters. They need to deal with rude customers.", "Fashion designers. They need to create new clothes."],
							answer: "Engineering. They need to solve a lot of architectural problems."
						}
					]
				}
			} else if (this.parent.subType === "oralParagraph") {
				this.parent.defaultQuestion = [
					{
						name: `You are the most famous baseball legend in history.`,
					},
					{
						name: `In addition to being a great baseball player, you broke the colour barrier in American sports as the first black baseball player on a major league team.`,
					},
					{
						name: `Living in a time of segregation, it must have been frustrating to be denied a position on a major league team because of the colour of your skin.`,
					},
					{
						name: `Only white players were allowed to play on the well-known teams.`,
					},
					{
						name: `Black players had to play on separate teams that paid their players a lot less than those on the white teams.`,
					}
				]
			}
		}
		this.parent.totalPageNumber = this.parent.defaultQuestion.length;
	}
}