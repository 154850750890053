import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AlertModule } from './sharedModule/alertModule/alert.module';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingModule } from './sharedModule/loadingModule/loading.module';
import { FormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { ProgressBoxModule } from './sharedModule/progressBoxModule/progressBox.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuillModule } from 'ngx-quill';
import { Alert2Module } from './sharedModule/alert2Module/alert2.module';
import { LanguageFileSetting, TranslateModuleLoader } from './common/TranslateModuleLoader';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppStatusModule } from './sharedModule/appStatusModule/AppStatus.module';
import { ThemeService } from './service/theme.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	//suppressScrollX: true
};

export function HttpLoaderFactory(http: HttpClient) {
	let ts = +new Date();
	return new TranslateHttpLoader(http, "./assets/", ".json?" + ts);
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [ 
    	MbscModule,  
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		LoadingModule,
		AlertModule,
		Alert2Module,
		ProgressBoxModule,
		DeviceDetectorModule.forRoot(),
		TranslateModuleLoader.forRoot(),
		AngularDraggableModule,
		FontAwesomeModule, ToastModule,
		QuillModule.forRoot(),
		AppStatusModule,
		HammerModule
	],
	providers: [
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{
			provide: LanguageFileSetting, 
			useValue: {
				basePath:"./assets/langs/",
				languages:["home-screen", "home-screen-news"]
			}
		}, MessageService, ThemeService
	],
	bootstrap: [AppComponent],
	entryComponents: []
})
export class AppModule { }
