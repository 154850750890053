export class ObjectUtils {
	static swapKey(obj: any, key1: string, key2: string): void {
		var tmp: any = obj[key1];
		obj[key1] = obj[key2];
		obj[key2] = tmp;
	}
	static merge(data1: any, data2: any) {
		var clone: any = ObjectUtils.clone(data1);
		ObjectUtils.copyTo(data2, clone);
		return clone;
	}

	static copyTo(from: any, to: any): void {
		if (!from) return;
		for (var key in from) {
			to[key] = from[key];
		}
	}


	public static assign(obj: any, path: string, value: any) {
		var key: string;
		var keys: string[] = path.split(".");
		var pointer: any = obj;
		var last: number = keys.length - 1;
		for (var i = 0; i < last; i++) {
			key = keys[i];
			if (pointer == null || typeof (pointer) != "object") return false;
			if (pointer.hasOwnProperty(key) == false) pointer[key] = {};
			pointer = pointer[key];
		}
		if (pointer == null || typeof (pointer) != "object") return false;
		key = keys[last];
		pointer[key] = value;
		return true;
	}

	public static navigate(obj: any, path: string, defaultValue: any = null): any {
		var pointer = obj;
		var parts = path.split(".");
		var len = parts.length;
		for (var i = 0; i < len; i++) {
			var key: string = parts[i];
			if (pointer && typeof pointer == "object" && pointer.hasOwnProperty(key)) {
				pointer = pointer[key];
			} else {
				return defaultValue;
			}
		}
		return pointer;
	}

	public static clone(data: any): any {
		var tmp = {};
		ObjectUtils.copy(data, tmp);
		return tmp;
	}

	public static copy(from: any, to: any): void {
		for (var key in from) {
			to[key] = from[key];
		}
	}

	public static match(element: any, matchObject: any): boolean {
		if (typeof matchObject == "function") {
			return matchObject.call(null, element);
		} else {
			return ObjectUtils.matchElement(element, matchObject);
		}
	}

	public static matchElement(element: any, matchObject: any): boolean {
		for (var key in matchObject) {
			if (element[key] !== matchObject[key]) return false;
		}
		return true;
	}

	public static fillObjectDefaultValue(target: any, defVal: any, overwrite: boolean = false): void {
		for (var key in defVal) {
			if (!target.hasOwnProperty(key) || overwrite) {
				// 無這個 property 就要加給他
				if (ObjectUtils.isObject(defVal[key])) {
					target[key] = {};
					ObjectUtils.fillObjectDefaultValue(target[key], defVal[key], overwrite);
				} else if (Array.isArray(defVal[key])) {
					// 暫時應該未有需要
					//target[key] = [];
				} else {
					// 值資料
					target[key] = defVal[key];
				}
			} else if (ObjectUtils.isObject(defVal[key])) {
				ObjectUtils.fillObjectDefaultValue(target[key], defVal[key], overwrite);
			}
		}
	}

	public static isObject(val: any): boolean {
		return val != null && typeof val === 'object' && Array.isArray(val) === false;
	}

	public static getObjectValueByPath(obj: any, path: string): any {
		var keys: string[] = path.split(".");
		while (keys.length > 0) {
			var k: string = keys.shift();
			if (obj.hasOwnProperty(k)) {
				if (keys.length > 0)
					obj = obj[k];
				else
					return obj[k];
			} else {
				return null;
			}
		}
		return null;
	}

	public static setObjectValueByPath(obj: any, path: string, val: any): boolean {
		var keys: string[] = path.split(".");
		while (keys.length > 0) {
			var k: string = keys.shift();
			if (obj.hasOwnProperty(k)) {
				if (keys.length > 0)
					obj = obj[k];
				else {
					obj[k] = val;
					return true;
				}
			} else {
				return false;
			}
		}
		return false;
	}

	static getPropertyFromNestedObj(data: object, condition: Function, propertyName: string) {
		const results = [];

		// 递归函数用于遍历对象和数组
		function search(obj) {
			if (Array.isArray(obj)) {
				// 如果是数组，逐一检查每个元素
				for (const item of obj) {
					search(item);
				}
			} else if (typeof obj === 'object' && obj !== null) {
				// 如果是对象，检查条件
				if (condition(obj)) {
					// 如果符合条件，获取指定属性
					results.push(obj[propertyName]);
				}

				// 遍历对象的每个属性
				for (const key in obj) {
					if (obj.hasOwnProperty(key)) {
						search(obj[key]); // 递归检查每个属性
					}
				}
			}
		}

		// 开始搜索
		search(data);

		return results; // 返回所有符合条件的属性值
	}

	static extractObjectsWithProperty(nestedObject, callback) {
		let result = []; 
		
		const recursiveSearch = (obj)=>{
			if (obj && Array.isArray(obj)){
				for(let i=0; i < obj.length;i++){
					recursiveSearch(obj[i]);
				}
			} else if (obj && typeof obj === 'object') { 
				if (callback(obj)) { 
					result.push(obj); 
				} 
				for (let key in obj) { 
					recursiveSearch(obj[key]); 
				} 
			} 
		}; 
		
		recursiveSearch(nestedObject); 
		return result;
	}
	
}
